<mat-toolbar class="companiesbg">

    <div>
        <button mat-icon-button *ngIf="!pageConfig.edit" (click)="goBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <a mat-icon-button (click)="displayItem()" *ngIf="pageConfig.edit">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <div class="title">
            <mat-icon>{{GLOBAL.ICONS.WORK}}</mat-icon>
            <ng-container *ngIf="!new">
                O{{item.values.ref}}:
                {{item.values.displayname}}
            </ng-container>
            <ng-container *ngIf="new">New Company</ng-container>
        </div>
    </div>
    <div>
        <profile></profile>
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="logs()">
                <mat-icon>{{GLOBAL.ICONS.LOGS}}</mat-icon>
                <span>Logs</span>
            </button>
        </mat-menu>
    </div>
</mat-toolbar>
<div class="toolbartop"></div>


<!------------------------------ READ MODE  ----------------------------------------->

<div class="mdc-layout-grid companyPage" *ngIf="!pageConfig.edit">
    <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell--span-4">
            <div>
                <mat-card class="full">
                    <mat-list *ngIf="pageConfig.loaded">
                        <mat-list-item>
                            <mat-icon matListIcon>
                                {{GLOBAL.ICONS.CONTACT}}</mat-icon>
                            <h3 matLine> {{item.values.displayname}} </h3>
                        </mat-list-item>
                        <ng-container *ngIf="item.values.tels">
                            <mat-list-item *ngFor="let tel of item.values.tels | toarray">
                                <mat-icon matListIcon>{{GLOBAL.ICONS.PHONE}}</mat-icon>
                                <h3 matLine>{{tel.number}}</h3>
                            </mat-list-item>
                        </ng-container>
                        <ng-container *ngIf="item.values.emails">
                            <mat-list-item *ngFor="let email of item.values.emails | toarray">
                                <mat-icon matListIcon>{{GLOBAL.ICONS.EMAIL}}</mat-icon>
                                <h3 matLine>{{email.email}}</h3>
                            </mat-list-item>
                        </ng-container>
                    </mat-list>
                </mat-card>
            </div>
        </div>



        <!------------------------------ EMPLOYEES  ----------------------------------------->

        <div class="mdc-layout-grid__cell--span-8" *ngIf="item.values.synaps?.employeesArray">
            <app-table class="main" [item]="contact" [items]="item.values.synaps?.employeesArray"
                (clickFn)="goItem('contacts', $event)" style="height: 400px;" tableKey="employees">
            </app-table>
        </div>



        <!------------------------------ PROMOTER  ----------------------------------------->
        <div class="mdc-layout-grid__cell--span-12" *ngIf="item.values.synaps?.promoterArray">
            <app-table class="main" [item]="residence" [items]="item.values.synaps?.promoterArray"
                (clickFn)="goItem('residences', $event)" style="height: 400px;" tableKey="promoter">
            </app-table>
        </div>

    </div>
</div>


<!------------------------------ EDIT MODE  ----------------------------------------->


<div class="contactPage" *ngIf="pageConfig.edit">

    <div class="mdc-layout-grid">
        <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell--span-6">
                <form-builder [item]="item" [values]="item.values" section="main" [edit]="true"></form-builder>

            </div>
        </div>
    </div>

</div>


<mat-spinner [strokeWidth]="5" class="fixed" *ngIf="!(pageConfig.loaded || new)"></mat-spinner>

<!------------------------------ BUTTONS  ----------------------------------------->

<!-- NEW -->
<button *ngIf="new" mat-fab class="bottom-right pfixed" [disabled]="!item.valid || !item.modified || item.saving"
    (click)="saveNewItem()">
    <mat-icon matListIcon>check</mat-icon>
</button>
<!-- EDIT -->
<button *ngIf="!new && !pageConfig.edit" mat-fab class="bottom-right pfixed" (click)="editItem()">
    <mat-icon matListIcon>edit</mat-icon>
</button>
<!-- SAVE -->
<button *ngIf="!new && pageConfig.edit" mat-fab class="bottom-right pfixed"
    [disabled]="!item.valid  || !item.modified || item.saving" (click)="saveItem(false)">
    <mat-icon matListIcon>check</mat-icon>
</button>
<!-- CANCEL -->
<button mat-fab *ngIf="!new && pageConfig.edit" class="bottom-right pfixed p2"
    [disabled]="item.saving" color="basic" (click)="cancelEdit(true)">
    <mat-icon matListIcon>close</mat-icon>
</button>

<!-- DELETE -->
<button *ngIf="!new && !pageConfig.edit && isRole('admin')" mat-fab class="bottom-left pfixed" color="basic"
    (click)="db.deleteItem(item)">
    <mat-icon matListIcon>delete</mat-icon>
</button>