import { Injectable } from '@angular/core';
import { MainService } from '../../common/services/main/main.service';
import { AuthService } from '../../common/services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Residence } from './residence.model';

@Injectable({
  providedIn: 'root'
})
export class ResidenceService extends MainService {

  public refMode = true;
  
  constructor(
    public auth: AuthService,
    public afs: AngularFirestore,
  ) {
    super(auth, afs, 'residence', 'residences', Residence);

    this.itemsPageConfig.mapParams = {
      'bounds': null,
      'lat': -20.2674,
      'long': 57.5766,
      'zoom': 10,
    }
    
  }



}