<div mat-dialog-content>
    <div class="mdc-layout-grid" *ngIf="pageConfig.loaded">
        <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell--span-12">
                <mandate-hcard [mandate]="item.values.synaps.mandateMatchingsObjList" class="cursor" (click)="goMandate()" ></mandate-hcard>
            </div>
            <div class="mdc-layout-grid__cell--span-12">
                <form-builder [item]="item" [values]="item.values" [edit]="true"></form-builder>
            </div>
        </div>
        <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell--span-6">
                <form-builder [item]="item" [values]="item.values" [edit]="true" section="pos"></form-builder>
            </div>
            <div class="mdc-layout-grid__cell--span-6">
                <form-builder [item]="item" [values]="item.values" [edit]="true" section="neg"></form-builder>
            </div>
        </div>
    </div>
    <mat-spinner *ngIf="!pageConfig.loaded" [strokeWidth]="5"></mat-spinner>
</div>

<div mat-dialog-actions>
    <div>
        <ng-container *ngIf="item.values.synaps.mandateMatchingsObj?.status === 'active'">
            <ng-container *ngIf="item.values.match">
                <button mat-icon-button class="warm" (click)="match(false)"><mat-icon>thumb_down</mat-icon></button>
                <ng-container *ngIf="data.project.values.status === 'active'">
                    <button mat-raised-button color="accent" (click)="createActionSign('offer')"
                        cdkFocusInitial>Offer</button>
                    <button *ngIf="data.project.values.type === 'rental'" mat-raised-button color="accent"
                        (click)="createActionSign('signLease')" cdkFocusInitial>Sign Lease</button>
                    <button *ngIf="data.project.values.type === 'sale'" mat-raised-button color="accent"
                        (click)="createActionSign('signAgree')" cdkFocusInitial>Sign Agreement</button>
                    <button *ngIf="data.project.values.type === 'sale'" mat-raised-button color="accent"
                        (click)="createActionSign('signDeed')" cdkFocusInitial>Sign Deed</button>
                </ng-container>
            </ng-container>
            <button mat-icon-button *ngIf="!item.values.match" class="active"
                (click)="match(true)"><mat-icon>thumb_up</mat-icon></button>
        </ng-container>
    </div>
    <div>
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="primary" (click)="save()" [disabled]="!item.valid || !item.modified"
            cdkFocusInitial>Ok</button>
    </div>
</div>