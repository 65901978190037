import { Component, OnInit, Input } from '@angular/core';
import { Langs, Field } from '../../services/main/main.model';
import { SettingService } from 'src/common/services/setting/setting.service';

@Component({
  selector: 'field-display',
  templateUrl: './field-display.component.html',
  styleUrls: ['./field-display.component.scss']
})
export class FieldDisplayComponent implements OnInit {


  public langs = Langs;
  // private _item: MainItem;
  private _field: Field;

  @Input()
  public rootValues: any;
  
  @Input()
  public set field(field: Field) {
    this._field = field;
  }
  public get field() {
    return this._field;
  }

  @Input()
  public section: string;

  // @Input()
  // public set item(item: MainItem) {
  //   this._item = item;
  // }
  // public get item() {
  //   return this._item;
  // }

  @Input()
  public values: any;

  constructor(
    public setting: SettingService,
  ) { }

  ngOnInit(): void {
  }


}

