import { Component, Input } from '@angular/core';


@Component({
  selector: 'sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss']
})
export class SignComponent {

  @Input()
  action: any;

  @Input()
  moduleType: string;

  constructor(
  ) {
  }




}
