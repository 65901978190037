<mat-card class="unit" *ngIf="values">
    <!-- LIST MODE -->
    <mat-card-header *ngIf="list">
      <div mat-card-avatar>
        <mat-icon>domain</mat-icon>
      </div>
      <mat-card-title>{{values.name}}</mat-card-title>
      <mat-card-subtitle *ngIf="values.promoter">
        {{values.promoter}}
      </mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image [src]="values.photo | thumb:'400'">
    <mat-list class="h">
      <mat-list-item>
        <mat-icon matListIcon>{{GLOBAL.ICONS.UNIT}}</mat-icon>
        <h3 matLine>{{values.units}}/{{values.totalUnits}} </h3>
      </mat-list-item>
    </mat-list>
    <mat-list class="h j">
      <mat-list-item>
        <h3 matLine>R{{values.ref}}</h3>
      </mat-list-item>
    </mat-list>
  </mat-card>