<mat-card class="cursor" *ngIf="item.values.synaps?.unitMandatesObj?.alerts"
    (click)="goItem('units', item.values.synaps.unitMandatesObj)">
    <mat-card-content>
        <mat-list>
            <mat-list-item>
                <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.ALERT}}</mat-icon>
                <div mat-line>{{item.values.synaps?.unitMandatesObj?.alerts}} Missing Parameters
                </div>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="!edit">
    <mat-card-content>
        <mat-list>
            <mat-list-item *ngIf="syncPortal.loadedMandate && syncPortal.mandate">
                <mat-icon matListIcon color="accent">
                    {{GLOBAL.ICONS.INFO}}</mat-icon>
                <h3 matLine>Pending Next Sync</h3>
            </mat-list-item>
        </mat-list>
        <mat-list *ngIf="unitPortals">
            <mat-list-item *ngIf="!item.values.ad">
                <mat-icon matListIcon color="accent">
                    {{GLOBAL.ICONS.OFFLINE}}</mat-icon>
                <div mat-line>Not Advertise</div>
            </mat-list-item>
            <mat-list-item *ngIf="!item.values.priceVisible && item.values.ad">
                <mat-icon matListIcon class="hot">
                    {{GLOBAL.ICONS.HIDEPRICE}}</mat-icon>
                <div mat-line>Hidden Price</div>
            </mat-list-item>
            <mat-list-item *ngIf="!unitPortals.length && item.values.ad">
                <mat-icon matListIcon class="hot">
                    {{GLOBAL.ICONS.MARKETING}}</mat-icon>
                <div mat-line>No Web Synchronisation Yet</div>
            </mat-list-item>
            <a mat-list-item *ngFor="let portal of unitPortals" target="_blank" href={{portal.url}}
                [matTooltip]="portal.errors ? portal.errors : (portal.boost ? ('Next Boost: ' + portal.boost.toDate() + ' - ' + portal.boost.boostType) : '')">
                <mat-icon matListIcon [ngClass]="{active:portal.online, hot: portal.errors}">
                    {{GLOBAL.ICONS.MARKETING}}</mat-icon>
                <div mat-line>
                    {{portal.name | param:'portals'}}
                    <span *ngIf="portal.nb">({{portal.nb}})</span>
                    <span *ngIf="portal.errors"> -
                        Error
                    </span>
                </div>
                <div mat-line><i> {{portal.updated?.toDate() | date:'dd MMM yyyy HH:mm'}}</i>
                </div>
            </a>
        </mat-list>
    </mat-card-content>
    <button *ngIf="isRealtor()" color="primary" mat-icon-button class="top-right" (click)="editCard()">
        <mat-icon>{{GLOBAL.ICONS.EDIT}}</mat-icon>
    </button>
</mat-card>

<div style="position: relative" *ngIf="edit">

    <!-- <form-builder [item]="item" [values]="item.values" section="ad"
        [edit]="true"></form-builder> -->
    <form-builder [item]="item" [values]="item.values" section="portals" [edit]="true"></form-builder>
    <!-- SAVE -->
    <button mat-fab class="bottom-right"
        [disabled]="!item.valid || !item.modified || item.saving || !!(item.values.ad && item.values.synaps?.unitMandatesObj?.alerts)"
        (click)="saveItem()">
        <mat-icon matListIcon>check</mat-icon>
    </button>
    <!-- CANCEL -->
    <button mat-fab class="bottom-right p2" color="basic" (click)="cancelEdit()">
        <mat-icon matListIcon>close</mat-icon>
    </button>
</div>