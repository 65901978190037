import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mandateIconColor'
})
export class MandateIconColorPipe implements PipeTransform {

  transform(value: any): string {
    if (value) {
      if (value.status == 'active') {
        return value.temperature;
      }
      if (value.status == 'pending') {
        return 'warm';
      }
      if (value.status == 'deal') {
        return 'active';
      }
    }
    return '';
  }

}
