import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/common/services/auth/auth.service';
import { MainService } from 'src/common/services/main/main.service';
import { Blog } from './blog.model';

@Injectable({
  providedIn: 'root'
})
export class BlogService extends MainService {

  public refMode = true;

  constructor(
    public auth: AuthService,
    public afs: AngularFirestore,
  ) {
    super(auth, afs, 'blog', 'blogs', Blog);
  }


}
