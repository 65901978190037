import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SelectService } from 'src/common/services/select/select.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { ActionEditComponent } from 'src/components/actions-list/action-edit/action-edit.component';
import { ActionService } from 'src/services/action/action.service';

@Component({
  selector: 'project-discovery',
  templateUrl: './discovery.component.html',
  styleUrls: ['./discovery.component.scss']
})
export class DiscoveryComponent extends ActionEditComponent {

  constructor(protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ActionService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public dialog: MatDialog,
    public backURL: BackurlService,
  ) {
    super(_cd, setting, db, select, route, router, dialog, backURL);
  }

  protected _pushModuleItemData() {
    this.moduleItem.modifyData('status', 'active');
    this.moduleItem.modifyData('temperature', this.item.values.data.temperature);
    this.moduleItem.modifyData('dateNeed', this.item.values.data.dateNeed);
    this.moduleItem.modifyData('sectors', this.item.values.data.sectors);
    this.moduleItem.modifyData('unitTypes', this.item.values.data.unitTypes);
    this.moduleItem.modifyData('conditions', this.item.values.data.conditions);
    this.moduleItem.modifyData('minBedrooms', this.item.values.data.minBedrooms);
    this.moduleItem.modifyData('cyBudget', this.item.values.data.cyBudget);
    this.moduleItem.modifyData('maxBudget', this.item.values.data.maxBudget);
    this.moduleItem.modifyData('foreigner', !!this.item.values.data.foreigner);
    this.moduleItem.modifyData('residentPermit', !!this.item.values.data.residentPermit);
  }

}
