import * as firebase from 'firebase/app';
import { SettingService } from '../setting/setting.service';
import { FileRecord } from '../upload-file/upload-file-model';
import { createList } from '../../../../functions/src/shared/list';
import createAlias from '../../../../functions/src/shared/alias';
import { Synap, Synaps } from 'functions/src/shared/synaps-config';
import * as moment from 'moment';
import { mergeDeep } from 'functions/src/shared/tools';
import { GlobalConstants } from 'functions/src/shared/global-constants';


export interface Lang {
    fr: string,
    en: string;
    de: string;
    ru: string;
}


const GLOBAL = new GlobalConstants();

export const Langs = GLOBAL.LANGS;

export interface Tel {
    country: string,
    number: string,
    mobile: boolean,
}

// EMAIL
export interface Email {
    order: number,
    email: string,
    enews: boolean,
}

export interface Address {
    unitNb?: string,
    streetNb?: string,
    street?: string,
    city?: string,
    eCity?: number,
    eCityName?: string,
    postcode?: string,
    district?: string,
    country?: string,
    gps?: firebase.firestore.GeoPoint,
    eGps?: firebase.firestore.GeoPoint,
    sectors?: string[],
    sector?: string,
    note?: string,
}


export interface AgencyFee {
    type?: string,
    price?: number,
    cy?: string,
    comPerc?: number,
    comFix?: number,
    comMin?: number,
    agencyCA?: number,
    agencyTotal?: number,
    agencyPayment?: number,
    realtorCom?: number,
    realtorPerc?: number,
    referralPerc?: number,
    referralCom?: number,
    broker?: string,
    brokerPerc?: number,
    brokerCom?: number,
}

export class AgencyFeeFields {
    type = new Field({
        label: 'Agency Fee', input: 'select', inputOptions: { param: 'agencyFeeTypes' },
        validations: { required: true },
        default: 'std',
    });
    price = new Field({
        label: 'Transaction Amount', input: 'number',
        show: () => false
    });
    cy = new Field({
        label: 'Currency', input: 'select', inputOptions: { param: 'cies' }, default: 'mur',
        show: () => false
    });
    comPerc = new Field({
        input: 'number', label: 'Agency Fee (%)',
        validations: { required: true },
        show: (data: any) => data && data.agencyFee && data.agencyFee.type === 'otherPerc',
    });
    comFix = new Field({
        input: 'number', label: 'Fix Amount + VAT',
        validations: { required: true },
        show: (data: any) => data && data.agencyFee && data.agencyFee.type === 'fix',
    });
    comMin = new Field({
        input: 'number', label: 'Min Amount + VAT',
        show: (data: any) => data && data.agencyFee && data.agencyFee.type === 'std',
    });
    agencyCA = new Field({
        input: 'number', label: 'Agency Fee (Rs Exc. VAT)',
        show: (data, subdata, setting) => { return setting.auth.isOneOfRoles(['admin', 'accountant']) }
    });
    agencyTotal = new Field({
        input: 'number', label: 'Agency Fee (Rs)',
        show: () => false
    });
    agencyPayment = new Field({
        input: 'number', label: 'Agency Fee (Rs)',
        show: () => false
    });
    realtorCom = new Field({
        input: 'number', label: 'Agent Commission (Rs)',
        show: () => false
    });
    realtorPerc = new Field({
        input: 'number', label: 'Agent % Commission',
        show: () => false
    });

}


export interface Alert {
    type: string,
    code: string
}

export class AddressFields {
    eCity = new Field({
        label: 'City (Web)',
        input: 'selectAuto',
        inputOptions: { param: 'cities' },
        listOptions: { filter: true, sort: true },

    });
    eCityName = new Field({
        input: 'auto', inputOptions: { param: 'cities' },

        autoFn: {
            toFB: (values, setting) => {
                return setting.getParamValueName('cities', values.eCity);
            }
        },

    });
    unitNb = new Field({ label: 'Unit Number' });
    streetNb = new Field({ label: 'Street Number' });
    street = new Field({ label: 'Street', show: () => false });
    city = new Field({ label: 'City', show: () => false });
    postcode = new Field({ label: 'Postcode', show: () => false });
    district = new Field({ label: 'Region', show: () => false });
    country = new Field({ label: 'Country', default: 'MU', show: () => false });
    gps = new Field({
        input: 'gps', label: 'GPS',
        show: () => false
    });
    eGps = new Field({ input: 'gps', label: 'GPS (Web)', show: () => false });
    sectors = new Field({
        input: 'arrayValue',
    });
    sector = new Field({ show: () => false });
    note = new Field({ label: 'Note', input: 'textarea' });
}


export class Field {
    key?: string;
    multiLang = false;
    default: any = null;
    filters: { new: boolean, save: boolean } = { new: true, save: true };
    checks: { space: boolean, trim: false } = { space: false, trim: false };
    alias: boolean = false;
    matching: boolean = false;
    input: 'text' | 'textarea' | 'number' | 'select' | 'selectAuto' | 'timestamp' |
        'servertimestamp' | 'date' | 'datetime' | 'boolean' | 'gps' | 'rating' | 'auto' | 'arrayValue' |
        'objectSub' | 'objectSubType' | 'dynSub' = 'text';
    label: string = null;
    inputOptions: { param?: string, multi?: boolean, icon?: boolean, ordered?: boolean, noComa?: boolean } = { multi: false };
    validations: { [name: string]: ((data: any) => boolean) | boolean } = {};
    show: ((data: any, subData?: any, setting?: SettingService) => boolean) = (data: any) => true;
    readOnly: ((data: any) => boolean) = (data: any) => false;
    autoFn: {
        toFB: (data, setting?: SettingService) => any,
        // autofromFB: (data) => any
    };
    sub: { [key: string]: Field }; // Used for Sub Object
    subType: { [type: string]: { [key: string]: Field } }; // Used for Sub Object link to Type field
    dynSub: { [key: string]: Field }; // Used for Sub Object
    deleteSub: { [key: string]: boolean }; // Used for Sub Object
    // listOptions: { sort: boolean, filter: boolean } = { sort: false, filter: false }; // List Field Options
    icon: string = null;

    constructor(values?: any) {
        if (values) {
            mergeDeep(this, values);
        }
    }

}

export class MainItem {

    values: {
        $key: string,
        ref: number,
        created: firebase.firestore.FieldValue,
        createdBy: string,
        updated: firebase.firestore.FieldValue,
        updatedBy: string,
        alerts: Alert[],
        synaps: any,

        [name: string]: any
    } =
        {
            $key: null,
            ref: null,
            created: null,
            createdBy: null,
            updated: null,
            updatedBy: null,
            alerts: [],
            synaps: {},
        }

    list: any;
    alias: any;

    fields: { [key: string]: Field } = {
        $key: new Field({ filters: { save: false } }),
        ref: new Field({ label: 'Ref' }),
        created: new Field({ filters: { new: true, save: false }, input: 'servertimestamp' }),
        createdBy: new Field({ filters: { new: true, save: false } }),
        updated: new Field({ filters: { new: true, save: true }, input: 'servertimestamp' }),
        updatedBy: new Field({ filters: { new: true, save: true } }),
        alerts: new Field({ filters: { new: false, save: false }, input: 'arrayValue' }),
    };
    updatedFields: { [key: string]: Date } = {}

    module: string;

    sections: { [name: string]: { title: string, fields: string[] } } = {};
    tables: { [name: string]: { title: string, columns: { field: string, type: string, label: string, options?: { multi?: boolean, sort?: boolean, filter?: boolean, prefix?: string, fcn?: (e: any, data: any) => any } }[] } } = {};

    validation: any = {};
    valid: boolean = false;
    modify: any = {};
    modified: boolean = false;
    loading: boolean = false;
    saving: boolean = false;
    activeSections: { [name: string]: { valid: boolean, modified: boolean } } = {};

    imageConfig: { path: string, filename: (data?) => string } = { path: 'bin/', filename: () => 'bin' };

    synapConfig?: { [name: string]: Synap };

    constructor(
        module: string,
        fields: { [key: string]: Field },
        sections: { [name: string]: { title: string, fields: string[] } },
        tables: { [name: string]: { title: string, columns: { field: string, type: 'string' | 'number' | 'date' | 'ago' | 'days' | 'icon' | 'user' | 'fcn', label: string, options?: { multi?: boolean, sort?: boolean, filter?: boolean, prefix?: string, suffix?: string, fcn?: (e: any, data: any) => string } }[] } },
        imageConfig: { path: string, filename: (data?: any) => string },
        public setting?: SettingService,
    ) {
        this.module = module;
        Object.assign(this.fields, fields);
        this.sections = sections;
        this.tables = tables;
        this.imageConfig = imageConfig;
        this.synapConfig = new Synaps().dispatch[module];

        // Put default values
        for (const fieldKey in this.fields) {
            this._initObjFieldList(this.values, this.fields, this.validation, this.modify, fieldKey);
        }

    }

    // Init Default Values and Sub Fields
    private _initObjFieldList(values: any, fields: { [key: string]: Field }, validation: any, modify: any, key: string) {

        // Take care of OBJECT 
        if (fields[key].input == 'objectSub') {
            if (!values[key]) { values[key] = {}; }
            validation[key] = {};
            modify[key] = {};
            for (const objFieldKey in fields[key].sub) {
                this._initObjFieldList(values[key], fields[key].sub, validation[key], modify[key], objFieldKey);
            }
        }

        // Take care of OBJECT link to TYPE
        else if (fields[key].input == 'objectSubType') {
            if (!values[key]) { values[key] = {}; }
            validation[key] = {};
            modify[key] = {};
            if (values.type && fields[key].subType[values.type]) {
                for (const objFieldKey in fields[key].subType[values.type]) {
                    this._initObjFieldList(values[key], fields[key].sub, validation[key], modify[key], objFieldKey);
                }
            }
        }

        // Take care of Dynamic Sub Object
        else if (fields[key].input == 'dynSub') {
            if (!values[key]) { values[key] = {}; }
            if (!fields[key].sub) { fields[key].sub = {}; }
            validation[key] = {};
            modify[key] = {};
            fields[key].deleteSub = {};
        }

        // Take care of MULTILANG
        else if (fields[key].multiLang) {
            // For multi lang replace field with field object containing all lang
            const template = Object.assign({}, fields[key], { multiLang: false });
            fields[key] = new Field({ input: 'objectSub', sub: {} });

            for (const lang of Langs) {
                fields[key].sub[lang] = Object.assign({}, template, { label: `${template.label} (${lang})` });
            }

            this._initObjFieldList(values, fields, validation, modify, key);
        }

        // Apply Default Values, taking care of Boolean!
        else if (fields[key].default || fields[key].default === false) {
            validation[key] = true;
            modify[key] = false;
            values[key] = fields[key].default;
        } else {
            validation[key] = true;
            modify[key] = false;
            if (fields[key].input == 'arrayValue') {
                values[key] = [];
            } else {
                values[key] = null;
            }
        }
    }


    //************** CONVERT DATA BETWEEN SYSTEM & FIRESTORE **********************/ 

    public fromFB(FBvalues: any, modifiedOnly = false) {

        if (FBvalues) {
            this._fromFB(this.values, this.fields, FBvalues, this.validation, this.modify, modifiedOnly, FBvalues.updated, FBvalues.updatedFields);
            this.values.synaps = FBvalues.synaps ? FBvalues.synaps : {}; // Put back synaps info
            this.refresh(FBvalues);
        }
    }

    public refresh(FBvalues: any) {
        this._initSynaps(this.values.synaps, this.synapConfig); // Init Synaps Array
        this._fnGetAutoData(); // Get automatique values
        this.list = createList(FBvalues, this.module); // Keep List for easy display
        this.alias = createAlias(FBvalues, this.module); // Keep Alias for easy Synap management
    }

    private _fromFB(values: any, fields: { [key: string]: Field }, FBvalues: any, validation: any, modify: any, modifiedOnly = false, mainUpdate: any, updatedFields: any = null) {
        if (FBvalues) {
            for (const fieldKey in fields) {
                // Check if value in FB and which value has been modified
                // if (FBvalues[fieldKey]) {
                // First check if object
                if (fields[fieldKey].input == 'objectSub') {
                    this._fromFB(values[fieldKey], fields[fieldKey].sub, FBvalues[fieldKey], validation[fieldKey], modify[fieldKey], modifiedOnly, mainUpdate, (modifiedOnly && updatedFields) ? updatedFields[fieldKey] : null);
                }
                // Check if Object link to Type
                else if (fields[fieldKey].input == 'objectSubType') {
                    if (values.type) {
                        this._fromFB(values[fieldKey], fields[fieldKey].subType[values.type], FBvalues[fieldKey], validation[fieldKey], modify[fieldKey], modifiedOnly, mainUpdate, (modifiedOnly && updatedFields) ? updatedFields[fieldKey] : null);
                    }
                }
                // Or if Dynamic object
                else if (fields[fieldKey].input == 'dynSub') {

                    if (!fields[fieldKey].sub)
                        fields[fieldKey].sub = {};
                    if (!values[fieldKey])
                        values[fieldKey] = {};
                    if (FBvalues[fieldKey]) {
                        for (const objKey in FBvalues[fieldKey]) {

                            // Init Field & Default data if not already   
                            if (!values[fieldKey][objKey]) {
                                values[fieldKey][objKey] = {};
                                validation[fieldKey][objKey] = {};
                                modify[fieldKey][objKey] = {};

                                fields[fieldKey].sub[objKey] = new Field({ input: 'objectSub', sub: Object.assign({}, fields[fieldKey].dynSub, { order: new Field({ input: 'number', show: () => { return false } }) }) });

                                for (const objFieldKey in fields[fieldKey].sub[objKey].sub) {
                                    this._initObjFieldList(values[fieldKey][objKey], fields[fieldKey].sub[objKey].sub, validation[fieldKey][objKey], modify[fieldKey][objKey], objFieldKey);
                                }
                            }
                            this._fromFB(values[fieldKey][objKey], fields[fieldKey].sub[objKey].sub, FBvalues[fieldKey][objKey], validation[fieldKey][objKey], modify[fieldKey][objKey], modifiedOnly, mainUpdate, (modifiedOnly && updatedFields && updatedFields[fieldKey]) ? updatedFields[fieldKey][objKey] : null);
                        }
                    }
                }
                else if ((!modifiedOnly || (updatedFields && updatedFields[fieldKey] && mainUpdate && mainUpdate.seconds == updatedFields[fieldKey].seconds))) {
                    // Check if Timestamp... Convert to JS Date
                    if (FBvalues[fieldKey]) {
                        if (fields[fieldKey].input == 'date'
                            || fields[fieldKey].input == 'servertimestamp') {
                            values[fieldKey] = FBvalues[fieldKey].toDate();
                        } else if (fields[fieldKey].input == 'datetime') {
                            values[fieldKey] = this._timeStampToDatetime(FBvalues[fieldKey]);
                        }
                        // Otherwise take value as it is... 
                        else {
                            values[fieldKey] = FBvalues[fieldKey];
                        }
                    }
                    //  else if (fields[fieldKey].default) {
                    //     values[fieldKey] = fields[fieldKey].default;
                    // }
                    else {
                        values[fieldKey] = null;
                    }

                }
            }
        }
    }

    public newDynItem(values: any, validation: any, modify: any, field: Field): string {
        const newKey = Math.random().toString(36).substring(2, 5);

        values[newKey] = {};
        validation[newKey] = {};
        modify[newKey] = {};

        field.sub[newKey] = new Field({ input: 'objectSub', sub: Object.assign({}, field.dynSub, { order: new Field({ input: 'number', show: () => { return false } }) }) });

        for (const objFieldKey in field.sub[newKey].sub) {
            this._initObjFieldList(values[newKey], field.sub[newKey].sub, validation[newKey], modify[newKey], objFieldKey);
        }

        return newKey;
    }

    public removeDynItem(values: any, validation: any, modify: any, fields: Field, deleteKey: string) {

        delete values[deleteKey];
        delete validation[deleteKey];
        delete modify[deleteKey];

        fields.deleteSub[deleteKey] = true;
        delete fields.sub[deleteKey];
    }

    public toFB(filter: string, specificfieldsOnly: { [fieldKey: string]: boolean } = null): any {
        let FBvalues: any = {
            updatedFields: {},
            listUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        };

        let modifyOnly = true;
        if (filter === 'new') {
            modifyOnly = false;
            // Keep pre-saved Synaps if new
            if (this.values.synaps) {
                FBvalues.synaps = this.values.synaps;
                this._cleanSynaps(FBvalues.synaps);
            }
        }
        this._fnSaveAutoData();
        this._toFB(this.values, this.fields, modifyOnly, this.modify, FBvalues, FBvalues.updatedFields, filter, specificfieldsOnly);

        // Manage Alerts

        this._checkAlerts(this.values, FBvalues);


        // Give Synap Update path if Alias has been modified
        // if (aliasModified.modified) {
        //     FBvalues.syncSynaps = true;
        // }

        // Manage List for quick List Access
        // if (filter == 'list') {
        //     const flatObj = {};
        //     this._flatObj(flatObj, '', values, this.fields);
        //     values = flatObj;
        // }

        return FBvalues;
    }

    private _toFB(item: any, fields: { [key: string]: Field }, modifyOnly, modify: any, values: any, updatedFields: any, filter: string, specificfieldsOnly: { [fieldKey: string]: boolean } = null) {

        for (const fieldKey in fields) {

            // Check filter && If specific Fields Only
            if (fields[fieldKey].filters[filter] && (!specificfieldsOnly || (specificfieldsOnly && specificfieldsOnly[fieldKey]))) {

                // Check if Object 
                if (fields[fieldKey].input == 'objectSub' || fields[fieldKey].input == 'objectSubType' || fields[fieldKey].input == 'dynSub') {

                    values[fieldKey] = {};
                    updatedFields[fieldKey] = {};

                    // Manage Dyn Object Deleted
                    if (fields[fieldKey].deleteSub) {
                        for (const deleteKey in fields[fieldKey].deleteSub) {
                            values[fieldKey][deleteKey] = firebase.firestore.FieldValue.delete();
                            updatedFields[fieldKey][deleteKey] = firebase.firestore.FieldValue.delete();
                        }
                    }

                    this._toFB(item[fieldKey], fields[fieldKey].input == 'objectSubType' ? fields[fieldKey].subType[item.type] : fields[fieldKey].sub, modifyOnly, modify[fieldKey], values[fieldKey], updatedFields[fieldKey], filter);
                    if (Object.keys(values[fieldKey]).length === 0 /*&& fields[fieldKey].input !== 'objectSubType'*/) { // Keep empty object for objectSubType in order to fill it... in _fromFB function
                        delete values[fieldKey];
                        delete updatedFields[fieldKey];
                    }
                }
                // Check if Automatic value
                else if (fields[fieldKey].input == 'auto') {
                    values[fieldKey] = fields[fieldKey].autoFn.toFB(item, this.setting);
                    item[fieldKey] = values[fieldKey];
                }

                // Check if TIMESTAMP
                else if (fields[fieldKey].input == 'servertimestamp') {
                    values[fieldKey] = firebase.firestore.FieldValue.serverTimestamp();
                }

                // Check if value exist , or is a Boolean (FALSE)                
                else if ((!modifyOnly || modify[fieldKey])) {
                    if ((item[fieldKey] || item[fieldKey] === false || item[fieldKey] === null)) {
                        // Check if DATE
                        if (fields[fieldKey].input == 'date') {
                            values[fieldKey] = item[fieldKey] ? firebase.firestore.Timestamp.fromDate(moment.isMoment(item[fieldKey]) ? item[fieldKey].toDate() : item[fieldKey]) : null;
                        } else if (fields[fieldKey].input == 'datetime') {
                            values[fieldKey] = item[fieldKey] ? this._datetimeToTimeStamp(item[fieldKey]) : null;
                        } else {
                            // Do last data check
                            if (fields[fieldKey].checks.space) { // Remove Spaces
                                item[fieldKey] = item[fieldKey] ? item[fieldKey].replace(/\s/g, '') : null;
                            } else if (fields[fieldKey].checks.trim) { // Trim Spaces from begining and end
                                item[fieldKey] = item[fieldKey] ? item[fieldKey].trim() : null;
                            }

                            values[fieldKey] = item[fieldKey];
                        }
                        // Last take the value as it is...
                        // Update Field Modified Date
                        updatedFields[fieldKey] = firebase.firestore.FieldValue.serverTimestamp();
                    } else if (fields[fieldKey].default) { // Manage undefined and check if there is a default
                        values[fieldKey] = fields[fieldKey].default;
                        updatedFields[fieldKey] = firebase.firestore.FieldValue.serverTimestamp();
                    }
                }
            }
        }
    }

    private _cleanSynaps(synaps: any) {
        for (const synap in synaps) {
            if (!this.synapConfig[synap]) {
                delete synaps[synap];
            }
        }
    }

    private _timeStampToDatetime(datetime: firebase.firestore.Timestamp): string {

        if (datetime) {
            const date = datetime.toDate();
            return (date.getFullYear().toString() + '-'
                + ("0" + (date.getMonth() + 1)).slice(-2) + '-'
                + ("0" + (date.getDate())).slice(-2))
                + 'T' + date.toTimeString().slice(0, 5);
        }
        return null;
    }

    private _datetimeToTimeStamp(datetime: string): firebase.firestore.Timestamp {
        if (datetime) {
            datetime = datetime.replace('T', '-');
            let parts = datetime.split('-');
            let timeParts = parts[3].split(':');

            return firebase.firestore.Timestamp.fromDate(new Date(+parts[0], +parts[1] - 1, +parts[2], +timeParts[0], +timeParts[1]));
        }
        return null;
    }

    modifyData(field: string, data: any) {
        this.values[field] = data;
        this.modify[field] = true;
    }
    // private _flatObj(flatObj: any, parentKey: string, values: any, fields: { [key: string]: Field }): void {
    //     for (const valueKey in values) {
    //         // Check if a field
    //         if (fields[valueKey]) {
    //             // Check if Object
    //             if (fields[valueKey].input == 'objectSub') {
    //                 this._flatObj(flatObj, `${parentKey}${parentKey.length ? '_' : ''}${valueKey}`, values[valueKey], fields[valueKey].sub);
    //             }
    //             // Write Value
    //             else {
    //                 flatObj[`${parentKey}${parentKey.length ? '_' : ''}${valueKey}`] = values[valueKey];
    //             }
    //         }
    //     }
    // }




    /******************************  SYNAPS ********************/

    private _initSynaps(synaps: any, synapConfig: { [name: string]: Synap }) {
        if (synaps && synapConfig) {

            for (const synapName in synaps) {
                if (synapConfig[synapName]) {
                    // Multi Synaps -> Create Array
                    if (synapConfig[synapName].multi) {
                        synaps[synapName + 'Array'] = [];
                        for (const connector in synaps[synapName]) {
                            synaps[synapName + 'Array'].push(
                                createList(Object.assign({ $key: connector }, synaps[synapName][connector]), this.synapConfig[synapName].module)
                            );
                        }
                    }
                    // Single Synap, create direct access
                    else {
                        for (const connector in synaps[synapName]) {
                            synaps[synapName + 'Obj'] = Object.assign({ $key: connector }, synaps[synapName][connector]);
                            synaps[synapName + 'ObjList'] = createList(Object.assign({ $key: connector }, synaps[synapName][connector]), this.synapConfig[synapName].module);

                        }
                    }
                }
            }

        }
    }


    // Check Alerts

    public _checkAlerts(values: any, FBvalues: any) {

    }


    protected _fnSaveAutoData(): void {
    }

    protected _fnGetAutoData(): void {
    }

    // public createAlias(): any {
    //     return createAlias(this.values, this.module);
    // }

    protected _checkUndefined(value: any): any {
        return value ? value : null;
    }

    public resetItem() {
        this.values =
        {
            $key: null,
            ref: null,
            created: null,
            createdBy: null,
            updated: null,
            updatedBy: null,
            alerts: [],
            synaps: {},
            alias: {}
        };
        for (const fieldKey in this.fields) {
            this._initObjFieldList(this.values, this.fields, this.validation, this.modify, fieldKey);
        }
    }

    protected _getAgency(setting: SettingService, realtor: string): string {
        const users = setting.getParamsObj('users');
        if (realtor && users[realtor]) {
            if (users[realtor].agency) {
                return users[realtor].agency;
            }
            if (users[realtor].agencies && users[realtor].agencies.length) {
                return users[realtor].agencies[0];
            }
        }
        return null;
    }

    public createAlias(synaps?: string[]): any {
        const alias = createAlias(this.values, this.module);
        if (synaps) {
            alias.synaps = {};
            // TODO check dependant...
            for (const synap of synaps) {
                if (this.values.synaps && this.values.synaps[synap]) {
                    alias.synaps[synap] = this.values.synaps[synap];
                }
            }
        }
        return alias;
    }

    protected _checkError(values: any): boolean {
        if (values && values.alerts) {
            for (const alert of values.alerts) {
                if (alert.type === 'error') {
                    return true;
                }
            }
        }
        return false;
    }
}




export class Item extends MainItem {

    address?: Address;
    photos?: FileRecord[];

    constructor(
        values: any = null,
        public setting?: SettingService,
    ) {
        super(
            'bin',
            {},
            {},
            {},
            { path: 'binchild/', filename: () => 'binchild' },
            setting
        );
        if (values) {
            this.fromFB(values);
        }

    }


}