<!-- MANDATE DETAILS -->
<mat-card class="backspace" *ngIf="!edit">
    <mat-list>
        <mat-list-item>
            <div matLine>Transaction Amount: <b>{{item.values.agencyFee.cy | param:'cies'}}
                    {{item.values.agencyFee.price |
                    number}}</b></div>
        </mat-list-item>
        <mat-list-item>
            <div matLine>Agency Fee: <b>{{item.values.agencyFee.cy | param:'cies'}}
                    {{item.values.agencyFee.agencyCA | number}} + VAT</b></div>
            <div matLine><i>{{item.values.agencyFee.type | param:'agencyFeeTypes'}}</i></div>
        </mat-list-item>
    </mat-list>
    <button *ngIf="isAccountant()" color="primary" mat-icon-button class="top-right" (click)="editCard()">
        <mat-icon>{{GLOBAL.ICONS.EDIT}}</mat-icon>
    </button>
</mat-card>

<div style="position: relative" *ngIf="edit">
    <form-builder [item]="item" [values]="item.values" [edit]="true" section="accounting"></form-builder>

    <!-- EDIT MODE -->
    <button mat-fab class="bottom-right" [disabled]="!item.valid || !item.modified || item.saving" (click)="saveItem()">
        <mat-icon matListIcon>check</mat-icon>
    </button>
    <!-- CANCEL -->
    <button mat-fab class="bottom-right p2" color="basic" (click)="cancelEdit()">
        <mat-icon matListIcon>close</mat-icon>
    </button>
</div>