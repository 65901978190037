import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalConstants } from 'functions/src/shared/global-constants';
import { MainsComponent } from 'src/common/pages/mains/mains.component';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SelectService } from 'src/common/services/select/select.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { SyncPortalService } from 'src/services/syncPortal/sync-portal.service';

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss']
})
export class MarketingComponent {

  constructor(
    protected router: Router,
    public setting: SettingService,
    public portalSyncs: SyncPortalService,
    public backURL: BackurlService,
  ) {
  }

  public GLOBAL = new GlobalConstants();

  public preview: any = null;
  public tableCols = ['icon', 'date', 'ref', 'bedrooms', 'city', 'residence', 'price', 'realtor', 'yesno'];


  overSync(element: any) {
    if (!(this.preview && element && element.unitList && element.unitList.$key === this.preview.$key)) {
      this.preview = null;
      setTimeout(() => {
        this.preview = element.unitList;
      }, 50);
    }
  }

  goItem(element: any) {
    if (element.unitList && element.unitList.$key) {
      this.backURL.addURL(`/marketing`);
      this.router.navigate([`/units/${element.unitList.$key}`]);
    }
  }

  clickBack() {
    this.router.navigate([this.backURL.back()]);
  }

}
