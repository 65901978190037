import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from 'functions/src/shared/global-constants';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { LogsService } from 'src/common/services/logs/logs.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

  public GLOBAL = new GlobalConstants();
  public tableCols = [
    'icon',
    'date',
    'data',
    'user'
  ];
  public module: string;
  public $key: string;

  constructor(
    public logService: LogsService,
    public route: ActivatedRoute,
    protected router: Router,
    public backURL: BackurlService) { }

  ngOnInit(): void {
    this.module = this.route.snapshot.paramMap.get('module');
    this.$key = this.route.snapshot.paramMap.get('key');

    if (this.module && this.$key) {
      this.logService.init(this.module, this.$key);
    }

}

goBack() {
  this.router.navigate([this.backURL.back()]);
}
}
