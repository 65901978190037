import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Address } from '../../services/main/main.model';
import { MapsAPILoader } from '@agm/core';
import randomLocation from 'random-location'
import { FormControl } from '@angular/forms';
import * as firebase from 'firebase/app';
import { SettingService } from '../../services/setting/setting.service';

declare var google: any;

@Component({
  selector: 'app-gps',
  templateUrl: './gps.component.html',
  styleUrls: ['./gps.component.scss']
})
export class GpsComponent implements OnInit {

  private _address: Address = {};
  public latitude: number;
  public longitude: number;
  public rLatitude: number;
  public rLongitude: number;
  public place: any = null;
  public geocoder: any;
  public addressDisplay = 'Searching';
  public windowOpen = false;
  public changed = false;
  public searchControl: FormControl;
  public fullscreen = false;

  params = {
    lat: -20.2674,
    long: 57.5766,
    zoom: 8,
  }


  @Input() set values(values: any) {
    if (values && values.address)
      this._address = values.address;

    if (this._address && this._address.gps && this._address.gps.latitude) {
      this.latitude = this._address.gps.latitude;
      this.longitude = this._address.gps.longitude;
      this.params.lat = this.latitude;
      this.params.long = this.longitude;
      this.params.zoom = 13;
      if (this._address.eGps && this._address.eGps.latitude) {
        this.rLatitude = this._address.eGps.latitude;
        this.rLongitude = this._address.eGps.longitude;
      } else {
        if (this.edit) {
          this._generateRandomPoint();
          this.changed = true;
        }
      }
    } else {
      if (this.edit) {
        this.setCurrentPosition();
      }
    }
  };
  get address() {
    return this._address;
  }

  @Input() control = false;

  @Input() fullscreenMode = true;


  @Input() edit = false;

  @Output() modify: EventEmitter<any> = new EventEmitter<any>();


  @ViewChild("searchgm")
  public searchElementRef: ElementRef;


  constructor(
    private mapsAPILoader: MapsAPILoader,
    public ngZone: NgZone,
    private setting: SettingService,
  ) {

    //create search FormControl
    this.searchControl = new FormControl();

  }

  ngOnInit(): void {
    this.mapsAPILoader.load().then(() => {
      if (this.searchElementRef) {
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
          types: ["geocode"],
          componentRestrictions: { country: "mu" }
        });
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            const place = autocomplete.getPlace();

            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            this.params.zoom = 15;
            this.params.lat = place.geometry.location.lat();
            this.params.long = place.geometry.location.lng();

          });
        });
      }

      this.geocoder = new google.maps.Geocoder;
      // this._geocodeLatLng();
    });
  }

  toggleFullscreen() {
    this.fullscreen = !this.fullscreen;
    if (this.fullscreen) {
      this.control = true;
    } else {
      this.control = false;
    }
  }
  private setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.params.lat = this.latitude;
        this.params.long = this.longitude;
        this._generateRandomPoint();
        this.changed = false;
        this.params.zoom = 12;
      },
        () => {

          this.latitude = -20.2674;
          this.longitude = 57.5766;
        });
    }
  }


  draggEnded(e) {
    this.latitude = e.coords.lat;
    this.longitude = e.coords.lng;
    this._geocodeLatLng();
    // Generate Random Point
    this._generateRandomPoint();
    this.changed = true;
  }

  rDraggEnded(e) {
    this.rLatitude = e.coords.lat;
    this.rLongitude = e.coords.lng;
    this.changed = true;
  }

  public save() {
    let googleAddress: any = {
      street: '',
      city: '',
      district: '',
      country: 'MU',
      gps: '',
    };

    if (this.place && this.place.address_components) {
      for (let component of this.place.address_components) {
        if (component.types.indexOf("route") > -1 && component.long_name != 'Unnamed Road') {
          googleAddress.street = component.long_name;
        } else if (component.types.indexOf("sublocality") > -1) {
          googleAddress.city = component.long_name;
        } else if (googleAddress.city === '' && component.types.indexOf("locality") > -1) {
          googleAddress.city = component.long_name;
        } else if (component.types.indexOf("administrative_area_level_1") > -1) {
          googleAddress.district = component.long_name;
        }
      }
    }
    googleAddress.gps = new firebase.firestore.GeoPoint(this.latitude, this.longitude);

    googleAddress.eGps = new firebase.firestore.GeoPoint(this.rLatitude, this.rLongitude);

    this.changed = false;

    // Get Sectors
    googleAddress.sectors = this.gpsToSectors(this, this.setting);
    googleAddress.sector = (googleAddress.sectors && googleAddress.sectors.length) ? googleAddress.sectors[0] : null;

    this.modify.emit(googleAddress);
  }

  // Common functions
  public gpsToSectors(gps: any, setting): string[] {
    const sectors: string[] = [];
    if (gps.latitude && gps.longitude) {
      for (const sector of setting.regions.sectors) {
        if (this._isPointInPoly(sector.paths, gps)) {
          sectors.push(sector.name);
          if (sector.sectors) {
            for (const subsector of sector.sectors) {
              if (this._isPointInPoly(subsector.paths, gps)) {
                sectors.push(subsector.name);
              }
            }
          }
        }
      }
    }
    return sectors;
  }

  private _isPointInPoly(poly, pt): boolean {

    var polygon = new google.maps.Polygon({ paths: poly });
    let position = new google.maps.LatLng({ lat: pt.latitude, lng: pt.longitude });

    return google.maps.geometry.poly.containsLocation(
      position,
      polygon
    )
  }

  infoWindowClose() {
    this.windowOpen = false;
  }

  private _geocodeLatLng() {
    let wm = this;
    this.addressDisplay = 'Searching...';
    this.windowOpen = true;
    this.geocoder.geocode({ 'location': { lat: this.latitude, lng: this.longitude } }, function (results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          wm.searchControl.reset();
          wm.place = results[0];
          wm.addressDisplay = results[0].formatted_address;
        } else {
          console.log('Geocoder: No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });
  }
  private _generateRandomPoint(radius = 500) {
    const randomPoint = randomLocation.randomCircumferencePoint({ latitude: this.latitude, longitude: this.longitude }, radius);

    this.rLatitude = randomPoint.latitude;
    this.rLongitude = randomPoint.longitude;
  }
}
