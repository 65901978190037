<!-- MANDATE DETAILS -->
<mat-card class="backspace" *ngIf="!edit">
    <mat-list>
        <mat-list-item >
            <mat-icon matListIcon class="hot" *ngIf="!item.values.anyoneContact">{{GLOBAL.ICONS.ALERT}}</mat-icon>
            <mat-icon matListIcon *ngIf="item.values.anyoneContact">{{GLOBAL.ICONS.VISIT}}</mat-icon>
            <div matLine>
                <b>Organising Visits</b>
            </div>
        </mat-list-item>
        <mat-list-item *ngIf="item.values.keyOffice">
            <mat-icon matListIcon>{{GLOBAL.ICONS.KEY}}</mat-icon>
            <div matLine>
                <b>Key at the Office</b>
            </div>
        </mat-list-item>
        <mat-card-content *ngIf="item.values.organiseVisitNote">
            {{item.values.organiseVisitNote}}
        </mat-card-content>
        <mat-card-content *ngIf="!item.values.organiseVisitNote">
            No specification
        </mat-card-content>
    </mat-list>
    <button *ngIf="isRealtor()" color="primary" mat-icon-button class="top-right" (click)="editCard()">
        <mat-icon>{{GLOBAL.ICONS.EDIT}}</mat-icon>
    </button>
</mat-card>
<div style="position: relative" *ngIf="edit">

    <form-builder [item]="item" [values]="item.values" [edit]="true" section="contact"></form-builder>

    <!-- EDIT MODE -->
    <button mat-fab class="bottom-right" [disabled]="!item.valid || !item.modified || item.saving" (click)="saveItem()">
        <mat-icon matListIcon>check</mat-icon>
    </button>
    <!-- CANCEL -->
    <button mat-fab class="bottom-right p2" color="basic" (click)="cancelEdit()">
        <mat-icon matListIcon>close</mat-icon>
    </button>
</div>