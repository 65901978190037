<div class="login-page">
    <div class="mdc-layout-grid">
        <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell--span-12">
                <img src="assets/images/logo.png" />
            </div>
        </div>
        <div *ngIf="!loading" class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell--span-12">
                <div class="welcome" *ngIf="signupMode && !pendingEmailVerification">Hi
                    {{auth.pendingProfile?.values?.firstname}}!<br />
                    <span *ngIf="auth.config.password">You can now create your account using your email<br />
                        or directly Login with Google if you have a Google Account</span></div>
                <mat-error>{{msg}}</mat-error>
            </div>
        </div>
        <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell--span-2"></div>

            <div *ngIf="loading" class="mdc-layout-grid__cell--span-8">
                <mat-spinner [strokeWidth]="5" class="spinw"></mat-spinner>
            </div>
            <div *ngIf="!loading && !pendingEmailVerification" class="mdc-layout-grid__cell--span-8">
                <mat-card *ngIf="auth.config.password">
                    <mat-card-content>
                        <mat-form-field appearance="fill">
                            <mat-label>Email</mat-label>
                            <input matInput type="email" [(ngModel)]="email" required email>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Password</mat-label>
                            <input matInput type="password" [(ngModel)]="password">
                        </mat-form-field>
                        <mat-form-field *ngIf="signupMode" appearance="fill">
                            <mat-label>Repeat Password</mat-label>
                            <input matInput type="password" [(ngModel)]="repeatPassword">
                        </mat-form-field>
                        <div class="mdc-layout-grid__inner">
                            <div *ngIf="!signupMode" class="mdc-layout-grid__cell--span-3">
                                <button mat-raised-button color="primary" (click)="login()">Login</button>
                                <button mat-button class="reset" (click)="loginWithGoogle()">Reset Password
                                </button>
                            </div>
                            <div *ngIf="signupMode" class="mdc-layout-grid__cell--span-3">
                                <button mat-raised-button color="primary" (click)="signup()">Create Account</button>
                            </div>
                            <div class="mdc-layout-grid__cell--span-4"></div>
                            <div class="mdc-layout-grid__cell--span-5" *ngIf="auth.config.google">
                                <button mat-raised-button class="google" (click)="loginWithGoogle()">
                                    <mat-icon svgIcon="google"></mat-icon>Login With Google
                                </button>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <button mat-raised-button class="google" *ngIf="auth.config.google && !auth.config.password"
                    (click)="loginWithGoogle()">
                    <mat-icon svgIcon="google"></mat-icon>Login With Google
                </button>
            </div>
            <div class="mdc-layout-grid__cell--span-2"></div>
        </div>

    </div>
</div>