import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CardEditComponent } from 'src/common/components/card-edit/card-edit.component';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { MandateService } from 'src/services/mandate/mandate.service';
import { SyncPortalService } from 'src/services/syncPortal/sync-portal.service';

@Component({
  selector: 'app-advertise-edit',
  templateUrl: './advertise-edit.component.html',
  styleUrls: ['./advertise-edit.component.scss']
})
export class AdvertiseEditComponent extends CardEditComponent {

  @Input() unitPortals: any[];

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: MandateService,
    protected router: Router,
    public backURL: BackurlService,
    public syncPortal: SyncPortalService,
  ) {
    super(_cd, setting, db, router, backURL);
    // Check Next Sync
  }


  ngOnInit(): void {
    this.syncPortal.initMandate(this.item.values.$key);
  }

}
