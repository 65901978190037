import { Injectable } from '@angular/core';
import { UnitService } from '../../../services/unit/unit.service';
import { MainService } from '../main/main.service';
import { AuthService } from '../auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { ResidenceService } from '../../../services/residence/residence.service';
import { MandateService } from '../../../services/mandate/mandate.service';
import { ContactService } from '../../../services/contact/contact.service';
import { SettingService } from '../setting/setting.service';
import { google } from 'google-maps';
import { MapsAPILoader } from '@agm/core';
import { Unit } from 'src/services/unit/unit.model';
import { mergeResidenceUnitSynap } from 'functions/src/shared/tools';
import { Residence } from 'src/services/residence/residence.model';


@Injectable({
  providedIn: 'root'
})
export class AdminToolsService {

  constructor(
    public auth: AuthService,
    public afs: AngularFirestore,
    public setting: SettingService,
    private mapsAPILoader: MapsAPILoader,
  ) {
  }

  refreshAlias(comp: string): Promise<any> {


    if (comp == 'units') {
      return this._updateUnitAlias();
    }
    else if (comp == 'residences') {
      return this._updateResidenceAlias();
    }
    // else if (comp == 'mandates') {
    //   mod = new MandateService(this.auth, this.afs);
    // }
    // else if (comp == 'contacts') {
    //   mod = new ContactService(this.auth, this.afs);
    // }
    // Get all Items

    // Batch List ItemsList



  }


  private _updateUnitAlias() {

    return this.mapsAPILoader.load().then(() => {
      let mod: MainService;

      mod = new UnitService(this.auth, this.afs);
      const item = new Unit();

      const w = this;
      return firebase.firestore().collection(`/db/modules/units`).get().then(list => {

        const batches: any[] = [];
        batches.push(firebase.firestore().batch());
        let currentBatch = 0;
        let i = 0;

        // Create Batches
        list.forEach(function (doc) {

          if (i >= 250) {
            i = 0;
            currentBatch++;
            batches.push(firebase.firestore().batch());
          }

          console.log(`${i} -> ${currentBatch} ->/units/${doc.id}`);

          const unit = doc.data();
          mergeResidenceUnitSynap(unit);
          // let address: any = {};
          // if (unit && unit.address && unit.address.gps) {
          //   address = Object.assign({}, unit.address);
          //   address.sectors = w.gpsToSectors(unit.address.gps, w.setting);
          //   address.sector = (address.sectors && address.sectors.length) ? address.sectors[0]: null;
          // }
          const FBValues: any = { updatedFields: { alerts: [] }, alerts: [], listUpdated: firebase.firestore.FieldValue.serverTimestamp() }
          item._checkAlerts(unit, FBValues);

          batches[currentBatch].set(firebase.firestore().doc(`/db/modules/units/${doc.id}`),
            FBValues, { merge: true }
          );
          i++;
        });

        // comit All Batches
        return batches.reduce(function (p, batch) {
          return p.then(function () {

            return batch.commit().then(() => {
              console.log('Batch Done');
            });

          });
        }, Promise.resolve()).then(() => {
          console.log('All Batches Done');
        });
      });
    });

  }

  private _updateResidenceAlias() {

    return this.mapsAPILoader.load().then(() => {

      let mod: MainService;

      // mod = new UnitService(this.auth, this.afs);
      const item = new Residence(null, this.setting);

      const w = this;
      return firebase.firestore().collection(`/db/modules/residences`).get().then(list => {

        const batches: any[] = [];
        batches.push(firebase.firestore().batch());
        let currentBatch = 0;
        let i = 0;

        // Create Batches
        list.forEach(function (doc) {

          if (i >= 250) {
            i = 0;
            currentBatch++;
            batches.push(firebase.firestore().batch());
          }

          console.log(`${i} -> ${currentBatch} ->/residences/${doc.id}`);

          const residence = doc.data();


          const FBValues: any = { updatedFields: { alerts: [] }, alerts: [], listUpdated: firebase.firestore.FieldValue.serverTimestamp() }
          item._checkAlerts(residence, FBValues);

          batches[currentBatch].set(firebase.firestore().doc(`/db/modules/residences/${doc.id}`),
            FBValues, { merge: true }
          );
          i++;
        });

        // comit All Batches
        return batches.reduce(function (p, batch) {
          return p.then(function () {

            return batch.commit().then(() => {
              console.log('Batch Done');
            });

          });
        }, Promise.resolve()).then(() => {
          console.log('All Batches Done');
        });
      });

    });
  }


  refreshList(comp: string): Promise<any> {

    let mod: MainService;

    if (comp == 'units') {
      mod = new UnitService(this.auth, this.afs);
    }
    else if (comp == 'residences') {
      mod = new ResidenceService(this.auth, this.afs);
    }
    else if (comp == 'mandates') {
      mod = new MandateService(this.auth, this.afs, this.setting);
    }
    else if (comp == 'contacts') {
      mod = new ContactService(this.auth, this.afs);
    }
    // Get all Items
    return firebase.firestore().collection(`/db/modules/${comp}`).get().then(list => {

      const batches: any[] = [];
      batches.push(firebase.firestore().batch());
      let currentBatch = 0;
      let i = 0;

      // Create Batches
      list.forEach(function (doc) {

        if (i >= 250) {
          i = 0;
          currentBatch++;
          batches.push(firebase.firestore().batch());
        }

        console.log(`${i} -> ${currentBatch} ->/${comp}List/${doc.id}`);
        batches[currentBatch].set(firebase.firestore().doc(`/list/modules/${comp}/${doc.id}`),
          new mod.itemClass(doc.data()).toFB('list')
        );
        i++;
      });

      // comit All Batches
      return batches.reduce(function (p, batch) {
        return p.then(function () {

          return batch.commit().then(() => {
            console.log('Batch Done');
          });

        });
      }, Promise.resolve()).then(() => {
        console.log('All Batches Done');
      });
    });

    // Batch List ItemsList



  }

  // Common functions
  public gpsToSectors(gps: any, setting): string[] {
    const sectors: string[] = [];
    if (gps.latitude && gps.longitude) {
      for (const sector of setting.regions.sectors) {
        if (_isPointInPoly(sector.paths, gps)) {
          sectors.push(sector.name);
          if (sector.sectors) {
            for (const subsector of sector.sectors) {
              if (_isPointInPoly(subsector.paths, gps)) {
                sectors.push(subsector.name);
              }
            }
          }
        }
      }
    }
    return sectors;
  }


}


declare var google: any;

function _isPointInPoly(poly, pt): boolean {

  var polygon = new google.maps.Polygon({ paths: poly });
  let position = new google.maps.LatLng({ lat: pt.latitude, lng: pt.longitude });

  return google.maps.geometry.poly.containsLocation(
    position,
    polygon
  )
}
