<ng-container *ngIf="item.values.type">
    <form-builder [item]="item" [values]="item.values" section="note" [edit]="true"></form-builder>
</ng-container>

<div class="actionButtons" *ngIf="isRealtor()">
    <button mat-icon-button color="accent" [disabled]="!item.modified || item.saving" (click)="saveAction()">
        <mat-icon>check</mat-icon>
    </button>
    <button mat-icon-button class="active" [disabled]="item.saving" (click)="validAction()">
        <mat-icon>task_alt</mat-icon>
    </button>
    <button mat-icon-button (click)="dateAction()">
        <mat-icon>event</mat-icon>
    </button>
    <button mat-icon-button (click)="deleteAction()">
        <mat-icon>delete</mat-icon>
    </button>
</div>