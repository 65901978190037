import { Injectable } from '@angular/core';
import { MainService } from '../../common/services/main/main.service';
import { AuthService } from '../../common/services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Company } from './company.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends MainService {

  public refMode = true;
  
  constructor(
    public auth: AuthService,
    public afs: AngularFirestore,
  ) {
    super(auth, afs, 'company', 'companies', Company);
  }

}