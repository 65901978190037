<mat-toolbar class="dashbg">
    <div>
        <a mat-icon-button (click)="clickBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <span>Marketing Dashboard</span>
    </div>
    <div>
        <profile></profile>
    </div>
</mat-toolbar>
<div class="toolbartop"></div>


<div class="main-container" *ngIf="db.auth.profile?.values">
    <div class="mdc-layout-grid mainPage">
        <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell">
                <mat-card (click)="goPage('marketing/check')" class="backspace cursor title">
                    <mat-card-title >New Mandate Checks</mat-card-title>
                </mat-card>
            </div>
            <div class="mdc-layout-grid__cell">
                <mat-card (click)="goPage('marketing/blogs')" class="backspace cursor title">
                    <mat-card-title>Blogs</mat-card-title>
                </mat-card>
            </div>
            <div class="mdc-layout-grid__cell">
                <mat-card (click)="goPage('marketing/portals/lp')" class="backspace cursor title">
                    <mat-card-title>LP Refused List</mat-card-title>
                </mat-card>
            </div>
        </div>
    </div>
</div>