import { Component, OnInit } from '@angular/core';
import { MainsComponent } from '../../common/pages/mains/mains.component';
import { SettingService } from '../../common/services/setting/setting.service';
import { MandateService } from '../../services/mandate/mandate.service';
import { SelectService } from '../../common/services/select/select.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { BackurlService } from 'src/common/services/backurl/backurl.service';

@Component({
  selector: 'app-mandates',
  templateUrl: './mandates.component.html',
  styleUrls: ['./mandates.component.scss']
})
export class MandatesComponent extends MainsComponent {

  public preview: any = null;

  constructor(
    public setting: SettingService,
    public db: MandateService,
    public select: SelectService,
    protected router: Router,
    protected fb: FormBuilder,
    public backURL: BackurlService
  ) {
    super(setting, db, select, router, fb, backURL);

    this.sortServerForm = this.fb.group({
      agency: [],
      realtor: [],
      filters: this.fb.group({
        referral: [],
        referralAgency: [],
      }),
      multiFilters: this.fb.group({
        status: [],
      }),
    });

    this.sortLocalForm = this.fb.group({
      localSearch: [''],
      filters: this.fb.group({
        type: ['N'],
        condition: ['N'],
      }),
      budget: this.fb.group({
        saleMinPrice: [],
        saleMaxPrice: [],
        rentalMinPrice: [],
        rentalMaxPrice: [],
      }),
    });
  }

  goItem(item: any) {
    // Check if on preview first
    if (this.db.itemsPageConfig.sideTab === 1 && !this.select.active) {
      this.backURL.addURL(`/${this.db.moduleListName}/`);
      this.router.navigate([`/${this.db.moduleListName}/${item.$key}`]);
    } else {
      this.db.itemsPageConfig.sideTab = 1;
    }
  }

  toggleMap() {
    this.db.itemsPageConfig.map = !this.db.itemsPageConfig.map;
  }



  previewItem(item: any) {
    if (!(this.preview && item && item.$key === this.preview.$key)) {
      this.preview = null;
      setTimeout(() => {
        this.preview = item;
      }, 50);
    }
  }

}