import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Upload, FileRecord } from '../../services/upload-file/upload-file-model';
import { UploadFileService } from '../../services/upload-file/upload-file.service';

@Component({
  selector: 'upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent {

  dropzoneActive: boolean = false;
  currentUpload: Upload;


  @Input()
  fileRecord: FileRecord;

  @Input()
  config: { path: string, filename: string };


  @Input()
  title: string = null;

  @Output() newImage: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public upload: UploadFileService,
  ) {
  }

  dropzoneState($event: boolean) {
    this.dropzoneActive = $event;
  }

  handleDrop(file: File) {
    if (file && file[0]) {
      this.currentUpload = new Upload(file[0], this.config.path, this.config.filename);
      this.upload.pushUpload(this.currentUpload, 'image').then((fileUploaded) => {
        if (this.fileRecord){
          this.upload.deleteFileUpload(this.fileRecord);
        }
        this.fileRecord = fileUploaded;
        this.newImage.emit(fileUploaded);
        console.log(fileUploaded);
      });
    }
  }
}
