<mat-toolbar>
    
        <div>
            <a mat-icon-button routerLink='/settings/users'>
                <mat-icon>keyboard_arrow_left</mat-icon>
            </a>
            <span *ngIf="!new">{{user.values?.fullname}}</span>
            <span *ngIf="new">Invite a new User</span>
        </div>
        <profile></profile>
    
</mat-toolbar>
<div class="toolbartop"></div>

<div class="mdc-layout-grid">
    <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell">
            <form-builder *ngIf="new" [item]="user" [values]="user.values" section="new"></form-builder>
            <form-builder *ngIf="!new && user.values?.provider == 'password'" [item]="user" [values]="user.values" section="password" [edit]="true"></form-builder>
            <form-builder *ngIf="!new && user.values?.provider != 'password'" [item]="user" [values]="user.values" section="external" [edit]="true"></form-builder>
        </div>
        <!-- <div class="mdc-layout-grid__cell" *ngIf="!new">
            <upload-image [fileRecord]="user.image" [config]="user.imageConfig" (newImage)="saveData({image: $event})">
            </upload-image>
        </div> -->
    </div>
</div>

<button *ngIf="new" mat-fab class="bottom-right pfixed" [disabled]="!(user.valid && user.modified)" (click)="saveNewUser()">
    <mat-icon matListIcon>check</mat-icon>
</button>
<button *ngIf="!new && user.values?.status == 'active'" mat-fab class="bottom-right pfixed" (click)="saveUser()">
    <mat-icon matListIcon>check</mat-icon>
</button>
<button *ngIf="!new && user.values?.status == 'blocked'" mat-fab class="bottom-right pfixed" (click)="activeUser()">
    <mat-icon matListIcon>how_to_reg</mat-icon>
</button>
<button *ngIf="!new && user.values?.status == 'active'" mat-fab class="bottom-left pfixed" color="basic" (click)="blockUser()">
    <mat-icon matListIcon>block</mat-icon>
</button>