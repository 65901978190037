<mat-toolbar>

    <div>
        <a mat-icon-button routerLink='/settings'>
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <span>Agencies</span>
    </div>
    <profile></profile>

</mat-toolbar>
<div class="toolbartop"></div>
<table mat-table [dataSource]="agencies" class="mat-elevation-z8">

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.values.name}} </td>
    </ng-container>

     <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.values.email}} </td>
    </ng-container>

    <ng-container matColumnDef="tel">
        <th mat-header-cell *matHeaderCellDef> Tel </th>
        <td mat-cell *matCellDef="let element"> {{element.values.tel}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element">
            <mat-icon [ngClass]="{active:element.values.status == 'active'}">
                {{element.values.status | icon:'userStatus'}}
            </mat-icon>
        </td>
    </ng-container>

     <tr mat-header-row
        *matHeaderRowDef="['name', 'email', 'tel', 'status']">
    </tr>
    <tr mat-row
        *matRowDef="let row; let element; columns: ['name','email', 'tel', 'status'];"
        (click)="editAgency(element)"></tr>
</table>

<button mat-fab class="bottom-right pfixed" (click)="addAgency()">
    <mat-icon matListIcon>add</mat-icon>
</button>