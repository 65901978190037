<mat-toolbar>
    <div>
        <a mat-icon-button (click)="clickBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <span>LP Refused Ads</span>
    </div>
    <profile></profile>
</mat-toolbar>
<div class="toolbartop"></div>

<div class="main-container-flex split">
    <mat-spinner *ngIf="!lp.loaded" [strokeWidth]="5"></mat-spinner>
    <app-table *ngIf="lp.loaded" class="main" 
        [item]="item" [items]="lp.mandates" (clickFn)="goItem($event)"
        (overFn)="previewItem($event)"></app-table>
    <div class="side">
        <app-unit-card [values]="preview"></app-unit-card>
    </div>
</div>
