import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from 'functions/src/shared/global-constants';
import { DialogConfirmationComponent } from 'src/common/components/dialog-confirmation/dialog-confirmation.component';
import { MainComponent } from 'src/common/pages/main/main.component';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SelectService } from 'src/common/services/select/select.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { Tools } from 'src/common/services/tools';
import { ActionService } from 'src/services/action/action.service';

@Component({
  selector: 'app-action-main',
  templateUrl: './action-main.component.html',
  styleUrls: ['./action-main.component.scss']
})
export class ActionMainComponent extends MainComponent {

  public GLOBAL = new GlobalConstants();
  public tools = new Tools();
  protected _action: any;

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ActionService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public backURL: BackurlService,
    public dialog: MatDialog,
  ) {
    super(_cd, setting, db, select, route, router, backURL, dialog);
  }



  deleteAction() {
    const title = `Are you sure to cancel the ${this.setting.getParamValueName('actionTypes', this.item.values.type)}?`;

    const data = { title: title, ok: false };
    const dialogRef = this.dialog.open(DialogConfirmationComponent, {
      width: '350px',
      disableClose: true,
      data: data
    });

    dialogRef.afterClosed().subscribe(() => {
      if (data.ok) {
        this.db.deleteItem(this.item);
        this.goBack();
      }
    });
  }


}
