import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'actionDate'
})
export class ActionDatePipe implements PipeTransform {

  transform(value: Date): string {
    if (value) {
      const day = value.getDate().toString().padStart(2, '0');
      const month = this.getMonthAbbreviation(value.getMonth());
      const year = value.getFullYear();
      const hours = value.getHours().toString().padStart(2, '0');
      const minutes = value.getMinutes().toString().padStart(2, '0');

      if (hours === '00' && minutes === '00') {
        return `${day} ${month} ${year}`;
      } else {
        return `${day} ${month} ${year} ${hours}:${minutes}`;
      }
    }
    return '';
  }
  private getMonthAbbreviation(month: number): string {
    const monthsAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return monthsAbbreviations[month];
  }
}
