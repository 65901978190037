import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notNew'
})
export class NotNewPipe implements PipeTransform {

  transform(list: any[], notNewMatch: { [matchKey: string]: boolean }): any[] {
    return list.filter((v) => {
      if (notNewMatch[v.$key]) {
        return false;
      }
      return true;
    }).sort((a, b) => {
      if (a.project && b.project) {
        if (a.project.realtor < b.project.realtor) { return -1; }
        if (a.project.realtor > b.project.realtor) { return 1; }
      }
      return 0;
    });
  }

}
