import { MainItem, Field, Email, Tel, Alert } from '../../common/services/main/main.model';
import { FileRecord } from '../../common/services/upload-file/upload-file-model';
import { SettingService } from '../../common/services/setting/setting.service';


export class Company extends MainItem {

    values: {
        $key: string,
        ref: number,
        created: firebase.firestore.FieldValue,
        createdBy: string,
        updated: firebase.firestore.FieldValue,
        updatedBy: string,
        alerts: Alert[],

        
        synaps: any,
        
        
        name: string,
        tradingAs: string,
        displayname: string,
        //  activities: string[],
        country: string, // Living
        fullAddress: string, //IF not Mauritius
        emails: { [id: string]: Email },
        tels: { [id: string]: Tel },
        // address: Address,
        logo: FileRecord,
    }


    constructor(
        values: any = null,
        public setting?: SettingService,
    ) {
        super(
            'companies',
            {
                name: new Field({ label: 'Registered Name', }),
                tradingAs: new Field({ label: 'Trading Name', }),
                displayname: new Field({
                    input: 'auto',
                    autoFn: {
                        toFB: (values) => { return this._createFullName(values); }
                    },
                    
                }),
                emails: new Field({
                    label: 'Emails',
                    input: 'dynSub',
                    dynSub: {
                        email: new Field({ label: 'Email' }),
                    },
                }),
                tels: new Field({
                    label: 'Tels',
                    input: 'dynSub',
                    dynSub: {
                        number: new Field({ label: 'Number' }),
                    },
                }),
            },
            // Forms
            {
                main: {
                    title: null,
                    fields: ['tradingAs', 'name', 'emails', 'tels']
                },
            },
            // Tables
            {
                main: {
                    title: 'Company List',
                    columns: [
                        { field: 'icon', type: 'icon', label: '' },
                        { field: 'ref', type: 'string', label: 'Ref', options: { prefix: 'W' } },
                        { field: 'displayname', type: 'string', label: 'Name' },
                        { field: 'employees', type: 'string', label: 'Employees', options: { multi: true } },
                    ],
                },
            },
            // Config File
            {
                path: 'companies/', filename: (data) => {
                    let filename = 'contact-';
                    filename += 'W' + data.ref;
                    return filename
                }
            },
            setting,
        );
        if (values) {
            this.fromFB(values);
        }

    }


    private _createFullName(values): string {

        if (values.tradingAs) {
            return values.tradingAs;
        }
        if (values.name) {
            return values.name;
        }
        return '';
    }

}