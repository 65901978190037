<ng-container *ngIf="values && values[field.key] && field.show(rootValues, values, setting)">
    <ng-container [ngSwitch]="field.input">


        <!-- /********************* SUB OBJECT *******************/ -->
        <ng-container *ngSwitchCase="'objectSub'">
            <h2 *ngIf="field.label">{{field.label}}</h2>
            <ng-container *ngFor="let subField of field.sub | toarray">
                <field-display [values]="values[field.key]" [rootValues]="rootValues" [field]="subField" [section]="section">
                </field-display>
            </ng-container>
        </ng-container>

        <!-- /********************* SUB OBJECT LINK TO TYPE *******************/ -->
        <ng-container *ngSwitchCase="'objectSubType'">
            <ng-container *ngFor="let subField of field.subType[values.type] | toarray">
                <field-display [values]="values[field.key]" [rootValues]="rootValues" [field]="subField" [section]="section">
                </field-display>
            </ng-container>
        </ng-container>


        <!-- /********************* DYN OBJECT *******************/ -->
        <ng-container *ngSwitchCase="'dynSub'">
            <ng-container *ngFor="let subField of field.sub | toarray">
                <field-display [values]="values[field.key]" [rootValues]="rootValues" [field]="subField" [section]="section">
                </field-display>
            </ng-container>
        </ng-container>

        <!-- /********************* SELECT INPUT *******************/ -->
        <ng-container *ngSwitchCase="'select'">
            <mat-list-item *ngIf="!field.inputOptions.multi || (field.inputOptions.multi && values[field.key].length)">
                <p matLine *ngIf="!field.inputOptions.multi">
                    <span class="lbl" *ngIf="field.label">{{field.label}}</span>
                    <span class="value">{{values[field.key] | param:field.inputOptions.param}}</span>
                </p>
                <p matLine *ngIf="field.inputOptions.multi && values[field.key].length">
                    <span class="lbl" *ngIf="field.label">{{field.label}}</span>
                    <ng-container *ngIf="!field.inputOptions.icon">
                        <span>
                            <ng-container *ngFor="let v of values[field.key]; let last = last">
                                {{ v | param:field.inputOptions.param }}{{ !last ? ',' : '' }}
                            </ng-container>
                        </span>
                    </ng-container>
                    <span class="value" *ngIf="field.inputOptions.icon">
                        <mat-icon matListIcon *ngFor="let icon of values[field.key]">
                            {{icon | icon:field.inputOptions.param}}
                        </mat-icon>
                    </span>
                </p>
            </mat-list-item>
        </ng-container>

        <!-- /********************* DATE *******************/ -->
        <mat-list-item *ngSwitchCase="'date'">
            <p matLine>
                <span class="lbl" *ngIf="field.label">{{field.label}}</span>
                <span class="value">{{ values[field.key] | date:'dd MMM yyyy'}} </span>
            </p>
        </mat-list-item>

        <!-- /********************* NUMBER *******************/ -->
        <mat-list-item *ngSwitchCase="'number'">
            <p matLine>
                <span class="lbl" *ngIf="field.label">{{field.label}}</span>
                <span class="value" *ngIf="field.inputOptions.noComa">{{ values[field.key]}} </span>
                <span class="value" *ngIf="!field.inputOptions.noComa">{{ values[field.key] | number}} </span>
            </p>
        </mat-list-item>

        <!-- /********************* BOOLEAN *******************/ -->
        <mat-list-item *ngSwitchCase="'boolean'">
            <p matLine>
                <span class="lbl" *ngIf="field.label">{{field.label}}</span>
                <span class="value">
                    <mat-icon>check</mat-icon>
                </span>
            </p>
        </mat-list-item>

        <!-- /********************* TEXT INPUT *******************/ -->

        <mat-list-item *ngSwitchDefault>
            <p matLine>
                <span class="lbl" *ngIf="field.label">{{field.label}}</span>
                <span class="value">{{values[field.key]}}</span>
            </p>
        </mat-list-item>

    </ng-container>
</ng-container>