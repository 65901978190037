import { Component, OnInit, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { Field, MainItem } from '../../services/main/main.model';

@Component({
  selector: 'form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormBuilderComponent implements OnInit {

  public fields: Field[] = null;
  public title = null;
  private _section: string = 'main';
  private _item: MainItem = null;
  private _edit = true;

  @Input() set section(section: string) {
    this._section = section;
  }
  get section() {
    return this._section;
  }

  @Input() set item(item: MainItem) {
    if (item && this.section && item.sections[this.section]) {
      this._item = item;
      this.title = item.sections[this.section].title;
      // init Fields to Display
      this.fields = [];
      for (const fieldKey of item.sections[this.section].fields) {
        if (item.fields[fieldKey]) {
          this.fields.push(Object.assign({ key: fieldKey }, item.fields[fieldKey]));
        }
      }
    }

    // this._item.activeSections[this._section] = { valid: false };
  }
  get item() {
    return this._item;
  }

  @Input()
  public values: any;


  @Input() set edit(edit: boolean) {
    this._edit = edit;

    // init active Section for validation
    if (edit) {
      this._item.activeSections[this._section] = { valid: false, modified: false };
    } else {
      if (this._item && this._item.activeSections) {
        delete this._item.activeSections[this._section];
      }
    }
  }
  get edit() {
    return this._edit;
  }

  @Output() touch: EventEmitter<any> = new EventEmitter<any>();


  // @Input() set value(value: any) {
  //   if (value) {
  //     this._value = value;
  //   } else {
  //     this.value = {};
  //   }
  // }
  // get value() {
  //   return this._value;
  // }

  touched() {
    this.touch.emit();
  }


  constructor() { }

  ngOnInit(): void {
  }

}
