import { Pipe, PipeTransform } from '@angular/core';
import { SettingService } from 'src/common/services/setting/setting.service';

@Pipe({
  name: 'realtorName'
})
export class RealtorNamePipe implements PipeTransform {

  constructor(
    private _setting?: SettingService,
  ) {
  }

  transform(value: string, args?: boolean): string {
    return this._setting.getUserName(value);
  }


}
