<mat-card>
    <mat-card-title *ngIf="title">{{title}}</mat-card-title>
    <mat-card-content *ngIf="edit">
        <ng-container *ngFor="let field of fields">
            <field-builder [item]="item" [rootValues]="values" [values]="item.values" [validation]="item.validation" [modify]="item.modify"
                [field]="field" [section]="section" (touch)="touched()"></field-builder>
        </ng-container>
    </mat-card-content>
    <mat-card-content *ngIf="!edit">
        <mat-list dense>
            <field-display *ngFor="let field of fields" [values]="item.values" [rootValues]="item.values" [field]="field"
                [section]="section"></field-display>
        </mat-list>
    </mat-card-content>
</mat-card>