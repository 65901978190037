import { Component, OnInit } from '@angular/core';
import { SettingService } from '../../services/setting/setting.service';
import { Router } from '@angular/router';
import { MainService } from '../../services/main/main.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { SelectService } from '../../services/select/select.service';
import { GlobalConstants } from '../../../../functions/src/shared/global-constants';
import { BackurlService } from 'src/common/services/backurl/backurl.service';

@Component({
  selector: 'app-mains',
  templateUrl: './mains.component.html',
  styleUrls: ['./mains.component.scss']
})
export class MainsComponent implements OnInit {

  public GLOBAL = new GlobalConstants();
  // public items: any[] = [];
  public searchForm: FormGroup;
  public sortServerForm: FormGroup;
  public sortLocalForm: FormGroup = null;
  // public search = '';

  public item: any;

  // public mapParams = {
  //   'bounds': null,
  //   'lat': -20.2674,
  //   'long': 57.5766,
  //   'zoom': 10,
  // }
  public bounds: any = null;

  constructor(
    public setting: SettingService,
    public db: MainService,
    public select: SelectService,
    protected router: Router,
    protected fb: FormBuilder,
    public backURL: BackurlService) {
    this.item = new this.db.itemClass();

    this.searchForm = this.fb.group({
      search: [''],
    });

  }

  ngOnInit(): void {
    this.initFilters();
    if (!this.db.loaded) {
      this.db.subscribe();
    }
    this._initPages();
  }

  protected _initPages() {
  }

  protected initFilters(filters?: any) {

    // Subscribe to Local Filter Change
    this.searchForm.valueChanges.pipe(debounceTime(500)).subscribe(filters => {
      this.db.filterLoading = true;
      setTimeout(() => {
        this.db.setSearchFilter(filters.search);
      }, 10);
    });



    // LOCAL FILTER
    if (this.sortLocalForm) {
      if (this.select.active && this.select.filters && this.select.filters.local) {
        this.sortLocalForm.patchValue(this.select.filters.local);
        this.db.setLocalFilter(this.select.filters.local);
        if (this.select.filters.local.localSearch) {
          this.db.setSearchFilter(this.select.filters.local.localSearch);
        }
      }
      else if (this.db.localFilters) {
        this.sortLocalForm.patchValue(this.db.localFilters);
        this.db.setLocalFilter(this.db.localFilters);
      }

      // Subscribe to Local Filter Change
      this.sortLocalForm.valueChanges.pipe(debounceTime(500)).subscribe(filters => {
        this.db.filterLoading = true;
        setTimeout(() => {
          this.db.setLocalFilter(filters);
        }, 10);
      });
    }

    // SERVER FILTER
    if (this.sortServerForm) {

      if (this.select.active && this.select.filters && this.select.filters.server) {
        this.sortServerForm.patchValue(this.select.filters.server);
      }
      else if (this.db.serverFilters) {
        this.sortServerForm.patchValue(this.db.serverFilters);
      }

      // Subscribe to Server Filter Change
      this.sortServerForm.valueChanges.pipe(debounceTime(500)).subscribe(filters => {
        this.db.serverLoading = true;
        setTimeout(() => {
          this.db.setServerFilter(filters);
        }, 10);
      });
    }

    // SEARCH FILTER
    if (this.db.searchFilter) {
      this.searchForm.patchValue({ search: this.db.searchFilter });
    }
  }

  sortChange(sort: { active: string, direction: 'asc' | 'desc' }) {
    this.db.setLocalOrder(sort);
  }

  addItem() {
    this.backURL.addURL(`/${this.db.moduleListName}`);
    this.router.navigate([`/${this.db.moduleListName}/0`]);
  }

  goPage(moduleName: string) {
    if (moduleName) {
      this.backURL.addURL(`/${this.router.url}`);
      this.router.navigate([`/${moduleName}`]);
    }
  }

  goItem(item: any) {
    if (!this.select.active) {
      this.backURL.addURL(`/${this.db.moduleListName}`);
      this.router.navigate([`/${this.db.moduleListName}/${item.$key}`]);
    }
  }

  clickBack() {
    if (this.select.active) {
      this.select.cancel();
    } else {
      this.router.navigate([this.backURL.back()]);
    }
  }

  mapFilter(params) {
    this.db.itemsPageConfig.mapParams = params;
  }

  scrollItems(index) {
    this.db.itemsPageConfig.scrollIndex = index;
  }

  toggleReferral() {
    this.db.itemsPageConfig.referral = !this.db.itemsPageConfig.referral;
    if (this.db.itemsPageConfig.referral) {

      this.db.setServerFilter(
        {
          filters: {
            referral: this.db.serverFilters.realtor,
            referralAgency: this.db.serverFilters.agency
          },
          realtor: null,
          agency: null,
        }
      );
      this.sortServerForm.patchValue(this.db.serverFilters);
    } else {
      this.db.setServerFilter(
        {
          filters: {
            referral: null,
            referralAgency: null,
          },
          realtor: this.db.serverFilters.filters.referral,
          agency: this.db.serverFilters.filters.referralAgency,
        }
      );
      this.sortServerForm.patchValue(this.db.serverFilters);
    }
  }
}
