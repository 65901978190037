import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Upload, FileRecord } from '../../services/upload-file/upload-file-model';
import { UploadFileService } from '../../services/upload-file/upload-file.service';

@Component({
  selector: 'upload-images',
  templateUrl: './upload-images.component.html',
  styleUrls: ['./upload-images.component.scss']
})
export class UploadImagesComponent {

  dropzoneActive: boolean = false;
  currentUploads: Upload[] = [];


  @Input()
  fileRecords: FileRecord[];

  @Input()
  config: { path: string, filename: string };

  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  options: any;


  constructor(
    public upload: UploadFileService,
  ) {
    this.options = {
      onUpdate: (event: any) => {
        this.changeOrder(event);
      }
    };
  }


  dropzoneState($event: boolean) {
    this.dropzoneActive = $event;
  }

  handleDrop(fileList: FileList) {

    for (let j = 0; j < fileList.length; j++) {
      let newUpload = this.currentUploads.push(new Upload(fileList[j], this.config.path, this.config.filename));
      this.upload.pushUpload(this.currentUploads[newUpload - 1], 'image').then((fileUploaded) => {

        const wm = this;
        setTimeout(function () {
          wm._removeFromCurrentUploads(fileUploaded);
          // Add info
          fileUploaded.info = {
            online: true,
            portal: true,
          }

          wm.action.emit({ action: 'new', file: fileUploaded });
        }, 6000);
        // console.log(fileUploaded);
      });
    }

  }

  changeOrder(e) {
    if (e) {
      this.action.emit({ action: 'order' });
    }
  }

  private _removeFromCurrentUploads(fileUploaded) {
    for (let j = this.currentUploads.length - 1; j >= 0; j--) {
      if (fileUploaded.name.includes(this.currentUploads[j].name)) {
        this.currentUploads.splice(j, 1);
      }
    }
  }

  removeFile(fileIndex) {
    const file = this.fileRecords[fileIndex];
    this.fileRecords.splice(fileIndex, 1);
    this.upload.deleteFileUpload(file)
      .then(() => {
        this.action.emit({ action: 'delete', file: file });
      })
      .catch((error) => {
        if (error.code === 'storage/object-not-found') {
          this.action.emit({ action: 'delete', file: file });
        }

      });
  }

  toggleVisibility(fileIndex: number) {
    if (!this.fileRecords[fileIndex].info) {
      this.fileRecords[fileIndex].info = {};
      this.fileRecords[fileIndex].info.online = false;
    }
    this.fileRecords[fileIndex].info.online = !this.fileRecords[fileIndex].info.online;
    this.action.emit({ action: 'infoUpdate' });
  }

  togglePortal(fileIndex: number) {
    if (!this.fileRecords[fileIndex].info) {
      this.fileRecords[fileIndex].info = {};
      this.fileRecords[fileIndex].info.portal = false;
    }
    this.fileRecords[fileIndex].info.portal = !this.fileRecords[fileIndex].info.portal;
    this.action.emit({ action: 'infoUpdate' });
  }

}
