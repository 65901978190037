import { Component, Input } from '@angular/core';

@Component({
  selector: 'mandate-failed',
  templateUrl: './failed.component.html',
  styleUrls: ['./failed.component.scss']
})
export class MandateFailedComponent {

  @Input() action:any;

  constructor() { }

}
