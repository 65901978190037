import { Component } from '@angular/core';
import { MainsComponent } from '../../common/pages/mains/mains.component';
import { SettingService } from '../../common/services/setting/setting.service';
import { SelectService } from '../../common/services/select/select.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ProjectService } from '../../services/project/project.service';
import { BackurlService } from 'src/common/services/backurl/backurl.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent extends MainsComponent {

  constructor(
    public setting: SettingService,
    public db: ProjectService,
    public select: SelectService,
    protected router: Router,
    protected fb: FormBuilder,
    public backURL: BackurlService,
  ) {
    super(setting, db, select, router, fb, backURL);

    this.sortServerForm = this.fb.group({
      agency: [],
      realtor: [],
      filters: this.fb.group({
        referral: [],
        referralAgency: [],
      }),
      multiFilters: this.fb.group({
        status: [],
      }),
    });

    this.sortLocalForm = this.fb.group({
      localSearch: [''],
      filters: this.fb.group({
        type: ['N'],
      }),
      arrayFilters: this.fb.group({
        unitTypes: [''],
      }),
    });
  }

}