import { Component, Inject } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'datetime-dialog',
  templateUrl: './datetime-dialog.component.html',
  styleUrls: ['./datetime-dialog.component.scss'],
})
export class DatetimeDialogComponent {

  public selectedDate: Date; // Separate property for date
  public selectedTime: string; // Separate property for time
  public timeOptions: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<DatetimeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.selectedDate = data || new Date(); // Initialize selectedDate with the passed data or today's date
    this.selectedTime = this.getFormattedTimeFromData(data);
    this.generateTimeOptions();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    // Adjust for UTC+4 timezone
    if (this.selectedDate) {
      const utcOffset = 14400000; // UTC+4 in milliseconds
      const localDateTime = new Date(this.selectedDate).getTime() + utcOffset;
      const localDate = new Date(localDateTime);

      // Combine the selected date and time into a single Date object
      const dateStr = localDate.toISOString().slice(0, 10);
      const combinedDateTimeStr = dateStr + 'T' + this.selectedTime;
      const combinedDateTime = new Date(combinedDateTimeStr);

      // Pass the combined DateTime to the dialog close
      this.dialogRef.close(combinedDateTime);
    }
  }

  generateTimeOptions() {
    const startTime = 8; // Start at 8:00 AM
    const endTime = 18; // End at 6:00 PM
    const intervalMinutes = 30;

    for (let hour = startTime; hour <= endTime; hour++) {
      for (let minute = 0; minute < 60; minute += intervalMinutes) {
        const timeStr = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        this.timeOptions.push(timeStr);
      }
    }
  }

  private getFormattedTimeFromData(data: any): string {
    if (data && data instanceof Date) {
      const localDateTime = new Date(data).getTime();
      const localDate = new Date(localDateTime);
      return localDate.toTimeString().slice(0, 5);
    }
    return '00:00'; // Default to 00:00 if no data or invalid data
  }
}

// export class DatetimeDialogComponent {

//   public date: string

//   constructor(
//     public dialogRef: MatDialogRef<DatetimeDialogComponent>,
//     @Inject(MAT_DIALOG_DATA) public data: any) {
//     this.date = this._toDateString(data);
//   }

//   private _toDateString(date: Date): string {
//     return (date.getFullYear().toString() + '-'
//       + ("0" + (date.getMonth() + 1)).slice(-2) + '-'
//       + ("0" + (date.getDate())).slice(-2))
//       + 'T' + date.toTimeString().slice(0, 5);
//   }

//   public parseDateString(date: string): Date {
//     if (date) {
//       date = date.replace('T', '-');
//       let parts = date.split('-');
//       let timeParts = parts[3].split(':');

//       // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
//       return new Date(+parts[0], +parts[1] - 1, +parts[2], +timeParts[0], +timeParts[1]);
//     }
//     return null;
//   }

//   onNoClick(): void {
//     this.dialogRef.close();
//   }

// }
