<h1 mat-dialog-title>
    <mat-icon>{{GLOBAL.ICONS.HANDOVER}}</mat-icon>
    Handover
</h1>
<div mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>Agency</mat-label>
        <mat-select [(ngModel)]="data.agency">
            <mat-option *ngFor="let param of 'agencies' | params" [value]="param.id">
                {{param.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-selection-list [multiple]="false" [(ngModel)]="data.realtor">
        <mat-list-option [value]="null">
            <mat-icon matListIcon>person_off</mat-icon>
            <div mat-line>No Agent</div>
        </mat-list-option>
        <mat-list-option *ngFor="let realtor of setting.realtorsAgency[data.agency]" [value]="realtor.id">
            <img matListAvatar [src]="realtor.photo" />
            <div mat-line>{{realtor.name}}</div>
        </mat-list-option>
    </mat-selection-list>
</div>
<div mat-dialog-actions class="buttonsRight">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button [mat-dialog-close]="data.realtor" cdkFocusInitial>Ok</button>
</div>