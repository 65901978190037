import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalConstants } from 'functions/src/shared/global-constants';
import { SettingService } from 'src/common/services/setting/setting.service';

@Component({
  selector: 'dialog-temperature',
  templateUrl: './temperature.component.html',
  styleUrls: ['./temperature.component.scss']
})
export class TemperatureComponent {

  public GLOBAL = new GlobalConstants();
  
  constructor(
    public setting: SettingService,
    public dialogRef: MatDialogRef<TemperatureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.data.temperature = null;
    this.dialogRef.close();
  }

}
