<h1 mat-dialog-title>{{this.data.item.values.vefa ? 'Create VEFA ' : 'Create New '}}Mandate?</h1>
<div mat-dialog-content>
    <!-- <div class="mdc-layout-grid">
        <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell--span-12">
                <form-builder [item]="item" [values]="item.values" section="new" [edit]="true"></form-builder>
            </div>
            <div class="mdc-layout-grid__cell--span-6">
                <form-builder [item]="item" [values]="item.values" section="price" [edit]="true"></form-builder>
            </div>
        </div>
    </div> -->
    <form-builder *ngIf="!this.data.item.values.vefa" [item]="item" [values]="item.values" section="newRealtor"
        (touch)="touched()" [edit]="true"></form-builder>
    <!-- <form-builder *ngIf="this.data.item.values.vefa" [item]="item" [values]="item.values" section="newVEFA" (touch)="touched()" [edit]="true"></form-builder> -->

    <mat-spinner [strokeWidth]="5" class="center" *ngIf="item.saving"></mat-spinner>
</div>
<div mat-dialog-actions class="buttonsRight">
    <button mat-button (click)="onNoClick()">No Thanks</button>
    <button *ngIf="!this.data.item.values.vefa" mat-raised-button color="accent" (click)="onYesClick()"
        [disabled]="!(item.modify && item.valid && !item.saving)" cdkFocusInitial>Ok</button>
    <button *ngIf="this.data.item.values.vefa" mat-raised-button color="accent" (click)="onYesClick()"
        cdkFocusInitial>Ok</button>
</div>