import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MainComponent } from '../../common/pages/main/main.component';
import { Company } from '../../services/company/company.model';
import { Unit } from '../../services/unit/unit.model';
import { SettingService } from '../../common/services/setting/setting.service';
import { CompanyService } from '../../services/company/company.service';
import { SelectService } from '../../common/services/select/select.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { Residence } from 'src/services/residence/residence.model';
import { MatDialog } from '@angular/material/dialog';
import { Contact } from 'src/services/contact/contact.model';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent extends MainComponent {

  public item: Company;
  public residence: Residence;
  public contact: Contact;

  // To Use in Units Table
  public unitModel = new Unit();

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: CompanyService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public backURL: BackurlService,
    public dialog: MatDialog,
  ) {
    super(_cd, setting, db, select, route, router, backURL, dialog);
    this.item = new Company(null, setting);
    this.residence = new Residence(null, setting);
    this.contact = new Contact(null, setting);
  }


}
