<h1 mat-dialog-title>Fail this mandate?</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>Reason</mat-label>
        <mat-select [(ngModel)]="data.failedReason" [required]="true">
            <mat-option *ngFor="let param of 'projectFailedReasons' | params" [value]="param.id">
                {{param.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Note</mat-label>
        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" [(ngModel)]="data.note"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions class="buttonsRight">
    <button mat-button (click)="onNoClick()">No Thanks</button>
    <button mat-raised-button color="accent" (click)="onYesClick()" [disabled]="!data.failedReason" cdkFocusInitial>Ok</button>
</div>