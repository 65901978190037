import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { GlobalConstants } from '../../../functions/src/shared/global-constants';
import { MyActionService } from '../../services/action/action.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { Router } from '@angular/router';
import { SelectService } from 'src/common/services/select/select.service';
import { FormBuilder } from '@angular/forms';
import { MainsComponent } from 'src/common/pages/mains/mains.component';
import { Action } from 'src/services/action/action.model';
import { MyProjectService, ProjectService } from 'src/services/project/project.service';
import { MyMandateService } from 'src/services/mandate/mandate.service';
import { AllMatchingService } from 'src/services/matching/all-matching.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends MainsComponent {

  // public newRequests: ActionService = null;
  public actionItem = new Action(null, this.settings);
  public GLOBAL = new GlobalConstants();

  // public preview: any = null;

  constructor(
    protected afs: AngularFirestore,
    public db: MyActionService,
    public settings: SettingService,
    public backURL: BackurlService,
    protected router: Router,
    public setting: SettingService,
    public select: SelectService,
    protected fb: FormBuilder,
    public myProjects: MyProjectService,
    public myMandates: MyMandateService,
    public myActions: MyActionService,
    public allMatchings: AllMatchingService,
  ) {
    super(setting, db, select, router, fb, backURL);


  }

  protected _initPages() {
    if (!this.myProjects.loaded) {
      this.myProjects.subscribe();
    }
    if (!this.myMandates.loaded) {
      this.myMandates.subscribe();
    }
  }



}
