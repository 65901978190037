import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/common/services/auth/auth.service';
import { MatchingService } from './matching.service';

@Injectable({
  providedIn: 'root'
})
export class AllMatchingService extends MatchingService {

  protected _filters: {
    search: null,
    server: {
      filters?: any,
      multiFilters?: any,
      arrayFilters?: any,
      equalSup?: any,
      equalInf?: any,
      toDays?: number,
      year?: string,
      realtor?: string,
      sort?: { active: string, direction: 'asc' | 'desc' },
    },
    local: {
      localSearch?: string,
      gmap?: any,
      filters?: any,
      arrayFilters?: any,
      synaps?: any,
      equalSup?: any,
      equalInf?: any,
      sort?: { active: string, direction: 'asc' | 'desc' },
      budget?: any,
      matchOnly: boolean,
      newOnly: boolean
      visit: boolean
    },
  } = {
      search: null,
      server: {
        filters: { active: true },
        realtor: this.auth.uid,
      },
      local: {
        matchOnly: false,
        newOnly: false,
        visit: false
      },
    };


  constructor(
    public auth: AuthService,
    public afs: AngularFirestore,
  ) {
    super(auth, afs);
  }

  initFilters() {
    this._filters.server = {
      filters: { active: true },
      realtor: this.auth.uid,
    }
  }

  // protected _localFilter(): void {
  //   this.itemsFilteredOrdered = this.items;
  // }
}

