import { Component, Input, OnInit } from '@angular/core';
import { GlobalConstants } from '../../../../functions/src/shared/global-constants';
import { SettingService } from '../../../common/services/setting/setting.service';

@Component({
  selector: 'mandate-hcard',
  templateUrl: './mandate-hcard.component.html',
  styleUrls: ['./mandate-hcard.component.scss']
})
export class MandateHcardComponent implements OnInit {

  public GLOBAL = new GlobalConstants();

  public _item: any;

  @Input()
  public set mandate(mandate: any) {
    this._item = mandate;
  }
  public get mandate() {
    return this._item;
  }

  
  constructor(
    public setting: SettingService,
  ) { }

  ngOnInit(): void {
  }

}
