import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Agency } from 'src/common/services/auth/auth.model';
import { AuthService } from 'src/common/services/auth/auth.service';

@Component({
  selector: 'app-agencies',
  templateUrl: './agencies.component.html',
  styleUrls: ['./agencies.component.scss']
})
export class AgenciesComponent {

  public agencies : Agency[] = [];

  constructor(
    public auth: AuthService,
    protected router: Router,
  ) {
    auth.agencies$.subscribe(agencies =>{
      this.agencies = agencies;
    });
   }

  addAgency() {
    this.router.navigate([`/settings/agencies/0`]);
  }

  editAgency(agency: Agency) {
      this.router.navigate([`/settings/agencies/${agency.values.$key}`]);
  }

}
