import { Pipe, PipeTransform } from '@angular/core';
import { SettingService } from '../common/services/setting/setting.service';

@Pipe({
  name: 'realtor'
})
export class RealtorPipe implements PipeTransform {

  constructor(
    private _setting?: SettingService,
  ) {
  }

  transform(value: string, args?: boolean): string {
    return this._setting.getUserPhoto(value);
  }

}
