import { Component, OnInit, Input } from '@angular/core';
import { SettingService } from '../../../common/services/setting/setting.service';
import { GlobalConstants } from '../../../../functions/src/shared/global-constants';

@Component({
  selector: 'app-unit-card',
  templateUrl: './unit-card.component.html',
  styleUrls: ['./unit-card.component.scss']
})
export class UnitCardComponent implements OnInit {

  public options: {
    owners: true,
  }
  public _values: any;

  public display: any = null;

  public GLOBAL = new GlobalConstants();

  @Input()
  mandate: boolean = false;

  @Input()
  public set values(values: any) {
    if (values) {
      if (values.list) {
        this.display = values.list;
      } else {
        this.display = values;
      }
    } else {
      this.display = null;
    }
  }

  constructor(
    public setting: SettingService,
  ) { }

  ngOnInit(): void {
  }

}
