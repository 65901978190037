import { Component, OnInit, Input } from '@angular/core';
import { FileRecord } from '../../services/upload-file/upload-file-model';

@Component({
  selector: 'slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements OnInit {

  private _photos: FileRecord[];
  public last: number = 0;
  public pos: number = 0;
  public fullscreen = false;
  public dim = '400';

  @Input()
  set values(values: any) {
    if (values.photos){
    this._photos = values.photos;
    }
    if (this._photos) {
      this.last = this._photos.length;
    }
  }
  get photos() {
    return this._photos;
  }

  // @Input()
  // dim: string = '400';

  constructor() { }

  ngOnInit(): void {
  }

  previous() {
    if (this.pos > 0) {
      this.pos--;
    } else {
      this.pos = this.last;
    }
  }

  next() {
    if (this.pos >= this.last) {
      this.pos = 0;
    } else {
      this.pos++;
    }
  }

  toggleFullscreen() {
    this.fullscreen = !this.fullscreen;
    if (this.fullscreen){
      this.dim = '1200'
    } else {
      this.dim = '400';
    }
  }
}
