<mat-toolbar>

    <div>
        <a mat-icon-button (click)="clickBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <span *ngIf="db.itemsFiltered.length !== db.items.length">{{db.itemsFiltered.length | number}}/{{db.items.length
            | number}} Articles</span>
        <span *ngIf="db.itemsFiltered.length === db.items.length">{{db.items.length | number}} Articles</span>
    </div>
    <div class="form">
        <form [formGroup]="searchForm" novalidate>
            <mat-form-field appearance="fill">
                <mat-label>Search</mat-label>
                <input matInput formControlName="search">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </form>
    </div>
    <div>
        <profile></profile>
    </div>

</mat-toolbar>
<div class="toolbartop"></div>

<div class="main-container-flex">
    <mat-spinner *ngIf="!db.loaded" [strokeWidth]="5"></mat-spinner>
    <app-table [offset]="db.itemsPageConfig.scrollIndex" [item]="item" [items]="db.itemsFilteredOrdered" (sortChange)="sortChange($event)"
        (clickFn)="goItem($event)" (scrollFn)="scrollItems($event)">
    </app-table>
</div>


<button mat-fab class="bottom-right pfixed" [ngClass]="{p3: select.active}" color="basic" (click)="addItem()">
    <mat-icon matListIcon>add</mat-icon>
</button>

<button *ngIf="select.active" mat-fab color="basic" class="bottom-right pfixed p2" (click)="select.cancel()">
    <mat-icon matListIcon>close</mat-icon>
</button>

<button *ngIf="select.active" mat-fab class="bottom-right pfixed" (click)="select.done()">
    <mat-icon matListIcon>done</mat-icon>
</button>