<mat-toolbar class="matchingsbg">
    <div>
        <a mat-icon-button (click)="clickBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <span>{{db.items.length | number}} Matchings</span>
    </div>
    <div>
        <profile></profile>
    </div>
</mat-toolbar>
<div class="toolbartop"></div>


<div class="container split">
    <div class="main">
        <table mat-table [dataSource]="db.itemsFilteredOrdered" class="mat-elevation-z8">

            <ng-container matColumnDef="icon">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon matListIcon [ngClass]="element.iconColor">{{element.icon}}</mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="ref">
                <th mat-header-cell *matHeaderCellDef>Ref</th>
                <td mat-cell *matCellDef="let element"> C{{element.project.ref}} </td>
            </ng-container>

            <ng-container matColumnDef="clients">
                <th mat-header-cell *matHeaderCellDef>Clients</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngFor="let client of element.project.contactsProjects">{{client}}<br />
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="unitTypes">
                <th mat-header-cell *matHeaderCellDef>Unit Types</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon *ngFor="let icon of element.project.unitTypeIcons">{{icon}}</mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="minBedrooms">
                <th mat-header-cell *matHeaderCellDef>Min Beds</th>
                <td mat-cell *matCellDef="let element">{{element.project.minBedrooms}} </td>
            </ng-container>

            <ng-container matColumnDef="budget">
                <th mat-header-cell *matHeaderCellDef>Budget</th>
                <td mat-cell *matCellDef="let element">{{element.project.budget}} </td>
            </ng-container>

            <ng-container matColumnDef="days">
                <th mat-header-cell *matHeaderCellDef>Moving on</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="tools.days(element.project.dateNeed) as days"><span
                            [ngClass]="tools.daysColor(days)">{{days}}</span></ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon *ngFor="let visit of element.visits">{{GLOBAL.ICONS.VISIT}}</mat-icon>
                    <mat-icon *ngIf="element.offer">{{GLOBAL.ICONS.OFFER}}</mat-icon>
                    <mat-icon *ngIf="element.signAgree">{{GLOBAL.ICONS.SIGNAGREE}}</mat-icon>
                    <mat-icon *ngIf="element.signDeed || element.signLease">{{GLOBAL.ICONS.SIGN}}</mat-icon>
                </td>
            </ng-container>

            <!-- <ng-container matColumnDef="realtor">
                <th mat-header-cell *matHeaderCellDef>Agent</th>
                <td mat-cell *matCellDef="let element">
                    <img mat-card-avatar *ngIf="element.project.realtor" class="realtor"
                        [src]="element.project.realtor | realtor"
                        [matTooltip]="element.project.realtor | realtorName" />
                </td>
            </ng-container> -->

            <ng-container matColumnDef="yesno">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button color="primary" *ngIf="element.new" (click)="match(element, $event)">
                        <mat-icon>thumb_up</mat-icon>
                    </button>
                    <button mat-icon-button color="accent" *ngIf="element.new" (click)="unmatch(element, $event)">
                        <mat-icon>thumb_down</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="tableCols; sticky: true">
            </tr>
            <tr mat-row *matRowDef="let row; let element; columns: tableCols;" (click)="clickProject(element)"
                (mouseover)="overMatching(element)">
            </tr>
        </table>
    </div>
    <div class="side">
        <app-matchings-dash [showTitle]="false"></app-matchings-dash>
        <!-- PREVIEW -->
        <app-unit-card [values]="preview" [mandate]="true" (click)="clickMandate(preview)"></app-unit-card>
    </div>

</div>


<!-- <div class="main-container-flex">
    <mat-spinner *ngIf="!db.loaded" [strokeWidth]="5"></mat-spinner>

    <div class="main-container-flex matchings" *ngIf="db.itemsFilteredOrdered && db.itemsFilteredOrdered.length">
        <div class="matching" *ngIf="db.itemsFilteredOrdered[0] as matching">

            <project-card [values]="matching.project" [listDisplay]="true" (click)="goItemModule('projects', matching.project)">
            </project-card>
            <div class="yesno">
                <button mat-icon-button color="primary" (click)="match(matching)">
                    <mat-icon>thumb_up</mat-icon>
                </button>
                <button mat-icon-button color="accent" (click)="unmatch(matching)">
                    <mat-icon>thumb_down</mat-icon>
                </button>
            </div>
            <app-unit-card [values]="matching.mandate" [mandate]="true"
                (click)="goItemModule('mandates', matching.mandate)"></app-unit-card>

        </div>
    </div>-->