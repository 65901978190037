<mat-card class="map-container" [ngClass]="{fsmode: fullscreen, edit: edit}">
    <mat-form-field *ngIf="edit"  style="width:100%">
        <input #searchgm matInput class="form-control" placeholder="Search Location" autocorrect="off"
            autocapitalize="off" spellcheck="off" type="text" [formControl]="searchControl" />
    </mat-form-field>
    <agm-map  [latitude]="params.lat" [longitude]="params.long" [scrollwheel]="true"
        [zoom]="params.zoom" [mapTypeControl]="true" [zoomControl]="control" [streetViewControl]="control">
        <agm-marker *ngIf="address.eGps" iconUrl="https://chart.apis.google.com/chart?chst=d_map_pin_shadow"
            [markerDraggable]="edit" [latitude]="rLatitude" [longitude]="rLongitude" (dragEnd)="rDraggEnded($event)">
        </agm-marker>
        <agm-marker iconUrl="assets/map/blue_pin.png" [markerDraggable]="edit" [latitude]="latitude"
            [longitude]="longitude" (dragEnd)="draggEnded($event)">
            <agm-info-window [isOpen]="windowOpen" (infoWindowClose)="infoWindowClose()">{{addressDisplay}}</agm-info-window>
        </agm-marker>
    </agm-map>
    <button *ngIf="fullscreenMode && !fullscreen" class="fullscreen large" mat-icon-button (click)="toggleFullscreen()">
        <mat-icon>fullscreen</mat-icon>
    </button>
    <button *ngIf="fullscreen" class="fullscreen large" mat-icon-button (click)="toggleFullscreen()">
        <mat-icon>fullscreen_exit</mat-icon>
    </button>
    <button *ngIf="edit && changed" mat-fab class="top-right abs" (click)="save()">
        <mat-icon matListIcon>check</mat-icon>
    </button>
</mat-card>