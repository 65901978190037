import { Component, OnInit, Input } from '@angular/core';
import { GlobalConstants } from '../../../functions/src/shared/global-constants';
import { SettingService } from '../../common/services/setting/setting.service';

@Component({
  selector: 'app-mandate-list-card',
  templateUrl: './mandate-list-card.component.html',
  styleUrls: ['./mandate-list-card.component.scss']
})
export class MandateListCardComponent implements OnInit {

  public options: {
    owners: true,
  }
  public _item: any;

  public GLOBAL = new GlobalConstants();
  public activeMandates: any[] = [];

  @Input()
  public set values(values: any) {
    this._item = values;
  }
  public get values() {
    return this._item;
  }

  constructor(
    public setting: SettingService,
  ) { }

  ngOnInit(): void {

  }

}
