import { Injectable } from '@angular/core';
import { Upload, FileRecord } from './upload-file-model';
import { AngularFirestore } from '@angular/fire/firestore/firestore';
import { HttpClient } from '@angular/common/http';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  constructor(
    public http: HttpClient,
  ) {
  }

  pushUpload(upload: Upload, type: string): Promise<FileRecord> {

    return new Promise(resolve => {

      if (type && !this._typeCheck(upload, type)) {
        resolve(null);
      }

      const re = /(?:\.([^.]+))?$/;
      const fileExtension = re.exec(upload.file.name)[1].toLowerCase();

      let storageRef = firebase.storage().ref();
      // console.log(`${upload.path}${upload.name}.${fileExtension}`);
      const filename = `${upload.name}-${this._random()}.${fileExtension}`;
      let uploadTask = storageRef.child(`${upload.path}${filename}`).put(upload.file);

      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          // upload in progress
          const snap = snapshot as firebase.storage.UploadTaskSnapshot;
          upload.progress = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
        },
        (error) => {
          // upload failed
          console.log(error)
        },
        () => {
          // upload success
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            let fileRecord: FileRecord = {
              url: downloadURL,
              name: filename,
              path: upload.path,
              type: upload.file.type,
            }

            // Return the file Record
            resolve(fileRecord);
          });
        }
      );
    });
  }


  protected _random(): string {
    return Math.random().toString(36).substr(2, 9);
}

  deleteFileUpload(file: FileRecord): Promise<any> {
    const storageRef = firebase.storage().ref();
    return storageRef.child(`${file.path}${file.name}`).delete();
  }

  public async downloadResource(urlO: string): Promise<Blob> {
    const file = await this.http.get<Blob>(
      urlO,
      { responseType: 'blob' as 'json' }).toPromise();
    return file;
  }

  private _typeCheck(upload: Upload, type: string): boolean {
    return upload.file.type.includes(type);
  }
}
