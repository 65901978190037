<div class="visitreport">
    <mat-card *ngIf="action.done" class="mi">
        <mat-card-header>
            <mat-icon mat-card-avatar [ngClass]="action.com ? 'active' : 'hot'">
                {{action.com ? (action.medium | icon: 'mediums') : GLOBAL.ICONS.NONE}}
            </mat-icon>
            <mat-card-title>{{action.com ? ('Reported on ' + (action.doneDate.toDate() | date:'dd MMM yyyy - HH:mm')) :
                'Not Reported'}}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="action.note">
            <p>{{action.note}}</p>
        </mat-card-content>
    </mat-card>


    <div *ngIf="!action.done" class="mi">
        <form-builder [item]="item" [values]="item.values" section="main" [edit]="true"> </form-builder>
    </div>

    <mat-card *ngIf="match" class="mi">
        <mat-card-header>
            <mat-icon mat-card-avatar [ngClass]="action.com ? 'active' : 'hot'">
                {{GLOBAL.ICONS.PROJECT}}
            </mat-icon>
            <mat-card-title>C{{match.project.ref}} - <ng-container
                    *ngFor="let contact of match.project.contactsProjects">
                    {{contact}} - </ng-container>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <star-rating [rating]="match.rate" [edit]="false"></star-rating><br />
            <p>
                <span *ngFor="let txt of match.pos">+ {{txt}}<br /></span>
                <span *ngFor="let txt of match.neg">- {{txt}}<br /></span>
            </p>
        </mat-card-content>
    </mat-card>
</div>
<div class="actionButtons" *ngIf="!action.done &&  isRealtor()">
    <button mat-icon-button color="accent" [disabled]="!item.valid || !item.modify || item.saving"
        (click)="saveAction()">
        <mat-icon>check</mat-icon>
    </button>
    <button mat-icon-button class="active" [disabled]="item.saving || !item.valid" (click)="validAction(true)">
        <mat-icon>task_alt</mat-icon>
    </button>
    <button mat-icon-button [disabled]="item.saving" (click)="validAction(false)">
        <mat-icon>{{GLOBAL.ICONS.NONE}}</mat-icon>
    </button>
</div>