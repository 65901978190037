import { Component, Input, OnInit } from '@angular/core';
import { GlobalConstants } from '../../../../functions/src/shared/global-constants';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent {

  public GLOBAL = new GlobalConstants();

  @Input() contact: any;

  public contactList = null;

  @Input() set contacts(contacts: any) {
    this.contactList = [];
    if (Array.isArray(contacts)) {
      for (const fullname of contacts) {
        this.contactList.push({ fullname: fullname });
      }
    } else {
      for (const contactKey in contacts) {
        this.contactList.push(Object.assign(contacts[contactKey], { $key: contactKey }));
      }
    }
  }

  constructor() { }

}
