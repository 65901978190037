<mat-form-field style="width:100%">
    <input #searchgm matInput class="form-control" placeholder="Search Location" autocorrect="off" autocapitalize="off"
        spellcheck="off" type="text" />
</mat-form-field>
<agm-map (boundsChange)="boundsChange($event)" (centerChange)="centerChange($event)" (zoomChange)="zoomChange($event)" [latitude]="paramInit.lat"
    [longitude]="paramInit.long" [scrollwheel]="true" [zoom]="paramInit.zoom" [mapTypeControl]="true"
    zoomControlOptions="BOTTOM_LEFT">
    <agm-marker-cluster
        imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
        <agm-marker *ngFor="let marker of markers;  let j = index" [latitude]="marker.gps?.latitude"
            [longitude]="marker.gps?.longitude" data-ref="{marker.ref}" [iconUrl]="{url: 'assets/map/' + marker.icon + '-' + (marker.iconColor == 'active' ? 'blue' : 'grey') + '.svg', scaledSize: {height: 30, width: 30}}"
            [ngClass]="marker.iconColor" (mouseOver)="markerOver(marker)" (markerClick)="markerClick(marker)">
        </agm-marker>
        <!-- (mouseOut)="markerOut(j)" -->
    </agm-marker-cluster>
    <!-- <agm-marker *ngIf="agmmarker.show" [latitude]="agmmarker.latitude" [longitude]="agmmarker.longitude" [zIndex]="999"
        iconUrl="assets/map/red_pin.png">
    </agm-marker> -->
</agm-map>