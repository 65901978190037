<mat-toolbar>

    <div>
        <button mat-icon-button (click)="goBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <div class="title">
            <mat-icon>{{GLOBAL.ICONS.NEWREQUEST}}</mat-icon>
            <ng-container *ngIf="new">New Request</ng-container>
            <ng-container *ngIf="!new">New {{item.values.data?.origin | param:"projectOrigins"}}
                {{item.values.data?.projectType | param:"projectTypes"}} Request</ng-container>
        </div>
    </div>
    <div>
        <profile></profile>
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="logs()">
                <mat-icon>{{GLOBAL.ICONS.LOGS}}</mat-icon>
                <span>Logs</span>
            </button>
        </mat-menu>
    </div>

</mat-toolbar>
<div class="toolbartop"></div>


<!------------------------------ CREATE NEW  ----------------------------------------->

<div class="mdc-layout-grid" *ngIf="new">
    <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell--span-8">
            <form-builder [item]="item" [values]="item.values" section="agencymain" [edit]="true"
                (touch)="newRequestModified()"></form-builder>
        </div>
        <div class="mdc-layout-grid__cell--span-4">
            <app-unit-card [values]="preview" [mandate]="true"></app-unit-card>
            <mat-card *ngIf="!preview && item.values.data?.ref">
                <mat-card-header>
                    <mat-card-title>Wrong Ref Number</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <p>No mandate linked to this reference</p>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>



<!------------------------------ EDIT MODE  ----------------------------------------->

<table mat-table *ngIf="matchingProjects.length" [dataSource]="matchingProjects" class="mat-elevation-z8">

    <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <mat-icon matListIcon [ngClass]="element.status">{{element.status | icon :'projectStatus'}}</mat-icon>
        </td>
    </ng-container>

    <ng-container matColumnDef="ref">
        <th mat-header-cell *matHeaderCellDef>Ref</th>
        <td mat-cell *matCellDef="let element"> C{{element.ref}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let element"> {{element.type | param:'projectTypes'}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element">
            {{element.dateNeed?.toDate() | date:'dd MMM yyyy'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="unitTypes">
        <th mat-header-cell *matHeaderCellDef>Unit Types</th>
        <td mat-cell *matCellDef="let element">
            <mat-icon *ngFor="let unitType of element.unitTypes">{{unitType | icon:'unitTypes'}}
            </mat-icon>
        </td>
    </ng-container>

    <ng-container matColumnDef="minBedrooms">
        <th mat-header-cell *matHeaderCellDef>Min Beds</th>
        <td mat-cell *matCellDef="let element">{{element.minBedrooms}}</td>
    </ng-container>

    <ng-container matColumnDef="budget">
        <th mat-header-cell *matHeaderCellDef>Budget</th>
        <td mat-cell *matCellDef="let element">{{element.maxBudget | number}} {{element.cyBudget | param:'cies'}}</td>
    </ng-container>



    <ng-container matColumnDef="realtor">
        <th mat-header-cell *matHeaderCellDef>Agent</th>
        <td mat-cell *matCellDef="let element">
            <img mat-card-avatar *ngIf="element.realtor" class="realtor" [src]="element.realtor | realtor" [matTooltip]="element.realtor | realtorName"  />
        </td>
    </ng-container>

    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button color="primary"
                *ngIf="element.type === item.values.data?.projectType && element.status === 'failed'"
                (click)="projectSelect(element, true)">
                <mat-icon>refresh</mat-icon>
            </button>
            <button mat-icon-button color="accent"
                *ngIf="element.type === item.values.data?.projectType && (element.status === 'active' || element.status === 'new' || element.status === 'discovery')"
                (click)="projectSelect(element)">
                <mat-icon>check</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row
        *matHeaderRowDef="['icon', 'date', 'ref', 'type', 'unitTypes', 'minBedrooms', 'budget', 'realtor', 'select']; sticky: true">
    </tr>
    <tr mat-row
        *matRowDef="let row; let element; columns: ['icon', 'date', 'ref', 'type', 'unitTypes', 'minBedrooms', 'budget', 'realtor', 'select'];">
    </tr>
</table>

<mat-spinner [strokeWidth]="5" [diameter]="50" *ngIf="projectLoading"></mat-spinner>


<div class="mdc-layout-grid" *ngIf="!new">
    <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell--span-4">
            <new-request-card [values]="item.values"></new-request-card>
        </div>
        <div class="mdc-layout-grid__cell--span-4" *ngIf="item.values.synaps?.mandatesActionsObj">
            <app-unit-card [values]="item.list?.mandate" [mandate]="true"></app-unit-card>
        </div>
        <div class="mdc-layout-grid__cell--span-4" *ngIf="!item.values.synaps?.mandatesActionsObj">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>No Mandate</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <p>No mandate linked to this request</p>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="mdc-layout-grid__cell--span-4">
            <mat-card *ngIf="!item.values.synaps?.contactActionsObj">
                <mat-card-header>
                    <mat-card-title>No Contact</mat-card-title>
                </mat-card-header>
            </mat-card>
            <app-contact-card *ngIf="item.values.synaps?.contactActionsObj"
                [contact]="item.values.synaps.contactActionsObj"></app-contact-card>
        </div>
    </div>
</div>

<mat-spinner [strokeWidth]="5" class="fixed" *ngIf="!(pageConfig.loaded || new)"></mat-spinner>


<!------------------------------ BUTTONS  ----------------------------------------->
<!-- NEW -->
<button *ngIf="new" mat-fab class="bottom-right pfixed"
    [disabled]="!item.valid || !item.modified || item.saving || (!preview && item.values.data?.ref)"
    (click)="saveNewItem()">
    <mat-icon matListIcon>check</mat-icon>
</button>
<ng-container *ngIf="!new">
    <!-- SWITH REALTOR-->
    <button mat-fab class="bottom-right pfixed p3" (click)="selectRealtor()"
        [color]="item.values.realtor ? 'basic' : 'accent'">
        <mat-icon>{{GLOBAL.ICONS.HANDOVER}}</mat-icon>
    </button>
    <!-- SWITH CONTACT-->
    <button mat-fab class="bottom-right pfixed p2" [color]="item.values.synaps?.contactActionsObj ? 'basic' : 'accent'"
        (click)="selectContact()">
        <mat-icon matListIcon>person_add</mat-icon>
    </button>
    <!-- CREATE PROJECT-->
    <button mat-fab class="bottom-right pfixed" (click)="createProject()"
        [disabled]="!(item.values.synaps?.contactActionsObj && item.values.realtor && !projectCreated)">
        <mat-icon matListIcon>check</mat-icon>
    </button>

    <!-- REMOVE NEW REQUEST-->
    <button mat-fab class="bottom-left pfixed" color="basic" (click)="removeNewRequest()">
        <mat-icon matListIcon>delete</mat-icon>
    </button>
</ng-container>