import { Component, ChangeDetectorRef, Input, Inject, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { MainComponent } from '../../common/pages/main/main.component';
import { Mandate } from '../../services/mandate/mandate.model';
import { SettingService } from '../../common/services/setting/setting.service';
import { MandateService } from '../../services/mandate/mandate.service';
import { SelectService } from '../../common/services/select/select.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionService } from '../../services/action/action.service';
import { MatchingService } from '../../services/matching/matching.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwapAgentComponent } from '../../components/actions-dialog/swap-agent/swap-agent.component';
import { DialogConfirmationComponent } from '../../common/components/dialog-confirmation/dialog-confirmation.component';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { Observable, Subscription } from 'rxjs';
import * as firebase from 'firebase/app';
import { TemperatureComponent } from 'src/components/actions-dialog/temperature/temperature.component';
import { Tools } from 'src/common/services/tools';
import { UnitService } from 'src/services/unit/unit.service';
import { Unit } from 'src/services/unit/unit.model';
import { Action } from 'src/services/action/action.model';
import { ActionSignMandate } from 'src/services/action/actions/action-sign-mandate.model';

@Component({
  selector: 'app-mandate',
  templateUrl: './mandate.component.html',
  styleUrls: ['./mandate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MandateComponent extends MainComponent {

  public tools = new Tools();
  public item: Mandate;
  public matching: MatchingService = null;
  // public nextActions: any[] = [];
  public displayAction: any;
  public unitService: UnitService = null;
  private _unit$: Observable<any>;
  public unit: Unit;
  public unitPortals: any[] = [];
  public showPortalDash = true;
  private _unitSubscription: Subscription;
  private _actionSubscription: Subscription;
  private _matchingSubscription: Subscription;
  public matchNb: { new: number, match: number, total: number } = { new: 0, match: 0, total: 0 };
  public dashActions: { [actionType: string]: { icon: string, label: string, nb: number, timeago: string } } =
    {
      newRequest: { icon: this.GLOBAL.ICONS.NEWREQUEST, label: 'Leads', nb: 0, timeago: '' },
      visitReport: { icon: this.GLOBAL.ICONS.VISIT, label: 'Visits', nb: 0, timeago: '' },
      offer: { icon: this.GLOBAL.ICONS.OFFER, label: 'Offers', nb: 0, timeago: '' }
    };
  public dashDisplayActions = ['newRequest', 'visitReport', 'offer'];
  public matchOnly: boolean = true;
  // private _portalInit = false;

  public actionButtons: { id: string, icon: string, color?: string, label?: string }[] = [];


  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: MandateService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public dialog: MatDialog,
    public backURL: BackurlService,
    public actionService: ActionService,
  ) {
    super(_cd, setting, db, select, route, router, backURL, dialog);
    this.item = new Mandate(null, setting);
    this.unit = new Unit(null, setting);
    this.matching = new MatchingService(this.db.auth, this.db.afs);
    // this.actionService = new ActionService(this.db.auth, this.db.afs);
    this.unitService = new UnitService(this.db.auth, this.db.afs);
  }

  get actionSynap(): any {
    return { 'mandatesActions': { [this.item.values.$key]: this.item.createAlias(['unitMandates']) } };
  }



  _itemOnInit() {

    // Bind Unit
    this._syncUnit();

    // Set Filters
    if (this.item.values.$key) {
      this.actionService.setServerFilter(
        {
          filters: {
            'mandate.$key': this.item.values.$key
          },
          sort: { active: 'date', direction: 'desc' },
        }
      );

      this._actionSubscription = this.actionService.refresh.subscribe(() => {
        this._updateDash();
        this._cd.detectChanges();
      })

      this.matching.setServerFilter(
        {
          filters: {
            'mandate.$key': this.item.values.$key
          },
        }
      );
      this._matchingSubscription = this.matching.refresh.subscribe(() => {
        this._cd.detectChanges();
      })
    }
  }

  _ngOnDestroy() {
    if (this._unitSubscription) {
      this._unitSubscription.unsubscribe();
    }
    if (this._actionSubscription) {
      this._actionSubscription.unsubscribe();
    }
    if (this._matchingSubscription) {
      this._matchingSubscription.unsubscribe();
    }
  }

  protected _syncUnit() {
    this._unit$ = this.unitService.getDataObs(this.item.values.synaps.unitMandatesObj.$key);

    this._unitSubscription = this._unit$.subscribe((a) => {
      this.unitPortals = []
      this.unit.fromFB(a.payload.data());
      if (a.payload.exists) {
        if (this.unit.values.syncWeb && this.unit.values.syncWeb[this.item.values.type]) {
          for (const portal in this.unit.values.syncWeb[this.item.values.type]) {
            this.unitPortals.push(Object.assign(
              this.unit.values.syncWeb[this.item.values.type][portal],
              {
                name: portal,
                url: this.createPortalLink(portal, this.unit.values.syncWeb[this.item.values.type][portal], this.item.values.type, this.item.values.ref, this.unit.values.slug)
              }
            ));
          }
        }
      }
      this._cd.detectChanges();
    });
  }

  protected _afterBindUpdates() {
    this._manageMenu()

    // this._updateDash();

    this._updateMatchingInfo();
  }

  protected _manageMenu() {
    this.actionButtons = [{
      id: 'sms',
      icon: this.GLOBAL.ICONS.NOTE,
      color: 'link',
      label: 'Add Note',
    }];

    if (this.item.values.status !== 'failed' && this.item.values.status !== 'deal') {
      this.actionButtons.push({
        id: 'temperature',
        icon: this.GLOBAL.ICONS.TEMPERATURE,
        color: 'warn',
        label: 'Change Temperature',
      });
      this.actionButtons.push({
        id: 'call',
        icon: 'phone',
        color: 'primary',
        label: 'Call',
      });
      this.actionButtons.push({
        id: 'NACall',
        icon: 'phone_disabled',
        color: 'primary',
        label: 'Not Answering',
      });
      this.actionButtons.push({
        id: 'email',
        icon: 'email',
        color: 'primary',
        label: 'Message',
      });
      if (this.item.values.status === 'active') {
        this.actionButtons.push({
          id: 'price',
          icon: this.GLOBAL.ICONS.PRICER,
          color: 'accent',
          label: 'Change Price',
        });
      }
    }
  }

  private _updateMatchingInfo() {
    // Update Matchings Values
    this.matchNb = { new: 0, match: 0, total: 0 };
    if (this.item.values.synaps && this.item.values.synaps.mandateMatchings) {
      for (const matchKey in this.item.values.synaps.mandateMatchings) {
        if (this.item.values.synaps.mandateMatchings[matchKey].new) {
          this.matchNb.new++;
        } else if (this.item.values.synaps.mandateMatchings[matchKey].match) {
          this.matchNb.match++;
          this.matchNb.total++;
        } else {
          this.matchNb.total++;
        }
      }
    }
  }

  private _updateDash() {
    // Updates Actions Values

    if (this.actionService.items) {

      this.dashActions.newRequest.nb = 0;
      this.dashActions.newRequest.timeago = '';
      // this.nextActions = [];
      this.displayAction = null;
      this.showPortalDash = true;

      for (const action of this.actionService.items) {
        {
          // Update Summary Nb of Actions
          if (this.dashActions[action.type]) {
            if (!this.dashActions[action.type].timeago) {
              this.dashActions[action.type].timeago = this.tools.timeago(action.date);
            }
            this.dashActions[action.type].nb++;
            // Update Portal Nb of Leads
            if (action.type === 'newRequest' && this.unitPortals.length) {
              for (const portal of this.unitPortals) {
                if (portal.name === action.origin) {
                  !portal.nb ? portal.nb = 1 : portal.nb++;
                }
              }
            }
          }
          // Check if can show Portal Edit
          if (action.type === 'advertise' && !action.done) {
            this.showPortalDash = false;
          }
          //Get Last Action
          if (!action.done) {
            this.displayAction = action;
          }
        }
      }
      if (!this.displayAction && this.actionService.items && this.actionService.items[0]) {
        this.displayAction = this.actionService.items[0];
      }
    }
  }

  colorMandateDate(): string {
    if (this.item.values.type === 'sale') {
      if (this.item.values.dateStart) {
        const days = Math.floor(
          (firebase.firestore.Timestamp.now().seconds - this.item.values.dateStart.getTime() / 1000) / 86400
        );

        if (days < 90) {
          return 'active';
        }
        if (days < 180) {
          return 'warm';
        }
        return 'hot';
      }
    } else {
      if (this.item.values.dateAvailable) {
        const days = Math.floor(
          (firebase.firestore.Timestamp.now().seconds - this.tools.getMTime(this.item.values.dateAvailable) / 1000) / 86400
        );
        if (days < 7) {
          return 'active';
        }
        if (days < 15) {
          return 'warm';
        }
        return 'hot';
      }
    }
    return '';
  }

  beforeSaveItem() {
    // check if need to launch matching
    if (this.item.values.status === 'active') {
      this.item.values.matching = true;
      this.item.modify.matching = true;
    }
  }


  tabChanged() {
    if (this.pageConfig.mainTab === 2) {
      this.matching.setLocalFilter({ matchOnly: this.matchOnly, newOnly: false });
    } else if (this.pageConfig.mainTab === 3) {
      this.matching.setLocalFilter({ matchOnly: false, newOnly: true });
    }

  }

  moveTab(tab: number) {
    this.pageConfig.mainTab = tab;
  }

  backToMatching() {
    this.pageConfig.mainTab = 2;
  }

  createActionMenu(actionType: string) {
    const data: any = {};

    if (actionType === 'temperature') {
      this._temperature();
    } else {
      if (actionType === 'price') {
        data.cy = this.item.values.cy;
      }
      this.actionService.createAction(Action, actionType,
        [{ item: this.item }],
        {
          done: actionType === 'NACall' ? true : false,
          realtor: this.item.values.realtor,
          agency: this.item.values.agency,
          data: { data: data },
          syncList: true
        });
      this._updateDash();
      // this.goTab(1);
    }
  }




  actionDone(action: any) {
    this.saveItem();

    if (action.type === 'signMandate') {
      this.pageConfig.mainTab = 0;
    }
  }


  handover() {
    const data = { realtor: [this.item.values.realtor], agency: this.item.values.agency };
    const dialogRef = this.dialog.open(SwapAgentComponent, {
      width: '450px',
      disableClose: true,
      data: data
    });

    dialogRef.afterClosed().subscribe(async () => {
      if ((data.realtor && data.realtor[0] && data.realtor[0] !== this.item.values.realtor) || (data.agency && data.agency !== this.item.values.agency)) {
        this.startSaving();
        this.item.values.actionUpdated = new Date();
        this.item.modify.actionUpdated = true;
        this.item.values.realtor = data.realtor[0];
        this.item.modify.realtor = true;
        this.item.values.agency = data.agency;
        this.item.modify.agency = true;
        this.saveItem();
      }
    });
  }

  private _temperature() {
    const data: any = { temperature: [this.item.values.temperature], icon: this.GLOBAL.ICONS.PROJECT };
    const dialogRef = this.dialog.open(TemperatureComponent, {
      width: '450px',
      disableClose: true,
      data: data
    });


    dialogRef.afterClosed().subscribe(async () => {
      if (data.temperature && data.temperature[0] && data.temperature[0] !== this.item.values.temperature) {
        // this._changeStatus('active');
        this.startSaving();
        this.item.values.actionUpdated = new Date();
        this.item.modify.actionUpdated = true;
        this.item.values.temperature = data.temperature[0];
        this.item.modify.temperature = true;
        this.saveItem();
      }
    });

  }

  acceptMandate() {
    this._changeStatus('pending', false);
  }

  failed() {
    const data = { ok: false, failedReason: null, note: null };
    const dialogRef = this.dialog.open(DialogFailedMandateComponent, {
      width: '350px',
      disableClose: true,
      data: data
    });

    dialogRef.afterClosed().subscribe(async () => {
      if (data.ok) {
        this.startSaving();
        this.item.values.actionUpdated = new Date();
        this.item.modify.actionUpdated = true;
        this.item.values.failedReason = data.failedReason;
        this.item.modify.failedReason = true;
        this.item.values.failedNote = data.note;
        this.item.modify.failedNote = true;
        this.item.values.status = 'failed';
        this.item.modify.status = true;
        this.item.values.matching = true;
        this.item.modify.matching = true;
        this.saveItem();
      }
    });

  }


  pause(pause: boolean) {

      if (!pause) {
      const data = { title: 'Active the Mandate?', ok: false };
      const dialogRef = this.dialog.open(DialogConfirmationComponent, {
        width: '250px',
        disableClose: true,
        data: data
      });


      dialogRef.afterClosed().subscribe(() => {
        if (data.ok) {
          // Check the last actions -> go to status pending if necessary an recreate the appropriate action
          let signMandate = false;
          if (this.item.values.synaps.mandatesActions) {
            for (const actionKey in this.item.values.synaps.mandatesActions) {
              if (this.item.values.synaps.mandatesActions[actionKey] && this.item.values.synaps.mandatesActions[actionKey].type === 'signMandate') {
                signMandate = true;
              }
            }
          }
          if (signMandate) {
            this._changeStatus('active');
          } else {
            this._changeStatus('pending');
          }
        }
      });
    }
  }

  private _changeStatus(status: string, match = true) {
    this.item.values.status = status;
    this.item.modify.status = true;
    this.item.values.actionUpdated = new Date();
    this.item.modify.actionUpdated = true;
    if (match) {
      this.item.values.matching = true;
      this.item.modify.matching = true;
    }
    this.saveItem();
  }

  public forceMandateSync(portal: string | null = null) {
    this.db.forceMandateSync(this.item, portal);
  }

  public createPortalLink(name: string, portal: any, mandateType: string, mandateRef: number, slug: any) {
    if (name === 'web') {
      let url = 'https://www.nestenn.mu/fr/';
      if (mandateType === 'rental') {
        url += 'location-ile-maurice/';
      } else {
        url += 'achat-ile-maurice/';
      }
      url += slug ? slug.fr : 'error';
      return url;
    }
    if (name === 'lp') {
      let url = 'https://www.lexpressproperty.com/fr/';
      if (mandateType === 'rental') {
        url += 'louer-ile-maurice/appartement-';
      } else {
        url += 'acheter-ile-maurice/maison-';
      }
      url += portal.id;
      return url;
    }
    if (name === 'pc') {
      let url = 'https://www.propertycloud.mu/';
      if (mandateType === 'rental') {
        url += 'property-for-rent?propertyref_box=NES';
      } else {
        url += 'property-for-sale?propertyref_box=NES';
      }
      url += mandateRef;
      return url;
    }
    if (name === 'je') {
      let url = 'https://www.jamesedition.com/real_estate/mauritius/';
      return url;
    }
    if (name === 'pm') {
      let url = 'https://www.propertymap.mu';
      if (mandateType === 'rental') {
        url += 'property-rent?search=NES';
      } else {
        url += 'buy-property?search=NES';
      }
      url += mandateRef;
      return url;
    }
    return '';
  }
}



@Component({
  selector: 'app-dialog-failed-mandate',
  templateUrl: './failed-mandate.component.html',
})
export class DialogFailedMandateComponent {

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    this.data.ok = true;
    this.dialogRef.close();
  }
}




