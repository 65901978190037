import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { addZero } from 'functions/src/shared/tools';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingService } from 'src/common/services/setting/setting.service';

@Injectable({
  providedIn: 'root'
})
export class StatService {

  private _itemsSubcription: any;
  public statList$: Observable<any[]> = null
  public statList: any[] = [];
  public statChart: any[] = [];
  public statChartSeries: { [seriesName: string]: any } = {};
  public loaded = false;

  public statCols = {
    marketing: []
  }

  constructor(
    protected afs: AngularFirestore,
    protected setting: SettingService,
  ) {


  }

  initList(statReport: string, agency: string, realtor: string, year: number, type: string) {


    // this.stats$ = this._colWithIds(`/stats`, (ref) => this._queryFn(ref, module, $key));

    let dbpath = `/stats/${statReport}/${year ? 'months' : 'years'}/`;


    // if (agency) {
    //   dbpath += `/agencies/${agency}`;
    //   if (realtor) {
    //     dbpath += `/realtors/${realtor}`;
    //   }
    // }

    this.statList$ = this._colWithIds(dbpath, (ref) => this._queryFn(ref, year, agency, realtor));

    this.loaded = false;

    if (this._itemsSubcription) {
      this._itemsSubcription.unsubscribe();
    }

    this._itemsSubcription = this.statList$.subscribe(items => {
      this.statList = items;
      this.statChart = this._toChart(items, type, year);
      this.loaded = true;
    });
  }

  private _queryFn(ref, year: number, agency: string, realtor: string): any {

    let query = ref;

    if (year) {
      query = query.where('year', '==', year);
    }
    if (realtor) {
      query = query.where('realtor', '==', realtor);
      query = query.where('agency', '==', 'all');
    } else {
      query = query.where('realtor', '==', 'all');
      if (agency) {
        query = query.where('agency', '==', agency);
      } else {
        query = query.where('agency', '==', 'all');
      }
    }
    if (year) {
      query = query.orderBy('month', 'asc');
    } else {
      query = query.orderBy('year', 'asc');
    }

    return query;
  }

  private _toChart(items: any[], type: string, year: number): any[] {
    const tmp: any[] = [];
    this.statChartSeries = {};
    for (const item of items) {

      const obj = {
        name: '' + (year ? addZero(item.month) : item.year),
        series: []
      }

      if (type && item[type]) {
        for (const field of this.setting.getParamsSelect('projectOrigins')) {
          if (item[field.id]) {
            obj.series.push({ name: field.name, value: item[type][field.id] ? item[type][field.id] : 0 });
          }
        }
      } else {
        for (const field of this.setting.getParamsSelect('projectOrigins')) {
          if (item[field.id]) {
            obj.series.push({ name: field.name, value: item[field.id] ? item[field.id] : 0 });
          }
        }
      }

      this.statChartSeries[obj.name] = obj.series;

      tmp.push(obj);
    }
    return tmp;
  }


  initStatCols() {
    this.statCols.marketing = this.setting.getParamsSelect('projectOrigins');
  }


  protected _colWithIds(path, queryFn?): Observable<any[]> {
    return this.afs.collection(path, queryFn).snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            return a.payload.doc.data();
            // return new this.itemClass(a.payload.doc.data());
          });
        })
      );
  }
}
