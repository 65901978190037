import { Component, EventEmitter, Input, Output } from '@angular/core';
import { speedDialFabAnimations } from './speed-dial-fab.animations';

@Component({
  selector: 'app-speed-dial-fab',
  templateUrl: './speed-dial-fab.component.html',
  styleUrls: ['./speed-dial-fab.component.scss'],
  animations: speedDialFabAnimations
})
export class SpeedDialFabComponent {

  @Input()
  fabButtons: { id: string, icon: string, color?: string, label?: string }[] = [];

  buttons = [];
  fabTogglerState = 'inactive';

  @Output() clickFn: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons ? this.fabButtons : [];
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  click(id: string) {
    this.clickFn.emit(id);
    this.hideItems();
  }
}
