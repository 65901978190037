<!-- MANDATE DETAILS -->
<mat-card class="backspace" *ngIf="!edit">
    <mat-list>
        <mat-list-item *ngIf="item.values.exclusive">
            <mat-icon matListIcon matTooltip="Mandate Type" class="active">{{GLOBAL.ICONS.EXCLUSIVE}}</mat-icon>
            <div matLine>
                <b>Nestenn Contract</b>
            </div>
        </mat-list-item>
        <mat-list-item *ngIf="!item.values.exclusive">
            <mat-icon matListIcon matTooltip="Mandate Type">{{GLOBAL.ICONS.CONTRACT}}</mat-icon>
            <div matLine>
                <b>Simple Mandate</b>
            </div>
        </mat-list-item>
        <mat-list-item *ngIf="item.values.dateStart">
            <mat-icon matListIcon matTooltip="Signed on">{{GLOBAL.ICONS.SIGN}}</mat-icon>
            <div matLine>
                <b>{{item.values.dateStart | date:'dd MMM yyyy'}}</b>
            </div>
            <div matLine *ngIf="item.values.duration">
                <i>{{item.values.duration}} Months</i>
            </div>
        </mat-list-item>
        <mat-list-item *ngIf="isDateAvailable()">
            <mat-icon matListIcon matTooltip="Available on">{{GLOBAL.ICONS.DATE}}</mat-icon>
            <div matLine>
                <b>{{item.values.dateAvailable | date:'dd MMM yyyy'}}</b>
            </div>
        </mat-list-item>
        <mat-list-item *ngIf="item.values.origin">
            <mat-icon matListIcon matTooltip="Origin">{{GLOBAL.ICONS.NEWREQUEST}}</mat-icon>
            <div matLine>
                {{item.values.origin | param:'mandateOrigins'}}
            </div>
        </mat-list-item>
        <mat-list-item *ngIf="item.values.price">
            <mat-icon matListIcon matTooltip="Price">{{GLOBAL.ICONS.DOLLAR}}</mat-icon>
            <div matLine>
                <b> {{item.values.price | number}} {{item.values.cy |
                    param:'cies'}}{{item.values.type === 'rental' ? ' / month' : ''}}</b>
            </div>
            <div matLine *ngIf="item.values.priceNeg">
                <i> {{item.values.priceNeg | number}} {{item.values.cy |
                    param:'cies'}}{{item.values.type === 'rental' ? ' / month' : ''}}</i>
            </div>
        </mat-list-item>
        <mat-list-item *ngIf="item.values.type === 'sale' && item.values.agencyFee && item.values.agencyFee.type">
            <mat-icon matListIcon matTooltip="Commission">{{GLOBAL.ICONS.PERCENT}}</mat-icon>
            <div matLine>
                {{item.values.agencyFee.type | param: 'agencyFeeTypes'}}
            </div>
            <div matLine *ngIf="item.values.agencyFee.type === 'otherPerc'">
                <b>{{item.values.agencyFee.comPerc}}% + VAT</b>
            </div>
            <div matLine *ngIf="item.values.agencyFee.type === 'std'">
                <b>2% + VAT <i *ngIf="item.values.agencyFee.comMin">(Min: Rs {{item.values.agencyFee.comMin |
                        number}})</i></b>
            </div>
            <div matLine *ngIf="item.values.agencyFee.type === 'discountVAT'">
                <b>2% incl. VAT</b>
            </div>
            <div matLine *ngIf="item.values.agencyFee.type === 'discount50'">
                <b>1% + VAT</b>
            </div>
            <div matLine *ngIf="item.values.agencyFee.type === 'fix'">
                <b>Rs {{item.values.agencyFee.comFix | number}} + VAT</b>
            </div>
        </mat-list-item>
        <mat-list-item *ngIf="item.values.noAgencyFee">
            <mat-icon matListIcon matTooltip="Buyer Agency Fee" color="warn">{{GLOBAL.ICONS.ALERT}}</mat-icon>
            <div matLine>
                No Agency Fee for Buyer
            </div>
        </mat-list-item>
        <mat-list-item *ngIf="item.values.referral">
            <mat-icon matListIcon>{{GLOBAL.ICONS.REFERRAL}}</mat-icon>
            <div matLine>
                <b>{{item.values.referral | realtorName}} - {{item.values.referralAgency | param:'agencies'}}</b>
            </div>
            <div matLine>
                <i>{{item.values.referralCom}} %</i>
            </div>
        </mat-list-item>
    </mat-list>
    <button *ngIf="isRealtor()" color="primary" mat-icon-button class="top-right" (click)="editCard()">
        <mat-icon>{{GLOBAL.ICONS.EDIT}}</mat-icon>
    </button>
</mat-card>
<div style="position: relative" *ngIf="edit">

    <form-builder [item]="item" [values]="item.values" [edit]="true"></form-builder>

    <!-- EDIT MODE -->
    <button mat-fab class="bottom-right" [disabled]="!item.valid || !item.modified || item.saving" (click)="saveItem()">
        <mat-icon matListIcon>check</mat-icon>
    </button>
    <!-- CANCEL -->
    <button mat-fab class="bottom-right p2" color="basic" (click)="cancelEdit()">
        <mat-icon matListIcon>close</mat-icon>
    </button>
</div>