<mat-toolbar>
    <div>
        <a mat-icon-button (click)="clickBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <span>{{db.itemsFiltered.length}} Tasks</span>
    </div>
    <profile></profile>
</mat-toolbar>
<div class="toolbartop"></div>


<div class="main-container-flex split" *ngIf="db.auth.profile?.values">
    <mat-spinner *ngIf="!db.loaded" [strokeWidth]="5"></mat-spinner>
    <app-table class="main" [offset]="db.itemsPageConfig.scrollIndex"
        [tableKey]="db.isRole('realtor') ? 'main' : 'mainRealtor'" [item]="actionItem" [items]="db.itemsFilteredOrdered"
        (clickFn)="goItem($event)" (scrollFn)="scrollItems($event)"></app-table>
    <div class="side">
        <mat-tab-group [(selectedIndex)]="db.itemsPageConfig.sideTab">

            <!-- FILTERS -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.PARAM}}</mat-icon>
                </ng-template>
                <form [formGroup]="sortServerForm" novalidate>

                    <mat-form-field appearance="fill"
                        *ngIf="db.auth.profile.values.agencies.length > 1 && db.auth.isOneOfRoles(['admin', 'director', 'assistant'])">
                        <mat-label>Agency</mat-label>
                        <mat-select formControlName="agency">
                            <mat-option *ngFor="let param of 'userAgencies' | params" [value]="param.id">
                                {{param.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill"
                        *ngIf="db.auth.isOneOfRoles(['admin', 'director', 'assistant'])">
                        <mat-label>Realtor</mat-label>
                        <mat-select formControlName="realtor">
                            <mat-option value="">
                                All Agents
                            </mat-option>
                            <mat-option
                                *ngFor="let param of setting.realtorsAgency[sortServerForm.controls['agency'].value]"
                                [value]="param.id">
                                {{param.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </form>

                <form [formGroup]="sortLocalForm" novalidate>

                    <mat-form-field formGroupName="filters" appearance="fill">
                        <mat-label>Action Type</mat-label>
                        <mat-select formControlName="type">
                            <mat-option value="N">No selection</mat-option>
                            <mat-option *ngFor="let param of 'actionTypeFilters' | params" [value]="param.id">
                                {{param.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </form>

            </mat-tab>

            <!-- PREVIEW -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.VIEW}}</mat-icon>
                </ng-template>
                <app-unit-card [values]="preview" [mandate]="true"></app-unit-card>
            </mat-tab>

        </mat-tab-group>
    </div>
</div>