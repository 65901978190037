import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MainItem } from '../main/main.model';
import { MainService } from '../main/main.service';
import { MatchingService } from 'src/services/matching/matching.service';

@Injectable({
  providedIn: 'root'
})
export class SelectService {

  private _item: MainItem;
  private _db: MainService;
  private _route: boolean;
  public synap: string;
  public active: boolean;
  public multi: boolean;
  public filters: any;
  public prefil: any;
  private _matching = false;
  private _pageBack: string = null;
  public selectedItems: { [key: string]: any } = {};

  selectDone: EventEmitter<any> = new EventEmitter();

  constructor(
    protected router: Router,
    protected matching: MatchingService,
  ) { }


  init(item: MainItem, db: MainService, synap: string, filters: any = null, route = true, pageBack: string = null, prefil: any = null) {

    this._item = item;
    this._db = db;
    this._route = route;
    this._pageBack = pageBack;
    this.synap = synap;
    this._matching = false;

    this.active = true;
    this.multi = this._item.synapConfig[synap].multi;
    this.filters = filters;
    this.prefil = prefil;
    this.selectedItems = Object.assign({}, this._item.values.synaps[synap]);
    if (route) {
      this.router.navigate([`/${this._item.synapConfig[synap].module}`]);
    }
  };

  initAddMatching(item: MainItem, db: MainService, pageBack: string) {
    this._item = item;
    this._db = db;
    this._route = false;
    this._pageBack = pageBack;
    this.synap = null;
    this._matching = true;

    this.active = true;
    this.multi = false;

    this.filters = { local: { filters: { type: this._item.values.type } } };
    this.prefil = null;
    this.selectedItems = {};
    // const matchings: any = this._item.values.synaps.projectMatchings ? this._item.values.synaps.projectMatchings : {};
    // for (const match in matchings) {
    //   if (matchings[match].mandateKey) {
    //     this.selectedItems[matchings[match].mandateKey] = true;
    //   }
    // }
    this.router.navigate([`/mandates`]);
  }

  async done(): Promise<any> {
    if (this._matching) {
      // Manage specific add matching
      const matchings: any = this._item.values.synaps.projectMatchings ? this._item.values.synaps.projectMatchings : {};
      let mandateKey: string = null;
      // Get mandate Key of new mandate matching
      if (Object.keys(this.selectedItems).length) {
        mandateKey = Object.keys(this.selectedItems)[0];
      }
      // Check if already matching
      if (mandateKey) {
        for (const match in matchings) {
          if (matchings[match].mandateKey && this.selectedItems[matchings[match].mandateKey]) {
            mandateKey = null;
          }
        }
      }
      // Not already matching -> add matching
      if (mandateKey) {
        this.matching.addMatch(this._item.values, await this._db.getData(mandateKey, 'mandates'));;
      }

      this.router.navigate([`/${this._pageBack}`]);
    } else {
      return this._db.saveItemSynaps(this._item, this.synap, this.selectedItems).then(() => {
        if (this._route) {
          this.router.navigate([`/${this._pageBack ? this._pageBack : this._item.module}/${this._item.values.$key}`]);
        }
        this.selectDone.emit(this._item.values);
        this.reset();
      });
    }


    // // Check New and Remove Selection
    // const newSelections: any = {};
    // const deletedSelections: any = {};
    // if (this._item.values.synaps[this.synap] != 'loading') {
    //   Object.assign(deletedSelections, this._item.values.synaps[this.synap]);
    // }

    // for (const selectKey in this.selectedItems) {
    //   if (this._item.values.synaps[this.synap] && this._item.values.synaps[this.synap][selectKey]) {
    //     delete deletedSelections[selectKey];
    //   } else {
    //     newSelections[selectKey] = true;
    //   }
    // }

    // const batch = firebase.firestore().batch();

    // // Manage Delete Selection
    // for (const removedKey in deletedSelections) {
    //   mainService.saveRemoveSynap(batch, removedKey, this.synap, this._item);
    // }

    // // Manage New Selection
    // for (const selectKey in newSelections) {
    //   mainService.saveNewSynap(batch, selectKey, this.synap, this._item);
    // }


    // batch.commit().then(() => {
    //   // Go Back to Previous Page
    //   if (route) {
    //     this.router.navigate([`/${this._item.module}/${this._item.values.$key}`]);
    //   }
    // });
  };

  getSelectDoneEmitter() {
    return this.selectDone;
  }

  cancel() {

    // Reset selection
    this.reset();

    // Go Back to Previous Page
    if (this._route || this._matching) {
      this.router.navigate([`/${this._pageBack ? this._pageBack : (this._item.module + '/' + this._item.values.$key)}`]);
    }
  }


  public reset() {
    this.active = false;
    this.multi = null;
    this.filters = null;
    this.prefil = null;
    this.selectedItems = {};
  }

  public isSelected(key: string) {
    return this.selectedItems[key];
  }

  public toggle(itemList: any): void {
    if (this.selectedItems[itemList.$key]) {
      delete this.selectedItems[itemList.$key];
    } else {
      if (!this.multi) {
        this.selectedItems = {};
      }
      this.selectedItems[itemList.$key] = itemList;
    }
  }

}

