<mat-card class="dropzone" [ngClass]="{'active': dropzoneActive}" fileDrop (filesDropped)="handleDrop($event)"
    (filesHovered)="dropzoneState($event)">
    <mat-card-title *ngIf="title">{{title}}</mat-card-title>

    <img *ngIf="fileRecord" mat-card-image [src]="fileRecord.url">

    <mat-card-content>
        <h3>Drop Image</h3>
        <section *ngIf="currentUpload && currentUpload.progress < 100">
            <mat-progress-bar [value]="currentUpload.progress"></mat-progress-bar>
        </section>

    </mat-card-content>
</mat-card>