import { Pipe, PipeTransform } from '@angular/core';
import { SettingService } from '../services/setting/setting.service';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

constructor(public setting: SettingService){
}

  transform(values: any): string {
    let a = '';

    if (values.synaps && values.synaps.residenceUnitsObj) {
      a = values.synaps.residenceUnitsObj.name + ' - ';
    }

    if (values.address) {
      // if (values.address.streetNb) {
      //   a += values.address.streetNb + ' ';
      // }
      // if (values.address.street) {
      //   a += values.address.street + ', ';
      // }
      if (values.address.eCityName) {
        a += values.address.eCityName;
      }
    }

    if (!a.length) {
      a = 'No Address';
    }

    return a;
  }

}
