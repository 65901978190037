<mat-card class="full" *ngIf="contact">
    <mat-list>
        <mat-list-item>
            <mat-icon matListIcon>{{GLOBAL.ICONS.CONTACT}}</mat-icon>
            <h3 matLine>{{contact.fullname}}</h3>
        </mat-list-item>
        <mat-list-item>
            <mat-icon matListIcon>{{GLOBAL.ICONS.FLAG}}</mat-icon>
            <h3 matLine>
                <ng-container *ngFor="let n of contact.nationalities; let i = index">
                    {{i > 0 ? ', ' : '' }}{{n | param:'countries'}}
                </ng-container>
            </h3>
        </mat-list-item>
        <mat-list-item>
            <mat-icon matListIcon>
                {{GLOBAL.ICONS.LANG}}</mat-icon>
            <h3 matLine> {{contact.lang | param:'langs'}} </h3>
        </mat-list-item>
    </mat-list>
</mat-card>

<mat-card *ngIf="contactList">
    <mat-list>
        <mat-list-item *ngFor="let contact of contactList">
            <mat-icon matListIcon>{{GLOBAL.ICONS.CONTACT}}</mat-icon>
            <h3 matLine>{{contact.fullname}}</h3>
        </mat-list-item>
    </mat-list>
</mat-card>