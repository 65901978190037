<mat-card class="projectcard" *ngIf="values">
    <mat-card-header>
        <mat-icon mat-card-avatar [ngClass]="values.status === 'active' ? values.temperature : values.status">
            {{values.status | icon:'projectStatus'}}</mat-icon>
        <mat-card-title>C{{values.ref}}: {{values.type | param:'projectTypes'}} Project</mat-card-title>
    </mat-card-header>
    <mat-list *ngIf="listDisplay">
        <mat-list-item *ngFor="let contact of values.contactsProjects">
            <mat-icon matListIcon>{{GLOBAL.ICONS.CONTACT}}</mat-icon>
            <h3 matLine>{{contact}}</h3>
        </mat-list-item>
    </mat-list>
    <mat-list>
        <mat-list-item>
            <mat-icon matListIcon>{{GLOBAL.ICONS.DATE}}</mat-icon>
            <h3 matLine>{{values.dateNeed?.toDate() | date:'dd MMM yyyy'}}</h3>
        </mat-list-item>
    </mat-list>
    <mat-list class="h">
        <mat-list-item *ngIf="listDisplay">
            <mat-icon matListIcon *ngFor="let unitType of values.unitTypes">
                {{ unitType}}
            </mat-icon>
        </mat-list-item>
        <mat-list-item *ngIf="!listDisplay">
            <mat-icon matListIcon *ngFor="let unitType of values.unitTypes">
                {{ unitType | icon: 'unitTypes'}}
            </mat-icon>
        </mat-list-item>
        <mat-list-item>
            <mat-icon matListIcon>{{GLOBAL.ICONS.BED}}</mat-icon>
            <h3 matLine>{{values.minBedrooms}}</h3>
        </mat-list-item>
        <mat-list-item *ngIf="values.minBathrooms">
            <mat-icon matListIcon>{{GLOBAL.ICONS.BATH}}</mat-icon>
            <h3 matLine>{{values.minBathrooms}} </h3>
        </mat-list-item>
    </mat-list>
    <mat-list>
        <mat-list-item>
            <mat-icon matListIcon>{{GLOBAL.ICONS.DOLLAR}}</mat-icon>
            <h3 matLine *ngIf="listDisplay">{{values.budget}}</h3>
            <h3 matLine *ngIf="!listDisplay">{{values.minBudget | number}} < {{values.maxBudget | number}}
                    {{values.cyBudget | param:'cies'}}</h3>
        </mat-list-item>
    </mat-list>
    <img mat-card-avatar *ngIf="values.realtor" class="realtor" [src]="values.realtor | realtor"
        [matTooltip]="values.realtor | realtorName" />
</mat-card>