import { Injectable } from '@angular/core';
import { MainService } from '../../common/services/main/main.service';
import { AuthService } from '../../common/services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Matching } from './matching.model';
import * as firebase from 'firebase/app';
import createAlias from 'functions/src/shared/alias';
import { matchings } from 'functions/src/shared/list';
import { GlobalConstants } from 'functions/src/shared/global-constants';

@Injectable({
  providedIn: 'root'
})
export class MatchingService extends MainService {

  public GLOBAL = new GlobalConstants();

  protected _filters: {
    search: null,
    server: {
      filters?: any,
      multiFilters?: any,
      arrayFilters?: any,
      equalSup?: any,
      equalInf?: any,
      toDays?: number,
      year?: string,
      realtor?: string,
      orderBy?: string,
      orderDesc?: boolean,
    },
    local: {
      localSearch?: string,
      gmap?: any,
      filters?: any,
      arrayFilters?: any,
      synaps?: any,
      equalSup?: any,
      equalInf?: any,
      orderBy?: string,
      orderDesc?: boolean,
      budget?: any,
      matchOnly: boolean,
      newOnly: boolean
      visit: boolean
      // offer?: boolean
    },
  } = {
      search: null,
      server: {
      },
      local: {
        matchOnly: true,
        newOnly: false,
        visit: false
      },
    };

  public new: {
    all: number,
    cold: number,
    warm: number,
    hot: number,
  } = {
      all: 0,
      cold: 0,
      warm: 0,
      hot: 0,
    };

  public active: {
    all: number,
    cold: number,
    warm: number,
    hot: number,
  } = {
      all: 0,
      cold: 0,
      warm: 0,
      hot: 0,
    };

  public newMatchs: any[] = [];

  constructor(
    public auth: AuthService,
    public afs: AngularFirestore,
  ) {
    super(auth, afs, 'matching', 'matchings', Matching);
  }

  protected _afterRefreshList() {
    //Put all new matchings in different list, so do not have to filter after for the new Matching Tab
    this.newMatchs = [];
    this.new = {
      all: 0,
      cold: 0,
      warm: 0,
      hot: 0,
    }
    this.active = {
      all: 0,
      cold: 0,
      warm: 0,
      hot: 0,
    }
    for (const item of this.items) {
      if (item.new) {
        this.newMatchs.push(item);
        this.new.all++;
        if (item.score < 4) {
          this.new.cold++;
        } else if (item.score < 9) {
          this.new.warm++;
        } else {
          this.new.hot++;
        }
      } else {
        this.active.all++;
        if (item.score < 4) {
          this.active.cold++;
        } else if (item.score < 9) {
          this.active.warm++;
        } else {
          this.active.hot++;
        }
      }
    }
  }



  protected _localFilter(): void {

    this.itemsFiltered = this.items.filter((v) => {
      if (this._filters.local.matchOnly && !v.match) {
        return false;
      }
      if (this._filters.local.newOnly && !v.new) {
        return false;
      }
      if (this._filters.local.visit && !(v.visits && v.visits.length)) {
        return false;
      }
      return true;
    });

    const temp = {
      hot: 1,
      warm: 2,
      cold: 2,
    }

    this.itemsFilteredOrdered = this.itemsFiltered.sort((a, b) => {

      if (a.new && !b.new) {
        return -1;
      }
      if (!a.new && b.new) {
        return 1;
      }
      if (a.match && !b.match) {
        return -1;
      }
      if (!a.match && b.match) {
        return 1;
      }
      if (a.project.ref !== b.project.ref) {
        return a.project.ref - b.project.ref;
      }
      return temp[a.mandate.temperature] - temp[b.mandate.temperature];
    });

  }

  match(matching: any): Promise<any> {
    return this.saveMatch(matching, true);
  }

  unmatch(matching: any): Promise<any> {
    return this.saveMatch(matching, false);
  }

  canMatch(realtor: string): boolean {
    if (realtor === this.auth.uid) {
      return true;
    }
    if (this.isOneOfRoles(['admin', 'assistant', 'director'])) {
      return true;
    }
    return false;
  }

  public saveMatch(matchingList: any, match: boolean): Promise<any> {
    const batch = firebase.firestore().batch();

    if (matchingList.project && matchingList.project.$key && matchingList.mandate && matchingList.mandate.$key) {
      matchingList.match = match;

      batch.set(
        firebase.firestore().doc(`/db/modules/matchings/${matchingList.$key}`),
        { new: false, match: match, updated: firebase.firestore.FieldValue.serverTimestamp() },
        { merge: true }
      );
      batch.set(
        firebase.firestore().doc(`/list/modules/matchings/${matchingList.$key}`),
        { new: false, match: match, active: match, icon: this.GLOBAL.ICONS.MATCHING },
        { merge: true }
      );

      batch.set(
        firebase.firestore().doc(`/db/modules/projects/${matchingList.project.$key}`),
        {
          synaps: {
            projectMatchings: {
              [matchingList.$key]: { new: false, match: match }
            }
          },
          listUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      );

      batch.set(
        firebase.firestore().doc(`/db/modules/mandates/${matchingList.mandate.$key}`),
        {
          synaps: {
            mandateMatchings: {
              [matchingList.$key]: { new: false, match: match }
            }
          },
          listUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      );
    }

    return batch.commit();
  }


  public addMatch(project: any, mandate: any): Promise<any> {
    const batch = firebase.firestore().batch();
    // Get New Matching Key
    const matchingKey = firebase.firestore().collection(`/db/modules/matchings`).doc();

    // console.log(`New Matching Key: ${matchingKey}`);

    const newMatching = {
      $key: matchingKey.id,
      new: true,
      match: false,
      report: true,
      newActionSynap: {
        name: 'none',
        date: firebase.firestore.FieldValue.serverTimestamp(),
        origin: 'addMatch',
      },
      synaps: {
        projectMatchings: {
          [project.$key]: Object.assign({},
            createAlias(project, 'projects'),
            { synaps: { contactsProjects: project.synaps?.contactsProjects ? project.synaps.contactsProjects : null } })
        },
        mandateMatchings: {
          [mandate.$key]: Object.assign({},
            createAlias(mandate, 'mandates'),
            { synaps: { unitMandates: mandate.synaps?.unitMandates ? mandate.synaps.unitMandates : null } })
        }
      },
      created: firebase.firestore.FieldValue.serverTimestamp(),
      updated: firebase.firestore.FieldValue.serverTimestamp(),
    };

    const alias: any = {
      new: true,
      match: false,
      projectKey: project.$key,
      mandateKey: mandate.$key,
    };

    // Add Matchings
    batch.set(matchingKey,
      newMatching
    );

    // Add Matching List Directly
    batch.set(firebase.firestore().doc(`list/modules/matchings/${matchingKey.id}`),
      matchings(newMatching)
    );

    // console.log(`New Matching: create synaps mandate ${mandate.$key}`);

    // Update List Matching for Mandates
    batch.set(firebase.firestore().doc(`db/modules/mandates/${mandate.$key}`),
      {
        synaps: {
          mandateMatchings: {
            [matchingKey.id]: alias,
          }
        },
        listUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      }
      , { merge: true });

    // console.log(`New Matching: create synaps project ${project.$key}`);
    // Update List Matching for Projects
    batch.set(firebase.firestore().doc(`db/modules/projects/${project.$key}`),
      {
        synaps: {
          projectMatchings: {
            [matchingKey.id]: alias,
          }
        },
        listUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      }
      , { merge: true });


    return batch.commit();
  }
}

