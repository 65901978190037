import { Pipe, PipeTransform } from '@angular/core';
import { SettingService } from '../services/setting/setting.service';

@Pipe({
  name: 'params'
})
export class ParamsPipe implements PipeTransform {

  constructor(
    private setting: SettingService,
  ) {

  }

  transform(paramName: string): { id: string, name: string }[] {
    return this.setting.getParamsSelect(paramName);
  }

}
