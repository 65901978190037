<!------------------------------ DISPLAY MODE  ----------------------------------------->


<div *ngIf="!pageConfig.edit">
    <mat-card class="full">
        <mat-list *ngIf="pageConfig.loaded">
            <mat-list-item>
                <mat-icon matListIcon>
                    {{GLOBAL.ICONS.CONTACT}}</mat-icon>
                <h3 matLine> {{item.values.fullname}} </h3>
            </mat-list-item>
            <mat-list-item *ngIf="item.values.nationalities && item.values.nationalities.length">
                <mat-icon matListIcon>
                    {{GLOBAL.ICONS.FLAG}}</mat-icon>
                <h3 matLine>
                    <ng-container *ngFor="let n of item.values.nationalities; let i = index">
                        {{i > 0 ? ', ' : '' }}{{n | param:'countries'}}</ng-container>
                </h3>
            </mat-list-item>
            <mat-list-item *ngIf="item.values.country">
                <mat-icon matListIcon>{{GLOBAL.ICONS.UNIT}}</mat-icon>
                <h3 matLine>{{item.values.country | param:'countries'}}</h3>
            </mat-list-item>
            <mat-list-item>
                <mat-icon matListIcon>
                    {{GLOBAL.ICONS.LANG}}</mat-icon>
                <h3 matLine> {{item.values.lang | param:'langs'}} </h3>
            </mat-list-item>
            <mat-list-item *ngIf="item.values.birthday">
                <mat-icon matListIcon>
                    {{GLOBAL.ICONS.BIRTH}}</mat-icon>
                <h3 matLine> {{item.values.birthday | date:'dd MMM yyyy'}} </h3>
            </mat-list-item>
            <ng-container *ngIf="item.values.tels">
                <mat-list-item *ngFor="let tel of item.values.tels | toarray">
                    <mat-icon matListIcon>{{GLOBAL.ICONS.PHONE}}</mat-icon>
                    <h3 matLine *ngIf="displayTelMail">{{tel.number}}</h3>
                    <h3 matLine *ngIf="!displayTelMail">Accept To Display</h3>
                </mat-list-item>
            </ng-container>
            <ng-container *ngIf="item.values.emails">
                <mat-list-item *ngFor="let email of item.values.emails | toarray">
                    <mat-icon matListIcon>{{GLOBAL.ICONS.EMAIL}}</mat-icon>
                    <h3 matLine *ngIf="displayTelMail">{{email.email}}</h3>
                    <h3 matLine *ngIf="!displayTelMail">Accept To Display</h3>
                </mat-list-item>
            </ng-container>

            <ng-container *ngIf="item.values.synaps?.employeesArray">
                <mat-list-item *ngFor="let e of item.values.synaps?.employeesArray">
                    <mat-icon matListIcon>{{GLOBAL.ICONS.WORK}}</mat-icon>
                    <h3 matLine>{{e.displayname}}</h3>
                </mat-list-item>
            </ng-container>

            <mat-list-item *ngIf="item.values.synaps?.contactsProjectsArray">
                <mat-icon matListIcon>{{GLOBAL.ICONS.PROJECT}}</mat-icon>
                <h3 matLine>{{item.values.synaps.contactsProjectsArray.length}}</h3>
            </mat-list-item>

            <mat-list-item *ngIf="item.values.synaps?.ownersArray">
                <mat-icon matListIcon>{{GLOBAL.ICONS.UNIT}}</mat-icon>
                <h3 matLine>{{item.values.synaps.ownersArray.length}}</h3>
            </mat-list-item>
        </mat-list>

        <button *ngIf="displayTelMail" mat-fab class="bottom-right" color="basic" (click)="goItem()">
            <mat-icon matListIcon>{{GLOBAL.ICONS.CONTACT}}</mat-icon>
        </button>
    </mat-card>


    <!-- EDIT -->

    <button *ngIf="displayTelMail" color="primary" mat-icon-button class="top-right" (click)="editItem()">
        <mat-icon>{{GLOBAL.ICONS.EDIT}}</mat-icon>
    </button>

</div>


<!------------------------------ EDIT MODE  ----------------------------------------->


<div class="contactPage" *ngIf="pageConfig.edit">

    <form-builder [item]="item" [values]="item.values" section="main" [edit]="true"></form-builder>
    <form-builder [item]="item" [values]="item.values" section="emails" [edit]="true"> </form-builder>
    <form-builder [item]="item" [values]="item.values" section="tels" [edit]="true"></form-builder>

    <!-- EDIT MODE -->
    <button mat-fab class="bottom-right" [disabled]="!item.valid || !item.modified || item.saving"
        (click)="saveItemDisplayItem()">
        <mat-icon matListIcon>check</mat-icon>
    </button>
    <!-- CANCEL -->
    <button mat-fab class="bottom-right p2" color="basic" (click)="cancelEditDisplayItem()">
        <mat-icon matListIcon>close</mat-icon>
    </button>
</div>