<mat-toolbar class="contactsbg">

    <div>
        <a mat-icon-button (click)="clickBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <span *ngIf="db.itemsFiltered.length !== db.items.length">{{db.itemsFiltered.length | number}}/{{db.items.length
            | number}} Contacts</span>
        <span *ngIf="db.itemsFiltered.length === db.items.length">{{db.items.length | number}} Contacts</span>
    </div>
    <div class="form">
        <form [formGroup]="searchForm" novalidate>
            <mat-form-field appearance="fill">
                <mat-label>Search</mat-label>
                <input matInput formControlName="search">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </form>
    </div>
    <div>
        <button mat-icon-button (click)="goPage('/companies')" matTooltip="Companies">
            <mat-icon>{{GLOBAL.ICONS.WORK}}</mat-icon>
        </button>
        <profile></profile>
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="downloadFullList()"
                *ngIf="db.isRole('admin') || db.auth.uid === '0GpyrfjJ95bCcMS9qyt1s9gL0Rf2'">
                <mat-icon>{{GLOBAL.ICONS.DOWNLOAD}}</mat-icon>
                <span>Full List (Marketing Only)</span>
            </button>
            <button mat-menu-item (click)="downloadRealtorList()" *ngIf="db.auth.profile?.values?.agency === 'pde'">
                <mat-icon>{{GLOBAL.ICONS.DOWNLOAD}}</mat-icon>
                <span>My Contact's Clients</span>
            </button>
        </mat-menu>
    </div>

</mat-toolbar>
<div class="toolbartop"></div>

<div class="main-container-flex">
    <mat-spinner *ngIf="!db.loaded" [strokeWidth]="5"></mat-spinner>
    <app-table [item]="item" [items]="db.itemsFilteredOrdered" (sortChange)="sortChange($event)"
        (clickFn)="goItem($event)">
    </app-table>
</div>



<button mat-fab class="bottom-right pfixed" [ngClass]="{p3: select.active}" color="basic" (click)="addItem()">
    <mat-icon matListIcon>add</mat-icon>
</button>

<button *ngIf="select.active" mat-fab color="basic" class="bottom-right pfixed p2" (click)="select.cancel()">
    <mat-icon matListIcon>close</mat-icon>
</button>

<button *ngIf="select.active" mat-fab class="bottom-right pfixed" (click)="select.done()">
    <mat-icon matListIcon>done</mat-icon>
</button>