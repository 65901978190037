

export interface Synap {
    name: string,
    module: string,
    multi: boolean,
    synaps?: { [name: string]: Synap },
}

export class Synaps {

    dispatch: { [module: string]: { [name: string]: Synap } } = {
        actions: {
            contactActions: {
                name: 'contactActions',
                module: 'contacts',
                multi: false,
            },
            mandatesActions: {
                name: 'mandatesActions',
                module: 'mandates',
                multi: false,
            },
            projectsActions: {
                name: 'projectsActions',
                module: 'projects',
                multi: false,
            },
            residencesActions: {
                name: 'residencesActions',
                module: 'residences',
                multi: false,
            },
            visit: {
                name: 'visit',
                module: 'matchings',
                multi: true,
            },
            visitReport: {
                name: 'visitReport',
                module: 'matchings',
                multi: true,
            },
            offer: {
                name: 'offer',
                module: 'matchings',
                multi: false,
            },
            signAgree: {
                name: 'signAgree',
                module: 'matchings',
                multi: false,
            },
            signDeed: {
                name: 'signDeed',
                module: 'matchings',
                multi: false,
            },
            signLease: {
                name: 'signLease',
                module: 'matchings',
                multi: false,
            },
        },

        companies: {
            employees: {
                name: 'employees',
                module: 'contacts',
                multi: true,
            },
            syndic: {
                name: 'syndic',
                module: 'residences',
                multi: true,
            },
            promoter: {
                name: 'promoter',
                module: 'residences',
                multi: true,
                synaps: {
                    residenceUnits: {
                        name: 'residenceUnits',
                        module: 'units',
                        multi: true,
                        synaps: {
                            unitMandates: {
                                name: 'unitMandates',
                                module: 'mandates',
                                multi: true,
                                synaps: {
                                    mandateMatchings: {
                                        name: 'mandateMatchings',
                                        module: 'matchings',
                                        multi: true,
                                    }
                                },
                            },
                        }
                    },
                }
            },
            builder: {
                name: 'builder',
                module: 'residences',
                multi: true,
            },
        },

        contacts:
        {
            contactActions: {
                name: 'contactActions',
                module: 'actions',
                multi: false,
            },
            owners: {
                name: 'owners',
                module: 'units',
                multi: true,
                synaps: {
                    residenceUnits: {
                        name: 'residenceUnits',
                        module: 'residences',
                        multi: false,
                    },
                    unitMandates: {
                        name: 'unitMandates',
                        module: 'mandates',
                        multi: true,
                        synaps: {
                            mandateMatchings: {
                                name: 'mandateMatchings',
                                module: 'matchings',
                                multi: true,
                            }
                        },
                    },
                }
            },
            contactsProjects: {
                name: 'contactsProjects',
                module: 'projects',
                multi: true,
                synaps: {
                    projectsActions: {
                        name: 'projectsActions',
                        module: 'actions',
                        multi: true,
                    },
                    projectMatchings: {
                        name: 'projectMatchings',
                        module: 'matchings',
                        multi: true,
                    },
                }
            },
            employees: {
                name: 'employees',
                module: 'companies',
                multi: true,
            },
        },


        mandates: {
            unitMandates: {
                name: 'unitMandates',
                module: 'units',
                multi: false,
                synaps: {
                    owners: {
                        name: 'owners',
                        module: 'contacts',
                        multi: true,
                    },
                    residenceUnits: {
                        name: 'residenceUnits',
                        module: 'residences',
                        multi: false,
                    }
                },
            },
            mandatesActions: {
                name: 'mandatesActions',
                module: 'actions',
                multi: true,
            },
            mandateMatchings: {
                name: 'mandateMatchings',
                module: 'matchings',
                multi: true,
            }
        },

        matchings: {
            mandateMatchings: {
                name: 'mandateMatchings',
                module: 'mandates',
                multi: false,
            },
            projectMatchings: {
                name: 'projectMatchings',
                module: 'projects',
                multi: false,
            },
            visit: {
                name: 'visit',
                module: 'actions',
                multi: true,
            },
            visitReport: {
                name: 'visitReport',
                module: 'actions',
                multi: true,
            },
            offer: {
                name: 'offer',
                module: 'actions',
                multi: false,
            },
            signAgree: {
                name: 'signAgree',
                module: 'actions',
                multi: false,
            },
            signDeed: {
                name: 'signDeed',
                module: 'actions',
                multi: false,
            },
            signLease: {
                name: 'signLease',
                module: 'actions',
                multi: false,
            },
        },

        projects: {
            contactsProjects: {
                name: 'contactsProjects',
                module: 'contacts',
                multi: true,
            },
            projectsActions: {
                name: 'projectsActions',
                module: 'actions',
                multi: true,
            },
            projectMatchings: {
                name: 'projectMatchings',
                module: 'matchings',
                multi: true,
            }
        },

        residences: {
            residenceUnits: {
                name: 'residenceUnits',
                module: 'units',
                multi: true,
                synaps: {
                    unitMandates: {
                        name: 'unitMandates',
                        module: 'mandates',
                        multi: true,
                        synaps: {
                            mandateMatchings: {
                                name: 'mandateMatchings',
                                module: 'matchings',
                                multi: true,
                            },
                            mandatesActions: {
                                name: 'mandatesActions',
                                module: 'actions',
                                multi: true,
                            },
                        },
                    },
                    owners: {
                        name: 'owners',
                        module: 'contacts',
                        multi: true,
                    },
                }
            },
            syndic: {
                name: 'syndic',
                module: 'companies',
                multi: false,
            },
            promoter: {
                name: 'promoter',
                module: 'companies',
                multi: false,
            },
            builder: {
                name: 'builder',
                module: 'companies',
                multi: false,
            },
            residencesActions: {
                name: 'residencesActions',
                module: 'actions',
                multi: true,
            },
        },

        units: {
            residenceUnits: {
                name: 'residenceUnits',
                module: 'residences',
                multi: false,
            },
            owners: {
                name: 'owners',
                module: 'contacts',
                multi: true,
            },
            unitMandates: {
                name: 'unitMandates',
                module: 'mandates',
                multi: true,
                synaps: {
                    mandateMatchings: {
                        name: 'mandateMatchings',
                        module: 'matchings',
                        multi: true,
                    },
                    mandatesActions: {
                        name: 'mandatesActions',
                        module: 'actions',
                        multi: true,
                    },
                }
            }
        }
    }

}
