import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { LpService } from 'src/services/portals/lp.service';
import { Unit } from 'src/services/unit/unit.model';

@Component({
  selector: 'app-lp',
  templateUrl: './lp.component.html',
  styleUrls: ['./lp.component.scss']
})
export class LpComponent implements OnInit {

  public preview: any = null;
  public item = new Unit();

  constructor(
    protected router: Router,
    public setting: SettingService,
    public backURL: BackurlService,
    public lp: LpService,
  ) {
  }

  ngOnInit(): void {
    this.lp.init();
  }

  goItem(item: any) {
    this.backURL.addURL(`/marketing/portals/lp`);
    this.router.navigate([`/units/${item.$key}`]);
  }

  previewItem(item: any) {
    if (!(this.preview && item && item.$key === this.preview.$key)) {
      this.preview = null;
      setTimeout(() => {
        this.preview = item;
      }, 50);
    }
  }

  clickBack() {
    this.router.navigate([this.backURL.back()]);
  }

}

