import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalConstants } from 'functions/src/shared/global-constants';
import { MainsComponent } from 'src/common/pages/mains/mains.component';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SelectService } from 'src/common/services/select/select.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { Action } from 'src/services/action/action.model';
import { ActionPageService } from 'src/services/action/action.service';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent extends MainsComponent {

  // public newRequests: ActionService = null;
  public actionItem = new Action(null, this.settings);
  public GLOBAL = new GlobalConstants();

  public preview: any = null;

  constructor(
    protected afs: AngularFirestore,
    public db: ActionPageService,
    public settings: SettingService,
    public backURL: BackurlService,
    protected router: Router,
    public setting: SettingService,
    public select: SelectService,
    protected fb: FormBuilder,
  ) {
    super(setting, db, select, router, fb, backURL);

    this.sortServerForm = this.fb.group({
      agency: [],
      realtor: [],
      filters: this.fb.group({
        done: [],
      }),
    });

    this.sortLocalForm = this.fb.group({
      filters: this.fb.group({
        type: [],
      }),
    });

  }


  // ngOnInit(): void { // Avoid normal initialisation
    
  //   this.db.initFilters('asc');
  //   this.db.subscribe();

  //   this.initFilters(); // just manage the filters

  // }

  goItem(item: any) {
    this.backURL.addURL('/tasks');
    if (item.project && item.project.$key) {
      this.router.navigate([`/projects/${item.project.$key}`]);
    } else if (item.mandate && item.mandate.$key) {
      this.router.navigate([`/mandates/${item.mandate.$key}`]);
    }
  }
}
