import { Component, Input} from '@angular/core';
import { GlobalConstants } from 'functions/src/shared/global-constants';

@Component({
  selector: 'action-temperature',
  templateUrl: './temperature.component.html',
  styleUrls: ['./temperature.component.scss']
})
export class ActionTemperatureComponent {

  @Input() action:any;

  public GLOBAL = new GlobalConstants();
  constructor() { }


}
