import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { BackurlService } from "src/common/services/backurl/backurl.service";
import { MainItem } from "src/common/services/main/main.model";


@Component({
  selector: 'action-select',
  templateUrl: './action-select.component.html',
  styleUrls: ['./action-select.component.scss']
})
export class ActionSelectComponent {

  @Input() action: any;
  @Input() moduleItem: MainItem;
  @Input() allMatchings: any; @Input()
  @Input() newAction: { check: boolean };

  @Output() selectDoneFn: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectAddFn: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected router: Router,
    public backURL: BackurlService) {
  }

  public doneFn($event) {
    this.selectDoneFn.emit($event);
  }

  public addFn($event) {
    this.selectAddFn.emit($event);
  }

  goItem(moduleName: string, targetItem: any) {
    if (targetItem && targetItem.$key) {
      this.backURL.addURL(`/${this.moduleItem.module}/${this.moduleItem.values.$key}`);
      this.router.navigate([`/${moduleName}/${targetItem.$key}`]);
    }
  }
}
