<!------------------------------ DISPLAY MODE  ----------------------------------------->


<div *ngIf="!pageConfig.edit">
    <mat-card class="full">
        <mat-list *ngIf="pageConfig.loaded">
            <mat-list-item>
                <mat-icon matListIcon>
                    {{GLOBAL.ICONS.CONTACT}}</mat-icon>
                <h3 matLine> {{item.values.displayname}} </h3>
            </mat-list-item>
            <ng-container *ngIf="item.values.tels">
                <mat-list-item *ngFor="let tel of item.values.tels | toarray">
                    <mat-icon matListIcon>{{GLOBAL.ICONS.PHONE}}</mat-icon>
                    <h3 matLine>{{tel.number}}</h3>
                </mat-list-item>
            </ng-container>
            <ng-container *ngIf="item.values.emails">
                <mat-list-item *ngFor="let email of item.values.emails | toarray">
                    <mat-icon matListIcon>{{GLOBAL.ICONS.EMAIL}}</mat-icon>
                    <h3 matLine>{{email.email}}</h3>
                </mat-list-item>
            </ng-container>

            <!-- <ng-container *ngIf="item.values.synaps?.employeesArray">
                <mat-list-item *ngFor="let e of item.values.synaps?.employeesArray">
                    <mat-icon matListIcon>{{GLOBAL.ICONS.WORK}}</mat-icon>
                    <h3 matLine>{{e.displayname}}</h3>
                </mat-list-item>
            </ng-container> -->

        </mat-list>


    </mat-card>


    <!-- EDIT -->

    <button color="primary" mat-icon-button class="top-right" (click)="editItem()">
        <mat-icon>{{GLOBAL.ICONS.EDIT}}</mat-icon>
    </button>

</div>


<!------------------------------ EDIT MODE  ----------------------------------------->


<div class="companyPage" *ngIf="pageConfig.edit">

    <form-builder [item]="item" [values]="item.values" section="main" [edit]="true"></form-builder>

    <button mat-fab class="bottom-right" [disabled]="!item.valid  || !item.modified || item.saving"
        (click)="saveItem(false)">
        <mat-icon matListIcon>check</mat-icon>
    </button>

    <!-- CANCEL -->
    <button mat-fab class="bottom-right p2" color="basic" (click)="cancelEditDisplayItem()">
        <mat-icon matListIcon>close</mat-icon>
    </button>
</div>