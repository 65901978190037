import { Component, Input } from '@angular/core';

@Component({
  selector: 'action-handover',
  templateUrl: './handover.component.html',
  styleUrls: ['./handover.component.scss']
})
export class HandoverComponent {

  @Input() action: any;

  constructor() { }


}
