import { Component, Input } from '@angular/core';

@Component({
  selector: 'mandate-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.scss']
})

export class MandateNewRequestComponent {

  @Input() action: any;

  constructor() { }




}
