<mat-toolbar>
    
        <div>
            <a mat-icon-button routerLink='/settings'>
                <mat-icon>keyboard_arrow_left</mat-icon>
            </a>
            <span>{{paramSetting.title}}</span>
        </div>
        <mat-form-field appearance="fill">
            <mat-label>Search</mat-label>
            <input matInput [(ngModel)]="search" (ngModelChange)="filter()">
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <profile></profile>
    
</mat-toolbar><div class="toolbartop"></div>

<table *ngIf="paramSetting" mat-table [dataSource]="itemsFiltered" class="mat-elevation-z8">
    <ng-container *ngIf="!paramSetting.multiLang" matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}}</td>
    </ng-container>
    <ng-container *ngIf="paramSetting.multiLang" matColumnDef="namefr">
        <th mat-header-cell *matHeaderCellDef> Name (fr) </th>
        <td mat-cell *matCellDef="let element"> {{element.name?.fr}}</td>
    </ng-container>
    <ng-container *ngIf="paramSetting.multiLang" matColumnDef="nameen">
        <th mat-header-cell *matHeaderCellDef> Name (en) </th>
        <td mat-cell *matCellDef="let element"> {{element.name?.en}}</td>
    </ng-container>
    <ng-container *ngIf="paramSetting.multiLang" matColumnDef="namede">
        <th mat-header-cell *matHeaderCellDef> Name (de) </th>
        <td mat-cell *matCellDef="let element"> {{element.name?.de}}</td>
    </ng-container>
    <ng-container *ngIf="paramSetting.desc" matColumnDef="descfr">
        <th mat-header-cell *matHeaderCellDef> Description (fr) </th>
        <td mat-cell *matCellDef="let element"> {{element.desc?.fr}}</td>
    </ng-container>
    <ng-container *ngIf="paramSetting.desc" matColumnDef="descen">
        <th mat-header-cell *matHeaderCellDef> Description (en) </th>
        <td mat-cell *matCellDef="let element"> {{element.desc?.en}}</td>
    </ng-container>
    <ng-container *ngIf="paramSetting.desc" matColumnDef="descde">
        <th mat-header-cell *matHeaderCellDef> Description (de) </th>
        <td mat-cell *matCellDef="let element"> {{element.desc?.de}}</td>
    </ng-container>
    <ng-container *ngIf="paramSetting.desc" matColumnDef="descru">
        <th mat-header-cell *matHeaderCellDef> Description (ru) </th>
        <td mat-cell *matCellDef="let element"> {{element.desc?.ru}}</td>
    </ng-container>
    <ng-container *ngIf="paramSetting.editable" matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <button mat-button color="basic" (click)="archiveParam(element, $event)">
                <mat-icon matListIcon>delete</mat-icon>
            </button>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; let element; columns: displayedColumns;" (click)="editParam(element)"
        class="cursor">
</table>

<button *ngIf="paramSetting.editable" mat-fab class="bottom-right pfixed" (click)="addParam()">
    <mat-icon matListIcon>playlist_add</mat-icon>
</button>