import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { DatetimeDialogComponent } from '../../../common/components/datetime-dialog/datetime-dialog.component';
import { DialogConfirmationComponent } from '../../../common/components/dialog-confirmation/dialog-confirmation.component';
import { MainComponent } from '../../../common/pages/main/main.component';
import { SelectService } from '../../../common/services/select/select.service';
import { SettingService } from '../../../common/services/setting/setting.service';
import { Action } from '../../../services/action/action.model';
import { ActionService } from '../../../services/action/action.service';
import { MainItem } from 'src/common/services/main/main.model';

@Component({
  selector: 'action-edit',
  templateUrl: './action-edit.component.html',
  styleUrls: ['./action-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionEditComponent extends MainComponent {


  @Input() action: any;
  @Input() moduleItem: MainItem;

  @Output() doneFn: EventEmitter<any> = new EventEmitter<any>();

  protected doneEgaleDate = true;

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ActionService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public dialog: MatDialog,
    public backURL: BackurlService,
  ) {
    super(_cd, setting, db, select, route, router, backURL, dialog);
    this.item = new Action(null, setting);
    // this.actionService = new ActionService(this.db.auth, this.db.afs);
  }

  ngOnInit(): void {

    if (this.action && !this.action.done && this.action.$key) {
      this._$key = this.action.$key;

      this.new = false;

      this.db.initItem(this);
      this._cd.markForCheck();

      this._bindItem().then(() => {
        this.pageConfig.loaded = true;
        this._itemOnInit();
      });
    }

  }

  get actionSynap(): any {
    if (this.moduleItem && this.moduleItem.module) {
      if (this.moduleItem.module === 'mandates')
        return { 'mandatesActions': { [this.moduleItem.values.$key]: this.moduleItem.createAlias(['unitMandates']) } };
      if (this.moduleItem.module === 'projects')
        return { 'projectsActions': { [this.moduleItem.values.$key]: this.moduleItem.createAlias(['contactsProjects']) } };
    }
    return {};
  }

  validAction(actionNow = true) {
    const newDate = new Date();
    this.item.modifyData('done', true);
    if (actionNow || !this.item.values.date) {
      this.item.modifyData('date', newDate);
    }
    this.item.modifyData('doneDate', this.doneEgaleDate ? newDate : (this.item.values.data.date ? this.item.values.data.date : newDate));
    this.db.saveItem(this.item);
    if (this.item.values && this.item.values.data) {
      this._pushModuleItemData();
    }
    this.doneFn.emit(this.item.values);
  }

  protected _pushModuleItemData() {
  }

  acceptAction() {
    this.item.modifyData('accepted', true);
    this.validAction();
  }

  refuseAction() {
    this.item.modifyData('accepted', false);
    this.validAction();
  }


  saveAction() {
    this.db.saveItem(this.item);
  }

  deleteAction() {
    const data = { title: 'Delete Action?', ok: false };
    const dialogRef = this.dialog.open(DialogConfirmationComponent, {
      width: '250px',
      disableClose: true,
      data: data
    });

    dialogRef.afterClosed().subscribe(() => {
      if (data.ok) {
        this.db.deleteItem(this.item);
      }
    });
  }

}
