import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DatetimeDialogComponent } from '../../common/components/datetime-dialog/datetime-dialog.component';
import { DialogConfirmationComponent } from '../../common/components/dialog-confirmation/dialog-confirmation.component';
import { MainComponent } from '../../common/pages/main/main.component';
import { GlobalConstants } from '../../../functions/src/shared/global-constants';
import { SelectService } from '../../common/services/select/select.service';
import { SettingService } from '../../common/services/setting/setting.service';
import { Action } from '../../services/action/action.model';
import { ActionService } from '../../services/action/action.service';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { Tools } from 'src/common/services/tools';


@Component({
  selector: 'visit-edit',
  templateUrl: './visit-edit.component.html',
  styleUrls: ['./visit-edit.component.scss']
})
export class VisitEditComponent extends MainComponent {

  public visitList: any[] = [];
  public GLOBAL = new GlobalConstants();
  public tools = new Tools();

  @Input()
  allMatchings: any[];

  @Input()
  action: any

  @Input()
  newAction: { check: boolean };

  @Output() doneFn: EventEmitter<any> = new EventEmitter<any>();
  @Output() addFn: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ActionService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public dialog: MatDialog,
    public backURL: BackurlService,
  ) {
    super(_cd, setting, db, select, route, router, backURL, dialog);
    this.item = new Action(null, setting);
  }

  ngOnInit(): void {
    if (this.action && this.action.$key) {
      this._$key = this.action.$key;

      this.new = false;

      this.db.initItem(this);
      this._cd.markForCheck();

      this._bindItem().then(() => {
        this.pageConfig.loaded = true;
        this.refreshVisitList();
        if (this.newAction && this.newAction.check) { //Automatically goes to select matching if new action
          this.newAction.check = false;
          this.selectMatchings();
        }
      });

    }
  }

  _afterBindUpdates() {
    this.refreshVisitList();
  }


  refreshVisitList() {
    const visitTemp = [];
    if (this.item.values.synaps && this.item.values.synaps.visit && this.allMatchings) {
      for (const match of this.allMatchings) {
        if (this.item.values.synaps.visit[match.$key]) {
          visitTemp.push(match);
        }
      }
    }
    this.visitList = visitTemp;
  }

  validAction() {
    this.item.values.done = true;
    this.item.modify.done = true;
    this.item.values.doneDate = new Date();
    this.item.modify.doneDate = true;
    this.db.saveItem(this.item);
    this.doneFn.emit(this.item.values);
  }

  // dateAction() {
  //   const dialogRef = this.dialog.open(DatetimeDialogComponent, {
  //     width: '250px',
  //     disableClose: true,
  //     data: this.item.values.date,
  //   });

  //   dialogRef.afterClosed().subscribe(newDate => {
  //     if (newDate) {
  //       this.item.values.date = newDate;
  //       this.item.modify.date = true;
  //       this.saveItem();
  //     }
  //   });
  // }

  selectMatchings() {
    this.select.init(this.item, this.db, "visit", null, false);
    this.addFn.emit(this.item);
  }

  deleteItem() {
    const data = { title: 'Delete the Visit?', ok: false };
    const dialogRef = this.dialog.open(DialogConfirmationComponent, {
      width: '250px',
      disableClose: true,
      data: data
    });

    dialogRef.afterClosed().subscribe(() => {
      if (data.ok) {
        this.db.deleteItem(this.item);
      }
    });
  }
}

