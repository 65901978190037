<mat-card *ngIf="action.done">
    <mat-list>
        <mat-list-item>
            <mat-icon matListIcon>{{GLOBAL.ICONS.DOLLAR}}</mat-icon>
            <div matLine><b>{{action.amount}}</b></div>
            <div matLine><i>{{action.paymentTypeText}}: {{action.ref}}</i></div>
        </mat-list-item>
    </mat-list>
</mat-card>

<ng-container *ngIf="!action.done">
    <mat-card>
        <mat-list>
            <mat-list-item>
                <div matLine>Total Amount (incl. VAT): <b>Rs {{moduleItem.values.agencyFee?.agencyTotal | number}}</b></div> 
            </mat-list-item>
            <mat-list-item *ngIf="moduleItem.values.agencyFee?.agencyPayment">
                <div matLine>Already Paid: <b>Rs {{moduleItem.values.agencyFee?.agencyPayment | number}}</b></div> 
            </mat-list-item>
            <mat-list-item *ngIf="moduleItem.values.agencyFee?.agencyPayment">
                <div matLine>Remaining Amount: <b>Rs {{(moduleItem.values.agencyFee?.agencyTotal - moduleItem.values.agencyFee?.agencyPayment) | number}}</b></div> 
            </mat-list-item>
        </mat-list>
    </mat-card>
    <form-builder [item]="item" [values]="item.values" section="main" [edit]="true">
    </form-builder>

    <div class="actionButtons" *ngIf="isAccountant()">
        <button mat-icon-button color="accent" [disabled]="!item.modified || item.saving" (click)="saveAction()">
            <mat-icon>check</mat-icon>
        </button>
        <button mat-icon-button class="active" [disabled]="!item.valid || item.saving" (click)="validAction(false)">
            <mat-icon>task_alt</mat-icon>
        </button>
        <button mat-icon-button (click)="dateAction()">
            <mat-icon>event</mat-icon>
        </button>
    </div>

</ng-container>