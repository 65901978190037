import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SyncPortalService {

  private _itemsSubcription: any;
  private _itemsSubcriptionMandate: any;
  public syncs$: Observable<any[]> = null
  syncs: any[] = [];
  public mandate$: Observable<any> = null
  mandate: any = null;
  public loaded = false;
  public loadedMandate = false;

  constructor(
    protected afs: AngularFirestore,
  ) { }

  init() {


    this.syncs$ = this._colWithIds(`/syncMandates`, (ref) => this._queryFn(ref));

    this.loaded = false;

    if (this._itemsSubcription) {
      this._itemsSubcription.unsubscribe();
    }

    this._itemsSubcription = this.syncs$.subscribe(items => {
      this.syncs = items;
      this.loaded = true;
    });
  }

  initMandate(mandateKey: string) {
    this.mandate$ = this.afs.doc(`/syncMandates/${mandateKey}`).snapshotChanges().pipe(
      map(a => {
        if (a.payload.exists) {
          return a.payload.data();
        }
        return null;
      })
    );

    this.loadedMandate = false;

    if (this._itemsSubcriptionMandate) {
      this._itemsSubcriptionMandate.unsubscribe();
    }

    this._itemsSubcriptionMandate = this.mandate$.subscribe(item => {
      this.mandate = item;
      this.loadedMandate = true;
    });
  }

  protected _queryFn(ref): any {

    let query = ref.where('marketingCheck', '==', false);

    query = query.orderBy('updated', 'asc');

    return query;
  }

  protected _colWithIds(path, queryFn?): Observable<any[]> {
    return this.afs.collection(path, queryFn).snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            return a.payload.doc.data();
            // return new this.itemClass(a.payload.doc.data());
          });
        })
      );
  }
}
