<mat-card class="backspace cursor title">
    <mat-card-title class="matchingsbg" *ngIf="showTitle">
        {{allMatchings.new.all ? (allMatchings.new.all + ' New') : allMatchings.active.all}} Matchings
    </mat-card-title>
    <mat-list class="h" *ngIf="allMatchings.new.all">
        <mat-list-item>
            <ng-container *ngIf="allMatchings.new.hot">
                <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.NEW}}</mat-icon>
                <h3 matLine> {{allMatchings.new.hot}} </h3>
            </ng-container>
        </mat-list-item>
        <mat-list-item>
            <ng-container *ngIf="allMatchings.new.warm">
                <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.NEW}}</mat-icon>
                <h3 matLine> {{allMatchings.new.warm}} </h3>
            </ng-container>
        </mat-list-item>
        <mat-list-item>
            <ng-container *ngIf="allMatchings.new.cold">
                <mat-icon matListIcon class="cold">{{GLOBAL.ICONS.NEW}}</mat-icon>
                <h3 matLine> {{allMatchings.new.cold}} </h3>
            </ng-container>

        </mat-list-item>
    </mat-list>
    <mat-list class="h">
        <mat-list-item>
            <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.MATCHING}}</mat-icon>
            <h3 matLine> {{allMatchings.active.hot}} </h3>
        </mat-list-item>
        <mat-list-item>
            <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.MATCHING}}</mat-icon>
            <h3 matLine> {{allMatchings.active.warm}} </h3>
        </mat-list-item>
        <mat-list-item>
            <mat-icon matListIcon class="cold">{{GLOBAL.ICONS.MATCHING}}</mat-icon>
            <h3 matLine> {{allMatchings.active.cold}} </h3>
        </mat-list-item>
    </mat-list>

</mat-card>