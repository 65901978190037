import { Injectable } from '@angular/core';



@Injectable({
  providedIn: 'root'
})
export class BackurlService {

  private _urls: string[] = [];

  constructor() { }

  reset() {
    this._urls = [];
  }

  public addURL(url: string) {
    this._urls.push(url);
  }

  public back(): string {
    let url = '/';
    if (this._urls.length) {
      url = this.url;
      this._urls.pop();
    }
    return url;
  }

  get url(): string | null {
    if (this._urls.length) {
      return this._urls[this._urls.length - 1];
    }
    return null;
  }

}
