import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
// import { FormControl } from '@angular/forms';
import { Field, MainItem } from '../../services/main/main.model';
import { Langs } from '../../services/main/main.model'
import { SettingService } from '../../services/setting/setting.service';
import { Tools } from '../../services/tools';

const tools = new Tools();

@Component({
  selector: 'field-builder',
  templateUrl: './field-builder.component.html',
  styleUrls: ['./field-builder.component.scss']
})
export class FieldBuilderComponent implements OnInit {

  public langs = Langs;
  private _item: MainItem;
  public autoCompleteDis: string;
  public autoCompleteParams = [];
  public sub: any = {};
  private _field: Field;

  @Input()
  public set field(field: Field) {
    this._field = field;
    this.sub = field.sub;
  }
  public get field() {
    return this._field;
  }

  @Input()
  public section: string;

  @Input()
  public set item(item: MainItem) {
    this._item = item;
  }
  public get item() {
    return this._item;
  }

  @Input()
  public rootValues: any;

  @Input()
  public values: any;

  @Input()
  public validation: any;

  @Input()
  public modify: any;

  @Output() touch: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("name")
  public inputRef: any

  constructor(
    public setting: SettingService,
    protected _cd: ChangeDetectorRef,
    // private appRef: ApplicationRef,
  ) { }

  ngOnInit(): void {
    if (this.field.input === 'selectAuto') {
      this.autoCompleteDis = this.values[this.field.key];
    }
  }

  ngAfterViewChecked(): void {
    // Don't do validation if OBject Sub
    if (this.field.input != 'objectSub' && this.field.input != 'objectSubType' && this.field.input != 'dynSub') {



      // If not controller put valid as true (ex. Toggle) 
      if (this.inputRef && this.inputRef.control) {
        this._checkItemValidation(this.inputRef.control.errors);
      } else {
        this.validation[this.field.key] = true;

        // check if section is valid
        this._checkSectionAndMainValidation();
      }
    } else {
      // check if section is valid
      this._checkSectionAndMainValidation();
    }

  }

  // ngAfterViewChecked() {
  // }

  getErrorMessage(errors: any, field: Field): string {
    let i = 0;
    let errorMsg = '';
    for (const error in errors) {
      errorMsg += this._validation_msg(error, field);
      if (i > 0) errorMsg += '\n';
      i++;
    }
    return errorMsg;
  }


  private _validation_msg(error: string, field: Field): string {

    const msgs = {
      required: `${field.label} is required`,
      email: 'Enter a valid email'
      // pattern: 'Your username must contain only numbers and letters',
      // minlength: 'Username must be at least 5 characters long',
      // maxlength: 'Username cannot be more than 25 characters long',
      //   { type: 'areEqual', message: 'Password mismatch' }
    }

    return msgs[error];
  }

  private _checkItemValidation(errors: any/*, mainValidChecked = true*/) {
    if (!errors) {
      errors = {};
    }
    this.validation[this.field.key] = (Object.keys(errors).length === 0);

    // check if section is valid
    this._checkSectionAndMainValidation(/*mainValidChecked*/)
  }

  private _checkSectionAndMainValidation(/*modify: true*/) {
    // let v = { valid: true, modified: true };
    let v = { valid: true };
    for (const fieldKey of this.item.sections[this.section].fields) {
      this._checkFieldValidation(v, this.item.validation, fieldKey);
      // if (modifyChecked) {
      //   this._checkFieldModify(v, this.item.modify, fieldKey);
      // }
    }
    this.item.activeSections[this.section].valid = v.valid;
    // this.item.activeSections[this.section].modified = v.modified;

    // check if all sections are valid
    let valid = true;
    let modified = true;
    for (const sectionKey in this.item.activeSections) {
      if (!this.item.activeSections[sectionKey].valid) {
        valid = false;
      }
      // if (!this.item.activeSections[sectionKey].modified) {
      //   modified = false;
      // }
    }

    this.item.valid = valid;
    // if (modifyChecked) {
    //   this.item.modified = true;
    // }
  }

  private _checkFieldValidation(v: { valid: boolean/*, modified: boolean*/ }, validation: any, fieldKey: string) {
    if (validation[fieldKey]) {
      if (validation[fieldKey].constructor === Object) {
        for (const objKey in validation[fieldKey]) {
          this._checkFieldValidation(v, validation[fieldKey], objKey);
        }
      }
    } else {
      // if (!validation[fieldKey]) {
      v.valid = false;
      // }
    }
  }

  // private _checkFieldModify(v: { valid: boolean, modified: boolean }, modify: any, fieldKey: string) {
  //   if (modify[fieldKey]) {
  //     if (modify[fieldKey].constructor === Object) {
  //       for (const objKey in modify[fieldKey]) {
  //         this._checkFieldModify(v, modify[fieldKey], objKey);
  //       }
  //     } else {
  //       if (!modify[fieldKey]) {
  //         v.modified = false;
  //       }
  //     }
  //   }
  // }

  public get aValue() {
    return this.values[this.field.key];
  }
  public set aValue($event) {

    this._setValue($event);
    // this.rootValues = tools.mergeDeep({}, this.rootValues);
    // this._cd.detectChanges();
    // this._cd.markForCheck();
    // this.appRef.tick();
  }

  private _setValue(data) {
    this.values[this.field.key] = data;

    // Note that the field has been modified
    this.modify[this.field.key] = true;
    this.item.modified = true;

    if (this.field.input === 'selectAuto') {
      this.autoCompleteDis = this.values[this.field.key];
    }

    this._allCheck();
    this.touch.emit();
  }

  touched() {
    this.touch.emit();
  }

  private _allCheck() {
    // Check if there is a controller otherwise check sections and main validation only
    if (this.inputRef && this.inputRef.control) {
      this._checkItemValidation(this.inputRef.control.errors);
    } else {
      this._checkSectionAndMainValidation();
    }
  }

  doFilter() {
    const search = this.autoCompleteDis.toLocaleLowerCase();
    this.autoCompleteParams = this.setting.getParamsSelect(this.field.inputOptions.param).filter(param =>
      // used 'includes' here for demo, you'd want to probably use 'indexOf'
      param.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(search)
    );
  }

  autoCompleteSelect($event) {
    this._setValue($event.option.value);
  }

  displayFn(value?: string) {
    return value ? this.setting.getParamValueName(this.field.inputOptions.param, value) : undefined;
  }

  ratingUpdated(rating: number) {
    this._setValue(rating);
  }

  // Dynamic Object
  add() {
    const newKey = this.item.newDynItem(this.values[this.field.key], this.validation[this.field.key], this.modify[this.field.key], this.field);

    this.values[this.field.key][newKey].order = Object.keys(this.values[this.field.key]).length;
    this.modify[this.field.key][newKey].order = true;

    this.sub = Object.assign({}, this.field.sub);

    this._allCheck();
  }

  delete(key: string) {
    const deletedOrder = this.values[this.field.key][key].order;

    this.item.removeDynItem(this.values[this.field.key], this.validation[this.field.key], this.modify[this.field.key], this.field, key);

    if (deletedOrder) {
      for (const otherKey in this.values[this.field.key]) {
        if (this.values[this.field.key][otherKey].order > deletedOrder) {
          this.values[this.field.key][otherKey].order--;
          this.modify[this.field.key][otherKey].order = true;
        }
      }
    }

    this.sub = Object.assign({}, this.field.sub);
    this._allCheck();
  }

  orderUp(key: string) {
    const total = Object.keys(this.values[this.field.key]).length;
    const currentOrder = this.values[this.field.key][key].order ? this.values[this.field.key][key].order : 1;

    if (currentOrder < total) {
      const nextOrder = currentOrder + 1;

      for (const otherKey in this.values[this.field.key]) {
        if (this.values[this.field.key][otherKey].order == nextOrder) {
          this.values[this.field.key][otherKey].order = currentOrder;
          this.modify[this.field.key][otherKey].order = true;
        }
      }

      this.values[this.field.key][key].order = nextOrder;
      this.modify[this.field.key][key].order = true;

      this.sub = Object.assign({}, this.field.sub);
      this._allCheck();
    }
  }

  orderDown(key: string) {
    const currentOrder = this.values[this.field.key][key].order ? this.values[this.field.key][key].order : 1;

    if (currentOrder > 1) {
      const nextOrder = currentOrder - 1;

      for (const otherKey in this.values[this.field.key]) {
        if (this.values[this.field.key][otherKey].order == nextOrder) {
          this.values[this.field.key][otherKey].order = currentOrder;
          this.modify[this.field.key][otherKey].order = true;
        }
      }

      this.values[this.field.key][key].order = nextOrder;
      this.modify[this.field.key][key].order = true;

      this.sub = Object.assign({}, this.field.sub);
      this._allCheck();
    }
  }

  onKey(event: KeyboardEvent) {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  }

  prevent(event: KeyboardEvent) {
    event.preventDefault();
  }
}

