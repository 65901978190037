<mat-toolbar>
    <div>
        <a mat-icon-button (click)="clickBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <span *ngIf="!db.notAssigned">{{db.newRequests.length}} New Requests</span>
        <span *ngIf="db.notAssigned">{{db.notAssigned}} New Requests Not Assigned!</span>
    </div>
    <profile></profile>
</mat-toolbar>
<div class="toolbartop"></div>

<div class="main-container-flex split">
    <mat-spinner *ngIf="!db.loaded" [strokeWidth]="5"></mat-spinner>

    <app-table class="main" [offset]="db.itemsPageConfig.scrollIndex" [tableKey]="tableKey" [item]="item"
        [items]="db.itemsFilteredOrdered" (sortChange)="sortChange($event)" (clickFn)="goItem($event)"
        (overFn)="previewItem($event)" (scrollFn)="scrollItems($event)" columnAlert="notAssigned"></app-table>


    <div class="side">

        <mat-tab-group [(selectedIndex)]="db.itemsPageConfig.sideTab">

            <!-- FILTERS -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.PARAM}}</mat-icon>
                </ng-template>
                <form [formGroup]="sortServerForm" novalidate>

                    <mat-form-field appearance="fill"
                        *ngIf="db.auth.profile.values.agencies.length > 1 && db.auth.isOneOfRoles(['admin', 'director', 'assistant'])">
                        <mat-label>Agency</mat-label>
                        <mat-select formControlName="agency">
                            <mat-option value="" *ngIf="db.auth.isRole('admin')">
                                All Agencies
                            </mat-option>
                            <mat-option *ngFor="let param of 'userAgencies' | params" [value]="param.id">
                                {{param.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Realtor</mat-label>
                        <mat-select formControlName="realtor">
                            <mat-option value="">
                                All Agents
                            </mat-option>
                            <mat-option
                                *ngFor="let param of setting.realtorsAgency[sortServerForm.controls['agency'].value]"
                                [value]="param.id">
                                {{param.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </form>


            </mat-tab>

            <!-- PREVIEW -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.VIEW}}</mat-icon>
                </ng-template>
                <app-unit-card [values]="preview"></app-unit-card>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<button mat-fab class="bottom-right pfixed" color="basic" (click)="addItem()">
    <mat-icon matListIcon>add</mat-icon>
</button>