import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SelectService } from 'src/common/services/select/select.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { ActionEditComponent } from 'src/components/actions-list/action-edit/action-edit.component';
import { ActionService } from 'src/services/action/action.service';

@Component({
  selector: 'price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent extends ActionEditComponent {

  constructor(protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ActionService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public dialog: MatDialog,
    public backURL: BackurlService,
  ) {
    super(_cd, setting, db, select, route, router, dialog,backURL);
  }

  protected _pushModuleItemData() {
    this.moduleItem.modifyData('price', this.item.values.data.price);
    this.moduleItem.modifyData('priceNeg', this.item.values.data.priceNeg);
    // if (this.moduleItem.values.type === 'sale') {
    //   this.moduleItem.modifyData('priceFurniture', this.item.values.data.priceFurniture);
    // }
    this.moduleItem.modifyData('cy', this.item.values.data.cy);
    this.moduleItem.modifyData('priceNeg', this.item.values.data.priceNeg);
  }
}
