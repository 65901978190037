import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SelectService } from 'src/common/services/select/select.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { ActionService } from 'src/services/action/action.service';
import { ActionEditComponent } from '../action-edit/action-edit.component';
import { Action } from 'src/services/action/action.model';

@Component({
  selector: 'payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentComponent extends ActionEditComponent {

  protected doneEgaleDate = false;

  constructor(protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ActionService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public dialog: MatDialog,
    public backURL: BackurlService,
  ) {
    super(_cd, setting, db, select, route, router, dialog, backURL);
  }


  protected _pushModuleItemData() {

    // Manage several payments
    let agencyPayment = 0;
    if (this.moduleItem.values.agencyFee && this.moduleItem.values.agencyFee.agencyPayment) {
      agencyPayment = this.moduleItem.values.agencyFee.agencyPayment + this.item.values.data.amount;
    } else {
      agencyPayment = this.item.values.data.amount;
    }

    this.moduleItem.values.agencyFee.agencyPayment = agencyPayment;
    this.moduleItem.modify.agencyFee.agencyPayment = true;

    if (agencyPayment < this.moduleItem.values.agencyFee.agencyTotal) {
      // Not enought, create a new pending payment
      this.db.createAction(Action, 'payment', [{ item: this.moduleItem }], {
        realtor: 'hnRb4VzygSP6AgXD5xil2LCwqiv2',
        agency: this.moduleItem.values.agency,
        data: {
          data: {
            amount: this.moduleItem.values.agencyFee.agencyTotal - agencyPayment,
            cy: 'mur',
          }
        }
      }
      );
    } else {
      // All payment, change the status to Deal
      this.moduleItem.modifyData('status', 'deal');
    }

  }
}
