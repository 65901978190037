import { LatLngLiteral } from '@agm/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SettingService } from '../../common/services/setting/setting.service';
import { Tools } from '../../common/services/tools';

const tools = new Tools();

@Component({
  selector: 'sectors-select',
  templateUrl: './sectors-select.component.html',
  styleUrls: ['./sectors-select.component.scss']
})


// interface select { select: boolean, sectors: { [name: string]: select } }

export class SectorsSelectComponent implements OnInit {

  params = {
    lat: -20.26,
    long: 57.56,
    zoom: 10,
  }

  regions: any = {};
  private _value: string[];

  @Input() edit = false;

  @Input()
  set value(v: string[]) {
    this.regions = JSON.parse(JSON.stringify(this.setting.regions));
    if (v) {
      for (const sector of this.regions.sectors) {
        if (v.includes(sector.name)) {
          sector.checked = true;
        }
        if (sector.sectors) {
          for (const subSector of sector.sectors) {
            if (v.includes(subSector.name)) {
              sector.checked = false;
              sector.deep = true;
              subSector.checked = true;
            }
          }
        }
      }
    }
  }

  @Output() modify: EventEmitter<any> = new EventEmitter<any>();



  constructor(public setting: SettingService) {
  }

  ngOnInit(): void {
  }

  public click(keys: string[]) {
    let obj = this.regions;

    if (keys.length == 1) {
      obj = obj.sectors[keys[0]];
      obj.checked = !obj.checked;
    } else if (keys.length == 2) {
      obj.sectors[keys[0]].sectors[keys[1]].checked = !obj.sectors[keys[0]].sectors[keys[1]].checked;
      // check if all sectors are selected comeback to parent
      let back = true;
      for (const sector of obj.sectors[keys[0]].sectors) {
        if (!sector.checked) {
          back = false;
        }
      }
      if (back) {
        for (const i in obj.sectors[keys[0]].sectors) {
          obj.sectors[keys[0]].sectors[i].checked = false;
        }
        obj.sectors[keys[0]].deep = false;
        obj.sectors[keys[0]].checked = true;
      }
    }


    this.modify.emit(this._getCheckedSectorsOnly());
  }

  private _getCheckedSectorsOnly(): string[] {
    const sectors = [];

    if (this.regions.sectors) {
      for (const sector of this.regions.sectors) {
        if (sector.deep) {
          if (sector.sectors) {
            for (const subSector of sector.sectors) {
              if (subSector.checked) {
                sectors.push(subSector.name);
              }
            }
          }
        } else if (sector.checked) {
          sectors.push(sector.name);
        }
      }
    }
    return sectors;
  }


  public dblClick(keys: string[]) {
    let obj = this.regions;

    if (keys.length == 1) {
      obj = obj.sectors[keys[0]];
      if (obj.sectors.length) {
        obj.deep = true;
      }
    } else if (keys.length == 2) {
      obj = obj.sectors[keys[0]].sectors[keys[1]];
      if (obj.sectors.length) {
        obj.deep = true;
      }
    }
  }

  // public rightClick(names: string[]) {
  //   if (!this.select[name]) {
  //     this.select[name] = {};
  //   }
  //   this.select[name].sectors = !this.select[name].sectors;
  // }

}


