import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SelectService } from 'src/common/services/select/select.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { ActionEditComponent } from 'src/components/actions-list/action-edit/action-edit.component';
import { Action } from 'src/services/action/action.model';
import { ActionService } from 'src/services/action/action.service';

@Component({
  selector: 'mandate-r1',
  templateUrl: './r1.component.html',
  styleUrls: ['./r1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class R1Component extends ActionEditComponent {

  constructor(protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ActionService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public dialog: MatDialog,
    public backURL: BackurlService,
  ) {
    super(_cd, setting, db, select, route, router, dialog, backURL);
  }

  get actionSynap(): any {
    return { 'mandatesActions': { [this.moduleItem.values.$key]: this.moduleItem.createAlias(['unitMandates']) } };
  }


  protected _pushModuleItemData() {
    this.moduleItem.modifyData('temperature', this.item.values.data.temperature);

    let nextType = 'signMandate';
    const data: any = {
      temperature: this.item.values.data.temperature,
    };

    if (this.moduleItem.values.type === 'sale' && this.item.values.data.estimation) {
      // If estimation go to r2
      nextType = 'r2';
    }
    this.db.createAction(Action, nextType, [{ item: this.moduleItem }],
      {
        realtor: this.moduleItem.values.realtor,
        agency: this.moduleItem.values.agency,
        data: data
      }
    );
  }

}
