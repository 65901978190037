<mat-card *ngIf="action.done">
    <mat-card-content *ngIf="action.note">
        <p>{{action.note}}</p>
    </mat-card-content>
    <mat-list>
        <mat-list-item>
            <mat-icon matListIcon [ngClass]="action.temperature">{{GLOBAL.ICONS.TEMPERATURE}}</mat-icon>
        </mat-list-item>
        <mat-list-item *ngIf="action.dateNeed">
            <mat-icon matListIcon>{{GLOBAL.ICONS.DATE}}</mat-icon>
            <div matLine> {{action.dateNeed.toDate() | date:'dd MMM yyyy'}} </div>
        </mat-list-item>
        <mat-list-item *ngIf="action.foreigner">
            <mat-icon matListIcon>{{GLOBAL.ICONS.ALERT}}</mat-icon>
            <div matLine>Foreigner</div>
            <div matLine *ngIf="action.residentPermit"><i>Need Resident Permit</i></div>
        </mat-list-item>
        <mat-list-item>
            <mat-icon matListIcon *ngFor="let icon of action.unitTypeIcons">{{icon}}</mat-icon>
        </mat-list-item>
        <mat-list-item>
            <mat-icon matListIcon>{{GLOBAL.ICONS.MAP}}</mat-icon>
            <div matLine>{{action.sectors}}</div>
        </mat-list-item>
        <mat-list-item *ngIf="action.minBedrooms">
            <mat-icon matListIcon>{{GLOBAL.ICONS.BED}}</mat-icon>
            <div matLine>{{action.minBedrooms}}</div>
        </mat-list-item>
        <mat-list-item>
            <mat-icon matListIcon>{{GLOBAL.ICONS.DOLLAR}}</mat-icon>
            <div matLine>{{action.maxBudget}}</div>
        </mat-list-item>
    </mat-list>
</mat-card>

<ng-container *ngIf="!action.done">
    <form-builder [item]="item" [values]="item.values" section="notemain" [edit]="true">
    </form-builder>

    <div class="actionButtons" *ngIf="isRealtor()">
        <button mat-icon-button color="accent" [disabled]="!item.modified || item.saving" (click)="saveAction()">
            <mat-icon>check</mat-icon>
        </button>
        <button mat-icon-button class="active" [disabled]="!item.valid || item.saving" (click)="validAction()">
            <mat-icon>task_alt</mat-icon>
        </button>
        <button mat-icon-button (click)="dateAction()">
            <mat-icon>event</mat-icon>
        </button>
    </div>

</ng-container>