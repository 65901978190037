
<div class="mdc-layout-grid residencePage">
    <div class="mdc-layout-grid__inner" *ngIf="item.values">
        <div class="mdc-layout-grid__cell--span-3">   
            <form-builder [item]="item" [values]="item.values" section="tab" [edit]="true"></form-builder>
        </div>
        <div class="mdc-layout-grid__cell--span-3">                
            <form-builder [item]="item" [values]="item.values" section="params" [edit]="true"></form-builder>
        </div>
        <div class="mdc-layout-grid__cell--span-3">                
            <form-builder [item]="item" [values]="item.values" section="equipment" [edit]="true"></form-builder>
        </div>
        <div class="mdc-layout-grid__cell--span-3">                
            <form-builder [item]="item" [values]="item.values" section="security" [edit]="true"></form-builder>
            <form-builder [item]="item" [values]="item.values" section="area" [edit]="true"></form-builder>
        </div>

    </div>
</div>


<!-- EDIT MODE -->
<button mat-fab class="bottom-right pfixed" [disabled]="!item.valid || !item.modified || item.saving" (click)="saveItem()">
    <mat-icon matListIcon>check</mat-icon>
</button>