<h1 mat-dialog-title>
    <mat-icon>{{GLOBAL.ICONS.TEMPERATURE}}</mat-icon>
    Select New Temperature
</h1>
<div mat-dialog-content>
    <mat-selection-list [multiple]="false" [(ngModel)]="data.temperature">
        <mat-list-option *ngFor="let temp of 'temperatures' | params" [value]="temp.id">
            <mat-icon matListIcon [ngClass]="temp.id">{{data.icon}}</mat-icon>
            <div mat-line>{{temp.name}}</div>
        </mat-list-option>
    </mat-selection-list>
</div>
<div mat-dialog-actions class="buttonsRight">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button [mat-dialog-close]="data.temperature" cdkFocusInitial>Ok</button>
</div>