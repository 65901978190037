import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { Agency, Profile } from './auth.model';
import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public config = {
    google: true,
    password: false,
  }

  public users$: Observable<Profile[]>;
  public agencies$: Observable<Agency[]>;
  public invitations$: Observable<Profile[]>;
  public usersActivePending$: Observable<Profile[]>;
  private FBuser$: Observable<firebase.User>;
  public loggedIn: boolean = false;
  public uid: string = null;

  private _invitationKey: string = null;
  public pendingProfile: Profile = null;

  private _profile = new BehaviorSubject<any>(null);
  public profile$ = this._profile.asObservable();
  public profile: Profile = null;

  public msg: string = null;

  constructor(
    public afAuth: AngularFireAuth,
    protected afs: AngularFirestore,
  ) {
    this.FBuser$ = afAuth.authState;
    let notFirstTime = false;
    this.FBuser$.subscribe(FBuser => {
      if (FBuser) {
        this.checkProfile(FBuser);
      } else {
        this.loggedIn = false;
        this.uid = null;
        if (notFirstTime) {
          this._profile.next(null);
        } else {
          notFirstTime = true;
        }
      }
    });
  }



  //*****************  MANAGE LOGIN CHECK PROFILE AND INVITATION ************************/

  private checkProfile(FBuser) {
    //Check if email verified
    if (FBuser.emailVerified) {

      //Get Profile info
      firebase.firestore().doc(`users/${FBuser.uid}`).get()
        .then((data) => {
          // USER IN DATABASE
          if (data.exists) {
            let tmpProfile = new Profile(data.data());

            // Check Status
            if (tmpProfile.values.status == 'active') {
              this._initActiveProfile(tmpProfile, FBuser);
            }
            else {
              this._loggedOut();
            }
          }
          // USER IS NOT IN DATABASE
          else {
            FBuser.logout();
            this._loggedOut();
            FBuser.delete();
          }
        })
        .catch((error) => {
          // MANAGE PENDING USER FIRST FIRST LOG IN
          if (this._invitationKey) {
            this.checkInvitation()
              .then((profileValues) => {
                this.createActiveProfile(profileValues, FBuser, 'google.com');
              })
              .catch(() => {
                this._loggedOut();
              });
          } else {
            this._loggedOut();
          }
        });
    } else {
      this._loggedOut();
    }

  }

  private _initActiveProfile(tmpProfile, FBuser) {
    this.profile = tmpProfile;
    this._updateGoogleProfile(FBuser);
    this.uid = FBuser.uid;
    this.loggedIn = true;
    this._initUsers();
    this._initInvitations();
    this._initUsersInvitations();
    this._profile.next(this.profile.values);
  }



  //*****************  MANAGE LOGIN WITH GOOGLE ************************/

  loginWithGoogle(): Promise<any> {
    var provider = new firebase.auth.GoogleAuthProvider();

    provider.addScope('https://www.googleapis.com/auth/plus.login');

    return firebase.auth().signInWithPopup(provider)
      .catch((error) => {
        console.log(error);
      });
  }


  private _updateGoogleProfile(FBuser: any) {
    // Check if info changed
    const providerData = FBuser.providerData[0];
    if (providerData.photoURL != this.profile.values.extPhoto ||
      providerData.email != this.profile.values.email ||
      providerData.displayName != this.profile.values.fullname) {
      this._updateProfileInfo(providerData, FBuser.uid);
    }
  }

  private _updateProfileInfo(credentials, uid): Promise<any> {

    // update current info
    this.profile.values.extPhoto = credentials.photoURL;
    this.profile.values.email = credentials.email;
    this.profile.values.fullname = credentials.displayName;

    // update main info
    return firebase.firestore().doc(`users/${uid}`).set(
      {
        fullname: credentials.displayName,
        email: credentials.email,
        extPhoto: credentials.photoURL,
        updated: firebase.firestore.FieldValue.serverTimestamp()
      },
      { merge: true }
    );
  }

  //*****************  MANAGE EMAIL/PASSWORD LOGIN ************************/

  loginWithEmail(email: string, password: string): Promise<any> {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((authData) => {
        return authData;
      })
      .catch(error => {
        return { error };
        // console.log(error);
      });
  }

  registerUserWithEmail(email: string, password: string): Promise<any> {
    if (this._invitationKey) {
      return this.checkInvitation().then(profileValues => {
        return this.afAuth.createUserWithEmailAndPassword(email, password).then(authData => {
          this.createActiveProfile(profileValues, authData.user, 'password');
          authData.user.sendEmailVerification();
          this.afAuth.sendSignInLinkToEmail(email, { url: "app.nestenn.mu" });
          return authData;
        });
      });
    }
  }

  public sendPasswordResetEmail(email: string): Promise<any> {
    return this.afAuth.sendPasswordResetEmail(email);
  }


  //*****************  MANAGE INVITATIONS ************************/

  public createInvitation(user: Profile): Promise<any> {

    return firebase.firestore().collection(`invitations`).add(
      user.toFB('new')
    ).then((doc) => {
      // Record UserID
      this.afs.doc(`/invitations/${doc.id}`).update(
        { $key: doc.id }
      );
      return (doc.id);
    })
      .catch(function (error) {
        console.log("Error creating Profile: ", error);
      });
  }

  public setInvitation(key: string) {
    this._invitationKey = key;
  }

  public checkInvitation(): Promise<Profile> {
    return firebase.firestore().doc(`invitations/${this._invitationKey}`).get().then(
      (data) => {
        // USER IN DATABASE
        if (data.exists) {
          this.pendingProfile = new Profile(data.data());
          return this.pendingProfile;
        }
        return null;
      }
    )
  }

  public removeInvitation(invitation) {
    return firebase.firestore().doc(`invitations/${invitation.$key}`).delete();
  }


  //*****************  MANAGE PROFILE ************************/

  // Create Profile and remove pending profile
  public createActiveProfile(pendingProfile, FBuser, provider: string): Promise<any> {

    this._invitationKey = null;

    const oldKey = pendingProfile.$key;

    // Update pending Profile with new data
    pendingProfile.status = 'active';
    pendingProfile.$key = FBuser.uid;
    pendingProfile.email = FBuser.email;
    pendingProfile.provider = provider;
    return firebase.firestore().doc(`users/${FBuser.uid}`).set(
      // Add Key$ now as we know it
      Object.assign({},
        { $key: FBuser.uid },
        pendingProfile.toFB('new')
      )
    ).then(() => {
      firebase.firestore().doc(`invitations/${oldKey}`).delete();

      // Active User automatically if does not need Email Verification
      if (provider != 'password') {
        this._initActiveProfile(pendingProfile, FBuser);
      }
    })
      .catch(function (error) {
        console.log("Error creating Profile: ", error);
      });
  }


  public logout() {
    // return this.afAuth.auth.signOut();
    return firebase.auth().signOut()
      .then(() => {
        this.profile = null;
        this._profile.next(null);
      })
      .catch((error) => {
        console.log(error);
      });
  }


  private _loggedOut() {
    this.profile = null;
    this._profile.next(null);
    this.uid = null;
    this.loggedIn = false;
  }


  public saveProfile(user: Profile): Promise<any> {
    if (this.loggedIn) {
      return this.afs.doc(`/users/${user.values.$key}`).set(
        user.toFB('save')
        , { merge: true });
    }
  }



  public blockProfile(user: Profile) {
    if (this.loggedIn) {
      return this.afs.doc(`/users/${user.values.$key}`).update(
        { status: 'blocked' }
      );
    }
  }


  public activeProfile(user: Profile) {
    if (this.loggedIn) {
      return this.afs.doc(`/users/${user.values.$key}`).update(
        { status: 'active' }
      );
    }
  }




  //*****************  MANAGE AGENCY ************************/

  public createNewAgency(agency: Agency): Promise<any> {

    // Update pending Profile with new data
    agency.values.status = 'active';
    const newKey = firebase.firestore().collection('/agencies').doc().id;
    return firebase.firestore().doc(`agencies/${newKey}`).set(
      // Add Key$ now as we know it
      Object.assign({ $key: newKey },
        agency.toFB('new')
      )
    )
      .catch(function (error) {
        console.log("Error creating Agency: ", error);
      });
  }

  public blockAgency(agency: Agency) {
    if (this.loggedIn) {
      return this.afs.doc(`/agencies/${agency.values.$key}`).update(
        { status: 'blocked' }
      );
    }
  }

  public saveAgency(agency: Agency): Promise<any> {
    if (this.loggedIn) {
      return this.afs.doc(`/agency/${agency.values.$key}`).set(
        agency.toFB('save')
        , { merge: true });
    }
  }

  public activeAgency(agency: Agency) {
    if (this.loggedIn) {
      return this.afs.doc(`/agencies/${agency.values.$key}`).update(
        { status: 'active' }
      );
    }
  }

  //*****************  MANAGE ROLES ************************/

  public isRole(role: string): boolean {
    return this.profile.values.role === role;
  }

  public isOneOfRoles(roles: string[]): boolean {
    for (const role of roles) {
      if (this.profile.values.role === role) {
        return true;
      }
    }
    return false;
  }

  //*****************  MANAGE PROFILE DATABASE INIT ************************/

  protected _initUsers() {
    if (this.loggedIn) {
      this.users$ = this._colWithIds(`/users`, (ref) => this._queryFnUser(ref));
      this.agencies$ = this._colWithAgencyIds(`/agencies`, (ref) => this._queryFnAgency(ref));
    }
  }

  protected _queryFnUser(ref): any {

    let query = ref;

    query = query.orderBy('fullname', 'asc');

    return query;
  }

  protected _queryFnAgency(ref): any {

    let query = ref;

    query = query.orderBy('name', 'asc');

    return query;
  }

  protected _initInvitations() {
    if (this.loggedIn) {
      this.invitations$ = this._colWithIds(`/invitations`);
    }
  }

  protected _initUsersInvitations() {
    if (this.loggedIn) {
      this.usersActivePending$ = combineLatest([this.users$, this.invitations$])
        .pipe(
          map(([users, invitations]) => {
            return users.concat(invitations);
          })
        );
    }
  }

  public initUser(id: string): Observable<Profile> {
    if (this.loggedIn) {
      return this._docWithIds(`/users/${id}`);
    }
  }

  public initAgency(id: string): Observable<Agency> {
    if (this.loggedIn) {
      return this._docWithIds(`/agency/${id}`);
    }
  }


  //************** MANAGE DB **********************/ 


  protected _colWithIds(path, queryFn?): Observable<any[]> {
    return this.afs.collection(path, queryFn).snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            return new Profile(a.payload.doc.data());
          });
        })
      );
  }

  protected _colWithAgencyIds(path, queryFn?): Observable<any[]> {
    return this.afs.collection(path, queryFn).snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            return new Agency(a.payload.doc.data());
          });
        })
      );
  }

  protected _docWithIds(ref): Observable<any> {
    return this.afs.doc(ref).snapshotChanges().pipe(
      map(a => {
        if (a.payload.exists) {
          return a.payload.data();
        }
        return null;
      })
    );
  }



}