import { Pipe, PipeTransform } from '@angular/core';
import { FileRecord } from '../services/upload-file/upload-file-model';

@Pipe({
  name: 'thumb',
})
export class ThumbPipe implements PipeTransform {
  constructor(
  ) {
  }

  transform(img: FileRecord, dim: string) {
    if (img && img.name && dim) {
      const url = img.url.substr(0, img.url.lastIndexOf('/') + 1);
      const indexExt = img.name.lastIndexOf('.');
      const replacement = `.x${dim}.`;
      return url + 'public%2F' + img.name.substr(0, indexExt) + replacement + img.name.substr(indexExt + 1) + '?alt=media';
    }
    return '';
  }
}
