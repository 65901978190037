import { Component, OnInit } from '@angular/core';
import { MainsComponent } from '../../common/pages/mains/mains.component';
import { SettingService } from '../../common/services/setting/setting.service';
import { CompanyService } from '../../services/company/company.service';
import { SelectService } from '../../common/services/select/select.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { BackurlService } from 'src/common/services/backurl/backurl.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent extends MainsComponent {

  constructor(
    public setting: SettingService,
    public db: CompanyService,
    public select: SelectService,
    protected router: Router,
    protected fb: FormBuilder,
    public backURL: BackurlService,
  ) {
    super(setting, db, select, router, fb, backURL);
    
    this.sortLocalForm = this.fb.group({
      localSearch: [''],
    });
  }

}