import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { Lang } from '../main/main.model';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(
    protected afs: AngularFirestore,
  ) { }

  public translate(
    module: string,
    field: string,
    key: string,
    text: Lang,
    id?: string,
    idField?: string,
  ): Promise<any> {

    let input: null | string = null;
    const langs = [];
    // Check if at least one text
    if (text) {
      if (text.fr && text.fr.length > 10) {
        input = text.fr;
      } else {
        langs.push('fr');
      }
      if (text.en && text.en.length > 10) {
        if (!input) {
          input = text.en;
        }
      } else {
        langs.push('en');
      }
      if (text.de && text.de.length > 10) {
        if (!input) {
          input = text.de;
        }
      } else {
        langs.push('de');
      }
      if (text.ru && text.ru.length > 10) {
        if (!input) {
          input = text.ru;
        }
      } else {
        langs.push('ru');
      }
    }

    // Translate only if empty text
    if (input && langs.length) {
      return this.afs.doc(`/translations/${module}${key}${field}${id ? id : ''}${idField ? idField : ''}`).set({
        input: input,
        langs: langs,
        module: module,
        field: field,
        key: key,
        expired: firebase.firestore.FieldValue.serverTimestamp(),
        id: id ? id : null,
        idField: idField ? idField : null,
      }, { merge: true });
    }
    return Promise.resolve();
  }
}
