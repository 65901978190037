import { Component, Input } from '@angular/core';

@Component({
  selector: 'mandate-sign',
  templateUrl: './mandate-sign.component.html',
  styleUrls: ['./mandate-sign.component.scss']
})
export class MandateSignComponent {

  @Input()
  action: any;

  constructor(
  ) {
  }

}