import { AgencyFeeFields, Alert, Field } from "src/common/services/main/main.model";
import { Action, ActionAgencyFee, ActionAgencyFeeFields } from "../action.model";
import { SettingService } from "src/common/services/setting/setting.service";

export class ActionOffer extends Action {

    values: {
        $key: string,
        ref: number,
        created: firebase.firestore.FieldValue,
        createdBy: string,
        updated: firebase.firestore.FieldValue,
        updatedBy: string,
        alerts: Alert[],

        synaps: any,

        type: string, // Discovery, Visit, ...
        medium: string, // Call, WhatsApp, Email, Meet...
        date: Date,
        doneDate: Date,
        done: boolean,
        realtor: string,
        agency: string,
        note: string,
        data: any,
        projectFee: ActionAgencyFee,
        mandateFee: ActionAgencyFee,
    }


    constructor(
        values: any = null,
        public setting?: SettingService,
    ) {
        super(
            values,
            setting,
            {
                data: new Field({
                    label: 'Offer Details',
                    input: 'objectSub',
                    sub: {
                        price: new Field({
                            input: 'number', label: 'Transaction Amount',
                            validations: { required: true },
                        }),
                        cy: new Field({
                            label: 'Currency',
                            input: 'select', inputOptions: { param: 'cies' },
                            validations: { required: true },
                            default: 'mur',
                        }),
                        accepted: new Field({
                            input: 'boolean', label: 'Accepted',
                            default: false,
                            show: () => { return false },
                        }),
                        note: new Field({ label: 'Note' }),
                    },
                }),
                projectFee: new Field({
                    label: 'Project\'s Agency Fee',
                    input: 'objectSub',
                    sub: new ActionAgencyFeeFields()
                }),
                mandateFee: new Field({
                    label: 'Mandate\'s Agency Fee',
                    input: 'objectSub',
                    sub: new ActionAgencyFeeFields(),
                }),
            },
            {
                projectFee: {
                    title: null,
                    fields: ['projectFee']
                },
                mandateFee: {
                    title: null,
                    fields: ['mandateFee']
                },
            }


        )


    }
}