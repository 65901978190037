import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MainsComponent } from 'src/common/pages/mains/mains.component';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SelectService } from 'src/common/services/select/select.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { Tools } from 'src/common/services/tools';
import { AllMatchingService } from 'src/services/matching/all-matching.service';

@Component({
  selector: 'app-matchings',
  templateUrl: './matchings.component.html',
  styleUrls: ['./matchings.component.scss']
})
export class MatchingsComponent extends MainsComponent {

  public preview: any = null;
  public tools = new Tools();
  public tableCols = ['icon', 'ref', 'clients', 'unitTypes', 'minBedrooms', 'budget', 'days', 'actions', 'yesno'];

  constructor(
    public setting: SettingService,
    public db: AllMatchingService,
    public select: SelectService,
    protected router: Router,
    protected fb: FormBuilder,
    public backURL: BackurlService,
  ) {
    super(setting, db, select, router, fb, backURL);

  }

  goItemModule(moduleName: string, targetItem: any) {
    if (targetItem && targetItem.$key) {
      this.backURL.addURL(`/${this.db.moduleListName}`);
      this.router.navigate([`/${moduleName}/${targetItem.$key}`]);
    }
  }

  overMatching(match: any) {
    if (!(this.preview && match && match.mandate && match.mandate.$key === this.preview.$key)) {
      this.preview = null;
      setTimeout(() => {
        this.preview = match.mandate;
      }, 50);
    }
  }

  clickProject(match: any) {
    this.backURL.addURL(`/matchings`);
    this.router.navigate([`/projects/${match.project.$key}`]);
  }

  clickMandate(mandate: any) {
    this.backURL.addURL(`/matchings`);
    this.router.navigate([`/mandates/${mandate.$key}`]);
  }

  match(matching: any, event: MouseEvent) {
    event.stopPropagation();
    this.db.match(matching);
  }

  unmatch(matching: any, event: MouseEvent) {
    event.stopPropagation();
    this.db.unmatch(matching);
  }
}
