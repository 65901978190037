
import { MainItem, Field, Alert } from '../../common/services/main/main.model';
import { SettingService } from '../../common/services/setting/setting.service';

export class Matching extends MainItem {


    values: {
        $key: string,
        ref: number,
        created: firebase.firestore.FieldValue,
        createdBy: string,
        updated: firebase.firestore.FieldValue,
        updatedBy: string,
        alerts: Alert[],
        realtor: string,
        Agency: string


        synaps: any,


        new: boolean,
        match: boolean,
        visit: number,
        com: boolean,
        report: boolean,
        rate: number,
        pos: { [id: string]: { txt: string } },
        neg: { [id: string]: { txt: string } },
        // note: string,
    }

    constructor(
        values: any = null,
        public setting?: SettingService,
    ) {
        super(
            'matchings',
            {
                realtor: new Field({ input: 'string' }),
                agency: new Field({ input: 'string' }),
                new: new Field({ input: 'boolean', default: true }),
                match: new Field({ input: 'boolean', label: 'is Matching?', default: false }),
                visit: new Field({ input: 'number', default: false }),
                com: new Field({ input: 'boolean', default: false }),
                report: new Field({ input: 'boolean', default: true }),
                rate: new Field({ input: 'rating', }),
                pos: new Field({
                    label: 'Positive Points',
                    input: 'dynSub',
                    dynSub: {
                        txt: new Field(),
                    },
                }),
                neg: new Field({
                    label: 'Negative Points',
                    input: 'dynSub',
                    dynSub: {
                        txt: new Field(),
                    },
                }),
                note: new Field({}),
            },
            // Forms
            {
                main: {
                    title: null,
                    fields: ['rate']
                },
                pos: {
                    title: null,
                    fields: ['pos']
                },
                neg: {
                    title: null,
                    fields: [ 'neg']
                },
                nomatch: {
                    title: null,
                    fields: ['rate', 'pos', 'neg']
                },
            },
            // Tables
            {
                main: {
                    title: 'Matching List',
                    columns: [],
                },
            },
            // Config File
            {
                path: 'matchings/', filename: (data) => {
                    let filename = 'matching-';
                    filename += 'W' + data.ref;
                    return filename
                }
            },
            setting,
        );
        if (values) {
            this.fromFB(values);
        }

    }


}