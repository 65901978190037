<mat-card *ngIf="action.project" class="cursor">
    <mat-card-header>
        <!-- <img mat-card-avatar [src]="action.project.realtor | realtor" /> -->
        <mat-icon mat-card-avatar [ngClass]="action.project.iconColor">
            {{action.project.icon}}</mat-icon>
        <mat-card-title>{{action.originName}}</mat-card-title>
        <mat-card-subtitle>
            C{{action.project.ref}}: <ng-container *ngFor="let c of action.fullnames; let i = index">{{i > 0 ? ', ' :
                '' }}{{c}}
            </ng-container>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content *ngIf="action.note">
        <p>{{action.note}}</p>
    </mat-card-content>
</mat-card>