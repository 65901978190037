import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { GlobalConstants } from '../../../../../functions/src/shared/global-constants';
import { Action } from '../../../../services/action/action.model';
import { ActionService } from '../../../../services/action/action.service';

@Component({
  selector: 'project-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.scss']
})
export class NewRequestDisplayComponent {

  public GLOBAL = new GlobalConstants();

  public itemList: any;
  public mandate: any;
  public project: any;

  @Input()
  public set action(actionList: any) {
    this.itemList = actionList;
    this.mandate = (actionList.mandate && Object.keys(actionList.mandate).length) ? actionList.mandate : null;
    this.project = (actionList.project && Object.keys(actionList.project).length) ? actionList.project : null;
  }
  public get action() {
    return this.itemList;
  }

  // @Input()
  // public project: any = {};

  constructor(
    public db: ActionService,
    protected router: Router,
    public backURL: BackurlService,
    public setting: SettingService,
  ) {

  }

  goItem() {
    this.backURL.addURL(`/projects/${this.project.$key}`);
    this.router.navigate([`/mandates/${this.mandate.$key}`]);
  }


  validAction($event: any) {
    $event.preventDefault();
    this.db.manualSave(
      new Action(null, this.setting),
      this.action.$key,
      {
        done: true,
        doneDate: new Date(),
      },
      true
    );
  }

}
