import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Lang } from '../main/main.model';
import * as firebase from 'firebase';
import { Unit } from 'src/services/unit/unit.model';
import { SettingService } from '../setting/setting.service';
import { mergeResidenceUnitSynap } from 'functions/src/shared/tools';

@Injectable({
  providedIn: 'root'
})
export class OpenaiService {


  constructor(
    protected afs: AngularFirestore,
    private setting: SettingService,
  ) { }

  public advert(
    unit: any,
    typeOf: string,
    max: number,
    start: string,
    module: string,
    field: string,
    id: string,
    idField?: string,
  ): Promise<any> {

    const model = new Unit;

    if (!(unit[field] && unit[field][id] && unit[field][id].length)) {

      const residence: any = mergeResidenceUnitSynap(unit);

      let prompt: string = `I'm a real estate agency in Mauritius called Nestenn. Thanks to generate ${typeOf}.  the features are:\n `;

      prompt += `- The property is a ${unit.type === 'house' ? 'villa or a house, choose the most appropriate between the two' : unit.typeDisp}\n`
      if (unit.address?.eCityName) {
        prompt += `- address: ${unit.address.eCityName}\n`;
      }
      if (unit.bedrooms) {
        prompt += `- ${unit.bedrooms} bedrooms\n`;
      }
      if (unit.bathrooms) {
        prompt += `- ${unit.bathrooms} bathrooms\n`;
      }
      if (residence) {
        if (residence.scheme) {
          prompt += `- the property is part of ${this.setting.getParamValueName('residenceSchemes', residence.scheme)} residence\n`;
        } else {
          prompt += `- the property is part of a residence\n`;
        }
      }
      if (unit.condition) {
        prompt += `- condition: ${this.setting.getParamValueName('unitConditions', unit.condition)}\n`;
      }
      if (unit.style) {
        prompt += `- property Style: ${this.setting.getParamValueName('unitStyles', unit.style)}\n`;
      }
      if (unit.exposition) {
        prompt += `- exposition is ${this.setting.getParamValueName('expositions', unit.exposition)}\n`;
      }
      if (unit.carSpaces) {
        if (unit.carSpaces.garage) {
          prompt += `- ${unit.carSpaces.garage} garage spaces}\n`;
        }
        if (unit.carSpaces.carport) {
          prompt += `- ${unit.carSpaces.carport} carport parking spaces}\n`;
        }
        if (unit.carSpaces.outside) {
          prompt += `- ${unit.carSpaces.outside} Outside Parkings}\n`;
        }
      }
      if (unit.builtSurface) {
        prompt += `- build surface: ${unit.builtSurface} m²}\n`;
      }
      if (unit.livingSurface) {
        prompt += `- living surface: ${unit.livingSurface} m²}\n`;
      }
      if (unit.landSurface) {
        prompt += `- land surface: ${unit.landSurface} ${this.setting.getParamValueName('landUnits', unit.landUnit)}}\n`;
      }
      if (unit.includeAC) {
        prompt += `- includes air conditioning\n`;
      }
      if (unit.floor) {
        prompt += `- Floor: ${this.setting.getParamValueName('unitFloors', unit.floor)}\n`;
      }
      if (unit.storeys) {
        prompt += `- ${this.setting.getParamValueName('unitStoreys', unit.storeys)}\n`;
      }
      if (unit.waterHeaterType) {
        prompt += `- water heater type: ${this.setting.getParamValueName('waterHeaterTypes', unit.waterHeaterType)}\n`;
      }
      if (unit.kitchenType) {
        prompt += `- Kitchen type: ${this.setting.getParamValueName('kitchenTypes', unit.kitchenType)}\n`;
      }
      if (unit.params) {
        for (const param in unit.params) {
          if (unit.params[param] && model.fields.params.sub[param]) {
            prompt += `- ${model.fields.params.sub[param].label}\n`;
          }
        }
      }
      if (unit.equipment) {
        for (const param in unit.equipment) {
          if (unit.equipment[param] && model.fields.equipment.sub[param]) {
            prompt += `- ${model.fields.equipment.sub[param].label}\n`;
          }
        }
      }
      if (unit.appliances) {
        for (const param in unit.appliances) {
          if (unit.appliances[param] && model.fields.appliances.sub[param]) {
            prompt += `- ${model.fields.appliances.sub[param].label}\n`;
          }
        }
      }
      if (unit.security) {
        for (const param in unit.security) {
          if (unit.security[param] && model.fields.security.sub[param]) {
            prompt += `- ${model.fields.security.sub[param].label}\n`;
          }
        }
      }
      if (unit.view) {
        for (const param in unit.view) {
          if (unit.view[param] && model.fields.view.sub[param]) {
            prompt += `- ${model.fields.view.sub[param].label}\n`;
          }
        }
      }
      if (unit.area) {
        for (const param in unit.area) {
          if (unit.area[param] && model.fields.area.sub[param]) {
            prompt += `- ${model.fields.area.sub[param].label}\n`;
          }
        }
      }

      if (unit.keyPoints && Object.keys(unit.keyPoints).length) {
        prompt += `Highlighting the property's key features such as: \n`;
        for (const keyPoint in unit.keyPoints) {
          prompt += `- ${unit.keyPoints[keyPoint].text} \n`;
        }
      }

      prompt += `\n The text should have ${max} words maximum and no fancy ponctuations`;
      if (start) {
        prompt += `\n The text should start with "${start}"`;
      }

      // Translate only if empty text
      this.afs.doc(`/chatgpt/${module}${unit.$key}${field}${id ? id : ''}${idField ? idField : ''}`).set({
        prompt: prompt,
        module: module,
        field: field,
        key: unit.$key,
        expired: firebase.firestore.FieldValue.serverTimestamp(),
        id: id ? id : null,
        idField: idField ? idField : null,
      }, { merge: true });


    }
    return Promise.resolve();
  }
}
