import { Component, Input, OnInit } from '@angular/core';
import { GlobalConstants } from '../../../../functions/src/shared/global-constants';

@Component({
  selector: 'new-request-card',
  templateUrl: './new-request-card.component.html',
  styleUrls: ['./new-request-card.component.scss']
})
export class NewRequestCardComponent implements OnInit {

  public GLOBAL = new GlobalConstants();

  @Input()
  values: any;

  constructor() { }

  ngOnInit(): void {
  }

}
