<mat-card *ngIf="action.done">
    <mat-card-header>
        <mat-icon mat-card-avatar>east</mat-icon>
        <mat-card-title>{{action.priceDisplay}}</mat-card-title>
        <mat-card-subtitle *ngIf="action.priceNeg">{{action.furniturePriceDisplay}}
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content *ngIf="action.note">
        <p>{{action.note}}</p>
    </mat-card-content>
</mat-card>

<ng-container *ngIf="!action.done">
    <form-builder [item]="item" [values]="item.values" section="main" [edit]="true">
    </form-builder>

    <div class="actionButtons" *ngIf="isRealtor()">
        <button mat-icon-button *ngIf="item.values.type !== 'marketingCheck'" color="accent"
            [disabled]="!item.modified || item.saving" (click)="saveAction()">
            <mat-icon>check</mat-icon>
        </button>
        <button mat-icon-button class="active" [disabled]="!item.valid || item.saving" (click)="validAction(false)">
            <mat-icon>task_alt</mat-icon>
        </button>
        <button mat-icon-button (click)="dateAction()">
            <mat-icon>event</mat-icon>
        </button>
        <!-- <button mat-icon-button
            *ngIf="(item.values.type !== 'discovery' && item.values.type !== 'marketingCheck') || db.isRole('admin')"
            (click)="deleteAction()">
            <mat-icon>delete</mat-icon>
        </button> -->
    </div>

</ng-container>