import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Agency } from 'src/common/services/auth/auth.model';
import { AuthService } from 'src/common/services/auth/auth.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { mergeDeep } from 'functions/src/shared/tools';

@Component({
  selector: 'app-agency',
  templateUrl: './agency.component.html',
  styleUrls: ['./agency.component.scss']
})
export class AgencyComponent implements OnInit {


  public agency = new Agency();
  public agency$: Observable<Agency>;
  public new = true;
  private _agencyID = '0';

  constructor(
    protected _cd: ChangeDetectorRef,
    public auth: AuthService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected setting: SettingService,
  ) {
  }

  ngOnInit(): void {
    this._agencyID = this.route.snapshot.paramMap.get('id');
    this._initProfile();
  }

  private _initProfile() {
    if (this._agencyID && this._agencyID != '0') {
      this.new = false;
      this.agency$ = this.auth.initAgency(this._agencyID);
      this.agency$.subscribe((agency) => {
        this.agency.fromFB(agency);
        this.agency.values = mergeDeep({}, this.agency.values);
        this._cd.markForCheck();
      });
    } else {
      this._agencyID = '0';
    }
  }

  saveNewAgency() {
    this.auth.createNewAgency(this.agency).then(() => {
      this.router.navigate([`/settings/agencies`]);
    });
  }

  saveAgency() {
    this.auth.saveAgency(this.agency);
    this.router.navigate([`/settings/agencies`]);
  }

  blockAgency() {
    this.auth.blockAgency(this.agency);
  }

  activeAgency() {
    this.auth.activeAgency(this.agency);
  }

}
