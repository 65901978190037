import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalConstants } from '../../../../functions/src/shared/global-constants';
import { SettingService } from '../../../common/services/setting/setting.service';

@Component({
  selector: 'swap-agent',
  templateUrl: './swap-agent.component.html',
  styleUrls: ['./swap-agent.component.scss']
})
export class SwapAgentComponent {

  public GLOBAL = new GlobalConstants();

  constructor(
    public setting: SettingService,
    public dialogRef: MatDialogRef<SwapAgentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}