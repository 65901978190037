import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Profile } from '../../services/auth/auth.model';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { mergeDeep } from 'functions/src/shared/tools';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  public user = new Profile();
  public user$: Observable<Profile>;
  public new = true;
  private _userID = '0';

  constructor(
    protected _cd: ChangeDetectorRef,
    public auth: AuthService,
    protected route: ActivatedRoute,
    protected router: Router,
  ) { 
  }

  ngOnInit(): void {
    this._userID = this.route.snapshot.paramMap.get('id');
    this._initProfile();
  }

  private _initProfile() {
    if (this._userID && this._userID != '0') {
      this.new = false;
      this.user$ = this.auth.initUser(this._userID);
      this.user$.subscribe((user) => {
        this.user.fromFB(user);
        this.user.values = mergeDeep({}, this.user.values);
        this._cd.markForCheck();
      });
    } else {
      this._userID = '0';
    }
  }

  saveNewUser() {
    this.auth.createInvitation(this.user).then(() => {
      this.router.navigate([`/settings/users`]);
    });
  }

  saveUser() {
    this.auth.saveProfile(this.user);
    this.router.navigate([`/settings/users`]);
  }

  blockUser(){
    this.auth.blockProfile(this.user);
  }

  activeUser(){
    this.auth.activeProfile(this.user);
  }
}
