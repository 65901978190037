import { Alert, Field } from "src/common/services/main/main.model";
import { Action, ActionAgencyFee, ActionAgencyFeeFields } from "../action.model";
import { SettingService } from "src/common/services/setting/setting.service";

export class ActionSignMandate extends Action {

    values: {
        $key: string,
        ref: number,
        created: firebase.firestore.FieldValue,
        createdBy: string,
        updated: firebase.firestore.FieldValue,
        updatedBy: string,
        alerts: Alert[],

        synaps: any,

        type: string, // Discovery, Visit, ...
        medium: string, // Call, WhatsApp, Email, Meet...
        date: Date,
        doneDate: Date,
        done: boolean,
        realtor: string,
        agency: string,
        note: string,
        data: any,
        mandateFee: ActionAgencyFee,
    }


    constructor(
        values: any = null,
        public setting?: SettingService,
    ) {
        super(
            values,
            setting,
            {
                data: new Field({
                    label: 'Mandate Details',
                    input: 'objectSub',
                    sub: {
                        exclusive: new Field({
                            label: 'Exclusive',
                            input: 'boolean',
                            default: false
                        }),
                        // date: new Field({
                        //     input: 'date', label: 'Date Signature',
                        //     validations: { required: true },
                        // }),
                        duration: new Field({
                            input: 'number', label: 'Duration (Optional - Months)',
                        }),
                        temperature: new Field({
                            label: 'Temperature', input: 'select', inputOptions: { param: 'temperatures' },
                            validations: { required: true }
                        }),
                        price: new Field({
                            input: 'number', label: 'Total Amount',
                            validations: { required: true },
                        }),
                        priceNeg: new Field({
                            input: 'number', label: 'Negociable Price',
                        }),
                        // priceFurniture: new Field({
                        //     input: 'number', label: 'Including Furniture Price',
                        //     show: (data) => {
                        //         return data && data.cat == 'sale';
                        //     },
                        // }),
                        cy: new Field({
                            label: 'Currency',
                            input: 'select', inputOptions: { param: 'cies' },
                            validations: { required: true },
                            default: 'mur',
                        }),
                        dateAvailable: new Field({
                            label: 'Unit Available (Optional)', input: 'date',
                            show: (data) => {
                                return data &&
                                    data.synaps &&
                                    data.synaps.mandatesActionsObj &&
                                    data.synaps.mandatesActionsObj.type == 'rental';
                            },
                        }),
                        incl: new Field({ input: 'textarea', label: 'Other information (inclusions/exclusions):' }),

                        accepted: new Field({
                            input: 'boolean', label: 'Accepted',
                            show: () => { return false },
                        }),

                        note: new Field({ input: 'textarea', label: 'Note' }),

                    },
                }),
                mandateFee: new Field({
                    label: 'Mandate\'s Agency Fee',
                    input: 'objectSub',
                    sub: new ActionAgencyFeeFields(),
                }),
                noAgencyFee: new Field({
                    label: 'No Agency Fee for Buyer',
                    input: 'boolean',
                    default: false,
                    show: (data: any) => data && data.type === 'sale',
                }),
            },
            {
                mandateFee: {
                    title: null,
                    fields: ['mandateFee', 'noAgencyFee']
                },
            }


        )


    }
}