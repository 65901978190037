export class Upload {
    file: File;
    name: string;
    path: string;
    progress: number;

    constructor(file: File, path: string, filename: string) {
        this.file = file;
        this.name = filename;
        this.path = path;
        this.progress = 0;
    }
}

export interface FileRecord {
    name: string;
    path: string;
    type: string;
    url: string;
    info?: any;
}


export interface ExternalMediaRecord {
    url: string;
    ad: boolean;
    info?: any;
}