import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MainItem } from '../../services/main/main.model';
import { SettingService } from '../../services/setting/setting.service';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { SelectService } from '../../services/select/select.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import * as firebase from 'firebase/app';
import { Tools } from 'src/common/services/tools';
import { GlobalConstants } from 'functions/src/shared/global-constants';

export interface Filter {
  search: string,
  bounds: any;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  public tools = new Tools();
  public GLOBAL = new GlobalConstants();
  private _item: MainItem = null;
  public title = null;
  public colsDisplay: string[] = [];
  public ref: string = 'ref';
  // public colsParam: { sort: boolean, title: string, key: string }[] = [];


  public dataSource: any;
  // public selectedItems = [];
  // private _filters: Filter = { search: '', bounds: null };


  // @ViewChild(MatSort, { static: true }) mt: MatSort;
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

  @Input() tableKey: string = 'main';

  @Input() set item(item: MainItem) {
    if (item && item.tables[this.tableKey]) {
      this._item = item;
      this.title = item.tables[this.tableKey].title;
      // init cols to Display
      this.colsDisplay = [];
      if (this.select.active) {
        this.colsDisplay.push('select');
      }

      for (const column of item.tables[this.tableKey].columns) {
        this.colsDisplay.push(column.field);
      }
    }
  }
  get item() {
    return this._item;
  }

  @Input()
  columnAlert: string = null;

  @Input()
  sort: { active: string, direction: 'asc' | 'desc' } = { active: 'ref', direction: 'desc' };

  @Output() sortChange: EventEmitter<any> = new EventEmitter<any>();

  // @Input()
  // set mapBounds(bounds) {
  //   if (bounds) {
  //     this._filters.bounds = bounds;
  //     this.dataSource.filter = JSON.stringify(this._filters);
  //   }
  // }


  // @Input()
  // filterEnable = true;

  @Input()
  offset: number;

  @Input()
  set items(items: any[]) {
    if (items && items.length) {
      if (this.select.active) {
       items = items.sort((a: any, b: any) => {
          if (this.select.selectedItems[a.$key] && !this.select.selectedItems[b.$key]) {
            return -1;
          }
          return 1;
        });
      }
      this.dataSource = new TableVirtualScrollDataSource(items);

      // if (this.select.active) {
      //   this._initSelectedItems(items);
      // }

      // this.dataSource.sort = this.mt;
      // if (this.filterEnable) {
      //   this.dataSource.filterPredicate =
      //     (data: any, filter: string) => {

      //       const filters: Filter = JSON.parse(filter);

      //       if (!filters.search.length || isNaN(Number(filters.search))) {

      //         // SEARCH NORMAL SEARCH

      //         // Search String
      //         if (filters.search.length && ((JSON.stringify(data)).toLowerCase().indexOf(filters.search) < 0)) {
      //           return false;
      //         }
      //         // Google Map Bounds 
      //         if (filters.bounds
      //           && (!(data.address_gps && data.address_gps.latitude && data.address_gps.longitude)
      //             || data.address_gps.latitude < filters.bounds.south
      //             || data.address_gps.latitude > filters.bounds.north
      //             || data.address_gps.longitude < filters.bounds.west
      //             || data.address_gps.longitude > filters.bounds.east
      //           )) {
      //           return false;
      //         }

      //       }
      //       // Search Ref Number
      //       else if (data.ref != Number(filters.search)) {
      //         return false;
      //       }
      //       return true;
      //     }
      // }

      setTimeout(() => {
        if (this.offset) {
          this.viewPort.scrollToOffset(this.offset * 48, "auto");
        }

        this.viewPort.scrolledIndexChange.subscribe((index) => {
          this.scrollFn.emit(index);
        })
      }, 10);
    } else {
      this.dataSource = new TableVirtualScrollDataSource([]);
    }

  };

  // @Input()
  // set search(search: string) {
  //   if (this.filterEnable) {
  //     this._filters.search = search.trim().toLowerCase();
  //     this.dataSource.filter = JSON.stringify(this._filters);
  //   }
  // }



  @Output() clickFn: EventEmitter<any> = new EventEmitter<any>();
  @Output() overFn: EventEmitter<any> = new EventEmitter<any>();
  @Output() scrollFn: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public setting: SettingService,
    public select: SelectService,
  ) {
  }

  ngOnInit(): void {
  }

  clickItem(element) {
    if (this.select.active) {
      this.toggleSelect(element);
      this.clickFn.emit(element);
    } else {
      this.clickFn.emit(element);
    }
  }

  public toggleSelect(itemList: any) {
    this.select.toggle(itemList);
    // const tmp = [...this.selectedItems];
    // if (this.select.selectedItems[itemList.$key]) {
    //   tmp.push(itemList);
    // } else {
    //   let i = 0;
    //   for (const item of this.selectedItems) {
    //     if (item.$key === itemList.$key) {
    //       tmp.splice(i, 1);
    //     }
    //     i++;
    //   }
    // }
    // this.selectedItems = tmp;
  }

  overItem(element) {
    this.overFn.emit(element);
  }

  sortData($event) {
    this.sort = $event;
    this.sortChange.emit(this.sort);
  }

  trackFn(index, item) {
    return item.ref;
  }

 
  // private _initSelectedItems(items: any[]) {
  //   if (this.select.selectedItems && Object.keys(this.select.selectedItems).length) {
  //     const tmp = [];
  //     for (const item of items) {
  //       if (this.select.selectedItems[item.$key]) {
  //         tmp.push(item);
  //       }
  //     }
  //     this.selectedItems = tmp;
  //   }
  // }
}
