import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { Matching } from "../../services/matching/matching.model";
import { MatchingService } from "../../services/matching/matching.service";
import { EventEmitter } from "@angular/core";
import { Output } from "@angular/core";
import { GlobalConstants } from "../../../functions/src/shared/global-constants";
import { Tools } from "../../common/services/tools";
import { Router } from "@angular/router";
import { BackurlService } from "src/common/services/backurl/backurl.service";
import { FormBuilder } from "@angular/forms";
import { debounceTime } from "rxjs/internal/operators/debounceTime";

@Component({
    selector: 'project-matchings',
    templateUrl: './project-matchings.component.html',
})
export class ProjectMatchingsComponent implements OnInit {

    public GLOBAL = new GlobalConstants();
    public matchingItem = new Matching();
    public preview: any = null;
    public tools = new Tools();

    public notNewMatch: { [matchKey: string]: boolean } = {}; // In order to make it more userfriendly

    sortMatchingLocalForm = this.fb.group({
        matchOnly: [true],
        newOnly: [false],
        visit: [false],
    });

    @Input()
    public matching: MatchingService;

    @Input()
    matchOnly: boolean;

    @Input()
    status: string;

    @Output()
    doneFn: EventEmitter<any> = new EventEmitter<any>();

    tableCols = ['icon', 'ref', 'clients', 'unitTypes', 'minBedrooms', 'budget', 'days', 'actions', 'realtor', 'yesno'];

    constructor(
        protected _cd: ChangeDetectorRef,
        protected router: Router,
        protected fb: FormBuilder,
        public backURL: BackurlService,
    ) {

    }

    ngOnInit(): void {
        this.sortMatchingLocalForm.setValue(this.matching.localFilters);
        this.sortMatchingLocalForm.valueChanges.pipe(debounceTime(500)).subscribe(filter => {
            setTimeout(() => {
                this.matching.setLocalFilter(filter);
                this._cd.detectChanges();
            }, 10);
        });
    }

    // overMatching(match: any) {
    //     if (!(this.preview && match && match.mandate && match.mandate.$key === this.preview.$key)) {
    //         this.preview = null;
    //         setTimeout(() => {
    //             this.preview = match.mandate;
    //         }, 50);
    //     }
    // }



    clickMatching(match: any) {
        this.backURL.addURL(`/mandates/${match.mandate.$key}`);
        this.router.navigate([`/projects/${match.project.$key}`]);
    }


    match(matching: any) {
        this.matching.match(matching);
        this.notNewMatch[matching.$key] = true;
        this.notNewMatch = Object.assign({}, this.notNewMatch);
        this._checkIfLast();
    }

    unmatch(matching: any) {
        this.matching.unmatch(matching);
        this.notNewMatch[matching.$key] = true;
        this.notNewMatch = Object.assign({}, this.notNewMatch);
        this._checkIfLast();
    }


    private _checkIfLast() {
        if (this.matching.itemsFiltered && this.matching.itemsFiltered.length === 1) {
            this.doneFn.emit();
        }
    }

}