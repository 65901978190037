<mat-card *ngIf="values.data as data">
    <mat-card-header>
        <mat-card-title>{{data.origin | param:"projectOrigins"}} {{data.type | param:"projectTypes"}}
        </mat-card-title>
        <mat-card-subtitle *ngIf="data.referral">Referral: {{data.referral | param:"allUsers"}}
        </mat-card-subtitle>
    </mat-card-header>
    <mat-list>
        <mat-list-item>
            <mat-icon matListIcon>{{GLOBAL.ICONS.CONTACT}}</mat-icon>
            <h3 matLine>{{data.title | param:'titles'}} {{data.firstname}} {{data.lastname}}</h3>
        </mat-list-item>
        <mat-list-item>
            <mat-icon matListIcon>{{GLOBAL.ICONS.LANG}}</mat-icon>
            <h3 matLine>{{data.lang | param:'langs'}}</h3>
        </mat-list-item>
        <mat-list-item>
            <mat-icon matListIcon>{{GLOBAL.ICONS.FLAG}}</mat-icon>
            <h3 matLine>{{data.country | param:'countries'}}</h3>
        </mat-list-item>
        <mat-list-item>
            <mat-icon matListIcon>{{GLOBAL.ICONS.PHONE}}</mat-icon>
            <h3 matLine>{{data.tel}}</h3>
        </mat-list-item>
        <mat-list-item>
            <mat-icon matListIcon>{{GLOBAL.ICONS.EMAIL}}</mat-icon>
            <h3 matLine> {{data.email}}</h3>
        </mat-list-item>
    </mat-list>
    <mat-card-content *ngIf="data.msg">
        <p>{{data.msg}}</p>
    </mat-card-content>
    <img mat-card-avatar *ngIf="values.realtor" class="top-right"
        [src]="values.realtor | realtor" [matTooltip]="values.realtor | realtorName"  />
    <mat-icon mat-card-avatar *ngIf="!values.realtor" class="top-right big">
        no_accounts
    </mat-icon>
</mat-card>