import { Component, Input, OnInit } from '@angular/core';
import { GlobalConstants } from '../../../../functions/src/shared/global-constants';

@Component({
  selector: 'project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss']
})
export class ProjectCardComponent implements OnInit {

  public GLOBAL = new GlobalConstants();
  public _values: any;

  @Input()
  public set values(values: any) {
    this._values = values;
  }
  public get values() {
    return this._values;
  }
  
  @Input()
  public listDisplay = false;

  constructor() { }

  ngOnInit(): void {
  }

}
