<div class="container split">
    <div class="main">
        <table mat-table [dataSource]="matching?.itemsFiltered" class="mat-elevation-z8">
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <!-- <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox> -->
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? select.toggle(row) : null"
                        [checked]="select.isSelected(row.$key)">
                    </mat-checkbox>

                    <!-- (change)="$event ? select.toggle(row) : null"
                        [checked]="select.isSelected(row.$key)"> -->
                </td>
            </ng-container>
            <ng-container matColumnDef="icon">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon matListIcon [ngClass]="element.mandate.iconColor">
                        {{element.match ? 'thumb_up' : 'thumb_down'}}
                    </mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="ref">
                <th mat-header-cell *matHeaderCellDef>Ref</th>
                <td mat-cell *matCellDef="let element"> NES{{element.mandate.ref}} </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon matListIcon>
                        {{element.mandate.unitType | icon:'unitTypes'}}
                    </mat-icon>
                </td>
            </ng-container>
            <ng-container matColumnDef="city">
                <th mat-header-cell *matHeaderCellDef>City</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.mandate.residence">
                        {{element.mandate.residence}}<br />
                    </ng-container>
                    {{element.mandate.city}}
                </td>
            </ng-container>
            <ng-container matColumnDef="bedrooms">
                <th mat-header-cell *matHeaderCellDef>Beds</th>
                <td mat-cell *matCellDef="let element">
                    {{element.mandate.bedrooms}}
                </td>
            </ng-container>
            <ng-container matColumnDef="bathrooms">
                <th mat-header-cell *matHeaderCellDef>Baths</th>
                <td mat-cell *matCellDef="let element">
                    {{element.mandate.bathrooms}}
                </td>
            </ng-container>
            <!-- <ng-container matColumnDef="owners">
                <th mat-header-cell *matHeaderCellDef>Owners</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngFor="let contact of element.mandate.owners; let i = index"><br
                            *ngIf="i" />{{contact}}
                    </ng-container>
                </td>
            </ng-container> -->
            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef>Price</th>
                <td mat-cell *matCellDef="let element">
                    {{element.mandate.priceDisplay}}
                    <ng-container *ngIf="element.mandate.priceNegDisplay">
                        <br /><i>{{element.mandate.priceNegDisplay}}</i>
                    </ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon *ngFor="let visit of element.visits">{{GLOBAL.ICONS.VISIT}}</mat-icon>
                    <mat-icon *ngIf="element.offer">{{GLOBAL.ICONS.OFFER}}</mat-icon>
                    <mat-icon *ngIf="element.signAgree">{{GLOBAL.ICONS.SIGNAGREE}}</mat-icon>
                    <mat-icon *ngIf="element.signDeed || element.signLease">{{GLOBAL.ICONS.SIGN}}</mat-icon>
                </td>
            </ng-container>
            <ng-container matColumnDef="desc">
                <th mat-header-cell *matHeaderCellDef>Rating</th>
                <td mat-cell *matCellDef="let element">
                    <star-rating [rating]="element.rate" [edit]="false"></star-rating><br />
                    <ng-container *ngFor="let txt of element.pos">
                        + {{txt}}<br />
                    </ng-container>
                    <ng-container *ngFor="let txt of element.neg">
                        - {{txt}}<br />
                    </ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="match">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="openWebsite(element, $event)">
                        <mat-icon>{{GLOBAL.ICONS.LINK}}</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="tableCols; sticky: true">
            </tr>
            <tr mat-row *matRowDef="let row; let element; columns: tableCols;" (mouseover)="overMatching(element)"
                (click)="edit(element, $event)">
            </tr>
        </table>
    </div>
    <div class="side">
        <mat-tab-group [(selectedIndex)]="matchTab">

            <!-- FILTERS -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.PARAM}}</mat-icon>
                </ng-template>


                <form [formGroup]="sortMatchingLocalForm" novalidate>

                    <mat-form-field appearance="fill">
                        <mat-slide-toggle formControlName="matchOnly">Match
                        </mat-slide-toggle>
                        <textarea matInput hidden></textarea>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-slide-toggle formControlName="visit">Visited
                        </mat-slide-toggle>
                        <textarea matInput hidden></textarea>
                    </mat-form-field>

                </form>

            </mat-tab>

            <!-- PREVIEW -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.VIEW}}</mat-icon>
                </ng-template>
                <app-unit-card [values]="preview" [mandate]="true"></app-unit-card>
            </mat-tab>

        </mat-tab-group>
    </div>


</div>

<button *ngIf="!matchSelect" mat-fab color="basic" class="bottom-right pfixed p2"
    (click)="copyLinks()">
    <mat-icon matListIcon>{{GLOBAL.ICONS.LINK}}</mat-icon>
</button>