<mat-card class="unit" *ngIf="values">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon [ngClass]="values.status">{{setting.getParamIcon('unitTypes', values.unitType)}}</mat-icon>
      </div>
      <mat-card-title>TODO: Address</mat-card-title>
      <mat-card-subtitle *ngIf="values.synaps?.ownersArray">
        <ng-container *ngFor="let c of values.synaps.ownersArray; let i = index">{{i > 0 ? ', ' : '' }}{{c.fullname}}
        </ng-container>
      </mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image [src]="values.photo | thumb:'400'">
    <mat-list class="h">
      <mat-list-item>
        <mat-icon matListIcon>{{GLOBAL.ICONS.BED}}</mat-icon>
        <h3 matLine>{{values.bedrooms}} </h3>
      </mat-list-item>
      <mat-list-item>
        <mat-icon matListIcon>{{GLOBAL.ICONS.BATH}}</mat-icon>
        <h3 matLine>{{values.bathrooms}} </h3>
      </mat-list-item>
      <mat-list-item *ngIf="values.type !== 'land'">
        <mat-icon matListIcon>{{GLOBAL.ICONS.SURFACE}}</mat-icon>
        <h3 matLine> {{(values.livingSurface ? values.livingSurface  : values.builtSurface) | number}} m²</h3>
      </mat-list-item>
      <mat-list-item *ngIf="values.type == 'land'">
        <mat-icon matListIcon>{{GLOBAL.ICONS.SURFACE}}</mat-icon>
        <h3 matLine> {{values.landSurface | number}} {{values.landUnit | param:'landUnits'}} </h3>
      </mat-list-item>
    </mat-list>
    <mat-list class="h j">
      <mat-list-item>
        <h3 matLine>NES{{values.ref}}</h3>
      </mat-list-item>
    </mat-list>
    <mat-card-content>
      <!-- <ng-container *ngFor="let mandate of activeMandates">
        {{mandate.price | number}} {{mandate.cy | param:'cies'}}
      </ng-container> -->
    </mat-card-content>
    <!-- <mat-card-actions>
      <button mat-button>LIKE</button>
      <button mat-button>SHARE</button>
    </mat-card-actions> -->
  </mat-card>