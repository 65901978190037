import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public signupMode = false;

  public email: string = null;
  public password: string = null;
  public repeatPassword = null;

  public verifyEmailError = false;
  public error = false;
  public msg = '';
  public loading = true;
  public pendingEmailVerification = false;

  constructor(
    public auth: AuthService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.matIconRegistry.addSvgIcon(
      "google",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/google.svg")
    );


    let w = this;
    setTimeout(function () {
      w.auth.profile$.subscribe(user => {
        if (!w.auth.loggedIn) {
          w.loading = false;
        } else {
          w.loading = true;
        }
      });
    }, 2000);


  }

  ngOnInit(): void {
    if (this.auth.pendingProfile) {
      this.signupMode = true;
    }
  }


  login(): void {
    this.loading = true;
    this.auth.loginWithEmail(this.email, this.password)
      .then(FBAuth => {
        console.log(FBAuth);
        if (FBAuth && FBAuth.user && !FBAuth.user.emailVerified) {
          this.msg = "You have not verified your email yet!";
          this.loading = false;
        }
      })
      .catch(error => {
        this.error = error.code;
        this.msg = error.message;
        this.loading = false;
      });
  }

  loginWithGoogle(): void {
    this.auth.loginWithGoogle().then(() => {
      setTimeout(() => {
        this.msg = 'You have not been invited to login';
        this.loading = false;
      }, 3000);
    });
  }

  signup(): void {
    this.loading = true;
    this.auth.registerUserWithEmail(this.email, this.password)
      .then(() => {
        this.msg = "A verification link has been sent to your email address!";
        this.pendingEmailVerification = true;
      })
      .catch(error => {
        this.error = error.code;
        this.msg = error.message;
        this.loading = false;
      });
  }

  passwordReset() {
    this.auth.sendPasswordResetEmail(this.email).then(() => {
      this.msg = "A Password Reset link has been sent to your email!";
    })
      .catch(error => {
        this.error = error.code;
        this.msg = error.message;
      });
  }
}
