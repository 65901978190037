<h1 mat-dialog-title>Create New Unit</h1>
<div mat-dialog-content>
    <form-builder [item]="item" [values]="item.values" section="newResidenceUnit" [edit]="true"></form-builder>

    <mat-spinner [strokeWidth]="5" class="center" *ngIf="item.saving"></mat-spinner>
</div>
<div mat-dialog-actions class="buttonsRight">
    <button mat-button (click)="onNoClick()">No Thanks</button>
    <button mat-raised-button color="accent" (click)="onYesClick()"
        [disabled]="!(item.modify && item.valid && !item.saving)" cdkFocusInitial>Ok</button>
</div>