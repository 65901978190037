import { DataLayerManager } from '@agm/core';
import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import createAlias from 'functions/src/shared/alias';
import { createList } from 'functions/src/shared/list';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { MainComponent } from '../../common/pages/main/main.component';
import { SelectService } from '../../common/services/select/select.service';
import { SettingService } from '../../common/services/setting/setting.service';
import { Tools } from '../../common/services/tools';
import { SwapAgentComponent } from '../../components/actions-dialog/swap-agent/swap-agent.component';
import { Action } from '../../services/action/action.model';
import { ActionService } from '../../services/action/action.service';
import { ContactService } from '../../services/contact/contact.service';
import { MandateService } from '../../services/mandate/mandate.service';
import { Project } from '../../services/project/project.model';
import { ProjectService } from '../../services/project/project.service';

@Component({
  selector: 'app-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.scss']
})
export class NewRequestComponent extends MainComponent {

  public tools = new Tools();

  public item: Action;
  public preview: any = null;
  public previewData: any = null;
  public contactFilters = { local: { localSearch: '' } };
  public matchingProjects: any[] = [];
  public projectLoading = false;
  public projectCreated = false;

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ActionService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    private _mandate: MandateService,
    private _contact: ContactService,
    private _project: ProjectService,
    public dialog: MatDialog,
    public backURL: BackurlService,
  ) {
    super(_cd, setting, db, select, route, router, backURL, dialog);
    this.item = new Action({ type: 'newRequest' }, setting);

    // this.backURL.url = '/new-requests';

    this.select.selectDone.subscribe(() => {
      this._getProjectsFromContact();
    });
  }

  protected _itemOnInit() {
    this._getSearchContactPattern();
    this._getProjectsFromContact();
  }

  protected _beforeSaveNewItem() {
    if (this.item.values.data.ref && this.preview) {
      this.item.values.data.projectType = this.preview.type;
      this.item.values.data.agency = this.preview.agency;
      if (this.previewData) {
        this.item.values.synaps = {
          mandatesActions: {
            [this.preview.$key]: createAlias(this.previewData, 'mandates', ['unitMandates'])
          }
        };
      }
    }
    this.item.values.date = new Date();
  }

  protected _afterSaveNewItem() {
    this._getSearchContactPattern();
  }

  private _getSearchContactPattern() {
    const searchPat: string[] = [];
    if (this.item.values.data) {
      if (this.item.values.data.tel && this.item.values.data.tel.length) {
        searchPat.push(this.item.values.data.tel.replace(/\s+/g, ''));
      }
      if (this.item.values.data.email && this.item.values.data.email.length) {
        searchPat.push(this.item.values.data.email);
      }
      if (this.item.values.data.lastname && this.item.values.data.lastname.length) {
        searchPat.push(this.item.values.data.lastname);
      }
      this.contactFilters = { local: { localSearch: searchPat.join('|') } };
    }
  }

  protected _updateURL() {
    window.history.pushState('page2', 'Title', `/new-requests/${this.item.values.$key}`);
  }

  async newRequestModified() {
    if (this.item.values.data && this.item.values.data.ref) { // Check if ref
      if (!this.preview || this.item.values.data.ref !== this.preview.ref) { // Check if ref is different
        //  get new mandate
        const data = await this._mandate.getDatabyRef(this.item.values.data.ref); // Get mandate by ref
        if (data) {
          this.previewData = data;
          this.preview = createList(data, 'mandates');
        } else {
          this.previewData = null;
          this.preview = null;
        }
        // if (this.preview) {
        //   this.item.values.synaps = { // Create synaps link with mandate
        //     mandatesActions: {
        //       [this.preview.$key]: this._mandate.createAlias(data, ['unitMandates'])
        //     }
        //   };
        // } else {
        //   delete this.item.values.synaps;
        // }
      }
    } else {
      this.preview = null;
      delete this.item.values.synaps;
    }
  }

  private async _getProjectsFromContact() {
    // Search for Already created project
    this.matchingProjects = [];
    if (this.item.values.synaps && this.item.values.synaps.contactActions) {
      this.projectLoading = true;
      for (const cKey in this.item.values.synaps.contactActions) {
        const contact = await this._contact.getData(cKey);
        if (contact && contact.synaps && contact.synaps.contactsProjects) {
          for (const pKey in contact.synaps.contactsProjects) {
            this.matchingProjects.push(Object.assign(contact.synaps.contactsProjects[pKey], { $key: pKey }));
          }
        }
      }
      this.projectLoading = false;
    }
  }


  public createProject() {
    this.projectCreated = true;
    const newProject = new Project({
      status: 'new',
      type: this.item.values.data.projectType,
      origin: this.item.values.data.origin,
      originMedium: this.item.values.data.originMedium,
      realtor: this.item.values.realtor,
      agency: this.item.values.agency,
      referral: this.item.values.data.referral,
      synaps: {
        projectsActions: { [this.item.values.$key]: this.item.alias },
        contactsProjects: this.item.values.synaps.contactActions,
      }
    }, this.setting);
    this._project.saveNewItem(newProject);
    this.router.navigate([`/assign-leads`]);
  }

  public removeNewRequest() {
    this.db.deleteItem(this.item);
    this.router.navigate([`/assign-leads`]);
  }

  public selectRealtor() {
    //Choose Who can be selected: Agents part of the mandate
    const data = { realtor: [this.item.values.realtor], agency: this.item.list?.mandate?.agency };
    const dialogRef = this.dialog.open(SwapAgentComponent, {
      width: '450px',
      disableClose: true,
      data: data
    });

    dialogRef.afterClosed().subscribe(() => {
      if (data.realtor && data.realtor[0]) {
        this.startSaving();
        this.item.values.realtor = data.realtor[0];
        this.item.modify.realtor = true;
        this.item.values.agency = data.agency;
        this.item.modify.agency = true;
        this.saveItem();
      }
    });

  }

  public selectContact() {

    const contact: any = {};

    if (this.item.values.data) {
      const data = this.item.values.data;
      if (data.title && data.title.length) contact.title = data.title;
      if (data.firstname && data.firstname.length) contact.firstname = data.firstname;
      if (data.lastname && data.lastname.length) contact.lastname = data.lastname;
      if (data.country && data.country.length) {
        contact.country = data.country;
        contact.nationalities = [data.country];
      }
      if (data.lang && data.lang.length) contact.lang = data.lang;

      if (data.email && data.email.length) {
        const newKey = Math.random().toString(36).substring(2, 5);
        contact.emails = { [newKey]: { email: data.email, order: 1 } }
      }

      if (data.tel && data.tel.length) {
        const newKey = Math.random().toString(36).substring(2, 5);
        contact.tels = { [newKey]: { number: data.tel, order: 1 } }
      }
    }


    this.select.init(this.item, this.db, 'contactActions', this.contactFilters, true, 'new-requests', contact)
  }

  public async projectSelect(project: any, activate: boolean = false) {
    this.projectCreated = true;
    const data: any = {
      synaps: {
        projectsActions: { [this.item.values.$key]: this.item.alias },
      }
    };

    if (activate) {
      data.status = 'active';
    }
    this._project.manualSave(new Project(), project.$key, data, true);

    if (project && project.realtor) {
      this.item.values.realtor = project.realtor;
      this.item.modify.realtor = true;
      this.item.values.agency = project.agency;
      this.item.modify.agency = true;
      this.db.saveItem(this.item);
    }

    this.router.navigate([`/assign-leads`]);
  }


}
