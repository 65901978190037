import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { addVat } from 'functions/src/shared/tools';
import { CardEditComponent } from 'src/common/components/card-edit/card-edit.component';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { Tools } from 'src/common/services/tools';
import { Action } from 'src/services/action/action.model';
import { ActionService } from 'src/services/action/action.service';
import { MandateService } from 'src/services/mandate/mandate.service';
import { ProjectService } from 'src/services/project/project.service';

@Component({
  selector: 'app-agency-fee-project-edit',
  templateUrl: './agency-fee-edit.component.html',
  styleUrls: ['./agency-fee-edit.component.scss']
})
export class AgencyFeeProjectEditComponent extends CardEditComponent {
  remainingAmount: number = 0; // Declare and initialize remainingAmount
  public tools = new Tools();

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ProjectService,
    protected router: Router,
    public backURL: BackurlService,
    public actionService: ActionService,
  ) {
    super(_cd, setting, db, router, backURL);
  }


  saveItem(): Promise<any> {
    this.edit = false;
    this.calculateAgencyTotalAndRemainingAmount();

    if (this.remainingAmount > 0) {
      this.handleRemainingAmountGreaterThanZero();
    } else {
      this.handleRemainingAmountZeroOrNegative();
    }

    return this.db.saveItem(this.item).then(() => {
      this.item.modified = false;
      this.saved.emit();
    });
  }

  private calculateAgencyTotalAndRemainingAmount() {
    this.item.values.agencyFee.agencyTotal = addVat(this.item.values.agencyFee.agencyCA);
    this.item.modify.agencyFee.agencyTotal = true;

    this.remainingAmount = this.item.values.agencyFee.agencyTotal;

    if (this.item.values.agencyFee.agencyPayment) {
      this.remainingAmount -= this.item.values.agencyFee.agencyPayment;
    }
  }

  private handleRemainingAmountGreaterThanZero() {
    this.item.modifyData('status', 'active');
    for (const action of this.actionService.items) {
      if (action.type === 'payment' && !action.done) {
        if (this.remainingAmount > 0) {
          this.actionService.manualSave(new Action(), action.$key, { data: { amount: this.remainingAmount, cy: 'mur' } });
          this.remainingAmount = 0;
        } else {
          this.actionService.deleteItemKey(action.$key);
        }
      }
    }

    if (this.remainingAmount > 0) {
      this.actionService.createAction(Action, 'payment', [{ item: this.item }], {
        realtor: 'hnRb4VzygSP6AgXD5xil2LCwqiv2',
        agency: this.item.values.agency,
        data: { data: { amount: this.remainingAmount, cy: 'mur' } }
      });
    }
  }

  private handleRemainingAmountZeroOrNegative() {
    this.item.modifyData('status', 'deal');
    for (const action of this.actionService.items) {
      if (action.type === 'payment' && !action.done) {
        this.actionService.deleteItemKey(action.$key);
      }
    }
  }
}

@Component({
  selector: 'app-agency-fee-mandate-edit',
  templateUrl: './agency-fee-edit.component.html',
  styleUrls: ['./agency-fee-edit.component.scss']
})
export class AgencyFeeMandateEditComponent extends CardEditComponent {
  remainingAmount: number = 0; // Declare and initialize remainingAmount
  public tools = new Tools();

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: MandateService,
    protected router: Router,
    public backURL: BackurlService,
    public actionService: ActionService,
  ) {
    super(_cd, setting, db, router, backURL);
  }

  saveItem(): Promise<any> {
    this.edit = false;
    this.calculateAgencyTotalAndRemainingAmount();

    if (this.remainingAmount > 0) {
      this.handleRemainingAmountGreaterThanZero();
    } else {
      this.handleRemainingAmountZeroOrNegative();
    }

    return this.db.saveItem(this.item).then(() => {
      this.item.modified = false;
      this.saved.emit();
    });
  }

  private calculateAgencyTotalAndRemainingAmount() {
    this.item.values.agencyFee.agencyTotal = addVat(this.item.values.agencyFee.agencyCA);
    this.item.modify.agencyFee.agencyTotal = true;

    this.remainingAmount = this.item.values.agencyFee.agencyTotal;
    if (this.item.values.agencyFee.agencyPayment) {
      this.remainingAmount -= this.item.values.agencyFee.agencyPayment;
    }
  }

  private handleRemainingAmountGreaterThanZero() {
    this.item.modifyData('status', 'active');
    for (const action of this.actionService.items) {
      if (action.type === 'payment' && !action.done) {
        if (this.remainingAmount > 0) {
          this.actionService.manualSave(new Action(), action.$key, { data: { amount: this.remainingAmount, cy: 'mur' } });
          this.remainingAmount = 0;
        } else {
          this.actionService.deleteItemKey(action.$key);
        }
      }
    }

    if (this.remainingAmount > 0) {
      this.actionService.createAction(Action, 'payment', [{ item: this.item }], {
        realtor: 'hnRb4VzygSP6AgXD5xil2LCwqiv2',
        agency: this.item.values.agency,
        data: { data: { amount: this.remainingAmount, cy: 'mur' } }
      });
    }
  }

  private handleRemainingAmountZeroOrNegative() {
    this.item.modifyData('status', 'deal');
    for (const action of this.actionService.items) {
      if (action.type === 'payment' && !action.done) {
        this.actionService.deleteItemKey(action.$key);
      }
    }
  }
}


