import { Component, OnInit, Input } from '@angular/core';
import { GlobalConstants } from '../../../../functions/src/shared/global-constants';
import { SettingService } from '../../../common/services/setting/setting.service';

@Component({
  selector: 'app-residence-card',
  templateUrl: './residence-card.component.html',
  styleUrls: ['./residence-card.component.scss']
})
export class ResidenceCardComponent {

  public options: {
    owners: true,
  }
  public _item: any;

  public GLOBAL = new GlobalConstants();
  public activeMandates: any[] = [];

  @Input()
  list: boolean = false;

  @Input()
  public set values(values: any) {
    this._item = values;
  }
  public get values() {
    return this._item;
  }

  constructor(
    public setting: SettingService,
  ) { }

}
