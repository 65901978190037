import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from '../common/services/auth/auth.service';
import { SettingService } from '../common/services/setting/setting.service';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../functions/src/shared/global-constants';
import { SelectService } from '../common/services/select/select.service';
import { MyActionService, NewRequestService } from '../services/action/action.service';
import { SyncPortalService } from 'src/services/syncPortal/sync-portal.service';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { AllMatchingService } from 'src/services/matching/all-matching.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'nestenn-app';
  public GLOBAL = new GlobalConstants();
  public menuLarge = false;

  constructor(
    public auth: AuthService,
    public setting: SettingService,
    public select: SelectService,
    private router: Router,
    private location: Location,
    public newRequests: NewRequestService,
    public myActions: MyActionService,
    public portalSyncs: SyncPortalService,
    public allMatchings: AllMatchingService,
    public backURL: BackurlService
  ) {

    // Chech if invitation
    let url = this.location.path(true);
    if (url.includes('invitation')) {
      const params = url.split('#');
      if (params[1]) {
        this.auth.setInvitation(params[1]);
      }
      url = '/';
    }
    this._checkLastVersion();
    this._initProfileChangeDetection(url);
  }

  private async _checkLastVersion() {

    const onlineVersion = await this.setting.getVersion();
    // const appVersion = localStorage.getItem('appVersion');
    const appVersion = '3.3.3';

    if (onlineVersion !== appVersion) {
      // New version detected, force a browser reload
      // localStorage.setItem('appVersion', version);
      window.location.reload();
    }
  }

  private async _initProfileChangeDetection(url: string) {

    this.auth.profile$.subscribe(async profile => {
      if (!profile) {
        this.router.navigate(['/login']);
      }
      else {
        await this.setting.initAllParams();
        this._initServices();
        let splitst1 = '%3Bback%3D%252F';
        let splitst2 = '%252F';
        if (url.includes(';back=%2F')) {
          splitst1 = ';back=%2F';
          splitst2 = '%2F';
        }
        const urls = url.split(splitst1);
        const path: any[] = [];
        if (urls[0] === '/login') { // check if page login, go to dashboard
          path.push('/');
        } else {
          path.push(urls[0]);
          if (urls[1]) { // Check back parameters
            const back = urls[1].split(splitst2);
            path.push({ back: `/${back[0]}/${back[1]}` });
          }
        }
        this.router.navigate(path);
      }
    });
  }

  private _initServices() {

    if (this.auth.isOneOfRoles(['marketing', 'admin', 'assistant'])) {
      this.portalSyncs.init();
    }
    this.myActions.initFilters();
    this.myActions.subscribe();

    if (this.auth.isOneOfRoles(['admin', 'director', 'assistant'])) {
      this.newRequests.initFilters();
      this.newRequests.subscribe();
    }

    if (this.auth.isOneOfRoles(['admin', 'director', 'realtor'])) {
      this.allMatchings.initFilters();
      this.allMatchings.subscribe();
    }
  }


  public goList(module: string) {
    this.backURL.reset();
    this.select.cancel();
    this.router.navigate([`/${module}`]);
  }

  public toggleMenu() {
    this.menuLarge = !this.menuLarge;
  }
}
