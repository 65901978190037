<mat-card class="unit" *ngIf="display">

  <mat-card-header>
    <div mat-card-avatar>
      <mat-icon [ngClass]="display.iconColor">
        {{display.icon}}
      </mat-icon>
      <!-- <mat-icon *ngIf="!mandate">
        {{display.unitTypeIcon}}
      </mat-icon> -->
    </div>
    <mat-card-title class="text-oneline">
      {{display.residence ? display.residence + ' - ' : ''}}{{display.city}}
    </mat-card-title>
    <mat-card-subtitle *ngIf="display.owner">
      {{display.owner}}
    </mat-card-subtitle>
  </mat-card-header>

  <div class="card-img-fix">
    <mat-spinner [strokeWidth]="5" *ngIf="display.photo?.name"></mat-spinner>
    <img mat-card-avatar *ngIf="display.realtor" class="realtor" [src]="display.realtor | realtor"
      [matTooltip]="display.realtor | realtorName" />
    <div class="img">
      <img *ngIf="display.photo" [src]="display.photo | thumb:'400'">
    </div>
    <mat-icon color="primary" *ngIf="!display.photo">no_photography</mat-icon>
  </div>
  <mat-list class="h">
    <mat-list-item *ngIf="display.alerts">
      <mat-icon matListIcon color="warn">{{GLOBAL.ICONS.ALERT}}</mat-icon>
    </mat-list-item>
    <mat-list-item *ngIf="display.unitTypeIcon">
      <mat-icon matListIcon *ngIf="mandate">{{display.unitTypeIcon}}</mat-icon>
    </mat-list-item>
    <mat-list-item>
      <mat-icon matListIcon>{{GLOBAL.ICONS.BED}}</mat-icon>
      <h3 matLine>{{display.bedrooms}}</h3>
    </mat-list-item>
    <mat-list-item>
      <mat-icon matListIcon>{{GLOBAL.ICONS.BATH}}</mat-icon>
      <h3 matLine>{{display.bathrooms}}</h3>
    </mat-list-item>
    <mat-list-item *ngIf="display.type !== 'land'">
      <mat-icon matListIcon>{{GLOBAL.ICONS.SURFACE}}</mat-icon>
      <h3 matLine *ngIf="display.surface"> {{display.surface}} m²</h3>
    </mat-list-item>
    <mat-list-item *ngIf="display.type == 'land'">
      <mat-icon matListIcon>{{GLOBAL.ICONS.SURFACE}}</mat-icon>
      <h3 matLine> {{display.landSurface | number}} {{display.landUnit | param:'landUnits'}} </h3>
    </mat-list-item>
  </mat-list>
  <mat-list class="h">
    <mat-list-item>
      <h3 matLine>{{mandate ? 'NES' : 'P'}}{{display.ref}}</h3>
    </mat-list-item>
    <mat-list-item>
      <h3 matLine class="t_r">{{display.priceDisplay}}</h3>
    </mat-list-item>
  </mat-list>
  <mat-card-content>
  </mat-card-content>
  <!-- <mat-card-actions>
    <button mat-button>LIKE</button>
    <button mat-button>SHARE</button>
  </mat-card-actions> -->
</mat-card>