import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalConstants } from 'functions/src/shared/global-constants';
import { MainsComponent } from 'src/common/pages/mains/mains.component';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SelectService } from 'src/common/services/select/select.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { MyActionService } from 'src/services/action/action.service';

@Component({
  selector: 'app-marketing-dashboard',
  templateUrl: './marketing-dashboard.component.html',
  styleUrls: ['./marketing-dashboard.component.scss']
})
export class MarketingDashboardComponent extends MainsComponent {

  public GLOBAL = new GlobalConstants();

  constructor(
    protected afs: AngularFirestore,
    public db: MyActionService,
    public settings: SettingService,
    public backURL: BackurlService,
    protected router: Router,
    public setting: SettingService,
    public select: SelectService,
    protected fb: FormBuilder,
  ) {
    super(setting, db, select, router, fb, backURL);

  }

}
