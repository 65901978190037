import { Component } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Profile } from '../../services/auth/auth.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent {

public usersActivePending : Profile[] = [];

  constructor(
    public auth: AuthService,
    protected router: Router,
  ) {
    auth.usersActivePending$.subscribe(profiles =>{
      this.usersActivePending = profiles;
    });
  }

  addUser() {
    this.router.navigate([`/settings/users/0`]);
  }

  editUser(user: Profile) {
    if (user.values.status != 'pending') {
      this.router.navigate([`/settings/users/${user.values.$key}`]);
    }
  }

  removeInvitation(invitation, e) {
    e.stopPropagation();
    this.auth.removeInvitation(invitation);
  }
}
