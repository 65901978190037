<mat-card>
    <mat-card-header>
        <mat-card-title *ngIf="!action.done">Pending Mandate Signature
        </mat-card-title>
        <mat-card-title *ngIf="action.done">{{action.exclusive ? 'Exclusive': ''}} Mandate Signed: {{action.priceDisplay}}
        </mat-card-title>
        <mat-card-subtitle *ngIf="action.done && action.priceNeg">Negociable: {{action.priceNeg}}
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content *ngIf="action.done">
        <mat-list role="list">
            <mat-list-item role="listitem" *ngIf="action.doneDate">
                Signed on: {{action.doneDate.toDate() | date:'dd MMM yyyy'}}
            </mat-list-item>
            <mat-list-item role="listitem" *ngIf="action.duration">
                Duration: {{action.duration}} Months
            </mat-list-item>
            <mat-list-item role="listitem" *ngIf="action.furniturePriceDisplay">
                Incl. Furniture: {{action.furniturePriceDisplay}}
            </mat-list-item>
        </mat-list>
        <mat-list-item role="listitem" *ngIf="action.dateAvailable">
            Available on: {{action.dateAvailable.toDate() | date:'dd MMM yyyy'}}
        </mat-list-item>
        <p *ngIf="action.incl">{{action.incl}}</p>
        <p *ngIf="action.note">{{action.note}}</p>
    </mat-card-content>
</mat-card>
