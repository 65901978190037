<mat-toolbar>

    <div>
        <a mat-icon-button routerLink='/settings/agencies'>
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <span *ngIf="!new">{{agency.values?.name}}</span>
        <span *ngIf="new">Create a new Agency</span>
    </div>
    <profile></profile>

</mat-toolbar>
<div class="toolbartop"></div>

<div class="mdc-layout-grid" *ngIf="agency">
    <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell">
            <form-builder [item]="agency" [values]="agency.values" [edit]="true"></form-builder>
        </div>
        <!-- <div class="mdc-layout-grid__cell" *ngIf="!new">
        <upload-image [fileRecord]="agencyimage" [config]="agencyimageConfig" (newImage)="saveData({image: $event})">
        </upload-image>
    </div> -->
    </div>
</div>

<button *ngIf="new" mat-fab class="bottom-right pfixed" [disabled]="!(agency.valid && agency.modified)"
    (click)="saveNewAgency()">
    <mat-icon matListIcon>check</mat-icon>
</button>
<button *ngIf="!new && agency.values?.status == 'active'" mat-fab class="bottom-right pfixed" (click)="saveAgency()">
    <mat-icon matListIcon>check</mat-icon>
</button>
<button *ngIf="!new && agency.values?.status == 'blocked'" mat-fab class="bottom-right pfixed" (click)="activeAgency()">
    <mat-icon matListIcon>how_to_reg</mat-icon>
</button>
<button *ngIf="!new && agency.values?.status == 'active'" mat-fab class="bottom-left pfixed" color="basic"
    (click)="blockAgency()">
    <mat-icon matListIcon>block</mat-icon>
</button>