import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SelectService } from 'src/common/services/select/select.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { ActionService } from 'src/services/action/action.service';
import { MandateService } from 'src/services/mandate/mandate.service';
import { ProjectService } from 'src/services/project/project.service';
import { ActionMainComponent } from '../action-main/action-main.component';
import { ActionSignMandate } from 'src/services/action/actions/action-sign-mandate.model';
import { Mandate } from 'src/services/mandate/mandate.model';
import { Action } from 'src/services/action/action.model';
import { Tools } from 'src/common/services/tools';

@Component({
  selector: 'action-sign-mandate',
  templateUrl: './action-sign-mandate.component.html',
  styleUrls: ['./action-sign-mandate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionSignMandateComponent extends ActionMainComponent {
  public tools = new Tools();

  @Input() set action(action: any) {
    this._action = action;
  }

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ActionService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public backURL: BackurlService,
    public projectService: ProjectService,
    public mandateService: MandateService,
    public dialog: MatDialog,
  ) {
    super(_cd, setting, db, select, route, router, backURL, dialog);
  }

  ngOnInit(): void {
    if (this._action && this._action.type) {
      this._$key = this._action.$key; // Use key from First Action Page
      this.item = new ActionSignMandate(null, this.setting);
    }
    this._mainNgOnInit();
  }




  public validAction() {

    if (this.item.values.synaps.mandatesActionsObj && this.item.values.synaps.mandatesActionsObj.$key) {

      // Save Action Data

      const now = new Date();
      if (this.tools.ifFuture(this.item.values.date)) {
        this.item.modifyData('date', now);
      }

      this.item.modifyData('done', true);
      this.item.modifyData('doneDate', now);
      this.item.modifyData('actionUpdated', true);
      this.item.modifyData('actionUpdated', now);
      this.db.saveItem(this.item);

      this.goBack();
    }
  }


}


