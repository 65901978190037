<!-- MANDATE DETAILS -->
<mat-card class="backspace" *ngIf="!edit">
    <mat-list>
        <mat-list>
            <mat-list-item>
                <mat-icon matListIcon>{{GLOBAL.ICONS.DATE}}</mat-icon>
                <h3 matLine>{{item.values.dateNeed | date:'dd MMM yyyy'}}</h3>
            </mat-list-item>
        </mat-list>
    </mat-list>
    <mat-list class="h">
        <mat-list-item>
            <mat-icon matListIcon *ngFor="let unitType of item.values.unitTypes">
                {{ unitType | icon: 'unitTypes'}}
            </mat-icon>
        </mat-list-item>
        <mat-list-item>
            <mat-icon matListIcon>{{GLOBAL.ICONS.BED}}</mat-icon>
            <h3 matLine>{{item.values.minBedrooms}}</h3>
        </mat-list-item>
        <mat-list-item *ngIf="item.values.minBathrooms">
            <mat-icon matListIcon>{{GLOBAL.ICONS.BATH}}</mat-icon>
            <h3 matLine>{{item.values.minBathrooms}} </h3>
        </mat-list-item>
    </mat-list>
    <mat-list *ngIf="item.values.conditions && item.values.conditions.length">
        <mat-list-item>
            <!-- <mat-icon matListIcon>{{GLOBAL.ICONS.DOLLAR}}</mat-icon> -->
            <h3 matLine> <span>
                    <ng-container *ngFor="let v of item.values.conditions; let last = last">
                        {{ v | param:'unitConditionsVEFA' }}{{ !last ? ',' : '' }}
                    </ng-container>
                </span></h3>
        </mat-list-item>
    </mat-list>
    <mat-list>
        <mat-list-item>
            <mat-icon matListIcon>{{GLOBAL.ICONS.DOLLAR}}</mat-icon>
            <h3 matLine>{{item.values.minBudget | number}} < {{item.values.maxBudget | number}} {{item.values.cyBudget |
                    param:'cies'}}</h3>
        </mat-list-item>
        <mat-list-item *ngIf="item.values.referral">
            <mat-icon matListIcon>{{GLOBAL.ICONS.REFERRAL}}</mat-icon>
            <div matLine>
                <b>{{item.values.referral | realtorName}} - {{item.values.referralAgency | param:'agencies'}}</b>
            </div>
            <div matLine>
                <i>{{item.values.referralCom}} %</i>
            </div>
        </mat-list-item>
    </mat-list>
    <!-- <button *ngIf="isRealtor()" color="primary" mat-icon-button class="top-right" (click)="editCard()">
        <mat-icon>{{GLOBAL.ICONS.EDIT}}</mat-icon>
    </button> -->
</mat-card>



<div style="position: relative" *ngIf="edit">
    <form-builder [item]="item" [values]="item.values" section="unit" [edit]="true">
    </form-builder>

    <!-- EDIT MODE -->
    <button mat-fab class="bottom-right" [disabled]="!item.valid || !item.modified || item.saving" (click)="saveItem()">
        <mat-icon matListIcon>check</mat-icon>
    </button>
    <!-- CANCEL -->
    <button mat-fab class="bottom-right p2" color="basic" (click)="cancelEdit()">
        <mat-icon matListIcon>close</mat-icon>
    </button>
</div>