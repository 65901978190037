<div class="container">
    <div style="width:100%">
        <table mat-table [dataSource]="matching?.newMatchs | notNew:notNewMatch" class="mat-elevation-z8">

            <ng-container matColumnDef="icon">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon matListIcon [ngClass]="element.mandate.iconColor">{{GLOBAL.ICONS.PROJECT}}</mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="ref">
                <th mat-header-cell *matHeaderCellDef>Ref</th>
                <td mat-cell *matCellDef="let element"> C{{element.project.ref}} </td>
            </ng-container>

            <ng-container matColumnDef="clients">
                <th mat-header-cell *matHeaderCellDef>Clients</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngFor="let client of element.project.contactsProjects">{{client}}<br />
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="unitTypes">
                <th mat-header-cell *matHeaderCellDef>Unit Types</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon *ngFor="let icon of element.project.unitTypeIcons">{{icon | icon:'unitTypes'}}
                    </mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="minBedrooms">
                <th mat-header-cell *matHeaderCellDef>Min Beds</th>
                <td mat-cell *matCellDef="let element">{{element.project.minBedrooms}} </td>
            </ng-container>

            <ng-container matColumnDef="budget">
                <th mat-header-cell *matHeaderCellDef>Budget</th>
                <td mat-cell *matCellDef="let element">{{element.project.budget}} </td>
            </ng-container>

            <ng-container matColumnDef="days">
                <th mat-header-cell *matHeaderCellDef>Moving on</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="tools.days(element.project.dateNeed) as days"><span
                            [ngClass]="tools.daysColor(days)">{{days}}</span></ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="realtor">
                <th mat-header-cell *matHeaderCellDef>Agent</th>
                <td mat-cell *matCellDef="let element">
                    <img mat-card-avatar *ngIf="element.project.realtor" class="realtor"
                        [src]="element.project.realtor | realtor" [matTooltip]="element.project.realtor | realtorName"  />
                </td>
            </ng-container>

            <ng-container matColumnDef="yesno">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="matching.canMatch(element.project.realtor)">
                        <button mat-icon-button color="primary" [disabled]="!element.new && element.match"
                            (click)="match(element)">
                            <mat-icon>thumb_up</mat-icon>
                        </button>
                        <button mat-icon-button color="accent" [disabled]="!element.new && !element.match"
                            (click)="unmatch(element)">
                            <mat-icon>thumb_down</mat-icon>
                        </button>
                    </ng-container>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="tableCols; sticky: true">
            </tr>
            <tr mat-row *matRowDef="let row; let element; columns: tableCols;" (click)="clickMatching(element)">
            </tr>
        </table>
    </div>
</div>