<mat-toolbar class="contactsbg">

    <div>
        <button mat-icon-button *ngIf="!pageConfig.edit || new" (click)="goBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <a mat-icon-button (click)="displayItem()" *ngIf="pageConfig.edit && !new">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <div class="title">
            <mat-icon>{{GLOBAL.ICONS.CONTACT}}</mat-icon>
            <ng-container *ngIf="!new">
                I{{item.values.ref}}:
                {{item.values.fullname}}
            </ng-container>
            <ng-container *ngIf="new">New Contact</ng-container>
        </div>
    </div>
    <div>
        <profile></profile>
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="logs()">
                <mat-icon>{{GLOBAL.ICONS.LOGS}}</mat-icon>
                <span>Logs</span>
            </button>
        </mat-menu>
    </div>

</mat-toolbar>
<div class="toolbartop"></div>

<div class="mdc-layout-grid contactPage" *ngIf="!pageConfig.edit">
    <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell--span-4">
            <mat-card class="full">
                <mat-list *ngIf="pageConfig.loaded">
                    <mat-list-item>
                        <mat-icon matListIcon>
                            {{GLOBAL.ICONS.CONTACT}}</mat-icon>
                        <h3 matLine> {{item.values.fullname}} </h3>
                    </mat-list-item>
                    <mat-list-item *ngIf="item.values.nationalities && item.values.nationalities.length">
                        <mat-icon matListIcon>
                            {{GLOBAL.ICONS.FLAG}}</mat-icon>
                        <h3 matLine>
                            <ng-container *ngFor="let n of item.values.nationalities; let i = index">
                                {{i > 0 ? ', ' : '' }}{{n | param:'countries'}}</ng-container>
                        </h3>
                    </mat-list-item>
                    <mat-list-item *ngIf="item.values.country">
                        <mat-icon matListIcon>{{GLOBAL.ICONS.UNIT}}</mat-icon>
                        <h3 matLine>{{item.values.country | param:'countries'}}</h3>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-icon matListIcon>
                            {{GLOBAL.ICONS.LANG}}</mat-icon>
                        <h3 matLine> {{item.values.lang | param:'langs'}} </h3>
                    </mat-list-item>
                    <mat-list-item *ngIf="item.values.birthday">
                        <mat-icon matListIcon>
                            {{GLOBAL.ICONS.BIRTH}}</mat-icon>
                        <h3 matLine> {{item.values.birthday | date:'dd MMM yyyy'}} </h3>
                    </mat-list-item>
                    <!-- <mat-list-item *ngIf="item.values.soncas">
                        <mat-icon matListIcon>
                            {{GLOBAL.ICONS.SONCAS}}</mat-icon>
                        <h3 matLine>
                            <ng-container *ngFor="let s of item.values.soncas; let i = index">
                                {{i > 0 ? ', ' : '' }}{{s | param:'soncas'}}</ng-container>
                        </h3>
                    </mat-list-item> -->
                    <ng-container *ngIf="item.values.tels">
                        <mat-list-item *ngFor="let tel of item.values.tels | toarray">
                            <mat-icon matListIcon>{{GLOBAL.ICONS.PHONE}}</mat-icon>
                            <h3 matLine>{{tel.number}}</h3>
                        </mat-list-item>
                    </ng-container>
                    <ng-container *ngIf="item.values.emails">
                        <mat-list-item *ngFor="let email of item.values.emails | toarray">
                            <mat-icon matListIcon>{{GLOBAL.ICONS.EMAIL}}</mat-icon>
                            <h3 matLine>{{email.email}}</h3>
                        </mat-list-item>
                    </ng-container>

                    <ng-container *ngIf="item.values.synaps?.employeesArray">
                        <mat-list-item *ngFor="let e of item.values.synaps?.employeesArray">
                            <mat-icon matListIcon>{{GLOBAL.ICONS.WORK}}</mat-icon>
                            <h3 matLine>{{e.displayname}}</h3>
                        </mat-list-item>
                    </ng-container>
                </mat-list>
            </mat-card>
        </div>

        <div class="mdc-layout-grid__cell--span-4" *ngFor="let project of item.values.synaps?.contactsProjectsArray">
            <project-card [values]="project" (click)="goItem('projects', project)">
            </project-card>
        </div>

        <div class="mdc-layout-grid__cell--span-4" *ngFor="let unit of item.values.synaps?.ownersArray">
            <app-unit-card [values]="unit" (click)="goItem('units', unit)"></app-unit-card>
        </div>
    </div>

</div>


<!------------------------------ EDIT MODE  ----------------------------------------->


<div class="contactPage" *ngIf="pageConfig.edit">
    <mat-tab-group [(selectedIndex)]="pageConfig.editTab">


        <!-- CONTACT DETAILS -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.DETAILS}}</mat-icon>&nbsp;
                Details
            </ng-template>

            <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                    <div class="mdc-layout-grid__cell--span-4">
                        <form-builder [item]="item" [values]="item.values" section="main" [edit]="true"></form-builder>
                    </div>
                    <div class="mdc-layout-grid__cell--span-4">
                        <form-builder [item]="item" [values]="item.values" section="emails" [edit]="true">
                        </form-builder>
                    </div>
                    <div class="mdc-layout-grid__cell--span-4">
                        <form-builder [item]="item" [values]="item.values" section="tels" [edit]="true"></form-builder>

                    </div>
                </div>
            </div>

            <!-- EDIT MODE -->
            <button *ngIf="!new" mat-fab class="bottom-right pfixed"
                [disabled]="!item.valid || !item.modified || item.saving" (click)="saveItem()">
                <mat-icon matListIcon>check</mat-icon>
            </button>
            <!-- CANCEL -->
            <button mat-fab class="bottom-right pfixed p2" [disabled]="!item.modified || item.saving" color="basic"
                (click)="cancelEdit()">
                <mat-icon matListIcon>close</mat-icon>
            </button>
        </mat-tab>

        <!-- COMPANIES -->
        <mat-tab [disabled]="new">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.CONTACTS}}</mat-icon>&nbsp;
                Work
            </ng-template>


            <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                    <div class="mdc-layout-grid__cell" *ngFor="let company of item.values.synaps?.employeesArray">
                        <app-company-tab [key]="company.$key" model="contacts" [modelKey]="item.values.$key"></app-company-tab>
                    </div>
                </div>
            </div>

            <button mat-fab class="bottom-right pfixed p2" color="basic" (click)="select.init(item, db, 'employees')">
                <mat-icon matListIcon>sync_alt</mat-icon>
            </button>

        </mat-tab>

    </mat-tab-group>




</div>


<mat-spinner [strokeWidth]="5" class="fixed" *ngIf="!(pageConfig.loaded || new)"></mat-spinner>

<!------------------------------ BUTTONS  ----------------------------------------->
<!-- NEW -->
<button *ngIf="new" mat-fab class="bottom-right pfixed" [disabled]="!item.valid || !item.modified || item.saving"
    (click)="saveNewItem()">
    <mat-icon matListIcon>check</mat-icon>
</button>
<!-- EDIT -->
<button *ngIf="!new && !pageConfig.edit" mat-fab class="bottom-right pfixed" (click)="editItem()">
    <mat-icon matListIcon>edit</mat-icon>
</button>

<!-- NEW PROJECT -->
<button *ngIf="!new && !pageConfig.edit" color="primary" mat-fab class="bottom-right pfixed p2" (click)="addProject()">
    <mat-icon matListIcon>{{GLOBAL.ICONS.PROJECTADD}}</mat-icon>
</button>

<!-- DELETE -->
<button *ngIf="!new && !pageConfig.edit && isRole('admin')" mat-fab class="bottom-left pfixed" color="basic"
    (click)="db.deleteItem(item)">
    <mat-icon matListIcon>delete</mat-icon>
</button>