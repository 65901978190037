import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Param } from '../../../../functions/src/shared/setting-model';
import { SettingService } from '../../services/setting/setting.service';
import { Langs } from '../../services/main/main.model';

@Component({
  selector: 'app-settings-list',
  templateUrl: './settings-list.component.html',
  styleUrls: ['./settings-list.component.scss']
})
export class SettingsListComponent implements OnInit {

  public paramSetting: Param = null;
  public displayedColumns: string[] = [];
  public editable = false;

  public items: any[] = [];
  public itemsFiltered: any[] = [];

  public search = '';

  constructor(
    private route: ActivatedRoute,
    public setting: SettingService,
    public dialog: MatDialog
  ) {

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.paramSetting = this.setting.settings[params.key];
      if (this.paramSetting.multiLang) {
        this.displayedColumns = ['namefr', 'nameen'];
      } else {
        this.displayedColumns = ['name'];
      }
      if (this.paramSetting.desc) {
        this.displayedColumns.push('descfr');
        this.displayedColumns.push('descen');
      }
      if (this.paramSetting.editable) {
        this.displayedColumns.push('actions');
      }
      this.setting.getParams$(this.paramSetting.$key).subscribe(items =>{
        this.items = items;
        this.filter();
      })
    });
  }

  public addParam() {
    const dialogRef = this.dialog.open(DialogParam, {
      width: '800px',
      data: { paramSetting: this.paramSetting },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.setting.addNewParam(this.paramSetting.$key, result);
      }
    });

  }

  public editParam(data) {
    const dialogRef = this.dialog.open(DialogParam, {
      width: '800px',
      data: { paramSetting: this.paramSetting, data: data },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.setting.saveParam(this.paramSetting.$key, data.$key, result);
      }
    });

  }

  public archiveParam(data: any, e: any) {
    e.stopPropagation();
    this.setting.archiveParam(this.paramSetting.$key, data.$key);
  }

  
  public filter() {
    this.itemsFiltered = this.items.filter((v) => {

      let r = true;

      
      if (this.search.length > 1 && (JSON.stringify(v)).toLowerCase().indexOf(this.search.toLowerCase()) < 0) {
        r = false;
      }

      return r;
    });
  }
}

@Component({
  selector: 'dialog-param',
  templateUrl: 'dialog-param.html',
  styleUrls: ['./settings-list.component.scss']
})
export class DialogParam {

  paramSetting: Param;
  value: any = {};
  new = true;

  public langs = Langs;
  
  constructor(
    public dialogRef: MatDialogRef<DialogParam>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.paramSetting = data.paramSetting;

    if (this.paramSetting.multiLang) {
      this.value.name = {};
    }
    if (this.paramSetting.desc) {
      this.value.desc = {};
    }

    if (data.data) {
      Object.assign(this.value, data.data);
      this.new = false;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}