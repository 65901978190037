<mat-toolbar>

    <div>
        <button mat-icon-button *ngIf="!pageConfig.edit || new" (click)="goBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <a mat-icon-button (click)="displayItem()" *ngIf="pageConfig.edit && !new">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <div class="title">
            <mat-icon [ngClass]="{online : item.values.online}">{{GLOBAL.ICONS.BLOG}}</mat-icon>
            <ng-container *ngIf="!new">
                B{{item.values.ref}}:
                {{item.values.title?.en}}
            </ng-container>
            <ng-container *ngIf="new">New Article</ng-container>
        </div>
    </div>
    <div>
        <profile></profile>
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="logs()">
                <mat-icon>{{GLOBAL.ICONS.LOGS}}</mat-icon>
                <span>Logs</span>
            </button>
        </mat-menu>
    </div>

</mat-toolbar>
<div class="toolbartop"></div>

<div class="mdc-layout-grid contactPage" *ngIf="!pageConfig.edit">
    <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell--span-4">
            <mat-card class="full">
                <mat-list *ngIf="pageConfig.loaded">
                    <mat-list-item>
                        <mat-icon matListIcon>
                            {{GLOBAL.ICONS.DATE}}</mat-icon>
                        <h3 matLine> {{item.values.created | date:'dd MMM yyyy'}} </h3>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-icon matListIcon>
                            {{GLOBAL.ICONS.BLOG}}</mat-icon>
                        <h3 matLine> {{item.values.date | date:'dd MMM yyyy'}} </h3>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-icon matListIcon>
                            {{GLOBAL.ICONS.SECTION}}</mat-icon>
                        <h3 matLine> {{item.values.sections ? item.values.sections.length : 0}} </h3>
                    </mat-list-item>
                    <mat-list-item *ngFor="let topic of item.values.topics">
                        <mat-icon matListIcon>
                            {{GLOBAL.ICONS.TOPIC}}</mat-icon>
                        <h3 matLine> {{topic | param:'blogTopics'}} </h3>
                    </mat-list-item>
                    <mat-list>
                        <a mat-list-item target="_blank"
                            href="https://www.nestenn.mu/en/real-estate-news-mauritius/article/{{item.values.slug?.en}}">
                            <mat-icon matListIcon style="margin-right:5px" [ngClass]="{online:item.values.online}">
                                {{GLOBAL.ICONS.MARKETING}}</mat-icon>
                            <div mat-line> Website Link </div>
                        </a>
                    </mat-list>
                </mat-list>
            </mat-card>
        </div>

        <div *ngIf="item.values.image?.url" class="mdc-layout-grid__cell--span-4 mainimage">
            <img [src]="item.values.image.url">
        </div>

    </div>

</div>


<!------------------------------ EDIT MODE  ----------------------------------------->


<div class="contactPage" *ngIf="pageConfig.edit">
    <mat-tab-group [(selectedIndex)]="pageConfig.editTab">


        <!-- BLOG DETAILS -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.DETAILS}}</mat-icon>&nbsp;
                Details
            </ng-template>

            <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                    <div class="mdc-layout-grid__cell--span-4">
                        <form-builder [item]="item" [values]="item.values" section="main" [edit]="true"></form-builder>
                    </div>
                    <div class="mdc-layout-grid__cell--span-4">
                        <form-builder [item]="item" [values]="item.values" section="title" [edit]="true"></form-builder>
                    </div>
                    <div class="mdc-layout-grid__cell--span-4">
                        <upload-image *ngIf="item.values.ref" [fileRecord]="item.values.image" [config]="imageConf"
                            (newImage)="saveImage($event)">
                        </upload-image>
                    </div>
                </div>
            </div>


            <!-- EDIT MODE -->
            <button *ngIf="!new" mat-fab class="bottom-right pfixed"
                [disabled]="!item.valid || !item.modified || item.saving" (click)="saveItem(false)">
                <mat-icon matListIcon>check</mat-icon>
            </button>
            <!-- CANCEL -->
            <button mat-fab class="bottom-right pfixed p2" [disabled]="!item.modified || item.saving" color="basic"
                (click)="cancelEdit()">
                <mat-icon matListIcon>close</mat-icon>
            </button>
        </mat-tab>


        <!-- BLOG SECTIONS -->
        <ng-container *ngIf="!new">
            <mat-tab *ngFor="let lang of setting.langs">
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.DESC}}</mat-icon>&nbsp;
                    Text ({{lang}})
                </ng-template>
                <div *ngIf="item.values.sections && item.values.sections.length">
                    <div class="section" *ngFor="let section of item.values.sections; let i = index">
                        <mat-form-field appearance="fill">
                            <mat-label>Section Type</mat-label>
                            <mat-select #name="ngModel" [(ngModel)]="section.type" required>
                                <mat-option value="text">Text</mat-option>
                                <mat-option value="img">Image</mat-option>
                                <mat-option value="imgText">Text/Image</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="flex">
                            <div class="secTxt" *ngIf="section.type === 'text' || section.type === 'imgText'">
                                <ckeditor [(ngModel)]="section.html[lang]" [config]="editorConfig" [editor]="Editor"
                                    [config]="editorConfig"></ckeditor>
                            </div>
                            <div class="secImg" *ngIf="section.type === 'img' || section.type === 'imgText'">
                                <mat-form-field appearance="fill">
                                    <mat-label>Image Position</mat-label>
                                    <mat-select #name="ngModel" [(ngModel)]="section.imagePos" required>
                                        <mat-option value="right">Right</mat-option>
                                        <mat-option value="left">Left</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <upload-image [fileRecord]="section.image" [config]="imageConf"
                                    (newImage)="saveImageSection($event, i)">
                                </upload-image>
                            </div>
                            <div>
                                <button color="warn" (click)="removeSection(i)">-</button>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- EDIT MODE -->
                <button *ngIf="!new" mat-fab class="bottom-right pfixed" [disabled]="item.saving"
                    (click)="saveSection()">
                    <mat-icon matListIcon>check</mat-icon>
                </button>
                <!-- CANCEL -->
                <button mat-fab class="bottom-right pfixed p2" [disabled]="!item.modified || item.saving" color="basic"
                    (click)="cancelEdit()">
                    <mat-icon matListIcon>close</mat-icon>
                </button>
                <!-- ADD -->
                <button *ngIf="!new" mat-fab class="bottom-right pfixed p3" color="primary" (click)="addSection()">
                    <mat-icon matListIcon>add</mat-icon>
                </button>
            </mat-tab>
        </ng-container>


    </mat-tab-group>




</div>


<mat-spinner [strokeWidth]="5" class="fixed" *ngIf="!(pageConfig.loaded || new)"></mat-spinner>

<!------------------------------ BUTTONS  ----------------------------------------->
<!-- NEW -->
<button *ngIf="new" mat-fab class="bottom-right pfixed" [disabled]="!item.valid || !item.modified || item.saving"
    (click)="saveNewItem()">
    <mat-icon matListIcon>check</mat-icon>
</button>
<!-- EDIT -->
<button *ngIf="!new && !pageConfig.edit" mat-fab class="bottom-right pfixed" (click)="editItem()">
    <mat-icon matListIcon>edit</mat-icon>
</button>


<!-- DELETE -->
<button *ngIf="!new && !pageConfig.edit && isRole('admin')" mat-fab class="bottom-left pfixed" color="basic"
    (click)="db.deleteItem(item)">
    <mat-icon matListIcon>delete</mat-icon>
</button>