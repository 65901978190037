<mat-toolbar>
    <div>
        <button mat-icon-button (click)="goBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <div class="title">
            <mat-icon [ngClass]="item.values.done ? 'active' : 'warm'">
                {{item.values.type | icon:'actionTypes'}}</mat-icon>
            <span>{{item.values.done ? 'Mandate Signed' : 'Pending Mandate Signature'}} </span>
        </div>
    </div>
    <div>
        <profile></profile>
    </div>
</mat-toolbar>

<div class="toolbartop"></div>

<div class="mainPage">

    <div class="mdc-layout-grid">
        <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell--span-4">
                <mat-card class="backspace cursor" (click)="dateAction()">
                    <mat-list>
                        <mat-list-item>
                            <div matLine style="font-size: 35px;text-align: center;">
                                {{item.values.date | actionDate}}
                            </div>
                            <div matLine style="text-align: center;">
                                <i> {{tools.timeago(item.values.date)}}</i>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </mat-card>
                <mandate-hcard [mandate]="item.list?.mandate" class="cursor"
                    (click)="goItem('mandates', item.values.synaps.mandatesActionsObj)"></mandate-hcard>
            </div>
            <div class="mdc-layout-grid__cell--span-4">
                <form-builder [item]="item" [values]="item.values" [edit]="!item.values.done"> </form-builder>

            </div>
            <div class="mdc-layout-grid__cell--span-4">
                <form-builder [item]="item" [values]="item.values" [edit]="!item.values.done" section="mandateFee">
                </form-builder>
            </div>
        </div>
    </div>
</div>


<button *ngIf="!item.values.done" mat-fab class="bottom-right pfixed p2" color="primary"
    [disabled]="!item.modified || item.saving" (click)="saveItemAndClose()" matTooltip="Save">
    <mat-icon matListIcon>check</mat-icon>
</button>

<button *ngIf="!item.values.done" mat-fab class="bottom-right pfixed" color="accent"
    [disabled]="!item.valid || item.saving || !tools.ifPast(item.values.date)" (click)="validAction()" matTooltip="Valid">
    <mat-icon matListIcon>thumb_up</mat-icon>
</button>

<!-- DELETE -->
<!-- <button *ngIf="!item.values.done" mat-fab class="bottom-left pfixed" color="basic" (click)="deleteAction()"
    matTooltip="Cancel">
    <mat-icon matListIcon>delete</mat-icon>
</button> -->