import { Component, OnInit } from '@angular/core';
import { SettingService } from '../../services/setting/setting.service';
import { AdminToolsService } from '../../services/admin-tools/admin-tools.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(
    public setting: SettingService,
    public admin: AdminToolsService,
    ) { }

  ngOnInit(): void {
  }

}
