import { Component, ChangeDetectorRef } from '@angular/core';
import { MainComponent } from '../../common/pages/main/main.component';
import { Residence } from '../../services/residence/residence.model';
import { SettingService } from '../../common/services/setting/setting.service';
import { ResidenceService } from '../../services/residence/residence.service';
import { SelectService } from '../../common/services/select/select.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-residence-tab',
  templateUrl: './residence-tab.component.html',
  styleUrls: ['./residence-tab.component.scss']
})
export class ResidenceTabComponent extends MainComponent {

  public item: Residence;

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ResidenceService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public backURL: BackurlService,
    public dialog: MatDialog
  ) {
    super(_cd, setting, db, select, route, router, backURL, dialog);
    this.item = new Residence(null, setting);
  }


}