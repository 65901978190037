import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainComponent } from 'src/common/pages/main/main.component';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SelectService } from 'src/common/services/select/select.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { Blog } from 'src/services/blog/blog.model';
import { BlogService } from 'src/services/blog/blog.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FileRecord } from 'src/common/services/upload-file/upload-file-model';
import { UploadFileService } from 'src/common/services/upload-file/upload-file.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent extends MainComponent {

  public Editor = ClassicEditor;
  public item: Blog;

  public editorConfig = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'link',
        'insertTable',
        '|',
        'blockQuote',
        '|',
        'undo',
        'redo'
      ]
    },
    heading: {
      options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
        {
          model: 'intro', view: {
            name: 'p',
            classes: 'intro'
          }, title: 'Intro', class: 'intro'
        },
      ]
    }
    // plugins: [],
    // extraPlugins: [MyUploadAdapter],
    // image: {
    //   resizeUnit:'%',
    //   toolbar: [
    //     // 'resizeImage:50',
    //     // 'resizeImage:75',
    //     // 'resizeImage:original',
    //     'imageStyle:inline',
    //     'imageStyle:block',
    //     '|',
    //     'imageStyle:side',
    //     'toggleImageCaption',
    //     'imageTextAlternative',
    //     '|',
    //   ],
    //   resizeOptions: [
    //     {
    //         name: 'resizeImage:original',
    //         value: null,
    //         icon: 'original'
    //     },
    //     {
    //         name: 'resizeImage:50',
    //         value: '50',
    //         icon: 'medium'
    //     },
    //     {
    //         name: 'resizeImage:75',
    //         value: '75',
    //         icon: 'large'
    //     }
    // ],
    // styles: {
    //   options: [
    //     {
    //       name: 'fullSize',
    //       title: 'Full size image',
    //       className: 'image-full-size',
    //       modelElements: [ 'imageBlock', 'imageInline' ]
    //     }
    //   ]
    // }
    // }
  }

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: BlogService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public backURL: BackurlService,
    public upload: UploadFileService,
    public dialog: MatDialog,
  ) {
    super(_cd, setting, db, select, route, router, backURL, dialog);
    this.item = new Blog((this.select.active && this.select.prefil) ? this.select.prefil : null, setting);
  }

  _itemOnInit() {
    this.imageConf = { path: this.item.imageConfig.path, filename: this.item.imageConfig.filename(this.item.values) };
  }

  public addSection() {
    if (!this.item.values.sections) {
      this.item.values.sections = [];
    }
    this.item.values.sections.push({
      type: 'text',
      html: {
        en: '',
        fr: '',
        de: '',
        ru: '',
      },
      image: null,
      imagePos: 'right',
    });
  }

  saveSection() {
    this.item.modify.sections = true;
    this.saveItem();

  }

  removeSection(section: number) {

    //REMOVE IMAGE
    if (this.item.values.sections[section] && this.item.values.sections[section].image) {
      this.upload.deleteFileUpload(this.item.values.sections[section].image);
    }

    this.item.values.sections.splice(section, 1);

    this.db.saveItem(this.item);
  }

  saveImage(file: FileRecord) {

    //REMOVE PREVIOUS IMAGE
    if (this.item.values.image) {
      this.upload.deleteFileUpload(this.item.values.image);
    }

    this.item.values.image = file;
    this.item.modify.image = true;
    this.db.saveItem(this.item);
  }

  saveImageSection(file: FileRecord, section: number) {
    //REMOVE PREVIOUS IMAGE
    if (this.item.values.sections[section] && this.item.values.sections[section].image) {
      this.upload.deleteFileUpload(this.item.values.sections[section].image);
    }

    this.item.values.sections[section].image = file;
    this.item.modify.sections = true;
    this.db.saveItem(this.item);
  }


}

