import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { LoginComponent } from '../common/pages/login/login.component';
import { SettingsComponent } from '../common/pages/settings/settings.component';
import { SettingsListComponent } from '../common/pages/settings-list/settings-list.component';
import { UsersComponent } from '../common/pages/users/users.component';
import { UserComponent } from '../common/pages/user/user.component';
import { UnitsComponent } from '../pages/units/units.component';
import { UnitComponent } from '../pages/unit/unit.component';
import { ResidencesComponent } from '../pages/residences/residences.component';
import { ResidenceComponent } from '../pages/residence/residence.component';
import { MandatesComponent } from '../pages/mandates/mandates.component';
import { ContactsComponent } from '../pages/contacts/contacts.component';
import { MandateComponent } from '../pages/mandate/mandate.component';
import { ContactComponent } from '../pages/contact/contact.component';
import { CompanyComponent } from '../pages/company/company.component';
import { CompaniesComponent } from '../pages/companies/companies.component';
import { ProjectComponent } from '../pages/project/project.component';
import { ProjectsComponent } from '../pages/projects/projects.component';
import { NewRequestComponent } from '../pages/new-request/new-request.component';
import { LogsComponent } from 'src/common/pages/logs/logs.component';
import { MatchingsComponent } from 'src/pages/matchings/matchings.component';
import { BlogsComponent } from 'src/pages/marketing/blogs/blogs.component';
import { BlogComponent } from 'src/pages/marketing/blog/blog.component';
import { StatsComponent } from 'src/pages/stats/stats.component';
import { AssignedLeadsComponent } from 'src/pages/assigned-leads/assigned-leads.component';
import { ActionsComponent } from 'src/pages/actions/actions.component';
import { ActionComponent } from 'src/pages/action/action.component';
import { MytasksComponent } from 'src/pages/mytasks/mytasks.component';
import { AgenciesComponent } from 'src/common/pages/agencies/agencies.component';
import { AgencyComponent } from 'src/common/pages/agency/agency.component';
import { MarketingDashboardComponent } from 'src/pages/marketing/marketing-dashboard/marketing-dashboard.component';
import { LpComponent } from 'src/pages/marketing/portals/lp/lp.component';
import { MarketingComponent } from 'src/pages/marketing/new-mandate-check/marketing.component';


const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'login', component: LoginComponent },
  { path: 'invitation', redirectTo: '/login', pathMatch: 'full' },
  { path: 'settings', component: SettingsComponent },
  { path: 'settings/list', component: SettingsListComponent },
  { path: 'settings/users', component: UsersComponent },
  { path: 'settings/users/:id', component: UserComponent },
  { path: 'settings/agencies', component: AgenciesComponent },
  { path: 'settings/agencies/:id', component: AgencyComponent },
  { path: 'logs/:module/:key', component: LogsComponent },
  { path: 'tasks', component: ActionsComponent },
  { path: 'tasks/:id', component: ActionComponent },
  { path: 'mytasks', component: MytasksComponent },
  { path: 'units', component: UnitsComponent },
  { path: 'units/:id', component: UnitComponent },
  { path: 'residences', component: ResidencesComponent },
  { path: 'residences/:id', component: ResidenceComponent },
  { path: 'mandates', component: MandatesComponent },
  { path: 'mandates/:id', component: MandateComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'projects/:id', component: ProjectComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'contacts/:id', component: ContactComponent },
  { path: 'companies', component: CompaniesComponent },
  { path: 'companies/:id', component: CompanyComponent },
  // { path: 'new-requests', component: NewRequestsComponent },
  { path: 'new-requests/:id', component: NewRequestComponent },
  { path: 'assign-leads', component: AssignedLeadsComponent },
  { path: 'marketing', component: MarketingDashboardComponent },
  { path: 'marketing/check', component: MarketingComponent },
  { path: 'marketing/portals/lp', component: LpComponent },
  { path: 'marketing/blogs', component: BlogsComponent },
  { path: 'marketing/blogs/:id', component: BlogComponent },
  { path: 'stats', component: StatsComponent },
  { path: 'matchings', component: MatchingsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
