<mat-toolbar>
    <div>
        <a mat-icon-button (click)="clickBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <span>{{portalSyncs.syncs.length}} Sync Portals</span>
    </div>
    <profile></profile>
</mat-toolbar>
<div class="toolbartop"></div>

<div class="container split">
    <div class="main">
        <table mat-table [dataSource]="portalSyncs.syncs" class="mat-elevation-z8">

            <ng-container matColumnDef="icon">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon matListIcon class="active">{{setting.getParamIcon('unitTypes', element.unitList?.type)}}
                    </mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>Last Update</th>
                <td mat-cell *matCellDef="let element"> {{element.updated.toDate() | date:'dd MMM HH:mm' }} </td>
            </ng-container>

            <ng-container matColumnDef="ref">
                <th mat-header-cell *matHeaderCellDef>Ref</th>
                <td mat-cell *matCellDef="let element"> P{{element.unitList?.ref}} </td>
            </ng-container>

            <ng-container matColumnDef="bedrooms">
                <th mat-header-cell *matHeaderCellDef>Beds</th>
                <td mat-cell *matCellDef="let element">
                    {{element.unitList?.bedrooms}}
                </td>
            </ng-container>
            <ng-container matColumnDef="city">
                <th mat-header-cell *matHeaderCellDef>City</th>
                <td mat-cell *matCellDef="let element">
                    {{element.unitList?.city}}
                </td>
            </ng-container>
            <ng-container matColumnDef="residence">
                <th mat-header-cell *matHeaderCellDef>Residence</th>
                <td mat-cell *matCellDef="let element">
                    {{element.unitList?.residence}}
                </td>
            </ng-container>
            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef>Price</th>
                <td mat-cell *matCellDef="let element">
                    {{element.mandatePrice}}
                </td>
            </ng-container>
            <ng-container matColumnDef="realtor">
                <th mat-header-cell *matHeaderCellDef>User</th>
                <td mat-cell *matCellDef="let element">
                    <img mat-card-avatar *ngIf="element.updatedBy" class="realtor"
                        [src]="element.updatedBy | realtor" [matTooltip]="element.updatedBy | realtorName"  />
                </td>
            </ng-container>
            <ng-container matColumnDef="yesno">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <!-- <button mat-icon-button color="primary" (click)="$event.stopPropagation(); approve(element)">
                        <mat-icon>thumb_up</mat-icon>
                    </button> -->
                    <!-- <button mat-icon-button color="accent" (click)="$event.stopPropagation(); refuse(element)">
                        <mat-icon>thumb_down</mat-icon>
                    </button> -->
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="tableCols; sticky: true">
            </tr>
            <tr mat-row *matRowDef="let row; let element; columns: tableCols;" (mouseover)="overSync(element)"
                (click)="goItem(element)">
            </tr>
        </table>
    </div>
    <div class="side">
        <!-- PREVIEW -->
        <app-unit-card [values]="preview" [mandate]="true"></app-unit-card>
    </div>


</div>