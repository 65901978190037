<ng-container *ngIf="field.show(rootValues, values, setting)">

    <!-- /********************* SUB OBJECT *******************/ -->
    <ng-container *ngIf="field.input == 'objectSub'">
        <h2 *ngIf="field.label">{{field.label}}</h2>
        <ng-container *ngFor="let subField of field.sub | toarray">
            <field-builder [item]="item" [rootValues]="rootValues" [values]="values[field.key]"
                [validation]="validation[field.key]" [modify]="modify[field.key]" [field]="subField" [section]="section"
                (touch)="touched()"></field-builder>
        </ng-container>
    </ng-container>

    <!-- /********************* SUB OBJECT LINK TO TYPE *******************/ -->
    <ng-container *ngIf="field.input == 'objectSubType' && rootValues.type">
        <ng-container *ngFor="let subField of field.subType[rootValues.type] | toarray">
            <field-builder [item]="item" [rootValues]="rootValues" [values]="values[field.key]"
                [validation]="validation[field.key]" [modify]="modify[field.key]" [field]="subField" [section]="section"
                (touch)="touched()"></field-builder>
        </ng-container>
    </ng-container>

    <!-- /********************* DYN OBJECT *******************/ -->
    <ng-container *ngIf="field.input == 'dynSub'">
        <mat-card-header>
            <mat-card-title>{{field.label}}</mat-card-title>
        </mat-card-header>
        <div class="dynSub" *ngFor="let subField of sub | toarraydyn:values[field.key]">
            <field-builder class="fieldDynSub" [item]="item" [rootValues]="rootValues" [values]="values[field.key]"
                [validation]="validation[field.key]" [modify]="modify[field.key]" [field]="subField"
                [section]="section"></field-builder>
            <div class="fieldDynButtons">
                <ng-container *ngIf="field.inputOptions.ordered">
                    <button mat-icon-button (click)="orderDown(subField.key)">
                        <mat-icon>expand_less</mat-icon>
                    </button>
                    <button mat-icon-button (click)="orderUp(subField.key)">
                        <mat-icon>expand_more</mat-icon>
                    </button>
                </ng-container>
                <button mat-icon-button (click)="delete(subField.key)">
                    <mat-icon>remove</mat-icon>
                </button>
            </div>
        </div>
        <mat-action-list dense>
            <button mat-list-item (click)="add()">
                <mat-icon matListIcon>add</mat-icon>
                <h3>Add Item</h3>
            </button>
        </mat-action-list>
        <br /><br />
    </ng-container>

    <!-- /********************* TEXT INPUT *******************/ -->
    <ng-container *ngIf="field.input == 'text'">
        <mat-form-field appearance="fill">
            <mat-label *ngIf="field.label" [ngClass]="{hot:field.validations.required && !(aValue && aValue.length)}">
                {{field.label}}</mat-label>
            <input matInput #name="ngModel" [(ngModel)]="aValue" [required]="field.validations.required"
                [email]="field.validations.email">
            <mat-error *ngIf="name.invalid && (name.dirty || name.touched)">{{getErrorMessage(name.errors, field)}}
            </mat-error>
        </mat-form-field>
    </ng-container>

    <!-- /********************* NUMBER *******************/ -->
    <mat-form-field *ngIf="field.input == 'number'">
        <mat-label *ngIf="field.label" [ngClass]="{hot:field.validations.required && !(aValue && aValue.length)}">
            {{field.label}}</mat-label>
        <input matInput #name="ngModel" [(ngModel)]="aValue" type="number" (wheel)="prevent($event)"
            (keydown)="onKey($event)" (keyup)="onKey($event)" [required]="field.validations.required">
    </mat-form-field>


    <!-- /********************* TEXT AREA *******************/ -->
    <ng-container *ngIf="field.input == 'textarea'">
        <mat-form-field appearance="fill">
            <mat-label *ngIf="field.label" [ngClass]="{hot:field.validations.required && !(aValue && aValue.length)}">
                {{field.label}}</mat-label>
            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" #name="ngModel" [(ngModel)]="aValue"
                [required]="field.validations.required"></textarea>
        </mat-form-field>
    </ng-container>

    <!-- /********************* SELECT *******************/ -->
    <mat-form-field *ngIf="field.input == 'select' && field.inputOptions.param" appearance="fill">
        <mat-label *ngIf="field.label" [ngClass]="{hot:field.validations.required && !(aValue && aValue.length)}">
            {{field.label}}</mat-label>
        <mat-select #name="ngModel" [(ngModel)]="aValue" [multiple]="field.inputOptions.multi"
            [required]="field.validations.required">
            <mat-option [value]="null" *ngIf="!field.inputOptions.multi">No selection</mat-option>
            <mat-option *ngFor="let param of field.inputOptions.param | params" [value]="param.id">
                {{param.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <!-- /********************* SELECT AUTOCOMPLETE *******************/ -->
    <mat-form-field *ngIf="field.input == 'selectAuto' && field.inputOptions.param" appearance="fill">
        <mat-label *ngIf="field.label" [ngClass]="{hot:field.validations.required && !(aValue && aValue.length)}">
            {{field.label}}</mat-label>
        <input matInput #name="ngModel" [(ngModel)]="autoCompleteDis" [required]="field.validations.required"
            [matAutocomplete]="auto" (ngModelChange)="doFilter()">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)"
            (optionSelected)="autoCompleteSelect($event)">
            <mat-option [value]="null">No selection</mat-option>
            <mat-option *ngFor="let param of autoCompleteParams" [value]="param.id">
                {{param.name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <!-- /********************* DATE *******************/ -->
    <mat-form-field *ngIf="field.input == 'date'">
        <mat-label *ngIf="field.label" [ngClass]="{hot:field.validations.required && !(aValue && aValue.length)}">
            {{field.label}}</mat-label>
        <input matInput #name="ngModel" [(ngModel)]="aValue" [matDatepicker]="picker"
            [required]="field.validations.required">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <!-- /********************* DATETIME *******************/ -->
    <mat-form-field *ngIf="field.input == 'datetime'">
        <mat-label *ngIf="field.label" [ngClass]="{hot:field.validations.required && !(aValue && aValue.length)}">
            {{field.label}}</mat-label>
        <input matInput type="datetime-local" #name="ngModel" [(ngModel)]="aValue"
            [required]="field.validations.required">
    </mat-form-field>

    <!-- /********************* BOOLEAN *******************/ -->
    <mat-form-field *ngIf="field.input == 'boolean'">
        <mat-slide-toggle [(ngModel)]="aValue">{{field.label}}</mat-slide-toggle>
        <textarea matInput hidden></textarea>
    </mat-form-field>

    <!-- /********************* NUMBER *******************/ -->
    <mat-form-field *ngIf="field.input == 'rating'">
        <!-- <mat-label *ngIf="field.label">{{field.label}}</mat-label>
        <input matInput #name="ngModel" [(ngModel)]="aValue" type="number" [required]="field.validations.required"> -->
        <star-rating [rating]="aValue" (ratingUpdated)="ratingUpdated($event)"></star-rating>
        <textarea matInput hidden></textarea>
    </mat-form-field>

</ng-container>