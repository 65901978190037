<h1 *ngIf="new" mat-dialog-title>New {{paramSetting.name}}</h1>
<h1 *ngIf="!new" mat-dialog-title>Edit {{paramSetting.name}}</h1>
<div mat-dialog-content *ngIf="!paramSetting.multiLang">
    <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="value.name">
    </mat-form-field>
</div>
<div mat-dialog-content *ngIf="paramSetting.multiLang">
    <ng-container *ngFor="let lang of langs">
        <mat-form-field appearance="fill">
            <mat-label>Name ({{lang}})</mat-label>
            <input matInput [(ngModel)]="value.name[lang]">
        </mat-form-field>
    </ng-container>
</div>
<div mat-dialog-content *ngIf="paramSetting.desc">
    <ng-container *ngFor="let lang of langs">
        <mat-form-field appearance="fill">
            <mat-label>Description ({{lang}})</mat-label>
            <textarea matInput [(ngModel)]="value.desc[lang]"></textarea>
        </mat-form-field>
    </ng-container>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button [mat-dialog-close]="value" color="primary" cdkFocusInitial>{{ new ? 'Add' : 'Save'}}</button>
</div>