import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'visit-matchings-table',
  templateUrl: './visit-matchings-table.component.html',
  styleUrls: ['./visit-matchings-table.component.scss']
})
export class VisitMatchingsTableComponent {


  private _allMatchings: any[];
  private _matchingKeys: string[];
  public matchingList: any[] = [];

  @Input()
  public set allMatchings(values: any[]) {
    if (values) {
      this._allMatchings = values;
    } else {
      this._allMatchings = [];
    }
    this._matchingsList();
  }

  @Input()
  public set matchingKeys(values: string[]) {
    this._matchingKeys = values;
    this._matchingsList();
  }

  constructor() { }

  private _matchingsList() {
    this.matchingList = [];
    if (this._matchingKeys) {
      for (const match of this._allMatchings) {
        if (this._matchingKeys.includes(match.$key)) {
          this.matchingList.push(match);
        }
      }
    }
  }
}
