import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SelectService } from 'src/common/services/select/select.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { ActionEditComponent } from 'src/components/actions-list/action-edit/action-edit.component';
import { Action } from 'src/services/action/action.model';
import { ActionService } from 'src/services/action/action.service';

@Component({
  selector: 'mandate-r2',
  templateUrl: './r2.component.html',
  styleUrls: ['./r2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class R2Component extends ActionEditComponent {

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ActionService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public dialog: MatDialog,
    public backURL: BackurlService,
  ) {
    super(_cd, setting, db, select, route, router, dialog, backURL);
  }

  get actionSynap(): any {
    return { 'mandatesActions': { [this.moduleItem.values.$key]: this.moduleItem.createAlias(['unitMandates']) } };
  }

  protected _pushModuleItemData() {
    this.moduleItem.modifyData('temperature', this.item.values.data.temperature);

    this.db.createAction(Action, 'signMandate', [{ item: this.moduleItem }],
      {
        realtor: this.moduleItem.values.realtor,
        agency: this.moduleItem.values.agency,
        data: {
          temperature: this.item.values.data.temperature,
          price: this.item.values.data.price,
          // priceFurniture: this.item.values.data.priceFurniture,
          cy: this.item.values.data.cy,
          comPerc: this.moduleItem.values.type === 'sale' ? 2 : 100,
        }
      }
    );
  }
}
