<mat-card class="hcard" *ngIf="mandate">
    <div class=img>
        <img [src]="mandate.photo | thumb:'400'" />
    </div>
    <div class="desc">
        <mat-card-title>{{mandate.city}} <span *ngIf="mandate.residence">- {{mandate.residence}}</span></mat-card-title>
        <!-- <mat-card-subtitle *ngIf="mandate.owners?.length">
            <ng-container *ngFor="let c of mandate.owners; let i = index">{{i > 0 ? ', ' : '' }}{{c.fullname}}
            </ng-container>
        </mat-card-subtitle> -->
        <mat-list class="h">
            <mat-list-item>
                <mat-icon matListIcon>{{GLOBAL.ICONS.BED}}</mat-icon>
                <h3 matLine>{{mandate.bedrooms}} </h3>
            </mat-list-item>
            <mat-list-item>
                <mat-icon matListIcon>{{GLOBAL.ICONS.BATH}}</mat-icon>
                <h3 matLine>{{mandate.bathrooms}} </h3>
            </mat-list-item>
            <mat-list-item *ngIf="mandate.type !== 'land'">
                <mat-icon matListIcon>{{GLOBAL.ICONS.SURFACE}}</mat-icon>
                <h3 matLine> {{(mandate.livingSurface ? mandate.livingSurface : mandate.builtSurface) | number}} m²</h3>
            </mat-list-item>
            <mat-list-item *ngIf="mandate.type == 'land'">
                <mat-icon matListIcon>{{GLOBAL.ICONS.SURFACE}}</mat-icon>
                <h3 matLine> {{mandate.landSurface | number}} {{mandate.landUnit | param:'landUnits'}} </h3>
            </mat-list-item>
        </mat-list>
        <div class="refprice">
            <span>NES{{mandate.ref}}</span>
            <h4 matLine>{{mandate.priceDisplay}}</h4>
        </div>
    </div>

</mat-card>