<!-- <div mat-dialog-content>
    <mat-form-field>
        <mat-label>Date</mat-label>
        <input matInput type="datetime-local" [(ngModel)]="date">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button [mat-dialog-close]="parseDateString(date)" cdkFocusInitial>Ok</button>
</div> -->
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>Date</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="selectedDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Time</mat-label>
        <mat-select [(ngModel)]="selectedTime">
            <mat-option value="00:00">No Time</mat-option>
            <mat-option *ngFor="let timeOption of timeOptions" [value]="timeOption">{{ timeOption }}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>