import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader } from '@agm/core';

declare var google: any;

@Component({
  selector: 'app-gmap',
  templateUrl: './gmap.component.html',
  styleUrls: ['./gmap.component.scss']
})
export class GmapComponent implements OnInit {


  public paramConf: any = {};
  public paramInit: any = {};

  @Input() options = {
    zoomFilter: 15,
  };


  @Input() set params(conf: any) {
    if (conf) {
      Object.assign(this.paramConf, conf);
      Object.assign(this.paramInit, conf);
    }
    else {
      this.paramConf = {
        'bounds': null,
        'lat': -20.2674,
        'long': 57.5766,
        'zoom': 10,
      }
      this.paramInit = {
        'bounds': null,
        'lat': -20.2674,
        'long': 57.5766,
        'zoom': 10,
      }
    }
  }

  @Input() markers;

  @Output() filter: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickFn: EventEmitter<any> = new EventEmitter<any>();
  @Output() overFn: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("searchgm")
  public searchElementRef: ElementRef;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    public ngZone: NgZone,
  ) {
  }

  ngOnInit(): void {
    // Object.assign(this._params, this.params);

    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["geocode"],
        componentRestrictions: { country: "mu" }
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          const place = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.paramConf.zoom = 13;
          this.paramConf.lat = place.geometry.location.lat();
          this.paramConf.long = place.geometry.location.lng();

        });
      });
    });
  }

  boundsChange(e) {
    // || !this.params.bounds
    this.paramConf.bounds = e;
    // if (this.params.zoom >= this.options.zoomFilter) {
    this.filter.emit(this.paramConf);
    // }
  }

  centerChange(e) {
    // || !this.params.bounds
    this.paramConf.lat = e.lat;
    this.paramConf.long = e.lng;
    // if (this.params.zoom >= this.options.zoomFilter) {
    this.filter.emit(this.paramConf);
    // }
  }

  zoomChange(e) {
    this.paramConf.zoom = e;
    // if (this.params.zoom >= this.options.zoomFilter) {
    this.filter.emit(this.paramConf);
    // }
  }

  mouseEnter(i) {
    // if (this.i.itemsFiltered[i]) {
    //   this.i.itemsFiltered[i].hover = true;
    //   if (this.i.itemsFiltered[i].address && this.i.itemsFiltered[i].address.gps) {
    //     // if (this.params.bounds && !this.params.bounds.contains(new google.maps.LatLng(this.i.itemsFiltered[i].address.gps.latitude, this.i.itemsFiltered[i].address.gps.longitude))) {
    //     //   this.sortLocalForm.controls.gmap.patchValue({ lat: this.i.itemsFiltered[i].address.gps.latitude, long: this.i.itemsFiltered[i].address.gps.longitude });
    //     // }
    //     this.agmmarker = { show: true, latitude: this.i.itemsFiltered[i].address.gps.latitude, longitude: this.i.itemsFiltered[i].address.gps.longitude }
    //   }
    // }
  }

  mouseLeave(i) {
    // if (this.i.itemsFiltered[i]) {
    //   this.i.itemsFiltered[i].hover = false;
    //   this.agmmarker.show = false;
    // }
  }

  markerClick(i) {
    this.clickFn.emit(i);
  }



  markerOver(i) {
    this.overFn.emit(i);
  }

  markerOut(i) {
    // this.i.itemsFiltered[i].hover = false;
  }

}
