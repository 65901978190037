import { Component, Input } from '@angular/core';

@Component({
  selector: 'new-mandate',
  templateUrl: './new-mandate.component.html',
  styleUrls: ['./new-mandate.component.scss']
})
export class NewMandateComponent  {

  @Input() action:any;
  constructor() { }


}
