
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit {


  @Input()
  edit: boolean = true;

  @Input()
  rating: number = 0;


  @Output()
  private ratingUpdated = new EventEmitter();

  public ratingArr = [];

  constructor() {
  }


  ngOnInit() {
    if (this.edit) {
      for (let index = 0; index < 5; index++) {
        this.ratingArr.push(index);
      }
    } else {
      for (let index = 0; index < this.rating; index++) {
        this.ratingArr.push(index);
      }
    }
  }
  onClick(rating: number) {
    this.ratingUpdated.emit(rating);
    return false;
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

}

