import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toarray'
})
export class ToarrayPipe implements PipeTransform {

  transform(value: any, order: boolean = false): any[] {
    let tmp = [];
    for (let key in value) {
      tmp.push(Object.assign({}, { key: key }, value[key]));
    }
    if (order) {
      tmp.sort((a: any, b: any) => { return a.order - b.order > 0 ? 1 : -1 });
    }
    return tmp;
  }

}
