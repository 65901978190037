import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ActionMainComponent } from '../action-main/action-main.component';
import { Project } from 'src/services/project/project.model';
import { Mandate } from 'src/services/mandate/mandate.model';
import { SettingService } from 'src/common/services/setting/setting.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { ActionSignLease } from 'src/services/action/actions/action-sign-lease.model';
import { SelectService } from 'src/common/services/select/select.service';
import { ActionService } from 'src/services/action/action.service';
import { ActionSignAgree } from 'src/services/action/actions/action-sign-agree.model';
import { ActionSignDeed } from 'src/services/action/actions/action-sign-deed.model';
import { MatDialog } from '@angular/material/dialog';
import { ActionOffer } from 'src/services/action/actions/action-offer.model';
import { Tools } from 'src/common/services/tools';
import { calculAgencyComSaleExclVat, calculAgencyComSaleInclVat } from 'functions/src/shared/tools';

@Component({
  selector: 'action-sign',
  templateUrl: './action-sign.component.html',
  styleUrls: ['./action-sign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionSignComponent extends ActionMainComponent {

  public tools = new Tools();
  public calculAgencyComSaleExclVat = calculAgencyComSaleExclVat;
  public calculAgencyComSaleInclVat = calculAgencyComSaleInclVat;

  @Input() set action(action: any) {
    this._action = action;
  }

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ActionService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public backURL: BackurlService,
    public dialog: MatDialog,
  ) {
    super(_cd, setting, db, select, route, router, backURL, dialog);
  }

  ngOnInit(): void {
    if (this._action && this._action.type) {
      this._$key = this._action.$key; // Use key from First Action Page

      if (this._action.type === 'offer') { // Choose the right Model
        this.item = new ActionOffer(null, this.setting);
      } else if (this._action.type === 'signLease') {
        this.item = new ActionSignLease(null, this.setting);
      } else if (this._action.type === 'signAgree') {
        this.item = new ActionSignAgree(null, this.setting);
      } else if (this._action.type === 'signDeed') {
        this.item = new ActionSignDeed(null, this.setting);
      }
    }
    this._mainNgOnInit();
  }


  acceptAction() {
    this._validAction(true);
  }

  refuseAction() {
    this._validAction(false);
  }

  private _validAction(accepted: boolean) {

    this.item.values.data.accepted = accepted;
    this.item.modify.data.accepted = true;
    this.item.modifyData('done', true);
    this.item.modifyData('doneDate', this.item.values.date);
    this.item.modifyData('actionUpdated', new Date());
    this.db.saveItem(this.item);

    this.goBack();
  }







}
