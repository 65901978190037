<mat-card class="mapselect">
    <agm-map style="height: 490px" [latitude]="params.lat" [longitude]="params.long" [scrollwheel]="true"
        [zoom]="params.zoom" [mapTypeControl]="false" [zoomControl]="false" [streetViewControl]="false">
        <agm-polygon [clickable]="edit" *ngFor="let region of regions.sectors; index as i" [paths]="region.paths" [fillColor]="region.color"
            [visible]="!region.deep" [zIndex]="1" [fillOpacity]="region.checked ? 0.8 : 0.2" (polyClick)="click([i])" (polyDblClick)="dblClick([i])">
            <ng-container *ngIf="region.deep">
                <agm-polygon *ngFor="let sector of region.sectors; index as j" [paths]="sector.paths" [fillColor]="sector.color"
                    [visible]="true" [zIndex]="1" [fillOpacity]="sector.checked ? 0.8 : 0.2"
                    (polyClick)="click([i, j])">
                </agm-polygon>
            </ng-container>
        </agm-polygon>
    </agm-map>
</mat-card>

