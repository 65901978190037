import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Action } from '../../services/action/action.model';
import { MainComponent } from '../../common/pages/main/main.component';
import { SelectService } from '../../common/services/select/select.service';
import { SettingService } from '../../common/services/setting/setting.service';
import { ActionService } from '../../services/action/action.service';
import { GlobalConstants } from '../../../functions/src/shared/global-constants';
import { BackurlService } from 'src/common/services/backurl/backurl.service';


@Component({
  selector: 'visit-report',
  templateUrl: './visit-report.component.html',
  styleUrls: ['./visit-report.component.scss']
})
export class VisitReportComponent extends MainComponent {

  public match: any = null;
  public GLOBAL = new GlobalConstants();
  private _allMatchings: any[];

  @Input()
  public set allMatchings(values: any[]) {
    if (values) {
      this._allMatchings = values;
    } else {
      this._allMatchings = [];
    }
    if (this.action.done) {
      this._getMatch();
    } else {
      this._refreshMatch();
    }
  }

  @Input()
  action: any

  @Output() doneFn: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ActionService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public dialog: MatDialog,
    public backURL: BackurlService,
  ) {
    super(_cd, setting, db, select, route, router, backURL, dialog);
    this.item = new Action(null, setting);
  }

  ngOnInit(): void {
    if (this.action && !this.action.done && this.action.$key) {
      this._$key = this.action.$key;

      this.new = false;

      this.db.initItem(this);
      this._cd.markForCheck();

      this._bindItem().then(() => {
        this.pageConfig.loaded = true;
        this._refreshMatch();
      });
    } else {
      this._getMatch();
    }
  }

  _afterBindUpdates() {
    this._refreshMatch();
  }


  saveAction() {
    this.db.saveItem(this.item);
  }

  validAction(com: boolean) {
    this.item.values.data.com = com;
    this.item.modify.data.com = true;
    this.item.values.done = true;
    this.item.modify.done = true;
    this.item.values.doneDate = new Date();
    this.item.modify.doneDate = true;
    this.db.saveItem(this.item);
    this.doneFn.emit(this.item.values);
  }

  private _refreshMatch() {
    if (this.item.values.synaps && this.item.values.synaps.visitReport && this._allMatchings) {
      for (const match of this._allMatchings) {
        if (this.item.values.synaps.visitReport[match.$key]) {
          this.match = match;
          return;
        }
      }
    }
  }

  private _getMatch() {
    if (this._allMatchings) {
      for (const match of this._allMatchings) {
        if (match.$key === this.action.matching) {
          this.match = match;
          return;
        }
      }
    }
  }
}

