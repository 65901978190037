import { Component, ChangeDetectorRef, Input } from '@angular/core';
import { MainComponent } from '../../common/pages/main/main.component';
import { Contact } from '../../services/contact/contact.model';
import { SettingService } from '../../common/services/setting/setting.service';
import { ContactService } from '../../services/contact/contact.service';
import { SelectService } from '../../common/services/select/select.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-contact-tab',
  templateUrl: './contact-tab.component.html',
  styleUrls: ['./contact-tab.component.scss']
})
export class ContactTabComponent extends MainComponent {

  public item: Contact;

  @Input()
  displayTelMail = true;

  @Input()
  model: string;

  @Input()
  modelKey: string;

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ContactService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public backURL: BackurlService,
    public dialog: MatDialog,
  ) {
    super(_cd, setting, db, select, route, router, backURL, dialog);
    this.item = new Contact(null, setting);
  }

  goItem() {
    if (this.model && this.modelKey && this.item.values.$key) {
      this.backURL.addURL(`/${this.model}/${this.modelKey}`);
      this.router.navigate([`/contacts/${this.item.values.$key}`]);
    }
  }
}
