import { Pipe, PipeTransform } from '@angular/core';
import { SettingService } from '../services/setting/setting.service';

@Pipe({
  name: 'icon'
})
export class IconPipe implements PipeTransform {

  constructor(
    private _setting: SettingService,
  ) {
  }

  transform(value: string, args: string): string {
    return this._setting.getParamIcon(args, value);
  }

}
