import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalConstants } from 'functions/src/shared/global-constants';
import { mergeDeep } from 'functions/src/shared/tools';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { Field, Item } from 'src/common/services/main/main.model';
import { PageConfig, MainService } from 'src/common/services/main/main.service';
import { SettingService } from 'src/common/services/setting/setting.service';

@Component({
  selector: 'app-card-edit',
  templateUrl: './card-edit.component.html',
  styleUrls: ['./card-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardEditComponent implements OnInit {

  public GLOBAL = new GlobalConstants();

  public fields: { [key: string]: Field } = {};
  public edit = false;


  @Input() item: Item;
  @Input() bckpValues: any;
  @Input() pageConfig: PageConfig;
  @Output() saved: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: MainService,
    protected router: Router,
    public backURL: BackurlService) {
  }


  ngOnInit(): void {
  }


  goItem(moduleName: string, targetItem: any) {
    if (targetItem && targetItem.$key) {
      this.backURL.addURL(`/${this.db.moduleListName}/${this.item.values.$key}`);
      this.router.navigate([`/${moduleName}/${targetItem.$key}`]);
    }
  }

  goTab(tab: number) {
    if (tab) {
      this.pageConfig.mainTab = tab;
    }
  }

  editCard() {
    this.edit = true;
  }

  cancelEdit() {
    this.item.values = mergeDeep({}, this.bckpValues);
    this._cd.markForCheck();
    this.item.modified = false;
    this.edit = false;
  }


  saveItem(): Promise<any> {
    this.edit = false;
    return this.db.saveItem(this.item).then(() => {
      this.item.modified = false;
      this.saved.emit();
    });
  }

  isOneOfRoles(roles: string[]): boolean {
    return this.db.isOneOfRoles(roles);
  }

  isRole(role: string): boolean {
    return this.db.isRole(role);
  }

  isRealtor(): boolean {
    return (this.isRole('realtor') && this.item.values.realtor === this.db.auth.uid) || this.isOneOfRoles(['admin', 'director', 'assistant']);
  }

  isAccountant(): boolean {
    return this.isOneOfRoles(['admin', 'accountant']);
  }

}
