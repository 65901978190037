import { Injectable } from '@angular/core';
import { MainService } from '../../common/services/main/main.service';
import { AuthService } from '../../common/services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Action } from './action.model';
import * as firebase from 'firebase';
import { MainItem } from 'src/common/services/main/main.model';
import { getActionIcon } from 'functions/src/shared/list';
import { SettingService } from 'src/common/services/setting/setting.service';

@Injectable({
  providedIn: 'root'
})
export class ActionService extends MainService {

  constructor(
    public auth: AuthService,
    public afs: AngularFirestore,
    public setting: SettingService,
  ) {
    super(auth, afs, 'action', 'actions', Action);

    this._deleteRoles = { all: true };
  }

  // user synap for matching item Modules
  private _actionSynap(moduleItems: { item?: MainItem, synap?: string, synapObj?: any }[]): any {

    const synaps: any = {};

    for (const moduleItem of moduleItems) {

      if (moduleItem.synapObj) {
        Object.assign(synaps, moduleItem.synapObj)
      }
      else if (moduleItem.item && moduleItem.item.module) {
        if (moduleItem.item.module === 'mandates')
          synaps.mandatesActions = { [moduleItem.item.values.$key]: moduleItem.item.createAlias(['unitMandates']) }
        else if (moduleItem.item.module === 'projects') {
          synaps.projectsActions = { [moduleItem.item.values.$key]: moduleItem.item.createAlias(['contactsProjects']) }
        } else if (moduleItem.item.module === 'matchings' && moduleItem.synap) {
          synaps[moduleItem.synap] = { [moduleItem.item.values.$key]: moduleItem.item.createAlias([]) }
        }
      }
    }
    return synaps;
  }



  public async createAction(
    itemClass = Action,
    actionType: string,
    moduleItems: { item?: MainItem, synap?: string, synapObj?: any }[],
    newOptions: any = {},
  ) {

    const options = Object.assign({
      done: false,
      realtor: this.auth.uid,
      agency: this.auth.profile.values.agency,
      data: {},
      date: new Date(),
      syncList: false,
    }, newOptions);

    const actionItem = new itemClass(Object.assign(options.data, {
      type: actionType,
      date: options.date,
      done: options.done,
      doneDate: options.done ? new Date() : options.date,
      realtor: options.realtor,
      agency: options.agency,
      synaps: this._actionSynap(moduleItems),
    }), this.setting);

    if (options.syncList, this.items) {
      this._addCreatingAction(this.items, actionType);
    }
    return this.saveNewItem(actionItem);

  }

  _addCreatingAction(list: any[], actionType: string) {
    list.unshift({
      type: actionType,
      icon: getActionIcon(actionType),
      done: false,
      date: firebase.firestore.Timestamp.now(),
      creating: true,
    })
  }

}

@Injectable({
  providedIn: 'root'
})
export class ActionPageService extends MainService {

  constructor(
    public auth: AuthService,
    public afs: AngularFirestore,
  ) {
    super(auth, afs, 'action', 'actions', Action);

    this._deleteRoles = { all: true };
    this.initFilters('asc');
  }

  public initFilters(direction: 'asc' | 'desc' = 'desc') {
    // if (this.auth.isOneOfRoles(['assistant', 'admin'])) {
    //   this._filters = {
    //     search: '',
    //     server: {
    //       filters: { done: false, type: 'newRequest' },
    //       sort: { active: 'date', direction: direction },
    //     },
    //     local: {
    //     }
    //   };
    // } else {
    this._filters = {
      search: '',
      server: {
        filters: { done: false },
        realtor: this.selectRealtorFilter(),
        agency: this.selectAgencyFilter(),
        sort: { active: 'date', direction: direction },
      },
      local: {
        localSearch: '',
        filters: { type: 'N' },
      }
    };
    // }
  }

  // protected _afterRefreshList() {
  //   this.newRequests = [];
  //   this.discoveries = [];
  //   this.notAssigned = 0;
  //   for (const action of this.items) {
  //     if (action.type === 'newRequest') {
  //       this.newRequests.push(action);
  //       if (action.notAssigned) {
  //         this.notAssigned++;
  //       }
  //     } else if (action.type === 'discovery') {
  //       this.discoveries.push(action);
  //     }
  //   }
  // }

  // public updateSelectMatchingToMandateSynap(action: any) {
  //   if (action.type !== 'visit') { // type visitReport will be created to link matchings to mandates
  //     if (action.synaps && action.synaps[action.type]) {
  //       for (const matchingKey in action.synaps[action.type]) {
  //         const matching = action.synaps[action.type][matchingKey];

  //         this.afs.doc(`/db/modules/${this.moduleListName}/${action.$key}`).update({
  //           'synaps.mandatesActions': { [matching.mandateKey]: {} },
  //           listUpdated: firebase.firestore.FieldValue.serverTimestamp(),
  //         });
  //       }
  //     }
  //   }
  // }

}

@Injectable({
  providedIn: 'root'
})
export class MyActionService extends MainService {

  // newRequestsNb = 0;
  // newClientsNb = 0;
  // newClients = [];

  actionTypes: { [type: string]: number } = {};
  actionTypesArray: { type: string, nb: number, module: string, order: number, split: boolean }[];
  actionTime = {
    today: 0,
    overdue: 0,
    future: 0,
  }

  constructor(
    public auth: AuthService,
    public afs: AngularFirestore,
    public setting: SettingService,
  ) {
    super(auth, afs, 'action', 'actions', Action);

    this._deleteRoles = { all: true };
  }

  public initFilters() {
    this._filters = {
      search: '',
      server: {
        realtor: this.auth.uid,
        filters: { done: false },
        sort: { active: 'date', direction: 'asc' },
      },
      local: {
        filters: {
          time: 'today'
        }
      }
    };
  }


  protected _afterRefreshList() {

    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0); // Set time to midnight for accurate date comparisonjokyLEOVO

    this.actionTime = {
      today: 0,
      overdue: 0,
      future: 0,
    }

    for (const action of this.items) {

      if (action.date) {
        const inputDateMidnight = action.date.toDate();
        inputDateMidnight.setHours(0, 0, 0, 0); // Convert the input date to midnight for accurate date comparison

        if (inputDateMidnight.getTime() === currentDate.getTime()) {
          this.actionTime.today++;
        } else if (inputDateMidnight < currentDate) {
          this.actionTime.overdue++;
        } else {
          this.actionTime.future++;
        }
      }

    }

  }

  public refreshActionTypes(time = 'today') {
    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0); // Set time to midnight for accurate date comparison
    this.actionTypes = {};
    this.actionTypesArray = [];
    const moduleOrder = {
      'projects': 2,
      'mandates': 3,
      'others': 1,
    };

    const settingActionTypes = this.setting.getParamsObj('actionTypes');

    for (const action of this.items) {
      if (action.date) {
        const inputDateMidnight = action.date.toDate();
        inputDateMidnight.setHours(0, 0, 0, 0); // Convert the input date to midnight for accurate date comparison
        if (!time
          || time === ''
          || (time === 'today' && inputDateMidnight.getTime() === currentDate.getTime())
          || (time === 'overdue' && inputDateMidnight < currentDate)
          || (time === 'future' && inputDateMidnight > currentDate)) {
          if (settingActionTypes[action.type].info && settingActionTypes[action.type].info.fullname) { // Add Group Action
            if (settingActionTypes[action.type].info.fullname === 'projects') {
              this.actionTypes.projects ? this.actionTypes.projects++ : this.actionTypes.projects = 1;
            } else if (settingActionTypes[action.type].info.fullname === 'mandates') {
              this.actionTypes.mandates ? this.actionTypes.mandates++ : this.actionTypes.mandates = 1;
            }
          }
          this.actionTypes[action.type] ? this.actionTypes[action.type]++ : this.actionTypes[action.type] = 1;
        }
      }
    }

    for (const type in this.actionTypes) {
      if (this.actionTypes[type]) {
        this.actionTypesArray.push(
          {
            type: type,
            nb: this.actionTypes[type],
            module: settingActionTypes[type].info.fullname ? settingActionTypes[type].info.fullname : 'others',
            order: settingActionTypes[type].info.order,
            split: ['projects', 'mandates'].includes(type),
          }
        );
      }
    }

    this.actionTypesArray.sort((a, b) => {

      const moduleA = moduleOrder[a.module] || 4;
      const moduleB = moduleOrder[b.module] || 4;

      if (moduleA < moduleB) return -1;
      if (moduleA > moduleB) return 1;

      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;

      return 0;
    });

  }


  protected _localFilter() {

    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0); // Set time to midnight for accurate date comparison

    // FILTER BAR ONLY
    this.itemsFiltered = this.items.filter((v) => {

      if (this._filters.local.filters.type && this._filters.local.filters.type !== v.type) {
        return false;
      }
      // Simple Filters
      if (this._filters.local.filters.time && v.date) {
        const inputDateMidnight = v.date.toDate();
        inputDateMidnight.setHours(0, 0, 0, 0); // Convert the input date to midnight for accurate date comparison

        if (this._filters.local.filters.time === 'today') {
          return inputDateMidnight.getTime() === currentDate.getTime() ? true : false;
        } else if (this._filters.local.filters.time === 'overdue') {
          return inputDateMidnight < currentDate ? true : false;
        } else if (this._filters.local.filters.time === 'future') {
          return inputDateMidnight > currentDate ? true : false;
        }
      }

      return true;
    });

    this._localOrder();

    this.filterLoading = false;
  }

}

@Injectable({
  providedIn: 'root'
})
export class NewRequestService extends MainService {

  newRequests = [];
  notAssigned = 0;

  constructor(
    public auth: AuthService,
    public afs: AngularFirestore,
  ) {
    super(auth, afs, 'action', 'actions', Action);

    this._deleteRoles = { all: true };
  }

  public initFilters() {
    this._filters = {
      search: '',
      server: {
        filters: { done: false, type: 'newRequest', agency: this.selectAgencyFilter(), realtor: '' },
        sort: { active: 'date', direction: 'desc' },
      },
      local: {
      }
    };
  }

  protected _afterRefreshList() {
    this.notAssigned = 0;
    for (const action of this.items) {
      if (action.notAssigned) {
        this.notAssigned++;
      }
    }
  }


}
