import { MainItem, Field, Lang, Alert } from '../../common/services/main/main.model';
import { FileRecord } from '../../common/services/upload-file/upload-file-model';
import { SettingService } from '../../common/services/setting/setting.service';
import { slugify } from 'functions/src/shared/tools';


export class Blog extends MainItem {

    values: {
        $key: string,
        ref: number,
        created: firebase.firestore.FieldValue,
        createdBy: string,
        updated: firebase.firestore.FieldValue,
        updatedBy: string,
        alerts: Alert[],
        synaps: any,

        date: Date,
        image: FileRecord,
        online: boolean,
        sections: any[],
        // {
        //     [id: string]: {
        //         order: number,
        //         html: Lang,
        //         image: FileRecord,
        //         type: string
        //     }
        // },
        slug: Lang,
        title: Lang,
        topics: string[];
    }

    constructor(
        values: any = null,
        public setting?: SettingService,
    ) {
        super(
            'blogs',
            {
                date: new Field({ input: 'date', label: 'Date' }),
                image: new Field({ input: 'image' }),
                online: new Field({ input: 'boolean', label: 'Online' }),
                sections: new Field({
                    label: 'Sections',
                    input: 'arrayValue',
                    // input: 'dynSub',
                    // dynSub: {
                    //     html: new Field({ input: 'text', multiLang: true, label: 'Title' }),
                    //     type: new Field({ label: 'Type', input: 'select', inputOptions: { param: 'blogSectionTypes' } }),
                    //     image: new Field({ input: 'image' }),
                    //     imagePos: new Field({
                    //         label: 'Image Position', input: 'select', inputOptions: { param: 'blogImagePositions' },
                    //     }),
                    // },
                }),
                slug: new Field({
                    input: 'auto',
                    filters: { new: false, save: true },
                    autoFn: {
                        toFB: (values) => {
                            return {
                                en: slugify(this._slugBlog(values, 'en')),
                                fr: slugify(this._slugBlog(values, 'fr')),
                                de: slugify(this._slugBlog(values, 'de')),
                                ru: slugify(this._slugBlog(values, 'ru')),
                            }
                        },
                    }
                }),

                title: new Field({ multiLang: true, label: 'Main Title' }),
                topics: new Field({ label: 'Topics', input: 'select', inputOptions: { param: 'blogTopics', multi: true } }),



            },
            // Forms
            {
                main: {
                    title: null,
                    fields: ['date', 'online', 'topics', 'sections']
                },
                title: {
                    title: null,
                    fields: ['title']
                },
            },
            // Tables
            {
                main: {
                    title: 'Contact List',
                    columns: [
                        { field: 'icon', type: 'icon', label: '' },
                        { field: 'ref', type: 'string', label: 'Ref', options: { prefix: 'B' } },
                        { field: 'created', type: 'date', label: 'Created' },
                        { field: 'date', type: 'date', label: 'Date' },
                        { field: 'title', type: 'string', label: 'Title' },
                    ],
                },
            },
            // Config File
            {
                path: 'blogs/', filename: (data) => {
                    return (data.slug && data.slug.fr) ? data.slug.fr : `article-B${data.ref}`;
                }
            },
            setting,
        );
        if (values) {
            this.fromFB(values);
        }

    }


    private _createFullName(setting: SettingService, values): string {

        let fullname = '';
        if (values.title) {
            fullname += setting.getParamValueName('titles', values.title) + ' ';
        }
        if (values.firstname) {
            fullname += values.firstname;
        }
        if (values.firstname && values.lastname) {
            fullname += ' ';
        }
        if (values.lastname) {
            fullname += values.lastname;
        }
        if (values.nickname) {
            fullname += ' (' + values.nickname + ')';
        }
        return fullname;
    }

    private _slugBlog(blog: any, lang: string): string {
        let slug = '';

        if (blog.title && blog.title[lang]) {
            slug += blog.title[lang] + ' ';
        }

        slug += blog.ref;

        return slug;
    }

}