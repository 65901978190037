import { Component } from '@angular/core';
import { MainsComponent } from '../../common/pages/mains/mains.component';
import { SettingService } from '../../common/services/setting/setting.service';
import { UnitService } from '../../services/unit/unit.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { SelectService } from '../../common/services/select/select.service';
import { BackurlService } from 'src/common/services/backurl/backurl.service';

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.scss']
})
export class UnitsComponent extends MainsComponent {

  public filterBeds = [1, 2, 3, 4, 5];
  public preview: any = null;
  public paramInit: any = {};

  constructor(
    public setting: SettingService,
    public db: UnitService,
    public select: SelectService,
    protected router: Router,
    protected fb: FormBuilder,
    public backURL: BackurlService,
  ) {
    super(setting, db, select, router, fb, backURL);

    Object.assign(this.paramInit, this.db.itemsPageConfig.mapParams);

    this.sortServerForm = this.fb.group({
      'multiFilters': this.fb.group({
        'sector': [],
      }),
      'arrayFilters': this.fb.group({
        'mandateTypes': [],
      }),
    });

    this.sortLocalForm = this.fb.group({
      localSearch: [''],
      multiFilters: this.fb.group({
        'type': [],
        'condition': [],
      }),
      equalSup: this.fb.group({
        'bedrooms': [],
      }),
      budget: this.fb.group({
        saleMinPrice: [],
        saleMaxPrice: [],
        rentalMinPrice: [],
        rentalMaxPrice: [],
      }),
    });
  }



  goItem(item: any) {
    // Check if on preview first
    if (this.db.itemsPageConfig.sideTab === 1 && !this.select.active) {
      this.backURL.addURL(`/${this.db.moduleListName}/`);
      this.router.navigate([`/${this.db.moduleListName}/${item.$key}`]);
    } else {
      this.db.itemsPageConfig.sideTab = 1;
    }
  }

  toggleMap() {
    this.db.itemsPageConfig.map = !this.db.itemsPageConfig.map;
  }

  previewItem(item: any) {
    if (!(this.preview && item && item.$key === this.preview.$key)) {
      this.preview = null;
      setTimeout(() => {
        this.preview = item;
      }, 50);
    }
  }

}
