<mandate-hcard *ngIf="moduleType === 'projects' || !moduleType" [mandate]="action.mandate"
    class="cursor"></mandate-hcard>
<app-contact-card *ngIf="moduleType === 'mandates'" [contacts]="action.project?.contactsProjects"></app-contact-card>
<mat-card>
    <mat-card-content>
        <mat-list>
            <mat-list-item>
                <mat-icon matListIcon [ngClass]="action.accepted ? 'active' : 'hot'">
                    {{action.done ? (action.accepted ? 'thumb_up' : 'thumb_down') :
                    'thumbs_up_down'}}
                </mat-icon>
                <h3 matLine> <span *ngIf="action.type === 'offer'">{{action.done ? (action.accepted ? 'Offer Accepted' :
                        'Offer Refused') : 'Pending Offer'}}</span>
                    <span *ngIf="action.type === 'signLease'">{{action.done ? 'Lease Signed' : 'Pending Lease
                        Signature'}}</span>
                    <span *ngIf="action.type === 'SignAgree'">{{action.done ?'Sale Agreement Signed': 'Pending Sale
                        Agreement Signature'}}</span>
                    <span *ngIf="action.type === 'SignDeed'">{{action.done ? 'Title Deed Signed' : 'Pending Title Deed
                        Signature'}}</span>
                    : {{action.priceDisplay ? action.priceDisplay : 'No Amount'}}
                </h3>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
    <mat-card-content *ngIf="action.note">
        <p>{{action.note}}</p>
    </mat-card-content>
</mat-card>