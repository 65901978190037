<ng-container [ngSwitch]="action.type">
    <new-mandate *ngSwitchCase="'newMandate'" [action]="action"></new-mandate>
    <ng-container *ngSwitchCase="'newRequest'">
        <mandate-new-request *ngIf="moduleItem.module === 'mandates'" (click)="goItem('projects', action.project)"
            [action]="action"></mandate-new-request>
        <project-new-request *ngIf="moduleItem.module === 'projects'" [action]="action"> </project-new-request>
    </ng-container>

    <mandate-r1 *ngSwitchCase="'r1'" [action]="action" [moduleItem]="moduleItem" (doneFn)="doneFn($event)"></mandate-r1>
    <mandate-r2 *ngSwitchCase="'r2'" [action]="action" [moduleItem]="moduleItem" (doneFn)="doneFn($event)"></mandate-r2>
   
   
   
    <mandate-advertise *ngSwitchCase="'advertise'" [action]="action" [moduleItem]="moduleItem"
        (doneFn)="doneFn($event)"></mandate-advertise>
    <mandate-marketing-check *ngSwitchCase="'marketingCheck'" [action]="action" [moduleItem]="moduleItem"
        (doneFn)="doneFn($event)"></mandate-marketing-check>
    <visit-report *ngSwitchCase="'visitReport'" [action]="action" [allMatchings]="allMatchings"
        (doneFn)="doneFn($event)">
    </visit-report>






    <action-handover *ngSwitchCase="'handover'" [action]="action"></action-handover>
    <action-temperature *ngSwitchCase="'temperature'" [action]="action"></action-temperature>
    <action-realtor *ngSwitchCase="'accept'" [action]="action"></action-realtor>
    <action-realtor *ngSwitchCase="'pause'" [action]="action"></action-realtor>
    <action-realtor *ngSwitchCase="'active'" [action]="action"></action-realtor>
    <price *ngSwitchCase="'price'" [action]="action" [moduleItem]="moduleItem" (doneFn)="doneFn($event)"></price>
    <ng-container *ngSwitchCase="'failed'">
        <mandate-failed *ngIf="moduleItem.module === 'mandates'" [action]="action"></mandate-failed>
        <project-failed *ngIf="moduleItem.module === 'projects'" [action]="action"></project-failed>
    </ng-container>
    <project-discovery *ngSwitchCase="'discovery'" [action]="action" [moduleItem]="moduleItem"
        (doneFn)="doneFn($event)"></project-discovery>
    <payment *ngSwitchCase="'payment'" [action]="action" [moduleItem]="moduleItem" (doneFn)="doneFn($event)"></payment>
    <ng-container *ngSwitchCase="'visit'">
        <visit-matchings-table *ngIf="action.done" [allMatchings]="allMatchings"
            [matchingKeys]="action.matchings"></visit-matchings-table>
        <visit-edit *ngIf="!action.done" [newAction]="newAction" [action]="action" [allMatchings]="allMatchings"
            (doneFn)="doneFn($event)" (addFn)="addFn($event)">
        </visit-edit>
    </ng-container>

    <!-- ACTION PAGES -->

    <sign *ngSwitchCase="'offer'" class="cursor" [moduleType]="moduleItem.module" [action]="action" (click)="goItem('tasks', action)">
    </sign>
    <sign *ngSwitchCase="'signAgree'" class="cursor" [moduleType]="moduleItem.module" [action]="action" (click)="goItem('tasks', action)">
    </sign>
    <sign *ngSwitchCase="'signDeed'" class="cursor" [moduleType]="moduleItem.module" [action]="action" (click)="goItem('tasks', action)">
    </sign>
    <sign *ngSwitchCase="'signLease'" class="cursor" [moduleType]="moduleItem.module" [action]="action" (click)="goItem('tasks', action)">
    </sign>
    <mandate-sign *ngSwitchCase="'signMandate'" class="cursor" [action]="action" (click)="goItem('tasks', action)"></mandate-sign>

    <!-- All other Actions -->
    <ng-container *ngSwitchDefault>
        <mat-card *ngIf="action.done && action.type !== 'NACall'">
            <mat-card-content *ngIf="action.note">
                <p>{{action.note}}</p>
            </mat-card-content>
        </mat-card>
        <action-edit [action]="action" (doneFn)="doneFn($event)" *ngIf="!action.done"></action-edit>
    </ng-container>
</ng-container>