<ng-container *ngIf="edit">
    <button mat-icon-button color="accent" *ngFor="let ratingId of ratingArr;index as i"
        (click)="onClick(i+1)">
        <mat-icon>
            {{showIcon(i)}}
        </mat-icon>
    </button>
</ng-container>

<ng-container *ngIf="!edit && rating">
    <mat-icon color="accent" *ngFor="let ratingId of ratingArr;index as i">
        {{showIcon(i)}}
    </mat-icon>
</ng-container>