import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MainsComponent } from 'src/common/pages/mains/mains.component';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SelectService } from 'src/common/services/select/select.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { NewRequestService } from 'src/services/action/action.service';

@Component({
  selector: 'app-assigned-leads',
  templateUrl: './assigned-leads.component.html',
  styleUrls: ['./assigned-leads.component.scss']
})
export class AssignedLeadsComponent extends MainsComponent {

  public preview: any = null;
  public previewProject: any = null;
  public tableKey = 'newRequestRealtor';

  constructor(
    public setting: SettingService,
    public db: NewRequestService,
    public select: SelectService,
    protected router: Router,
    protected fb: FormBuilder,
    public backURL: BackurlService
  ) {
    super(setting, db, select, router, fb, backURL);
    this.sortServerForm = this.fb.group({
      agency: [this.db.selectAgencyFilter()],
      realtor: [''],
      filters: this.fb.group({
        done: [false],
        type: ['newRequest']
      }),
    });

  }

  // Blank function to avoid normal initialisation
  // ngOnInit(): void {
  // }

  addItem() {
    this.backURL.addURL('/assign-leads/');
    this.router.navigate([`/new-requests/0`]);
  }
  goItem(item: any) {
    this.backURL.addURL('/assign-leads/');
    if (item.notAssigned) {
      this.router.navigate([`/new-requests/${item.$key}`]);
    } else {
      this.router.navigate([`/projects/${item.project.$key}`]);
    }
  }

  // goProject(project: any) {
  //   this.router.navigate([`/projects/${project.$key}`]);
  // }

  previewItem(item: any) {
    if (!(this.preview && item && item.mandate && item.mandate.$key === this.preview.$key)) {
      this.preview = null;
      setTimeout(() => {
        this.preview = item.mandate;
      }, 50);
    }
  }
}