import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SelectService } from 'src/common/services/select/select.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { ActionEditComponent } from 'src/components/actions-list/action-edit/action-edit.component';
import { Action } from 'src/services/action/action.model';
import { ActionService } from 'src/services/action/action.service';

@Component({
  selector: 'mandate-advertise',
  templateUrl: './advertise.component.html',
  styleUrls: ['./advertise.component.scss']
})
export class AdvertiseListActionComponent extends ActionEditComponent {

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ActionService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public dialog: MatDialog,
    public backURL: BackurlService,
  ) {
    super(_cd, setting, db, select, route, router, dialog, backURL);
  }

  get actionSynap(): any {
    return { 'mandatesActions': { [this.moduleItem.values.$key]: this.moduleItem.createAlias(['unitMandates']) } };
  }


  protected _pushModuleItemData() {
    this.moduleItem.modifyData('ad', !!this.item.values.data.ad);
    this.moduleItem.modifyData('priceVisible', !!this.item.values.data.priceVisible);
    this.moduleItem.modifyData('temperature', this.item.values.data.temperature);
    this.moduleItem.modifyData('temperature', this.item.values.data.temperature);

    this.moduleItem.values.portals = {};
    this.moduleItem.modify.portals = {};
    for (const portal in this.item.values.data.portals) {
      this.moduleItem.values.portals[portal] = !!this.item.values.data.portals[portal];
      this.moduleItem.modify.portals[portal] = true;
    }

    if (this.item.values.data.ad) {
      if (!this.moduleItem.values.marketingCheck) {
        this.db.createAction(Action, 'marketingCheck', [{ item: this.moduleItem }],
          { realtor: 'N1Cczjlbm7Nl0vkjF07Ze08L4Wv1', agency: this.moduleItem.values.agency });
      }
    }

  }
}