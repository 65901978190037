<mat-toolbar>

    <div>
        <button mat-icon-button (click)="goBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <div class="title">
            <mat-icon>{{GLOBAL.ICONS.LOGS}}</mat-icon>
            <ng-container>
                LOGS
            </ng-container>
        </div>
    </div>
    <profile></profile>

</mat-toolbar>
<div class="toolbartop"></div>

<table mat-table [dataSource]="logService.logs" class="mat-elevation-z8">

    <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <mat-icon matListIcon class="active" *ngIf="element.type === 'create'">{{GLOBAL.ICONS.NEW}}</mat-icon>
            <mat-icon matListIcon class="active" *ngIf="element.type === 'update'">{{GLOBAL.ICONS.EDIT}}</mat-icon>
            <mat-icon matListIcon class="active" *ngIf="element.type === 'view'">{{GLOBAL.ICONS.VIEW}}</mat-icon>
        </td>
    </ng-container>

    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element"> {{element.created.toDate() | date:'dd MMM yyyy HH:mm'}} </td>
    </ng-container>

    <ng-container matColumnDef="data">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element">
            {{element.data | json}}
        </td>
    </ng-container>

    <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef>User</th>
        <td mat-cell *matCellDef="let element">
            <img mat-card-avatar [src]="element.uid| realtor" [matTooltip]="element.uid | realtorName" />
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="tableCols; sticky: true">
    </tr>
    <tr mat-row *matRowDef="let row; let element; columns: tableCols;">
    </tr>
</table>