import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CardEditComponent } from 'src/common/components/card-edit/card-edit.component';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { SettingService } from 'src/common/services/setting/setting.service';
import { Tools } from 'src/common/services/tools';
import { ProjectService } from 'src/services/project/project.service';

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.scss']
})
export class ProjectEditComponent  extends CardEditComponent {

  public tools = new Tools();
  
  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ProjectService,
    protected router: Router,
    public backURL: BackurlService,
  ) {
    super(_cd, setting, db, router, backURL);
  }


}
