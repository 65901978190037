<table style="height: 100%;" mat-table [dataSource]="matchingList" class="mat-elevation-z8">

    <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <mat-icon matListIcon [ngClass]="element.mandate.iconColor">
                {{element.mandate.icon}}
            </mat-icon>
        </td>
    </ng-container>

    <ng-container matColumnDef="ref">
        <th mat-header-cell *matHeaderCellDef>Ref</th>
        <td mat-cell *matCellDef="let element"> NES{{element.mandate.ref}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let element">
            <mat-icon matListIcon>
                {{element.mandate.unitType | icon:'unitTypes'}}
            </mat-icon>
        </td>
    </ng-container>
    <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef>City</th>
        <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.mandate.residence">
                {{element.mandate.residence}}<br />
            </ng-container>
            {{element.mandate.city}}
        </td>
    </ng-container>
    <ng-container matColumnDef="bedrooms">
        <th mat-header-cell *matHeaderCellDef>Beds</th>
        <td mat-cell *matCellDef="let element">
            {{element.mandate.bedrooms}}
        </td>
    </ng-container>
    <ng-container matColumnDef="bathrooms">
        <th mat-header-cell *matHeaderCellDef>Baths</th>
        <td mat-cell *matCellDef="let element">
            {{element.mandate.bathrooms}}
        </td>
    </ng-container>
    <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef>Price</th>
        <td mat-cell *matCellDef="let element">
            {{element.mandate.priceDisplay}}
        </td>
    </ng-container>

    <!-- <tr mat-header-row
        *matHeaderRowDef="['icon','ref','city','type','bedrooms','bathrooms','price'];  sticky: true" >
    </tr> -->
    <tr mat-row
        *matRowDef="let row; let element; columns: ['icon','ref','city','type','bedrooms','bathrooms','price'];">
    </tr>
</table>

<!-- (mouseover)="overMatching(element)" -->