<mat-toolbar>

    <div>
        <button mat-icon-button (click)="goBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <div class="title">
            <mat-icon>{{GLOBAL.ICONS.STAT}}</mat-icon>
            <ng-container>
                STATS
            </ng-container>
        </div>
    </div>
    <profile></profile>

</mat-toolbar>
<div class="toolbartop"></div>

<div class="main-container-flex split s20">
    <div class="main">
        <ng-container *ngIf="series">
            <button mat-icon-button (click)="backToList()">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <ngx-charts-advanced-pie-chart [results]="statService.statChartSeries[series]">
            </ngx-charts-advanced-pie-chart>
        </ng-container>
        <ngx-charts-bar-vertical-stacked *ngIf="!series" [results]="statService.statChart" [gradient]="gradient"
            [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
            [animations]="animations" (select)="onSelectBar($event)">
        </ngx-charts-bar-vertical-stacked>
    </div>
    <div class="side">
        <form [formGroup]="sortLocalForm" novalidate>
            <mat-form-field appearance="fill">
                <mat-label>Start Report</mat-label>
                <mat-select formControlName="statReport">
                    <mat-option value="leads">
                        Leads
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Type</mat-label>
                <mat-select formControlName="type">
                    <mat-option [value]="null">
                        No Selection
                    </mat-option>
                    <mat-option value="sale">
                        Sale
                    </mat-option>
                    <mat-option value="rental">
                        Rental
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Agency</mat-label>
                <mat-select formControlName="agency">
                    <mat-option value="">
                        All Agencies
                    </mat-option>
                    <mat-option *ngFor="let param of 'userAgencies' | params" [value]="param.id">
                        {{param.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Realtor</mat-label>
                <mat-select formControlName="realtor">
                    <mat-option value="">
                        All Agents
                    </mat-option>
                    <mat-option *ngFor="let param of setting.realtorsAgency[sortLocalForm.controls['agency'].value]"
                        [value]="param.id">
                        {{param.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Year</mat-label>
                <mat-select formControlName="year">
                    <mat-option [value]="null">
                        No Selection
                    </mat-option>
                    <mat-option value="2018">
                        2018
                    </mat-option>
                    <mat-option value="2019">
                        2019
                    </mat-option>
                    <mat-option value="2020">
                        2020
                    </mat-option>
                    <mat-option value="2021">
                        2021
                    </mat-option>
                    <mat-option value="2022">
                        2022
                    </mat-option>
                    <mat-option value="2023">
                        2023
                    </mat-option>  <mat-option value="2024">
                        2024
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </form>
    </div>
</div>
<!-- <table mat-table [dataSource]="statService.statList" class="main">

    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element"> {{element.date}} </td>
    </ng-container>

    <ng-container *ngFor="let col of statService.statCols[groupStat]" [matColumnDef]="col.id">
        <th mat-header-cell *matHeaderCellDef>{{col.name}}</th>
        <td mat-cell *matCellDef="let element">
            {{element[col.id]}}
        </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="tableCols; sticky: true">
    </tr>
    <tr mat-row *matRowDef="let row; let element; columns: tableCols;">
    </tr>
</table> -->