<mat-card class="newrequest cursor" *ngIf="mandate" (click)="goItem()">
    <div class=img>
        <img [src]="mandate.photo | thumb:'400'" />
    </div>
    <div class="desc">
        <mat-card-title>{{mandate.city}} <span *ngIf="mandate.residence">- {{mandate.residence}}</span></mat-card-title>
        <!-- <mat-card-subtitle *ngIf="mandate.owners?.length">
            <ng-container *ngFor="let c of mandate.owners; let i = index">{{i > 0 ? ', ' : '' }}{{c.fullname}}
            </ng-container>
        </mat-card-subtitle> -->
        <mat-list class="h">
            <mat-list-item>
                <h3 matLine> NES{{mandate.ref}}</h3>
            </mat-list-item>
            <mat-list-item>
                <mat-icon *ngIf="mandate.bedrooms" matListIcon>{{GLOBAL.ICONS.BED}}</mat-icon>
                <h3 matLine>{{mandate.bedrooms}} </h3>
            </mat-list-item>
            <mat-list-item>
                <mat-icon *ngIf="mandate.bathrooms" matListIcon>{{GLOBAL.ICONS.BATH}}</mat-icon>
                <h3 matLine>{{mandate.bathrooms}} </h3>
            </mat-list-item>
            <mat-list-item>
                <ng-container *ngIf="mandate.landSurface && mandate.type !== 'land'">
                    <mat-icon matListIcon>{{GLOBAL.ICONS.SURFACE}}
                    </mat-icon>
                    <h3 matLine> {{(mandate.livingSurface ? mandate.livingSurface : mandate.builtSurface) | number}} m²
                    </h3>
                </ng-container>
            </mat-list-item>
            <mat-list-item>
                <ng-container *ngIf="mandate.landSurface && mandate.type == 'land'">
                    <mat-icon matListIcon>{{GLOBAL.ICONS.SURFACE}}</mat-icon>
                    <h3 matLine> {{mandate.landSurface | number}} {{mandate.landUnit | param:'landUnits'}} </h3>
                </ng-container>
            </mat-list-item>
        </mat-list>
        <div class="lastline">
            <div>
                <mat-icon>{{action.medium | icon:'mediums'}}</mat-icon>
                <span>{{action.origin}}</span>
            </div>
            <div class="price">
                <h4 matLine>{{mandate.priceDisplay}}</h4>
            </div>
        </div>
    </div>
</mat-card>

<mat-card class="nomandate" *ngIf="!mandate">
    <mat-list>
        <mat-list-item>
            <mat-icon *ngIf="action.medium" matListIcon>{{action.medium | icon:'mediums'}}</mat-icon>
            <h3 matLine>{{action.origin | param:'projectOrigins'}}</h3>
        </mat-list-item>
    </mat-list>
</mat-card>

<mat-card *ngIf="action.note">
    <mat-card-content>
        <p>{{action.note}}</p>
    </mat-card-content>
</mat-card>

<div class="actionButtons" *ngIf="!action.done && project?.status !== 'new'">
    <button mat-icon-button class="active" (click)="validAction($event)">
        <mat-icon>task_alt</mat-icon>
    </button>
</div>