import { Component, Input } from '@angular/core';
import { GlobalConstants } from 'functions/src/shared/global-constants';
import { AllMatchingService } from 'src/services/matching/all-matching.service';

@Component({
  selector: 'app-matchings-dash',
  templateUrl: './matchings-dash.component.html',
  styleUrls: ['./matchings-dash.component.scss']
})
export class MatchingsDashComponent {

  public GLOBAL = new GlobalConstants();

  @Input() showTitle = true;

  constructor(
    public allMatchings: AllMatchingService,
  ) { }



}
