import { Lang, MainItem, Field, Address, AddressFields, Alert } from '../../common/services/main/main.model';
import { FileRecord } from '../../common/services/upload-file/upload-file-model';
import { SettingService } from '../../common/services/setting/setting.service';
import { slugify, slugLangRes } from 'functions/src/shared/tools';
import * as firebase from 'firebase/app';

export class Residence extends MainItem {

    values: {
        $key: string,
        ref: number,
        created: firebase.firestore.FieldValue,
        createdBy: string,
        updated: firebase.firestore.FieldValue,
        updatedBy: string,
        alerts: Alert[],

        synaps: any,

        actionUpdated: Date,

        name: string;
        types: string[];
        morc: boolean;
        scheme: string;
        topFloor: string;
        landType: string;
        leasehold: boolean;

        address: Address;
        status: string;
        builtYear: number;
        totalUnits: number;

        // fullname: Lang;
        intro: Lang;
        snapshot: { [id: string]: { order: number, text: Lang } };
        descs: { [id: string]: { order: number, title: Lang, text: Lang } };
        slug: Lang;

        params: { [id: string]: string | boolean };
        equipment: { [id: string]: string | boolean };
        appliances: { [id: string]: string | boolean };
        security: { [id: string]: string | boolean };
        area: { [id: string]: string | boolean };

        photos: FileRecord[];
        vr: string;

        realtor: string,
        agency: string,
        ad: boolean;
        syncWeb: { [id: string]: { id: string, online: boolean, udpated: Date, errors: any } };
        error: boolean;
    }

    constructor(
        values: any = null,
        public setting: SettingService,
    ) {
        super(
            'residences',
            {
                actionUpdated: new Field({
                    input: 'date',
                }),
                name: new Field({ label: 'Short Name', validations: { required: true }, checks: { trim: true } }),
                types: new Field({
                    label: 'Type', input: 'select', inputOptions: { param: 'unitTypes', multi: true },
                    validations: { required: true }
                }),
                scheme: new Field({
                    label: 'Scheme', input: 'select', inputOptions: { param: 'residenceSchemes' },

                }),
                topFloor: new Field({
                    label: 'Top Floor', input: 'select', inputOptions: { param: 'unitFloors' },
                    show: (data: any) => data && data.types && (data.types.includes('apartment') || data.types.includes('office')),

                }),
                morc: new Field({
                    label: 'Morcellement',
                    input: 'boolean',
                    default: false,
                }),
                landType: new Field({
                    label: 'Land Type',
                    input: 'select',
                    inputOptions: { param: 'landTypes' },
                    validations: { required: true },
                    default: 'residential',

                }),
                leasehold: new Field({ label: 'Leasehold', input: 'boolean', }),
                address: new Field({
                    label: 'Address',
                    input: 'objectSub',
                    sub: new AddressFields(),

                }),
                totalUnits: new Field({
                    input: 'number', label: 'Nb of Units',
                }),
                status: new Field({
                    label: 'Status',
                    input: 'select',
                    inputOptions: {
                        param: 'residenceStatus',
                    },
                }),
                builtYear: new Field({
                    input: 'number', label: 'Delivery Year',
                }),
                params: new Field({
                    label: 'Main Param',
                    input: 'objectSub',
                    sub: {
                        commonPool: new Field({ label: 'Common Pool', input: 'boolean' }),
                        commonGym: new Field({ label: 'Common Gym', input: 'boolean' }),
                        commonTennis: new Field({ label: 'Common Tennis', input: 'boolean' }),
                        commonSpa: new Field({ label: 'Common Spa', input: 'boolean' }),
                        golf: new Field({ label: 'Golf', input: 'boolean' }),
                        marina: new Field({ label: 'Marina', input: 'boolean' }),
                    },

                }),
                equipment: new Field({
                    label: 'Equipment & Services',
                    input: 'objectSub',
                    sub: {
                        commonGenerator: new Field({ label: 'Common Generator', input: 'boolean', matching: true, icon: 'pool' }),
                        lift: new Field({ label: 'Lift', input: 'boolean' }),
                    },

                }),
                appliances: new Field({
                    label: 'Appliances',
                    input: 'objectSub',
                    sub: {
                        opticalFiber: new Field({ label: 'Optical Fiber', input: 'boolean' }),
                    },

                }),
                security: new Field({
                    label: 'Security',
                    input: 'objectSub',
                    sub: {
                        watchman: new Field({ label: 'Watchman', input: 'boolean' }),
                        securityGuard: new Field({ label: 'Security Guard', input: 'boolean' }),
                        secureParking: new Field({ label: 'Secured Parking', input: 'boolean' }),
                    },

                }),
                area: new Field({
                    label: 'Area',
                    input: 'objectSub',
                    sub: {
                        beachfront: new Field({ label: 'Seafront', input: 'boolean' }),
                        seaAccess: new Field({ label: 'Sea Access', input: 'boolean' }),
                        nearSchool: new Field({ label: 'Close to School', input: 'boolean' }),
                        nearBus: new Field({ label: 'Close to Bus Stop', input: 'boolean' }),
                        nearGolf: new Field({ label: 'Close to Golf', input: 'boolean' }),
                        nearHospital: new Field({ label: 'Close to Hospital', input: 'boolean' }),
                        nearShops: new Field({ label: 'Close to Shops', input: 'boolean' }),
                    },

                }),

                // fullname: new Field({ multiLang: true, label: 'Full Name', checks: { trim: true } }),
                intro: new Field({ multiLang: true, label: 'Intro' }),

                snapshot: new Field({
                    label: 'SnapShots',
                    input: 'dynSub',
                    dynSub: {
                        text: new Field({ multiLang: true, label: 'Text', checks: { trim: true } }),
                    },
                }),

                descs: new Field({
                    label: 'Paragraphs',
                    input: 'dynSub',
                    dynSub: {
                        title: new Field({ multiLang: true, label: 'Title' }),
                        text: new Field({ input: 'textarea', multiLang: true, label: 'Text' }),
                    },
                }),

                slug: new Field({
                    input: 'auto',
                    filters: { new: false, save: true },
                    autoFn: {
                        toFB: (values) => {
                            return {
                                en: slugify(slugLangRes(values, 'en')),
                                fr: slugify(slugLangRes(values, 'fr')),
                                de: slugify(slugLangRes(values, 'de')),
                                ru: slugify(slugLangRes(values, 'ru')),
                            }
                        }
                    },
                }),


                // params: { [id: string]: string | boolean };
                photos: new Field({ input: 'arrayValue' }),
                vr: new Field({ label: 'VR' }),
                ad: new Field({
                    label: 'Advertise',
                    input: 'boolean',
                    default: false,
                }),
                realtor: new Field({
                    label: 'Agent', input: 'select', inputOptions: { param: 'realtors' },
                    validations: { required: true },
                    show: (data, subData, setting) => {
                        return ['admin', 'director', 'assistant', 'marketing'].includes(setting.auth.profile.values.role)
                    }
                }),
                agency: new Field({
                    label: 'Agency', input: 'select', inputOptions: { param: 'tmp' },
                    validations: { required: true },
                    show: (data, subData, setting) => {
                        if (setting.show === false) {
                            setting.show = true;
                            return false;
                        }
                        return data.realtor && setting && setting.users && setting.users[data.realtor] && setting.users[data.realtor].agencies && setting.users[data.realtor].agencies.length > 1
                    }
                }),

            },
            // Forms
            {
                main: {
                    title: null,
                    fields: ['name', 'scheme', 'status', 'builtYear', 'types', 'morc', 'landType', 'leasehold', 'topFloor', 'totalUnits']
                },
                tab: {
                    title: null,
                    fields: ['morc', 'topFloor', 'landType', 'leasehold', 'builtYear']
                },
                address: {
                    title: null,
                    fields: ['address']
                },
                params: {
                    title: null,
                    fields: ['params']
                },
                equipment: {
                    title: null,
                    fields: ['equipment', 'appliances']
                },
                security: {
                    title: null,
                    fields: ['security']
                },
                area: {
                    title: null,
                    fields: ['area']
                },
                desc: {
                    title: null,
                    fields: ['intro', 'snapshot', 'descs']
                },
            },
            // Tables
            {
                main: {
                    title: 'Residence List',
                    columns: [
                        { field: 'icon', type: 'icon', label: '' },
                        { field: 'ref', type: 'string', label: 'Ref', options: { prefix: 'R' } },
                        { field: 'name', type: 'string', label: 'Name' },
                        { field: 'city', type: 'string', label: 'City' },
                        { field: 'schemeDisp', type: 'string', label: 'Scheme' },
                        { field: 'statusDisp', type: 'string', label: 'Status' },
                        { field: 'promoter', type: 'string', label: 'Promoter' },
                        { field: 'nb', type: 'string', label: 'Units' },
                        { field: 'realtor', type: 'user', label: 'Agent' },
                    ],
                },
                promoter: {
                    title: 'Residence List',
                    columns: [
                        { field: 'icon', type: 'icon', label: '' },
                        { field: 'ref', type: 'string', label: 'Ref', options: { prefix: 'R' } },
                        { field: 'name', type: 'string', label: 'Name' },
                        { field: 'city', type: 'string', label: 'City' },
                        { field: 'schemeDisp', type: 'string', label: 'Scheme' },
                        { field: 'statusDisp', type: 'string', label: 'Status' },
                        { field: 'nb', type: 'string', label: 'Units' },
                        { field: 'realtor', type: 'user', label: 'Agent' },
                    ],
                },
            },
            // Config File
            {
                path: 'residences/', filename: (data) => {
                    let filename = 'immobilier-ile-maurice-nestenn-';
                    filename += 'R' + data.ref;
                    return filename;
                }
            },
            setting,
        );
        if (values) {
            this.fromFB(values);
        }

    }


    public _checkAlerts(values: any, FBvalues: any) {

        const alerts: Alert[] = [];

        // ERRORS
        if (!(values.address && values.address.gps)) {
            alerts.push({ type: 'error', code: 'missingGPS' });
        }
        if (!(values.address && values.address.eCity)) {
            alerts.push({ type: 'error', code: 'missingCity' });
        }
        if (!values.landType) {
            alerts.push({ type: 'error', code: 'missingLandType' });
        }
        if (!values.totalUnits) {
            alerts.push({ type: 'error', code: 'missingTotalUnits' });
        }
        if (values.types && !values.types.length) {
            alerts.push({ type: 'error', code: 'missingTypes' });
        }
        if (values.types && values.types.includes('apartment') && !values.topFloor) {
            alerts.push({ type: 'error', code: 'missingTopFloor' });
        }
        if (!values.status) {
            alerts.push({ type: 'error', code: 'missingStatus' });
        }
        if (!values.builtYear) {
            alerts.push({ type: 'error', code: 'missingYear' });
        }



        FBvalues.alerts = alerts;
        FBvalues.updatedFields.alerts = firebase.firestore.FieldValue.serverTimestamp();

    }

    protected _fnGetAutoData(): void {
        if (this.values.synaps && this.values.synaps.residenceUnitsArray) {
            for (const i in this.values.synaps.residenceUnitsArray) {
                let activeMandate = false;
                if (this.values.synaps.residenceUnitsArray[i].synaps && this.values.synaps.residenceUnitsArray[i].synaps.unitMandatesArray) {
                    for (const j in this.values.synaps.residenceUnitsArray[i].synaps.unitMandatesArray) {
                        const mandate = this.values.synaps.residenceUnitsArray[i].synaps.unitMandatesArray[j]
                        if (mandate.status == 'active') {
                            activeMandate = true;
                        }
                    }
                }
                this.values.synaps.residenceUnitsArray[i].active = activeMandate;
            }
        }
        this.values.error = this._checkError(this.values);

    }

}

