<mat-toolbar class="mandatesbg">

    <div>
        <button mat-icon-button (click)="goBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <div class="title">
            <mat-icon [ngClass]="item.values | mandateIconColor">{{item.values.status | icon:'mandateStatus'}}
            </mat-icon>
            <ng-container *ngIf="!new">
                NES{{item.values.ref}}: {{item.values.exclusive ? 'Exclusive' : ''}}
                {{item.values.type | param:'mandateTypes'}} Mandate
                <ng-container *ngIf="item.values.price">: {{item.values.price | number}} {{item.values.cy |
                    param:'cies'}}{{item.values.type === 'rental' ? ' / month' : ''}}</ng-container>
            </ng-container>
            <ng-container *ngIf="new">New Mandate</ng-container>
        </div>
    </div>
    <div>
        <profile></profile>
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <ng-container
                *ngIf="isOneOfRoles(['admin', 'director', 'assistant', 'marketing']) || (isOneOfRoles(['realtor']) && item.values.realtor === db.auth.uid)">
                <button mat-menu-item *ngIf="item.values.status !== 'deal' && item.values.status !== 'failed'"
                    (click)="handover()">
                    <mat-icon>{{GLOBAL.ICONS.HANDOVER}}</mat-icon>
                    <span>Swap Agent</span>
                </button>
                <button *ngIf="item.values.status !== 'failed' && item.values.status !== 'deal'" mat-menu-item
                    (click)="failed()">
                    <mat-icon>{{GLOBAL.ICONS.FAILED}}</mat-icon>
                    <span>Failed</span>
                </button>
                <button *ngIf="item.values.status === 'failed' && isOneOfRoles(['admin', 'assistant', 'director'])"
                    mat-menu-item (click)="pause(false)">
                    <mat-icon class="active">{{GLOBAL.ICONS.ACTIVE}}</mat-icon>
                    <span>Active</span>
                </button>
                <button *ngIf="db.isOneOfRoles(['admin', 'director', 'assistant', 'marketing'])" mat-menu-item
                    color="primary" (click)="forceMandateSync()">
                    <mat-icon matListIcon>sync_problem</mat-icon>
                    <span>Force Portal Sync</span>
                </button>
            </ng-container>
            <button mat-menu-item (click)="logs()">
                <mat-icon>{{GLOBAL.ICONS.LOGS}}</mat-icon>
                <span>Logs</span>
            </button>
            <button mat-menu-item *ngIf="!new && !pageConfig.edit && isRole('admin')" (click)="db.deleteItem(item)">
                <mat-icon matListIcon>delete</mat-icon>
                <span>Delete Mandate!</span>
            </button>
        </mat-menu>
    </div>
</mat-toolbar>
<div class="toolbartop"></div>

<div class="mainPage">
    <img mat-card-avatar class="realtorPage" [src]="item.values.realtor | realtor"
        [matTooltip]="item.values.realtor | realtorName" *ngIf="db.auth.uid !== item.values.realtor" />
    <mat-tab-group [(selectedIndex)]="pageConfig.mainTab" (selectedTabChange)="tabChanged()">


        <!--------------------------------------------- OVERVIEW ----------------------------------------------------------->

        <mat-tab *ngIf="item.values.status !== 'new' && item.values.status !== 'pending'">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.DASHBOARD}}</mat-icon>&nbsp;
                Overview
            </ng-template>

            <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                    <div class="mdc-layout-grid__cell--span-3">
                        <mat-card class="backspace">
                            <mat-list>
                                <mat-list-item>
                                    <div matLine style="font-size: 35px;text-align: center;"
                                        [ngClass]="colorMandateDate()">
                                        {{tools.timeago(item.values.type === 'sale' ? item.values.dateStart :
                                        item.values.dateAvailable)}}</div>

                                </mat-list-item>
                            </mat-list>
                        </mat-card>
                        <app-mandate-edit [item]="item" [bckpValues]="bckpValues"></app-mandate-edit>
                        <app-agency-fee-mandate-edit [item]="item" [bckpValues]="bckpValues"
                            *ngIf="['payment', 'deal'].includes(item.values.status) && isOneOfRoles(['admin', 'accountant'])">
                        </app-agency-fee-mandate-edit>
                    </div>
                    <div class="mdc-layout-grid__cell--span-6">
                        <div class="mdc-layout-grid__inner">
                            <div class="mdc-layout-grid__cell--span-12">

                                <!-- Display Last Action -->
                                <div class="actionDash" *ngIf="displayAction">
                                    <div class="date cursor" (click)="goTab(1)">
                                        <div class="actionRealtor">
                                            <button mat-mini-fab [color]="displayAction.done ? 'primary' : 'accent'"
                                                style="cursor:none">
                                                <mat-icon>{{displayAction.icon}}</mat-icon>
                                            </button>
                                            <img mat-card-avatar [src]="displayAction.realtor | realtor"
                                                [matTooltip]="displayAction.realtor | realtorName" />
                                        </div>
                                        <div class="txt">
                                            {{displayAction.date?.toDate() | date:'dd MMM yy'}}<br />
                                            {{displayAction.date?.toDate() | date:'HH:mm'}}
                                        </div>
                                    </div>
                                    <div class="panel">
                                        <mat-spinner [strokeWidth]="3" [diameter]="70"
                                            *ngIf="displayAction.creating"></mat-spinner>
                                        <action-select *ngIf="!displayAction.creating" [action]="displayAction"
                                            [moduleItem]="item" [allMatchings]="matching.items"
                                            (selectDoneFn)="actionDone($event)"></action-select>
                                    </div>
                                </div>
                            </div>

                            <div class="mdc-layout-grid__cell--span-6">
                                <mat-card class="backspace">
                                    <!-- ACTIONS -->
                                    <mat-list>
                                        <mat-list-item *ngFor="let action of dashDisplayActions">
                                            <mat-icon matListIcon
                                                [color]="dashActions[action].nb ? 'primary' : ''">{{dashActions[action].icon}}</mat-icon>
                                            <div matLine>
                                                <b>{{dashActions[action].nb}} {{dashActions[action].label}}</b>
                                            </div>
                                            <div matLine>
                                                <i *ngIf="dashActions[action].timeago.length">
                                                    {{dashActions[action].timeago}}</i>
                                            </div>
                                        </mat-list-item>
                                    </mat-list>
                                </mat-card>


                                <!-- VISIT -->
                                <app-organise-visit-edit [item]="item"
                                    [bckpValues]="bckpValues"></app-organise-visit-edit>
                            </div>
                            <div class="mdc-layout-grid__cell--span-6">
                                <!-- Display Stats -->
                                <mat-card class="backspace ">
                                    <mat-list>
                                        <mat-list-item *ngIf="matchNb.new" class="cursor" (click)="moveTab(3)">
                                            <mat-icon matListIcon color="warn">{{GLOBAL.ICONS.NEW}}</mat-icon>
                                            <div matLine style="font-size: 25px;text-align: center;">
                                                {{matchNb.new}} New Matchings</div>
                                        </mat-list-item>
                                        <mat-list-item class="cursor" (click)="moveTab(2)">
                                            <mat-icon matListIcon>{{GLOBAL.ICONS.MATCHING}}</mat-icon>
                                            <div matLine style="font-size: 25px;text-align: center;">
                                                {{matchNb.match}}/{{matchNb.total}} Matchings</div>
                                        </mat-list-item>
                                    </mat-list>
                                    <mat-list *ngIf="item.values.matching" style="position:relative">
                                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                                    </mat-list>
                                </mat-card>
                                <!-- PORTALS -->
                                <app-advertise-edit *ngIf="showPortalDash && item.values.$key" [item]="item"
                                    [bckpValues]="bckpValues" [unitPortals]="unitPortals"></app-advertise-edit>
                            </div>
                        </div>
                    </div>
                    <div class="mdc-layout-grid__cell--span-3" *ngIf="unit && unit.values">
                        <mat-card class="backspace" *ngIf="unit.values.synaps?.ownersArray">
                            <mat-list>
                                <mat-list-item class="cursor" *ngFor="let contact of unit.values.synaps.ownersArray"
                                    (click)="goItem('contacts', contact)">
                                    <mat-icon matListIcon>{{GLOBAL.ICONS.CONTACT}}</mat-icon>
                                    <h3 matLine> {{contact.fullname}} </h3>
                                </mat-list-item>
                            </mat-list>
                        </mat-card>
                        <app-unit-card [values]="item.values.synaps.unitMandatesObjList"
                            (click)="goItem('units', item.values.synaps.unitMandatesObj)" class="cursor">
                        </app-unit-card>
                    </div>
                </div>
            </div>

        </mat-tab>


        <!--------------------------------------------- TIMELINE ----------------------------------------------------------->

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.SUMMARY}}</mat-icon>&nbsp;
                Timeline
            </ng-template>

            <div class="container split s25">
                <div class="main">
                    <div class="action" *ngFor="let action of actionService?.items">
                        <div class="date">
                            <div class="txt">
                                {{action.date?.toDate() | date:'dd MMM yy'}}<br />
                                {{action.date?.toDate() | date:'HH:mm'}}
                            </div>
                            <div class="actionRealtor">
                                <button mat-mini-fab [color]="action.done ? 'primary' : 'accent'"
                                    (click)="copyClipboard(action.$key)" style="cursor:none">
                                    <mat-icon>{{action.icon}}</mat-icon>
                                </button>
                                <img mat-card-avatar [src]="action.realtor | realtor"
                                    [matTooltip]="action.realtor | realtorName" (click)="copyClipboard(action.$key)" />
                            </div>
                        </div>
                        <div class="panel">
                            <mat-spinner [strokeWidth]="3" [diameter]="70" *ngIf="action.creating"></mat-spinner>
                            <action-select *ngIf="!action.creating" [action]="action" [moduleItem]="item"
                                [allMatchings]="matching.items" (selectDoneFn)="actionDone($event)"></action-select>
                        </div>
                    </div>
                </div>
                <div class="side">
                    <mat-card class="backspace" *ngIf="unit.values.synaps?.ownersArray">
                        <mat-list>
                            <mat-list-item class="cursor" *ngFor="let contact of unit.values.synaps.ownersArray"
                                (click)="goItem('contacts', contact)">
                                <mat-icon matListIcon>{{GLOBAL.ICONS.CONTACT}}</mat-icon>
                                <h3 matLine> {{contact.fullname}} </h3>
                            </mat-list-item>
                        </mat-list>
                    </mat-card>
                    <app-unit-card *ngIf="item.values.synaps?.unitMandatesObjList"
                        [values]="item.values.synaps.unitMandatesObjList"
                        (click)="goItem('units', item.values.synaps.unitMandatesObj)" class="cursor">
                    </app-unit-card>
                </div>
            </div>

        </mat-tab>


        <!--------------------------------- MATCHING DETAILS ------------------------------------------------------>


        <mat-tab *ngIf="item.values.status !== 'new' && item.values.status !== 'pending'">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.MATCHING}}</mat-icon>&nbsp;
                {{matchNb.match}}/{{matchNb.total}} Matchings
            </ng-template>
            <project-matchings [matching]="matching" [status]="item.values.status" [matchOnly]="matchOnly">
            </project-matchings>
        </mat-tab>
        <mat-tab *ngIf="matchNb.new">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon" color="warn">{{GLOBAL.ICONS.NEW}}</mat-icon>&nbsp;
                {{matchNb.new}} New Matchings
            </ng-template>
            <new-project-matchings [matching]="matching" (doneFn)="backToMatching()"></new-project-matchings>
        </mat-tab>



    </mat-tab-group>

    <button *ngIf="item.values.status === 'new'" mat-fab color="primary" class="bottom-right pfixed"
        (click)="acceptMandate()">
        <mat-icon matListIcon>thumb_up</mat-icon>
    </button>

    <app-speed-dial-fab *ngIf="isRealtor() && item.values.status !== 'new'" [fabButtons]="actionButtons"
        (clickFn)="createActionMenu($event)">
    </app-speed-dial-fab>

</div>


<mat-spinner [strokeWidth]="5" class="fixed" *ngIf="!(pageConfig.loaded || new)"></mat-spinner>