import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toarraydyn'
})
export class ToarraydynPipe implements PipeTransform {

  transform(value: any, data: any): any[] {
    let tmp = [];
    for (let key in value) {
      tmp.push(Object.assign({}, { key: key }, { order: (data[key] && data[key].order) ? data[key].order : 1 }, value[key]));
    }

    tmp.sort((a: any, b: any) => { return a.order - b.order > 0 ? 1 : -1 });

    return tmp;
  }

}
